export interface IDropdownSelectBox {
    id: string;
    name: string;
    label?: string;
    placeholder: string
    className?: string;
    value?: IDropdownSelectOption[];
    isDisabled?: boolean;
    isRequired?: boolean;
    allowSelectAll: boolean;
    options: IDropdownSelectOption[];

    inputState?: React.Dispatch<React.SetStateAction<any>>;
    validState?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IDropdownSelectOption {
    label: string;
    value: string;
}

export function LoadDropdownOptionFromEnum<E>(Enum: E): IDropdownSelectOption[] {
    return Object.keys(Enum).filter(key => isNaN(Number(key)))
        .map(key => (
            { label: key.replace(/_/g, " "), value: (Enum as any)[key] }
        ));
}