import { AccumulatorModel, AccumulatorListModel } from "../../../dto/models/JackpotModels";

export interface IAccumulatorState {
    loading: boolean;
    err: string;
    suc: string;
    accumulatorListData: AccumulatorListModel;
    accumulatorData: AccumulatorModel;
}


export const InitAccumulatorState: IAccumulatorState = {
    loading: false,
    err: "",
    suc: "",
    accumulatorListData: {} as AccumulatorListModel,
    accumulatorData: {} as AccumulatorModel,
}
