import { faArrowsRotate, faCheck, faFloppyDisk, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DropdownItem } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import { ModalType } from '../../components/ModalBox/IModalBox';
import ModalBox from '../../components/ModalBox/ModalBox';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import GameServiceMaintenanceModal from '../../components/_modals/Game/GameServiceMaintenanceModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { TableStatus } from '../../dto/enums/TableStatus';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetGameServiceRequest, RefreshGameService, SearchGameService, UpdateAllGameServiceStatus, UpdateGameServiceStatus } from '../../states/GameService/GameServiceActions';
import { IStore } from '../../states/store/IStore';

const GameServiceMaintenance = () => {
    const dispatch = useDispatch();
    const gameServiceState = useSelector((state: IStore) => state.gameServiceState);
    const { userProfileData } = useSelector((state: IStore) => state.userState);
    const { loading, err, suc, gameServiceList, requireReload } = gameServiceState;

    // main screen state
    const [currentPage, setCurrentPage] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    // edit / add modal state
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [id, setId] = useState(0);
    // status model status
    const [hasEnableRight, setHasEnableRight] = useState(false);
    const [singleServicedialog, setSingleServiceDialog] = useState(false);
    const [allServicedialog, setAllServiceDialog] = useState(false);
    const [stopID, setstopID] = useState(0);

    useEffect(() => {
        if (!openModal) {
            setstopID(0);
            dispatch(GetGameServiceRequest(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    useEffect(() => {
        if (requireReload) {
            dispatch(GetGameServiceRequest(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [requireReload]);

    useEffect(() => {
        if (userProfileData && userProfileData.acc) {
            setHasEnableRight(HASFLAG(userProfileData.acc.bow, BackOfficeAccess.TERMINATE_GAME_SERVICE));
        }
    }, [userProfileData]);

    const EditGameService = (id: number): void => {
        setIsEdit(true);
        setId(id);
        setOpenModal(true);
    }

    const AddGameService = (): void => {
        setIsEdit(false);
        setId(0);
        setOpenModal(true);
    }

    const search = (): void => {
        setCurrentPage(0);
        dispatch(SearchGameService(searchInput, 0, TABLE_MAX_ROW));
        setIsSearch(true);
    }

    const take = (page: number) => {
        setCurrentPage(page);
        dispatch(GetGameServiceRequest(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const Refresh = (id: number): void => {
        var refreshButton = document.getElementById(id + "RefreshButton")
        if (refreshButton) {
            (refreshButton as HTMLButtonElement).disabled = true
        }

        var refreshButtonIcon = document.getElementById(id + "RefreshButtonIcon")
        if (refreshButtonIcon) {
            (refreshButtonIcon as HTMLButtonElement).classList.add("rotate")
        }

        dispatch(RefreshGameService(id))
    }

    const Update = (id: number, stop: boolean): void => {
        if (!stop) {
            dispatch(UpdateGameServiceStatus(id, stop));
        }
        else {
            setstopID(id);
            setSingleServiceDialog(true);
        }
    }

    const pagination: ITablePagination = {
        id: "gameServicePagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: gameServiceList.ttc ?? 0
    };

    const gameServiceListTable = (): IDataTable => {
        var table: IDataTable = {
            title: ["No.", "Name", "Game", "External Gateway Listener", "Internal Gateway Listener", "Status", "Action", "View", "Refresh"],
            data: [],
            isStriped: false,
            isHover: true,
            tableStyle: TableStyle.ALL_BORDER,
            isloading: loading
        };

        if (gameServiceList.sgl) {
            if (gameServiceList.sgl.length > 0 && gameServiceList.sgl[0].sgc) {
                const startIndex = currentPage * TABLE_MAX_ROW;
                table.data = gameServiceList.sgl.map((item, index) => {
                    return [
                        startIndex + index + 1,
                        item.snm,
                        item.sgc.gmn,
                        item.lil,
                        item.pyl,
                        TableStatus[item.stt].toString().replace("_", " "),
                        <div>
                            {
                                hasEnableRight ?
                                    <Button onClick={() => { Update(item.sid, item.stt == TableStatus.ACTIVE) }} color={item.stt == TableStatus.ACTIVE ? "warning" : "success"} >
                                        {
                                            item.stt == TableStatus.ACTIVE ?
                                                <div className="text-nowrap"><FontAwesomeIcon icon={faXmark} /> Disable</div>
                                                :
                                                <div className="text-nowrap"><FontAwesomeIcon icon={faCheck} />  Enable</div>
                                        }
                                    </Button>
                                    : ""
                            }
                        </div>,
                        <Button onClick={() => EditGameService(item.sid)}><FontAwesomeIcon icon={faPenToSquare} /></Button>,
                        <Button id={item.sid + "RefreshButton"} name="refresh-button"
                            onClick={() => { Refresh(item.sid) }}
                            color="primary" disabled={loading} >
                            <FontAwesomeIcon id={item.sid + "RefreshButtonIcon"} name="refresh-button-icon" icon={faArrowsRotate} />
                        </Button>
                    ];
                });
            }
        }

        return table;
    }

    const dialogBoxContent = () => {
        return (
            <div>
                <p>Disable the Game Service will cause the Game Stop Operating. < br /> Are you sure ?</p >
            </div>
        );
    }

    const dialogSingleServiceButton = () => {
        return (
            <div>
                <Button
                    color="info"
                    onClick={() => {
                        dispatch(UpdateGameServiceStatus(stopID, true));
                        setSingleServiceDialog(false);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faFloppyDisk} /> Confirm
                </Button>
                {' '}
                <Button
                    color="secondary"
                    onClick={() => {
                        setSingleServiceDialog(false);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faXmark} /> Cancel
                </Button>
            </div>
        );
    }

    const dialogAllServiceButton = () => {
        return (
            <div>
                <Button
                    color="info"
                    onClick={() => {
                        dispatch(UpdateAllGameServiceStatus(true));
                        setAllServiceDialog(false);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faFloppyDisk} /> Confirm
                </Button>
                {' '}
                <Button
                    color="secondary"
                    onClick={() => {
                        setAllServiceDialog(false);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faXmark} /> Cancel
                </Button>
            </div>
        );
    }

    const dropDownActionButtons = (): JSX.Element[] => {
        const dropdownButtons = [
            <DropdownItem color="success" key="0" onClick={() => dispatch(UpdateAllGameServiceStatus(false))} ><FontAwesomeIcon icon={faCheck} />  Enable All</DropdownItem>,
            <DropdownItem color="warning" key="1" onClick={() => setAllServiceDialog(true)}><FontAwesomeIcon icon={faXmark} /> Disable All</DropdownItem>
        ]

        return dropdownButtons;
    }

    const clear = (): void => {
        setCurrentPage(0);
        dispatch(GetGameServiceRequest(0, TABLE_MAX_ROW));
        setIsSearch(false);
        setSearchInput("")
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.GAME_SERVICE_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <GameServiceMaintenanceModal isEdit={isEdit} setOpenState={setOpenModal} isOpen={openModal} id={id} />
            <ModalBox
                title={"Warning"}
                isOpen={singleServicedialog}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                child={dialogBoxContent()}
                type={ModalType.Dialog}
                openState={setSingleServiceDialog}
                footer={dialogSingleServiceButton()}
                error={""}
                success={""}
            />
            <ModalBox
                title={"Warning"}
                isOpen={allServicedialog}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                child={dialogBoxContent()}
                type={ModalType.Dialog}
                openState={setAllServiceDialog}
                footer={dialogAllServiceButton()}
                error={""}
                success={""}
            />
            <StandardContainer
                id="GameServiceMaintenance"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddGameService : undefined}
                tableProps={gameServiceListTable()}
                tablePagination={pagination}
                searchValue={searchInput}
                actionDropdownItems={hasEnableRight ? dropDownActionButtons() : undefined}
            />
        </div>
    );
};

export default GameServiceMaintenance;