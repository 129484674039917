import { BigWinPendingListModel } from "../../../dto/models/BigWinPendingModels";
import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ActionTypes } from "./JackpotApprovalActions";
import { JackpotApprovalActionTypes } from "./JackpotApprovalActionTypes";
import { IJackpotApprovalState, InitialJackpotApprovalState } from "./JackpotApprovalStates";

export const jackpotApprovalReducer = (state = InitialJackpotApprovalState, action: ActionTypes): IJackpotApprovalState => {
    switch (action.type) {
        case JackpotApprovalActionTypes.GET_JACKPOT_PENDING_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return {
                    ...state, loading: true, jackpotPendingList: {} as BigWinPendingListModel, err: "", suc: ""
                }
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var jackpotPendingListModel: BigWinPendingListModel = {} as BigWinPendingListModel;

                if (data && !data.err) {
                    jackpotPendingListModel = data.res;
                }

                let errMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }

                return {
                    ...state, loading: false, jackpotPendingList: jackpotPendingListModel, err: errMsg
                }
            }
        }
        case JackpotApprovalActionTypes.RESOLVE_JACKPOT_PENDING_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, err: "", suc: "" }
            }
            else {
                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "Pending Jackpot Resolved!";
                }

                return { ...state, loading: false, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}