import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import StandardContainer from "../../components/StandardContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import ApprovalTierModal from "../../components/_modals/PAS/ApprovalTierModal";
import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { TableStatus } from "../../dto/enums/TableStatus";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { HASFLAG } from "../../libraries/Functions";
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetApprovalTier, SearchApprovalTier } from "../../states/PAS/JackpotApprovalTier/ApprovalTierActions";
import { IStore } from "../../states/store/IStore";

const JackpotApprovalTier = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const prevIsSearch = useRef(false);
    const approvalTierState = useSelector((state: IStore) => state.approvalTierState);
    const { loading, err, suc, approvalTierListData } = approvalTierState;

    const emptyTable: IDataTable = {
        title: ["No", "Threshold (Credit)", "Status", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [accTable, setAccTable] = useState(emptyTable)
    const [isSearch, setSearch] = useState(false);


    useEffect(() => {
        if (!openModal) {
            dispatch(GetApprovalTier(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchApprovalTier(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetApprovalTier(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddApprovalTier = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditApprovalTier = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (approvalTierListData.atl && approvalTierListData.atl.length > 0) {

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = approvalTierListData.atl.map((item, index) => {
                return [
                    startIndex + index + 1,
                    CVT_TO_FLOAT_STRING(item.tsh),
                    TableStatus[item.stt],
                    <Button onClick={() => EditApprovalTier(item.jid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setAccTable(table)
    }, [approvalTierListData])

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetApprovalTier(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchApprovalTier(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "approvalTierPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: approvalTierListData.ttc ?? 0
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <ApprovalTierModal isEdit={isEdit} id={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="ApprovalTierContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddApprovalTier : undefined}
                tableProps={accTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default JackpotApprovalTier;
