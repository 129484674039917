import { GameRtpResponseModel } from "../../dto/models/GameRtpModel";
import { JackpotMeterAccumulatorModel } from "../../dto/models/JackpotModels";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./DashboardActions";
import { DashboardActionTypes } from "./DashboardActionTypes";
import { IDashboardState, InitialDashboardState } from "./DashboardStates";

export const dashboardReducer = (state = InitialDashboardState, action: ActionTypes): IDashboardState => {
    switch (action.type) {
        case DashboardActionTypes.GET_JACKPOT_METER_REQUEST:
            {
                var isloading = action.payload.loading;
                if (isloading) {
                    return { ...state, loading: true, jackpotMeterAccumulatorList: [] as JackpotMeterAccumulatorModel[], err: "", suc: "" };
                }
                else {
                    var data: PylonResponseModel = action.payload.data;
                    var meterList: JackpotMeterAccumulatorModel[] = [];

                    if (data && !data.err) {
                        meterList = data.res;
                    }

                    let errMsg: string = "";
                    let sucMsg: string = "";

                    if (action.payload.err) {
                        errMsg = action.payload.err;
                    }

                    return {
                        ...state, loading: false, jackpotMeterAccumulatorList: meterList, err: errMsg, suc: sucMsg
                    };
                }
            }
        case DashboardActionTypes.GET_GAME_SERVICE_RTP_REQUEST:
            {
                var isloading = action.payload.loading;
                if (isloading) {
                    return {
                        ...state, loading: true, gameServiceInfoList: {} as GameRtpResponseModel, err: "", suc: ""
                    };
                }
                else {
                    var data: PylonResponseModel = action.payload.data;
                    var gamelist: GameRtpResponseModel = {} as GameRtpResponseModel;

                    if (data && !data.err) {
                        gamelist = data.res;
                    }

                    let errMsg: string = "";
                    let sucMsg: string = "";

                    if (action.payload.err) {
                        errMsg = action.payload.err;
                    }
                    else if (gamelist && gamelist.grs && gamelist.grs.length <= 0) {
                        errMsg = "No Records Found.";
                    }

                    return {
                        ...state, loading: false, gameServiceInfoList: gamelist, err: errMsg, suc: sucMsg
                    };
                }
            }
        default:
            return state;
    }
}