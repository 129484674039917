import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { JackpotStrikeReportCSVModel, JackpotStrikeReportListModel, JackpotStrikeReportPDFModel, JackpotStrikeSummaryReportListModel } from "../../../dto/models/Reporting/JackpotStrikeReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";
import { ActionTypes } from "./JackpotStrikeReportingActions";
import { JackpotStrikeReportingActionTypes } from "./JackpotStrikeReportingActionTypes";
import { InitPoolStrikeReportState, IPoolStrikeReportState } from "./JackpotStrikeReportingStates";


export const JackpotStrikeReportingReducer = (state = InitPoolStrikeReportState, action: ActionTypes): IPoolStrikeReportState => {
    switch (action.type) {
        case JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_REPORT_REQUEST:
        case JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_SUMMARY_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, strikeReportListData: {} as JackpotStrikeReportListModel, strikeReportSummaryListData: {} as JackpotStrikeSummaryReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: JackpotStrikeReportListModel = {} as JackpotStrikeReportListModel;
                var summaryReportListModel: JackpotStrikeSummaryReportListModel = {} as JackpotStrikeSummaryReportListModel;

                if (data && !data.err) {
                    if (action.type == JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_REPORT_REQUEST)
                        reportListModel = data.res;
                    else
                        summaryReportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""

                if (action.type == JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_REPORT_REQUEST) {
                    if (action.payload.err !== "") {
                        errMsg = action.payload.err as string;
                    } else {
                        if (reportListModel.ttc && reportListModel.ttc != 0)
                            sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                        else
                            errMsg = "No Records Found"
                    }
                    return { ...state, loading: false, strikeReportListData: reportListModel, err: errMsg, suc: sucMsg };
                } else {
                    if (action.payload.err !== "") {
                        errMsg = action.payload.err as string;
                    } else {
                        if (summaryReportListModel.ttc && summaryReportListModel.ttc != 0)
                            sucMsg = summaryReportListModel.ttc.toString() + " records have been retrieved."
                        else
                            errMsg = "No Records Found"
                    }
                    return { ...state, loading: false, strikeReportSummaryListData: summaryReportListModel, err: errMsg, suc: sucMsg };
                }

            }
        }
        case JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotStrikeReportPDFModel, csvData: {} as JackpotStrikeReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: JackpotStrikeReportPDFModel = {} as JackpotStrikeReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("JackpotStrikeReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotStrikeReportPDFModel, csvData: {} as JackpotStrikeReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: JackpotStrikeReportCSVModel = {} as JackpotStrikeReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("JackpotStrikeReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
