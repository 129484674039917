import { GameCreditCurrency } from "../../../dto/models/ConfigurationModels";
import { PoolListModel, PoolModel } from "../../../dto/models/JackpotModels";
import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ActionTypes } from "./PoolActions";
import { PoolActionTypes } from "./PoolActionTypes";
import { InitPoolState, IPoolState } from "./PoolStates";

export const PoolReducer = (state = InitPoolState, action: ActionTypes): IPoolState => {
    switch (action.type) {
        case PoolActionTypes.SELECT_POOL_REQUEST:
        case PoolActionTypes.ADD_POOL_REQUEST:
        case PoolActionTypes.EDIT_POOL_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, poolData: {} as PoolModel, poolListData: {} as PoolListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var poolModel: PoolModel = {} as PoolModel;

                if (data && !data.err) {
                    poolModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == PoolActionTypes.ADD_POOL_REQUEST) {
                        sucMsg = "Pool Added Successfully.";
                    }
                    else if (action.type == PoolActionTypes.EDIT_POOL_REQUEST) {
                        sucMsg = "Pool Updated Successfully.";
                    }
                }
                return { ...state, loading: false, poolData: poolModel, err: errMsg, suc: sucMsg };
            }
        }
        case PoolActionTypes.GET_POOL_REQUEST:
        case PoolActionTypes.SEARCH_POOL_REQUEST:
        case PoolActionTypes.GET_POOL_LIST_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, poolData: {} as PoolModel, poolListData: {} as PoolListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var poolListModel: PoolListModel = {} as PoolListModel;

                if (data && !data.err) {
                    poolListModel = data.res;
                }
                return { ...state, loading: false, poolListData: poolListModel, err: action.payload.err ? action.payload.err : "" };
            }
        }
        case PoolActionTypes.GET_CREDIT_RATE: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return state;
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var rate: GameCreditCurrency = {} as GameCreditCurrency;

                if (data && !data.err) {
                    rate = data.res;
                }
                return { ...state, creditRate: rate };
            }
        }
        default:
            return state
    }
}
