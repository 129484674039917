import { GameCreditCurrency } from "../../dto/models/ConfigurationModels";
import { DenominationListModel, DenominationModel } from "../../dto/models/DenominationModels";

export interface IDenominationState {
    loading: boolean;
    err: string;
    suc: string;
    denominationList: DenominationListModel;
    denominationData: DenominationModel;
    creditRate: GameCreditCurrency;
}

export const InitialDenominationState: IDenominationState = {
    loading: false,
    err: "",
    suc: "",
    denominationList: {} as DenominationListModel,
    denominationData: {} as DenominationModel,
    creditRate: {} as GameCreditCurrency
}