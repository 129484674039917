import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "reactstrap";
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { AlertSize, AlertType } from '../../components/AlertMessage/IAlert';
import TextField from "../../components/InputBox/TextField";
import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { HASFLAG, TRIGGER_BLUR } from "../../libraries/Functions";
import { GET_PROFILE } from "../../libraries/Global";
import { EditNotification, GetNotification } from "../../states/Notification/NotificationActions";
import { IStore } from "../../states/store/IStore";

const NotificationMaintenance = () => {
    const dispatch = useDispatch();

    const notificationState = useSelector((state: IStore) => state.notificationState);
    const { data, err, suc, loading } = notificationState;

    const [isAlertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [canEdit, setCanEdit] = useState(false);

    const [closedMessageEN, setClosedMessageEN] = useState("");
    const [closedMessageSC, setClosedMessageSC] = useState("");
    const [closedMessageTC, setClosedMessageTC] = useState("");

    const [closedMessageEnValid, setClosedMessageEnValid] = useState(false);
    const [closedMessageScValid, setClosedMessageScValid] = useState(false);
    const [closedMessageTcValid, setClosedMessageTcValid] = useState(false);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.NOTIFICATION_MAINTENANCE))
        }

        dispatch(GetNotification());
    }, []);

    useEffect(() => {
        if (data && Object.keys(data).length != 0) {
            setClosedMessageEN(data.cen);
            setClosedMessageSC(data.csc);
            setClosedMessageTC(data.ctc);

            setClosedMessageEnValid(true);
            setClosedMessageScValid(true);
            setClosedMessageTcValid(true);
        }

        if (!isAlertOpen && alertMessage != "") {
            setAlertMessage("");
        }
        else {
            var isAlert = false
            if (err != undefined && err != "") {
                setAlertMessage(err);
                setAlertOpen(true);
                isAlert = true
            }
            else if (suc != undefined && suc != "") {
                setAlertMessage(suc);
                setAlertOpen(true);
                isAlert = true
            }

            if (isAlert) {
                var alert = document.getElementById('alertMessage')
                if (alert) {
                    var headerOffset = 65;
                    var elementPosition = alert.getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }
            }
        }
    }, [data]);

    const submit = () => {
        if (closedMessageEnValid && closedMessageScValid && closedMessageTcValid) {
            dispatch(EditNotification(closedMessageEN, closedMessageSC, closedMessageTC));
        }
        else {
            TRIGGER_BLUR("cen");
            TRIGGER_BLUR("csc");
            TRIGGER_BLUR("ctc");
        }
    }

    return (
        <Container>
            <Row id="alertMessage">
                <Col>
                    <AlertMessage
                        content={alertMessage}
                        alertSize={AlertSize.LONG}
                        alertType={AlertType.FAIL}
                        isOpen={isAlertOpen && err != undefined && err != ""}
                        openState={setAlertOpen}
                        disappearTime={5}
                    />
                    <AlertMessage
                        content={alertMessage}
                        alertSize={AlertSize.LONG}
                        alertType={AlertType.SUCCESS}
                        isOpen={isAlertOpen && suc != undefined && suc != ""}
                        openState={setAlertOpen}
                        disappearTime={5}
                    />
                </Col>
            </Row>
            <Form>
                <fieldset className="border p-2 mb-3">
                    <legend className="float-none w-auto">Closed Message</legend>
                    <Row>
                        <Col>
                            <TextField id="cen" name="cen" label="English"
                                placeholder="Closed Message in English."
                                value={closedMessageEN} inputState={setClosedMessageEN}
                                maxLength={200} col={6} row={3}
                                validState={setClosedMessageEnValid} isRequired={true}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField id="csc" name="csc" label="Simplified Chinese"
                                placeholder="Closed Message in Simplified Chinese."
                                value={closedMessageSC} inputState={setClosedMessageSC}
                                maxLength={200} col={6} row={3}
                                validState={setClosedMessageScValid} isRequired={true}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField id="ctc" name="ctc" label="Traditional Chinese"
                                placeholder="Closed Message in Traditional Chinese."
                                value={closedMessageTC} inputState={setClosedMessageTC}
                                maxLength={200} col={6} row={3}
                                validState={setClosedMessageTcValid} isRequired={true}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                </fieldset>

                <Row>
                    <Col>
                        {canEdit ? < Button color="info" onClick={submit} outline disabled={loading}><FontAwesomeIcon icon={faFloppyDisk} />  Save</Button> : <></>}
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default NotificationMaintenance;