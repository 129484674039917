import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { PlayerLeftOverBalanceCSVReportModel, PlayerLeftOverBalanceListModel, PlayerLeftOverBalancePDFReportModel } from "../../../dto/models/Reporting/PlayerLeftOverBalanceReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";

import { ActionTypes } from "./PlayerLeftOverBalanceReportActions";
import { PlayerLeftOverBalanceReportingActionTypes } from "./PlayerLeftOverBalanceReportActionTypes";
import { InitPlayerLeftOverBalanceReportState, IPlayerLeftOverBalanceReportState } from "./PlayerLeftOverBalanceReportStates";


export const PlayerLeftOverBalanceReportingReducer = (state = InitPlayerLeftOverBalanceReportState, action: ActionTypes): IPlayerLeftOverBalanceReportState => {
    switch (action.type) {
        case PlayerLeftOverBalanceReportingActionTypes.GET_PLAYER_LEFT_OVER_BALANCE_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, leftOverBalanceReportListData: {} as PlayerLeftOverBalanceListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: PlayerLeftOverBalanceListModel = {} as PlayerLeftOverBalanceListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrived."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, leftOverBalanceReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as PlayerLeftOverBalancePDFReportModel, csvData: {} as PlayerLeftOverBalanceCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: PlayerLeftOverBalancePDFReportModel = {} as PlayerLeftOverBalancePDFReportModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err != "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("PlayerLeftOverBalanceReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as PlayerLeftOverBalancePDFReportModel, csvData: {} as PlayerLeftOverBalanceCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: PlayerLeftOverBalanceCSVReportModel = {} as PlayerLeftOverBalanceCSVReportModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err != "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("PlayerLeftOverBalanceReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
