import { AccountingServiceListModel, AccountingServiceModel } from "../../dto/models/ServiceModels";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { AccountingActionTypes } from "./AccountingActionTypes";
import { TableStatus } from "../../dto/enums/TableStatus";
import { CmsConfigurationModel } from "../../dto/models/ConfigurationModels";
import { AccountingRefreshRequestModel } from "../../dto/models/RefreshServiceModel";

type AddAccountingRequest = { type: AccountingActionTypes.ADD_ACCOUNTING_REQUEST, payload: IPayload };
type EditAccountingRequest = { type: AccountingActionTypes.EDIT_ACCOUNTING_REQUEST, payload: IPayload };
type GetAccountingRequest = { type: AccountingActionTypes.GET_ACCOUNTING_REQUEST, payload: IPayload };
type SelectAccountingRequest = { type: AccountingActionTypes.SELECT_ACCOUNTING_REQUEST, payload: IPayload };
type SearchAccountingRequest = { type: AccountingActionTypes.SEARCH_ACCOUNTING_REQUEST, payload: IPayload };
type RefreshAccountingRequest = { type: AccountingActionTypes.REFRESH_ACCOUNTING_REQUEST, payload: IPayload };

export type ActionTypes =
    AddAccountingRequest | EditAccountingRequest | GetAccountingRequest | SelectAccountingRequest | SearchAccountingRequest | RefreshAccountingRequest;

export const AddAccounting = (
    name: string, lighthouse: string, pylon: string,
    description: string, status: TableStatus, cms: CmsConfigurationModel
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: AccountingServiceModel = {
            sid: 0,
            snm: name,
            lil: lighthouse,
            pyl: pylon,
            des: description,
            stt: status,
            cms: cms,
        };
        PostRequest("BackOffice/AddAccountingService", request, AccountingActionTypes.ADD_ACCOUNTING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccountingActionTypes.ADD_ACCOUNTING_REQUEST, dispatch);
    }
}

export const EditAccounting = (
    aid: number, name: string, lighthouse: string, pylon: string,
    description: string, status: TableStatus, cms: CmsConfigurationModel
) => async (dispatch: any) => {

    try {
        let request: AccountingServiceModel = {
            sid: aid,
            snm: name,
            lil: lighthouse,
            pyl: pylon,
            des: description,
            stt: status,
            cms: cms,
        };
        PostRequest("BackOffice/EditAccountingService", request, AccountingActionTypes.EDIT_ACCOUNTING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccountingActionTypes.EDIT_ACCOUNTING_REQUEST, dispatch);
    }

}

export const GetAccounting = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetAccountingService", request, AccountingActionTypes.GET_ACCOUNTING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccountingActionTypes.GET_ACCOUNTING_REQUEST, dispatch);
    }
}

export const SearchAccounting = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchAccountingService", request, AccountingActionTypes.SEARCH_ACCOUNTING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccountingActionTypes.SEARCH_ACCOUNTING_REQUEST, dispatch);
    }
}

export const SelectAccounting = (aid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: aid
        };
        PostRequest("BackOffice/SelectAccountingService", request, AccountingActionTypes.SELECT_ACCOUNTING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccountingActionTypes.SELECT_ACCOUNTING_REQUEST, dispatch);
    }
}

export const RefreshAccounting = (cmsType: number) => async (dispatch: any) => {
    let request: AccountingRefreshRequestModel = {
        cms: cmsType
    };

    try {
        PostRequest("BackOffice/RefreshAccountingService", request, AccountingActionTypes.REFRESH_ACCOUNTING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccountingActionTypes.REFRESH_ACCOUNTING_REQUEST, dispatch);
    }
}