export enum UserActionTypes {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST',
    EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST',
    CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
    SESSION_TIMEOUT = 'SESSION_TIMEOUT',

    GET_USER_REQUEST = 'GET_USER_REQUEST',
    EDIT_USER_REQUEST = 'EDIT_USER_REQUEST',
    ADD_USER_REQUEST = 'ADD_USER_REQUEST',
    SELECT_USER_REQUEST = 'SELECT_USER_REQUEST',
    SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST',
    GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST',
    RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
}