export enum GameServiceActionTypes {
    GET_GAME_SERVICE_REQUEST = "GET_GAME_SERVICE_REQUEST",
    GET_GAME_SERVICE_LIST_REQUEST = "GET_GAME_SERVICE_LIST_REQUEST",
    SELECT_GAME_SERVICE_REQUEST = "SELECT_GAME_SERVICE_REQUEST",
    EDIT_GAME_SERVICE_REQUEST = "EDIT_GAME_SERVICE_REQUEST",
    ADD_GAME_SERVICE_REQUEST = "ADD_GAME_SERVICE_REQUEST",
    GET_GAME_LIST_REQUEST = "GET_GAME_LIST_REQUEST",
    SEARCH_GAME_REQUEST = 'SEARCH_GAME_REQUEST',
    GET_JACKPOT_SERVICE_LIST_REQUEST = "GET_JACKPOT_SERVICE_LIST_REQUEST",
    REFRESH_GAME_SERVICE = "REFRESH_GAME_SERVICE",

    UPDATE_GAME_SERVICE_STATUS = "UPDATE_GAME_SERVICE_STATUS",
    UPDATE_ALL_GAME_SERVICE_STATUS = "UPDATE_ALL_GAME_SERVICE_STATUS"
}