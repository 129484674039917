import { WinLoseCSVReportModel, WinLosePDFReportModel, WinLoseReportListModel } from "../../../dto/models/Reporting/WinLoseReportModel";

export interface IGameWinLoseReportState {
    loading: boolean;
    err: string;
    suc: string;
    gameWinLoseReportListData: WinLoseReportListModel;
    pdfData: WinLosePDFReportModel;
    csvData: WinLoseCSVReportModel;
}

export const InitGameWinLoseReportState: IGameWinLoseReportState = {
    loading: false,
    err: "",
    suc: "",
    gameWinLoseReportListData: {} as WinLoseReportListModel,
    pdfData: {} as WinLosePDFReportModel,
    csvData: {} as WinLoseCSVReportModel,
}