import { JackpotContributionRequestModel } from "../../../dto/models/Reporting/JackpotContributionRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { JackpotContributionReportingActionTypes } from "./JackpotContributionReportingActionTypes";
type GetJackpotContributionReportRequest = { type: JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_REPORT_REQUEST, payload: IPayload };
type GetJackpotContributionSummaryReportRequest = { type: JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_SUMMARY_REPORT_REQUEST, payload: IPayload };
type GenerateJackpotContributionPDFRequest = { type: JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_PDF_REQUEST, payload: IPayload };
type GenerateJackpotContributionCSVRequest = { type: JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetJackpotContributionReportRequest | GetJackpotContributionSummaryReportRequest | GenerateJackpotContributionPDFRequest | GenerateJackpotContributionCSVRequest;

export const GetJackpotDetailContributionReportRequest = (
    accumulatorID: number, tradingDate: Date, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotContributionRequestModel = {
            aid: accumulatorID,
            tdd: tradingDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetContributionReport", request, JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_REPORT_REQUEST, dispatch);
    }
}

export const GetJackpotContributionSummaryReportRequest = (
    transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotContributionRequestModel = {
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetContributionSummaryReport", request, JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_SUMMARY_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_SUMMARY_REPORT_REQUEST, dispatch);
    }
}

export const GenerateJackpotDetailContributionPDFRequest = (
    accumulatorID: number, tradingDate: Date, from: number, takeCount: number,
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotContributionRequestModel = {
            aid: accumulatorID,
            tdd: tradingDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotContributionPDF", request, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_PDF_REQUEST, dispatch);
    }
}

export const GenerateJackpotDetailContributionCSVRequest = (
    accumulatorID: number, tradingDate: Date, from: number, takeCount: number,
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotContributionRequestModel = {
            aid: accumulatorID,
            tdd: tradingDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotContributionCSV", request, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_CSV_REQUEST, dispatch);
    }
}

export const GenerateJackpotSummaryContributionPDFRequest = (
    transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number,
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotContributionRequestModel = {
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotContributionSummaryPDF", request, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_PDF_REQUEST, dispatch);
    }
}

export const GenerateJackpotSummaryContributionCSVRequest = (
    transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number,
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotContributionRequestModel = {
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotContributionSummaryCSV", request, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_CSV_REQUEST, dispatch);
    }
}

