export enum WalletType {
    /// <summary>
    ///    Cashable e-Cash
    /// </summary>
    NVIP_EC = 1,
    /// <summary>
    ///    Non-negotiable e-Cash
    /// </summary>
    NVIP_NN = 2,
    /// <summary>
    ///    VIP E-cash
    /// </summary>
    VIP_EC = 3,
    /// <summary>
    ///    VIP NN
    /// </summary>
    VIP_NN = 4
}