import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import DenominationMaintenanceModal from '../../components/_modals/Game/DenominationMaintenanceModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { TableStatus } from '../../dto/enums/TableStatus';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetDenominationRequest, SearchDenominationRequest } from '../../states/Denomination/DenominationActions';
import { IStore } from '../../states/store/IStore';

const DenominationMaintenance = () => {
    const dispatch = useDispatch();
    const denominationState = useSelector((state: IStore) => state.denominationState);
    const { loading, err, suc, denominationList } = denominationState;

    // main screen state
    const [currentPage, setPage] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    // modal state
    const [isSearch, setIsSearch] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [id, setId] = useState(0);

    useEffect(() => {
        if (!openModal) {
            dispatch(GetDenominationRequest(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const EditDenomination = (id: number): void => {
        setEdit(true);
        setId(id);
        setOpenModal(true);
    }

    const AddDenomination = (): void => {
        setEdit(false);
        setId(0);
        setOpenModal(true);
    }

    const search = (): void => {
        setPage(0);
        dispatch(SearchDenominationRequest(searchInput, 0, TABLE_MAX_ROW));
        setIsSearch(true);
    }

    const take = (page: number) => {
        setPage(page);
        dispatch(GetDenominationRequest(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "denominationPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: denominationList.ttc ?? 0
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetDenominationRequest(0, TABLE_MAX_ROW));
        setIsSearch(false);
        setSearchInput("")
    }

    const denominationListTable = (): IDataTable => {
        var table: IDataTable = {
            title: ["No.", "Credit", "Status", "View"],
            data: [],
            isStriped: false,
            isHover: true,
            tableStyle: TableStyle.ALL_BORDER,
            isloading: loading
        };

        if (denominationList.dnl) {
            if (denominationList.dnl.length > 0) {
                const startIndex = currentPage * TABLE_MAX_ROW;
                table.data = denominationList.dnl.map((item, index) => {
                    return [
                        startIndex + index + 1,
                        item.dva,
                        TableStatus[item.stt].toString().replace("_", " "),
                        <Button onClick={() => EditDenomination(item.did)}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                    ];
                });
            }
        }

        return table;
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.DENOMINATION_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <DenominationMaintenanceModal isEdit={isEdit} setOpenState={setOpenModal} isOpen={openModal} id={id} />
            <StandardContainer
                id="DenominationMaintenanceContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddDenomination : undefined}
                tableProps={denominationListTable()}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default DenominationMaintenance;