import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { TableStatus } from "../../../dto/enums/TableStatus";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { AddGameServiceRequest, EditGameServiceRequest, GetGameListRequest, GetJackpotServiceList, SelectGameServiceRequest } from "../../../states/GameService/GameServiceActions";
import { IStore } from "../../../states/store/IStore";
import { ICheckBoxOption } from "../../InputBox/ICheckBoxGroup";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import TextField from "../../InputBox/TextField";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IGameServiceMaintenanceForm {
    isEdit: boolean;
    loading: boolean;
    id?: number;

    isSaveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const GameServiceMaintenanceForm = (props: IGameServiceMaintenanceForm) => {
    const dispatch = useDispatch();
    const gameServiceState = useSelector((state: IStore) => state.gameServiceState);
    const { gameServiceData, jackpotServiceList, gameList, loading } = gameServiceState;

    // input & validation
    const [name, setName] = useState("");
    const [lighthouse, setLighthouse] = useState("");
    const [pylon, setPylon] = useState("");
    const [game, setGame] = useState(0);
    const [jackpot, setJackpot] = useState(0);
    const [status, setStatus] = useState(TableStatus.ACTIVE);
    const [desc, setDesc] = useState("");
    const [terminateGameAccess, setTerminateGameAccess] = useState(false);

    const [nameValid, setNameValid] = useState(props.isEdit);
    const [lighthouseValid, setLighthouseValid] = useState(props.isEdit);
    const [pylonValid, setPylonValid] = useState(props.isEdit);
    const [gameValid, setGameValid] = useState(props.isEdit);
    const [jackpotValid, setJackpotValid] = useState(props.isEdit);

    // dropdown option
    const [gameOption, setGameOption] = useState([] as ISelectOption[]);
    const [jackpotOption, setJackpotOption] = useState([] as ISelectOption[]);
    const statusOption: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);

    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(!loading);
    }, [loading])

    // save button
    const save = () => {
        if (nameValid && lighthouseValid && pylonValid && gameValid && jackpotValid) {
            if (props.isEdit && props.id) {
                dispatch(EditGameServiceRequest(props.id, name, lighthouse, pylon, game, status, desc, jackpot));
            }
            else {
                dispatch(AddGameServiceRequest(name, lighthouse, pylon, game, status, desc, jackpot));
            }
        }
        else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("lighthouse");
            TRIGGER_BLUR("pylon");
            TRIGGER_BLUR("port");
            TRIGGER_BLUR("game");
            TRIGGER_BLUR("jackpot");
        }

        props.setSaveTrigger(false);
    }

    // states
    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setTerminateGameAccess(HASFLAG(access, BackOfficeAccess.TERMINATE_GAME_SERVICE))
        }

        if (props.isEdit && props.id) {
            dispatch(SelectGameServiceRequest(props.id));
        }

        dispatch(GetGameListRequest());
        dispatch(GetJackpotServiceList());
    }, [])

    useEffect(() => {
        if (gameList && gameList.glt) {
            const games: ICheckBoxOption[] = gameList.glt.map((item) => {
                return {
                    display: item.gmn,
                    value: item.gid.toString()
                };
            });

            setGameOption(games);
        }
    }, [gameList]);

    useEffect(() => {
        if (jackpotServiceList && jackpotServiceList.jpl) {
            const jackpots: ICheckBoxOption[] = jackpotServiceList.jpl.map((item) => {
                return {
                    display: item.snm,
                    value: item.sid.toString()
                };
            });

            setJackpotOption(jackpots);
        }
    }, [jackpotServiceList])

    useEffect(() => {
        if (gameServiceData.sid) {
            setName(gameServiceData.snm);
            setLighthouse(gameServiceData.lil);
            setPylon(gameServiceData.pyl);
            setGame(gameServiceData.sgc.gid);
            setStatus(gameServiceData.stt);
            setDesc(gameServiceData.des);
            setJackpot(gameServiceData.jps.sid);
        }
    }, [gameServiceData])

    useEffect(() => {
        if (props.isSaveTrigger) {
            save();
        }
    }, [props.isSaveTrigger]);

    if (isLoaded) {
        return (
            <Form>
                <Row>
                    <Col>
                        <InputBox id="name" name="name" label="Name" placeholder="Game Service Name"
                            type={TextboxType.TEXT} maxlength={50}
                            value={name} inputState={setName} validState={setNameValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox id="lighthouse" name="lighthouse" label="External Gateway Listener" placeholder="External Gateway Listener"
                            type={TextboxType.URL}
                            value={lighthouse} inputState={setLighthouse} validState={setLighthouseValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                    <Col>
                        <InputBox id="pylon" name="pylon" label="Internal Gateway Listener" placeholder="Internal Gateway Listener"
                            type={TextboxType.URL}
                            value={pylon} inputState={setPylon} validState={setPylonValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SelectBox id="game" name="game" label="Game"
                            options={gameOption} value={gameServiceData.sgc?.gid}
                            inputState={setGame} validState={setGameValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                    <Col>
                        <SelectBox id="jackpot" name="jackpot" label="Jackpot Service"
                            options={jackpotOption} value={gameServiceData.jps?.sid}
                            inputState={setJackpot} validState={setJackpotValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SelectBox id="status" name="status" label="Status"
                            options={statusOption} value={gameServiceData.stt}
                            inputState={setStatus}
                            isRequired={true} isDisabled={!props.isEdit || !props.canEdit || !terminateGameAccess}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField id="desc" name="desc" label="Description" placeholder="Description"
                            value={gameServiceData.des} inputState={setDesc}
                            maxLength={200} col={5} row={2}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
            </Form>
        );
    } else {
        return (<div className='text-center p-4'><Spinner key='1' /></div>);
    }
}

const GameServiceMaintenanceModal = (props: { isEdit: boolean, id?: number, isOpen: boolean, setOpenState: any }) => {
    const gameServiceState = useSelector((state: IStore) => state.gameServiceState);
    const { err, suc, loading } = gameServiceState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.GAME_SERVICE_MAINTENANCE))
        }

    }, [])

    return (
        <ModalBox
            title={props.isEdit ? "Edit Game Service" : "Add New Game Service"}
            isOpen={props.isOpen}
            child={<GameServiceMaintenanceForm isEdit={props.isEdit} canEdit={canEdit} id={props.id} loading={loading} isSaveTrigger={save} setSaveTrigger={setSave} />}
            type={ModalType.Window}
            openState={props.setOpenState}
            error={err}
            success={suc}
            footer={canEdit? < Button
                color="info"
                onClick={() => setSave(true)}
                outline
                disabled={loading}>
                <FontAwesomeIcon icon={faFloppyDisk} /> Save
            </Button> : <></>
            }
        />
    );
}

export default GameServiceMaintenanceModal;