import { GamingAreaListModel, GamingAreaModel } from "../../dto/models/GamingAreaModels";

export interface IGamingAreaState {
    loading: boolean;
    err: string;
    suc: string;
    gamingAreaList: GamingAreaListModel;
    gamingAreaData: GamingAreaModel;
}

export const InitialGamingAreaState: IGamingAreaState = {
    loading: false,
    err: "",
    suc: "",
    gamingAreaList: {} as GamingAreaListModel,
    gamingAreaData: {} as GamingAreaModel
}