import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import { AlertSize, IAlert } from './IAlert';

function AlertMessage(prop: IAlert) {
    var prevOpenState = useRef<boolean>(prop.isOpen);

    const dismiss = () => {
        if (prop.openState != undefined) {
            prop.openState(false);
            prevOpenState.current = false;
        }
    };
    
    useEffect(() => {
        if (prop.isOpen && !prevOpenState.current && prop.disappearTime) {
            if (prop.isOpen) {
                var countdown = prop.disappearTime;
                const timer = setInterval(() => {
                    countdown--;

                    if (countdown <= 0) {
                        dismiss();
                        clearInterval(timer);
                    }
                }, 1000);
            }
        }
        prevOpenState.current = prop.isOpen;
    })

    return (
        <Alert style={prop.alertSize == AlertSize.SHORT ? { width: "50%", marginLeft: "25%", display: "block", position: 'fixed' } : undefined} color={prop.alertType as string} isOpen={prop.isOpen} toggle={prop.disableCrossButon ? undefined : dismiss}>
            {prop.content}
        </Alert>
    );
}

export default AlertMessage;