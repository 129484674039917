import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { OLRReportListModel, OLRReportPDFModel, OLRReportCSVModel } from "../../../dto/models/Reporting/OLRReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";

import { ActionTypes } from "./OLRReportingActions";
import { OLRReportingActionTypes } from "./OLRReportingActionTypes";
import { InitOLRReportState, IOLRReportState } from "./OLRReportingStates";


export const OLRReportingReducers = (state = InitOLRReportState, action: ActionTypes): IOLRReportState => {
    switch (action.type) {
        case OLRReportingActionTypes.GET_OLR_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, olrReportListData: {} as OLRReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: OLRReportListModel = {} as OLRReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, olrReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case OLRReportingActionTypes.GENERATE_OLR_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as OLRReportPDFModel, csvData: {} as OLRReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: OLRReportPDFModel = {} as OLRReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("OLRReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case OLRReportingActionTypes.GENERATE_OLR_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as OLRReportPDFModel, csvData: {} as OLRReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: OLRReportCSVModel = {} as OLRReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("OLRReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
