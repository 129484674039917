import { faCashRegister, faFileInvoice, faHourglassHalf, faLockOpen, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import BigWinPendingMaintenance from "../containers/Cashier/BigWinPendingMaintenance";
import ManualCreditTransferMaintenance from "../containers/Cashier/ManualCreditTransferMaintenance";
import ManualSubmitOLRMaintenance from "../containers/Cashier/ManualSubmitOLRMaintenance";
import PlayerLockMaintenance from "../containers/Cashier/PlayerLockMaintenance";
import TransferCreditMaintenance from "../containers/Cashier/TransferCreditMaintenance";
import { CashierAccess } from "../dto/enums/AccessMatrix/CashierAccess";
import "../styles/Cashier.css";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const CreditTransfer_URL = "/TransferCredit";
const ManualSubmitOLR_URL = "/ManualSubmitOLR";
const ManualCreditTransfer_URL = "/ManualCreditTransfer";
const PlayerLock_URL = "/PlayerLock";
const BigWinPending_URL = "/BigWinPending";

export const CashierRoutesData: IRouteData[] = [
    {
        to: CreditTransfer_URL,
        element: <TransferCreditMaintenance />
    },
    {
        to: ManualSubmitOLR_URL,
        element: <ManualSubmitOLRMaintenance />
    },
    {
        to: ManualCreditTransfer_URL,
        element: <ManualCreditTransferMaintenance />
    },
    {
        to: PlayerLock_URL,
        element: <PlayerLockMaintenance />
    },
    {
        to: BigWinPending_URL,
        element: <BigWinPendingMaintenance />
    }
];

export const CashierNavigationData: INavigationData[] = [
    {
        requiredAccess: [CashierAccess.TRANSFER_IN, CashierAccess.TRANSFER_OUT, CashierAccess.CASH_OUT],
        icon: faMoneyBillTransfer,
        title: "Transfer Credit",
        path: "Cashier / Transfer Credit",
        searchKeyword: "Transfer Credit",
        to: CreditTransfer_URL
    },
    {
        requiredAccess: [CashierAccess.MANUAL_SUBMIT_OLR],
        icon: faFileInvoice,
        title: "Manual Submit OLR",
        path: "Cashier / Manual Submit OLR",
        searchKeyword: "Manual Submit OLR",
        to: ManualSubmitOLR_URL
    },
    {
        requiredAccess: [CashierAccess.MANUAL_CREDIT_TRANSFER],
        icon: faFileInvoice,
        title: "Manual Credit Transfer",
        path: "Cashier / Manual Credit Transfer",
        searchKeyword: "Manual Credit Transfer",
        to: ManualCreditTransfer_URL
    },
    {
        requiredAccess: [CashierAccess.PLAYER_LOCK],
        icon: faLockOpen,
        title: "Lock/Unlock Player",
        path: "Cashier / Lock or Unlock Player",
        searchKeyword: "Lock/Unlock Player",
        to: PlayerLock_URL
    },
    {
        requiredAccess: [CashierAccess.BIG_WIN_APPROVAL],
        icon: faHourglassHalf,
        title: "Big Win Pending",
        path: "Cashier / Big Win Pending",
        searchKeyword: "Big Win Pending",
        to: BigWinPending_URL
    }
];

export function CashierSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(CashierNavigationData, "Cashier", faCashRegister, "#8752ab", open, access, currentPath);
}