import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ManualSubmitOLRReportListModel, ManualSubmitOLRReportPDFModel, ManualSubmitOLRReportCSVModel } from "../../../dto/models/Reporting/ManualSubmitOLRReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";

import { ActionTypes } from "./ManualSubmitOLRReportingActions";
import { ManualSubmitOLRReportingActionTypes } from "./ManualSubmitOLRReportingActionTypes";
import { InitManualSubmitOLRReportState, IManualSubmitOLRReportState } from "./ManualSubmitOLRReportingStates";


export const ManualSubmitOLRReportingReducers = (state = InitManualSubmitOLRReportState, action: ActionTypes): IManualSubmitOLRReportState => {
    switch (action.type) {
        case ManualSubmitOLRReportingActionTypes.GET_MANUAL_SUBMIT_OLR_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, manualSubmitOLRReportListData: {} as ManualSubmitOLRReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: ManualSubmitOLRReportListModel = {} as ManualSubmitOLRReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, manualSubmitOLRReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as ManualSubmitOLRReportPDFModel, csvData: {} as ManualSubmitOLRReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: ManualSubmitOLRReportPDFModel = {} as ManualSubmitOLRReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("ManualSubmitOLRReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as ManualSubmitOLRReportPDFModel, csvData: {} as ManualSubmitOLRReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: ManualSubmitOLRReportCSVModel = {} as ManualSubmitOLRReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("ManualSubmitOLRReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
