import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import ManualCreditTransferModal from '../../components/_modals/Cashier/ManualCreditTransferModal';
import { FORMAT_DATETIME_TO_VIEW } from '../../libraries/DateHelper';
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetUnresolvedManualCreditTransfer } from '../../states/ManualCreditTransfer/ManualCreditTransferActions';
import { IStore } from '../../states/store/IStore';

const ManualCreditTransferMaintenance = () => {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const manualCreditTransferState = useSelector((state: IStore) => state.manualCreditTransferState);
    const { loading, err, suc, manualCreditTransferListData } = manualCreditTransferState;

    const emptyUnresolvedTable: IDataTable = {
        title: ["No", "Transaction ID", "Created At", ""],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };

    const [creditTranferTable, setCreditTranferTable] = useState(emptyUnresolvedTable)

    useEffect(() => {
        if (!openModal) {
            dispatch(GetUnresolvedManualCreditTransfer(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const Resolve = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    const SeeDetails = (id: number): void => {
        setOpenModal(true);
        setEdit(false);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyUnresolvedTable
        if (manualCreditTransferListData.mctl && manualCreditTransferListData.mctl.length > 0) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = manualCreditTransferListData.mctl.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.txid,
                    FORMAT_DATETIME_TO_VIEW(item.cra, "yyyy-MM-dd hh:mm:ss"),
                    <Button onClick={() => Resolve(item.mctid)}>Resolve</Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setCreditTranferTable(table)
    }, [manualCreditTransferListData])

    const take = (page: number) => {
        setPage(page);
        dispatch(GetUnresolvedManualCreditTransfer(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "manualCreditTransferPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: manualCreditTransferListData.ttc ?? 0
    }

    return (
        <>
            <Row>
                <Col sm="12">
                    <ManualCreditTransferModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
                    <StandardContainer
                        id="ManualCrediTransferMaintenanceContainer"
                        loading={loading}
                        error={err}
                        success={suc}
                        tableProps={creditTranferTable}
                        tablePagination={pagination}
                    />
                </Col>
            </Row>
        </>
    );
};
export default ManualCreditTransferMaintenance;
