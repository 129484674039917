
export interface IAlert {
    content: any;
    disappearTime?: number;
    alertType: AlertType;
    alertSize: AlertSize;
    isOpen: boolean;
    disableCrossButon?: true | undefined

    openState?: React.Dispatch<React.SetStateAction<boolean>>
}

export enum AlertType {
    FAIL = 'danger',
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'primary'
}

export enum AlertSize {
    SHORT,
    LONG
}