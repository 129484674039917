import { faBell, faCalendarPlus, faChess, faCoins, faGamepad, faServer } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DenominationMaintenance from "../containers/Game/DenominationMaintenance";
import GameMaintenance from "../containers/Game/GameMaintenance";
import GameServiceMaintenance from "../containers/Game/GameServiceMaintenance";
import NotificationMaintenance from "../containers/Game/NotificationMaintenance";
import { BackOfficeAccess } from "../dto/enums/AccessMatrix/BackOfficeAccess";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const GAME_MAINTENANCE_URL = "/GameMaintenance";
const GAME_SERVICE_MAINTENANCE_URL = "/GameServiceMaintenance";
const DENOMINATION_MAINTENANCE_URL = "/DenominationMaintenance";
const NOTIFICATION_MAINTENANCE_URL = "/NotificationMaintenance";

export const GameRoutesData: IRouteData[] = [
    {
        to: GAME_MAINTENANCE_URL,
        element: <GameMaintenance />
    },
    {
        to: GAME_SERVICE_MAINTENANCE_URL,
        element: <GameServiceMaintenance />
    },
    {
        to: DENOMINATION_MAINTENANCE_URL,
        element: <DenominationMaintenance />
    },
    {
        to: NOTIFICATION_MAINTENANCE_URL,
        element: <NotificationMaintenance />
    }
]

export const GameNavigationData: INavigationData[] = [
    {
        requiredAccess: [BackOfficeAccess.GAME_SERVICE_MAINTENANCE],
        icon: faServer,
        title: "Game Service",
        path: "Games / Game Service",
        searchKeyword: "Game Service Maintenance",
        to: GAME_SERVICE_MAINTENANCE_URL
    },
    {
        requiredAccess: [BackOfficeAccess.GAME_MAINTENANCE],
        icon: faGamepad,
        title: "Game",
        path: "Games / Game",
        searchKeyword: "Game Maintenance",
        to: GAME_MAINTENANCE_URL
    },
    {
        requiredAccess: [BackOfficeAccess.DENOMINATION_MAINTENANCE],
        icon: faCoins,
        title: "Denomination",
        path: "Games / Denomination",
        searchKeyword: "Denomination Maintenance",
        to: DENOMINATION_MAINTENANCE_URL
    },
    {
        requiredAccess: [BackOfficeAccess.NOTIFICATION_MAINTENANCE],
        icon: faBell,
        title: "Notification",
        path: "Games / Notification Maintenance",
        searchKeyword: "Notification Maintenance",
        to: NOTIFICATION_MAINTENANCE_URL
    }
]

export function GameSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(GameNavigationData, "Games", faChess, "#3a6c92", open, access, currentPath);
}