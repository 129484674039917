import { CreditTransferResponseModel, PlayerWalletResponseModel } from "../../dto/models/CreditTransactionModels";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./CreditTransactionActions";
import { CreditTransactionActionTypes } from "./CreditTransactionActionTypes";
import { ICreditTransactionState, InitialCreditTransactionState } from "./CreditTransactionStates";

export const CreditTransactionReducer = (state = InitialCreditTransactionState, action: ActionTypes): ICreditTransactionState => {
    switch (action.type) {
        case CreditTransactionActionTypes.TRANSFER_IN_REQUEST:
        case CreditTransactionActionTypes.TRANSFER_OUT_REQUEST:
        case CreditTransactionActionTypes.CASH_OUT_REQUEST:
        case CreditTransactionActionTypes.CASH_IN_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, creditTransferData: {} as CreditTransferResponseModel, err: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var creditTransfer: CreditTransferResponseModel = {} as CreditTransferResponseModel;
                var errorMessage: string = (data.res && data.res.err ? data.res.err : "");

                if (data && !data.err) {
                    creditTransfer = data.res;
                }

                return { ...state, loading: false, creditTransferData: creditTransfer, err: data.err ? data.err + " : " + (errorMessage ? errorMessage : "") : "" };
            }
        }
        case CreditTransactionActionTypes.GET_PLAYER_WALLET_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, playerWalletData: {} as PlayerWalletResponseModel, err: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var playerWallet: PlayerWalletResponseModel = {} as PlayerWalletResponseModel;

                if (data && !data.err) {
                    playerWallet = data.res;
                }
                return { ...state, loading: false, playerWalletData: playerWallet, creditTransferData: {} as CreditTransferResponseModel, err: action.payload.err ? action.payload.err : "" };
            }
        }
        default:
            return state;
    }
}
