import { JackpotPendingActionType } from "../../../dto/enums/JackpotPendingActionType";
import { BigWinResolveModel } from "../../../dto/models/BigWinPendingModels";
import { RequestListModel } from "../../../dto/models/GeneralModels";
import { GET_PROFILE } from "../../../libraries/Global";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { JackpotApprovalActionTypes } from "./JackpotApprovalActionTypes";

type GetJackpotPendingRequest = { type: JackpotApprovalActionTypes.GET_JACKPOT_PENDING_REQUEST, payload: IPayload };
type ResolveJackpotPendingRequest = { type: JackpotApprovalActionTypes.RESOLVE_JACKPOT_PENDING_REQUEST, payload: IPayload };

export type ActionTypes = GetJackpotPendingRequest | ResolveJackpotPendingRequest;

export const GetJackpotPendingRequest = (from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: take
        };

        PostRequest("Cashier/GetJackpotPending", request, JackpotApprovalActionTypes.GET_JACKPOT_PENDING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotApprovalActionTypes.GET_JACKPOT_PENDING_REQUEST, dispatch);
    }
}

export const ResolveJackpotRequest = (bigWinId: number, password: string, remarks: string, action: JackpotPendingActionType) => async (dispatch: any) => {
    try {
        let request: BigWinResolveModel = {
            bid: bigWinId,
            rmk: remarks,
            act: action,
            pwd: password,
            uid: GET_PROFILE().uid
        }

        PostRequest("Cashier/ResolvePending", request, JackpotApprovalActionTypes.RESOLVE_JACKPOT_PENDING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotApprovalActionTypes.RESOLVE_JACKPOT_PENDING_REQUEST, dispatch);
    }
}