import { PlayerLockModel } from "../../dto/models/PlayerLockModel";

export interface IPlayerLockStates {
    loading: boolean;
    err: string;
    suc: string;
    playerLockStatus: PlayerLockModel;
}


export const InitPlayerLockStates: IPlayerLockStates = {
    loading: false,
    err: "",
    suc: "",
    playerLockStatus: {} as PlayerLockModel,
}
