import React, { useEffect, useState } from 'react';
import { Form, Row, Col, FormGroup, Label, Input, Button, FormFeedback, Container, Spinner } from 'reactstrap';
import { TextboxType } from '../../InputBox/IInputBox';
import { DefaultLanguage } from '../../../dto/enums/DefaultLanguage'
import { DECODE_STR_TO_ARR, GET_FILE_DATA } from '../../../libraries/FileHelper'
import { CmsType } from '../../../dto/enums/CmsType';
import InputBox from '../../InputBox/InputBox';
import ModalBox from '../../ModalBox/ModalBox';
import { ModalType } from '../../ModalBox/IModalBox';
import { AddCms, EditCms, GetNonVipGamingArea, GetVipGamingArea, SelectCms } from '../../../states/Cms/CmsActions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../states/store/IStore';
import { ISelectOption, LoadSelectOptionFromEnum } from '../../InputBox/ISelectBox';
import SelectBox from '../../InputBox/SelectBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { HASFLAG, TRIGGER_BLUR } from '../../../libraries/Functions';
import { PraCode } from '../../../dto/enums/PraCode';
import DropdownSelectBox from '../../InputBox/DropdownSelectBox';
import { IDropdownSelectOption, LoadDropdownOptionFromEnum } from '../../InputBox/IDropdownSelectBox';
import { BackOfficeAccess } from '../../../dto/enums/AccessMatrix/BackOfficeAccess';
import { GET_PROFILE } from '../../../libraries/Global';

interface ICmsForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const CmsForm = (props: ICmsForm) => {
    const [usernameInput, setUsernameInput] = useState("");
    const [usernameValid, setUsernameValid] = useState(props.isEdit);
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordValid, setPasswordValid] = useState(props.isEdit);
    const [cashierIDInput, setCashierIDInput] = useState("");
    const [profitCenterInput, setProfitCenterInput] = useState("");
    const [cashierIDValid, setCashierIDValid] = useState(props.isEdit);
    const [profitCenterValid, setProfitCenterValid] = useState(props.isEdit);
    const [domainInput, setDomainInput] = useState("");
    const [domainValid, setDomainValid] = useState(props.isEdit);
    const [portInput, setPortInput] = useState<number | undefined>(undefined);
    const [portValid, setPortValid] = useState(props.isEdit);
    const [certPasswordInput, setCertPasswordInput] = useState("");
    const [certPasswordValid, setCertPasswordValid] = useState(props.isEdit);
    const [olrRetryCountInput, setOlrRetryCountInput] = useState<number | undefined>(undefined);
    const [olrRetryCountValid, setOlrRetryCountValid] = useState(props.isEdit);
    const [olrRetryIntervalInput, setOlrRetryIntervalInput] = useState<number | undefined>(undefined);
    const [olrRetryIntervalValid, setOlrRetryIntervalValid] = useState(props.isEdit);
    const [olrCurrencyInput, setOlrCurrencyInput] = useState("");
    const [olrCurrencyValid, setOlrCurrencyValid] = useState(props.isEdit);
    const [ecaRetryCountInput, setEcaRetryCountInput] = useState<number | undefined>(undefined);
    const [ecaRetryCountValid, setEcaRetryCountValid] = useState(props.isEdit);
    const [vip, setVip] = useState(0);
    const [vipValid, setVipValid] = useState(props.isEdit);
    const [nonVip, setNonVip] = useState(0);
    const [nonVipValid, setNonVipValid] = useState(props.isEdit);

    const [selectedFile, setFile] = useState<number[]>([]);
    const [isLoaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    const [selectedType, setType] = useState(CmsType.FSG_DYNAMIQ);
    const [selectedLanguage, setLanguage] = useState(DefaultLanguage.English);
    const [certFileValid, setFileValid] = useState("");
    const typeOptions: ISelectOption[] = LoadSelectOptionFromEnum(CmsType);
    const languageOptions: ISelectOption[] = LoadSelectOptionFromEnum(DefaultLanguage);
    const [vipGamingAreaOption, setVipGamingAreaOption] = useState([] as ISelectOption[]);
    const [nonVipGamingAreaOption, setNonVipGamingAreaOption] = useState([] as ISelectOption[]);

    const praOptions: IDropdownSelectOption[] = LoadDropdownOptionFromEnum(PraCode);
    const [selectedPra, selectPra] = useState<number[]>([]);
    const [praValid, setPraValid] = useState(props.isEdit);
    const [praValue, setPraValue] = useState<IDropdownSelectOption[]>([]);

    const cmsStates = useSelector((state: IStore) => state.cmsState);
    const { cmsData, vipGamingArea, nonVipGamingArea, loading } = cmsStates;

    useEffect(() => {
        if (props.isEdit && props.editID) {
            dispatch(SelectCms(props.editID));
        } else {
            dispatch(GetNonVipGamingArea());
            dispatch(GetVipGamingArea());
            ClearFields();
        }
    }, [props.isEdit]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        setLoaded(!loading);
    }, [loading])

    useEffect(() => {
        if (props.isEdit && cmsData.usm) {
            setUsernameInput(cmsData.usm);
            setPasswordInput(cmsData.usp);
            setCashierIDInput(cmsData.csid);
            setProfitCenterInput(cmsData.prc);
            setDomainInput(cmsData.dom);
            setPortInput(cmsData.prt);
            setCertPasswordInput(cmsData.cep);
            setOlrRetryCountInput(cmsData.orc);
            setEcaRetryCountInput(cmsData.erc);
            setOlrRetryIntervalInput(cmsData.ori);
            setOlrCurrencyInput(cmsData.ocu);
            setType(cmsData.typ);
            setLanguage(cmsData.dfl);
            setFile(DECODE_STR_TO_ARR(cmsData.cer as unknown as string));

            var selectedOptions: IDropdownSelectOption[] = []
            var pra = cmsData.pra
            praOptions.reverse().map(x => {
                if ((pra & Number(x.value)) === Number(x.value)) {
                    selectedOptions.push({ label: x.label, value: x.value })
                    pra &= ~Number(x.value)
                }
            })

            setPraValue(selectedOptions)

            dispatch(GetNonVipGamingArea());
            dispatch(GetVipGamingArea());
        }
    }, [cmsData]);

    useEffect(() => {
        if (nonVipGamingArea && nonVipGamingArea.gal) {
            var defaultID: number = 0;
            const gamingAreas: ISelectOption[] = nonVipGamingArea.gal.map((item, index) => {
                if (index == 0) {
                    defaultID = item.gid;
                }

                return {
                    display: item.gan,
                    value: item.gid.toString()
                };
            });

            setNonVipGamingAreaOption(gamingAreas);

            if (props.editID && cmsData.usm) {
                setNonVip(cmsData.nga ? cmsData.nga.gid : 0);
            }
            else {
                setNonVip(defaultID);
                setNonVipValid(true);
            }
        }
    }, [nonVipGamingArea]);

    useEffect(() => {
        if (vipGamingArea && vipGamingArea.gal) {
            var defaultID: number = 0;
            const gamingAreas: ISelectOption[] = vipGamingArea.gal.map((item, index) => {
                if (index == 0) {
                    defaultID = item.gid;
                }

                return {
                    display: item.gan,
                    value: item.gid.toString()
                };
            });

            setVipGamingAreaOption(gamingAreas);

            if (props.editID && cmsData.usm) {
                setVip(cmsData.vga ? cmsData.vga.gid : 0);
            }
            else {
                setVip(defaultID);
                setVipValid(true);
            }
        }
    }, [vipGamingArea]);

    const ClearFields = () => {
        setUsernameInput("");
        setPasswordInput("");
        setCashierIDInput("");
        setProfitCenterInput("");
        setDomainInput("");
        setPortInput(undefined);
        setCertPasswordInput("");
        setOlrRetryCountInput(undefined);
        setEcaRetryCountInput(undefined);
        setOlrRetryIntervalInput(undefined);
        setOlrCurrencyInput("");
        setType(1);
        setLanguage(1);
        setFile([]);
        setVip(0);
        setNonVip(0);
        setPraValue([]);
    }

    const Valid = (): boolean => {
        return usernameValid && passwordValid && cashierIDValid && profitCenterValid && praValid && domainValid && portValid && certPasswordValid
            && olrRetryCountValid && olrRetryIntervalValid && olrCurrencyValid && ecaRetryCountValid && selectedFile != []
            && vipValid && nonVipValid
    }

    const save = () => {
        if (Valid() && (olrRetryCountInput != null && olrRetryCountInput >= 0) && olrRetryIntervalInput
            && (ecaRetryCountInput != null && ecaRetryCountInput >= 0) && portInput) {
            if (!props.isEdit) {
                dispatch(
                    AddCms(
                        selectedType as number,
                        domainInput,
                        portInput,
                        selectedLanguage as number,
                        selectedFile,
                        certPasswordInput,
                        usernameInput,
                        passwordInput,
                        cashierIDInput,
                        profitCenterInput,
                        selectedPra,
                        olrRetryCountInput,
                        ecaRetryCountInput,
                        olrRetryIntervalInput,
                        olrCurrencyInput,
                        vip,
                        nonVip
                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditCms(
                            props.editID,
                            selectedType as number,
                            domainInput,
                            portInput,
                            selectedLanguage as number,
                            selectedFile,
                            certPasswordInput,
                            usernameInput,
                            passwordInput,
                            cashierIDInput,
                            profitCenterInput,
                            selectedPra,
                            olrRetryCountInput,
                            ecaRetryCountInput,
                            olrRetryIntervalInput,
                            olrCurrencyInput,
                            vip,
                            nonVip
                        ));
                }
            }
        } else {
            TRIGGER_BLUR("domain");
            TRIGGER_BLUR("port");
            TRIGGER_BLUR("type");
            TRIGGER_BLUR("lang");
            TRIGGER_BLUR("username");
            TRIGGER_BLUR("password");
            TRIGGER_BLUR("cert-password");
            TRIGGER_BLUR("olr-retry-count");
            TRIGGER_BLUR("eca-retry-count");
            TRIGGER_BLUR("olr-retry-interval");
            TRIGGER_BLUR("olr-currency");
            TRIGGER_BLUR("viparea");
            TRIGGER_BLUR("nonviparea");
            TRIGGER_BLUR("cashierID");
            TRIGGER_BLUR("profitCenter");
            TRIGGER_BLUR("praSelectDropdown");

            if (selectedFile.length <= 0) {
                setFileValid("Cert file is required");
            }
        }
        props.setSaveTrigger(false);
    }

    const onFileChange = async (files?: FileList) => {
        if (files) {
            var x = await GET_FILE_DATA(files);
            await setFile(x);
            setFileValid("");
        }
    }

    return (
        <Container>
            {isLoaded ? 
                <Form>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="domain">
                                    Domain
                                </Label>
                                <InputBox
                                    id="domain"
                                    name="domain"
                                    type={TextboxType.URL}
                                    inputState={setDomainInput}
                                    placeholder={"Domain"}
                                    validState={setDomainValid}
                                    isRequired={true}
                                    value={domainInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="port">
                                    Port
                                </Label>
                                <InputBox
                                    id="port"
                                    name="port"
                                    type={TextboxType.NUMBER}
                                    max={32767}
                                    inputState={setPortInput}
                                    placeholder={"Port"}
                                    validState={setPortValid}
                                    isRequired={true}
                                    value={portInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="type" name="type" label="Cms Type"
                                    options={typeOptions} value={cmsData.typ}
                                    inputState={setType}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="lang" name="lang" label="Default Language"
                                    options={languageOptions} value={cmsData.dfl}
                                    inputState={setLanguage}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="vip" name="vip" label="Vip Gaming Area"
                                    options={vipGamingAreaOption} value={cmsData.vga?.gid}
                                    inputState={setVip} validState={setVipValid}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="nonvip" name="nonvip" label="Non-Vip Gaming Area"
                                    options={nonVipGamingAreaOption} value={cmsData.nga?.gid}
                                    inputState={setNonVip} validState={setNonVipValid}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="username">
                                    Username
                                </Label>
                                <InputBox
                                    id="username"
                                    name="username"
                                    type={TextboxType.TEXT}
                                    inputState={setUsernameInput}
                                    validState={setUsernameValid}
                                    placeholder={"Username"}
                                    isRequired={true}
                                    value={usernameInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="password">
                                    Password
                                </Label>
                                <InputBox
                                    id="password"
                                    name="password"
                                    type={TextboxType.PASSWORD}
                                    inputState={setPasswordInput}
                                    placeholder={"Password"}
                                    validState={setPasswordValid}
                                    isRequired={true}
                                    value={passwordInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="cashierID">
                                    Cashier ID
                                </Label>
                                <InputBox
                                    id="cashierID"
                                    name="cashierID"
                                    type={TextboxType.TEXT}
                                    inputState={setCashierIDInput}
                                    validState={setCashierIDValid}
                                    placeholder={"Cashier ID"}
                                    isRequired={true}
                                    value={cashierIDInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="profitCenter">
                                    Profit Center
                                </Label>
                                <InputBox
                                    id="profitCenter"
                                    name="profitCenter"
                                    type={TextboxType.TEXT}
                                    inputState={setProfitCenterInput}
                                    validState={setProfitCenterValid}
                                    placeholder={"Profit Center"}
                                    isRequired={true}
                                    value={profitCenterInput}
                                    maxlength={5}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="cert">
                                    Certificate
                                </Label>
                                <Input
                                    id="cert"
                                    name="cert"
                                    type='file'
                                    onChange={e => onFileChange(e.target.files ? e.target.files : undefined)}
                                    invalid={certFileValid != ""}
                                    disabled={!props.canEdit}
                                    required={true}
                                />
                                <FormFeedback>{certFileValid}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="cert-password">
                                    Certificate Password
                                </Label>
                                <InputBox
                                    id="cert-password"
                                    name="cert-password"
                                    type={TextboxType.PASSWORD}
                                    inputState={setCertPasswordInput}
                                    placeholder={"Certificate Password"}
                                    validState={setCertPasswordValid}
                                    isRequired={true}
                                    value={certPasswordInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <fieldset className="border p-2 mt-3">
                        <legend className="float-none w-auto">OLR Setting</legend>
                        <Row>
                            <Col>
                                <InputBox
                                    id="olr-retry-count"
                                    name="olr-retry-count"
                                    label="OLR Retry Count"
                                    type={TextboxType.NUMBER}
                                    min={0}
                                    inputState={setOlrRetryCountInput}
                                    placeholder={"OLR Retry Count"}
                                    validState={setOlrRetryCountValid}
                                    isRequired={true}
                                    value={olrRetryCountInput}
                                    isDisabled={!props.canEdit}
                                />
                            </Col>
                            <Col>
                                <InputBox
                                    id="olr-retry-interval"
                                    name="olr-retry-interval"
                                    label="OLR Retry Interval (s)"
                                    type={TextboxType.NUMBER}
                                    inputState={setOlrRetryIntervalInput}
                                    placeholder={"OLR Retry Interval"}
                                    validState={setOlrRetryIntervalValid}
                                    isRequired={true}
                                    value={olrRetryIntervalInput}
                                    isDisabled={!props.canEdit}
                                />
                            </Col>
                            <Col>
                                <InputBox
                                    id="olr-currency"
                                    name="olr-currency"
                                    label="OLR Currency"
                                    type={TextboxType.TEXT}
                                    min={2}
                                    inputState={setOlrCurrencyInput}
                                    placeholder={"OLR Currency"}
                                    validState={setOlrCurrencyValid}
                                    isRequired={true}
                                    value={olrCurrencyInput}
                                    isDisabled={!props.canEdit}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Col>
                                    <DropdownSelectBox allowSelectAll={true} id="praSelectDropdown" name="praSelectDropdown" options={praOptions} value={praValue}
                                        isRequired={true} inputState={selectPra} validState={setPraValid} placeholder="----Select Player Rating Arguments----" label="Player Rating Arguments"
                                        isDisabled={!props.canEdit}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset className="border p-2 mt-3">
                        <legend className="float-none w-auto">ECA Setting</legend>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="eca-retry-count">
                                        ECA Retry Count
                                    </Label>
                                    <InputBox
                                        id="eca-retry-count"
                                        name="eca-retry-count"
                                        type={TextboxType.NUMBER}
                                        min={0}
                                        inputState={setEcaRetryCountInput}
                                        placeholder={"ECA Retry Count"}
                                        validState={setEcaRetryCountValid}
                                        isRequired={true}
                                        value={ecaRetryCountInput}
                                        isDisabled={!props.canEdit}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                </Form>
                :
                <div className='text-center p-4'><Spinner key='1' /></div>
            }
        </Container>
    )
}

const CmsModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const cmsState = useSelector((state: IStore) => state.cmsState);
    const { err, suc, loading } = cmsState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.CMS_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit CMS" : "Add CMS"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <CmsForm isEdit={props.isEdit} canEdit={canEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? <Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default CmsModal;

