import { JackpotServiceListModel, JackpotServiceModel } from "../../../dto/models/ServiceModels";
export interface IJackpotServiceStates {
    loading: boolean;
    err: string;
    suc: string;
    jackpotServiceListData: JackpotServiceListModel;
    jackpotServiceData: JackpotServiceModel;
}

export const InitJackpotServiceState: IJackpotServiceStates = {
    loading: false,
    err: "",
    suc: "",
    jackpotServiceListData: {} as JackpotServiceListModel,
    jackpotServiceData: {} as JackpotServiceModel,
}
