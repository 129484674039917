import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, DropdownItem, FormGroup, Row } from "reactstrap";
import { posix } from "rimraf";
import AlertMessage from "./AlertMessage/AlertMessage";
import { AlertSize, AlertType } from "./AlertMessage/IAlert";
import CollapseContainer from "./CollapseContainer/CollapseContainer";
import DataTable from "./DataTable/DataTable";
import { IDataTable } from "./DataTable/IDataTable";
import DropdownButton from "./Dropdown/DropdownButton";
import { DropDownButtonStyle, DropdownDirection } from "./Dropdown/IDropdownButton";
import { ITablePagination } from "./TablePagination/ITablePagination";
import TablePagination from "./TablePagination/TablePagination";

export interface IStandardReportContainer {
    id: string;
    loading: boolean;
    error: string | undefined;
    success: string | undefined;
    searchCriteriaContent: JSX.Element;

    tableProps: IDataTable;
    tablePagination: ITablePagination;

    generatePDFEvent: () => void;
    generateCSVEvent: () => void;
}

const StandardReportContainer = (props: IStandardReportContainer) => {
    const [dummy, setDummy] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [isExpand, setExpand] = useState(true);

    useEffect(() => {
        if (!isAlertOpen && alertMessage != "") {
            setAlertMessage("");
        }
        else {
            if (props.error != undefined && props.error != "") {
                setAlertMessage(props.error);
                setAlertOpen(true);
            }
            else if (props.success != undefined && props.success != "") {
                setAlertMessage(props.success);
                setAlertOpen(true);
            }
        }
        setDummy(!dummy);
    }, [props.error, props.success]);

    const dropdownActionButtons = (): JSX.Element[] => {
        const dropdownButtons: JSX.Element[] =
            [
                <DropdownItem key="0" onClick={props.generatePDFEvent}><FontAwesomeIcon icon={faDownload} /> Export PDF</DropdownItem>,
                <DropdownItem key="1" onClick={props.generateCSVEvent}><FontAwesomeIcon icon={faDownload} /> Export CSV</DropdownItem>,
            ];

        return dropdownButtons;
    }

    return (
        <Container fluid>
            <hr />
            <Row>
                <Col>
                    <AlertMessage
                        content={alertMessage}
                        alertSize={AlertSize.LONG}
                        alertType={AlertType.FAIL}
                        isOpen={isAlertOpen && props.error != undefined && props.error != ""}
                        openState={setAlertOpen}
                    />
                    <AlertMessage
                        content={alertMessage}
                        alertSize={AlertSize.LONG}
                        alertType={AlertType.SUCCESS}
                        isOpen={isAlertOpen && props.success != undefined && props.success != ""}
                        openState={setAlertOpen}
                    />
                </Col>
            </Row>
            <div>
                <Row>
                    <CollapseContainer
                        content={props.searchCriteriaContent}
                        isOpen={isExpand}
                        setOpenState={setExpand}
                        title={"Search Criteria"}
                    />
                </Row>
                <Row>
                    <Col className="col-sm-auto pt-2">
                        <DropdownButton dropdownButtonStyle={DropDownButtonStyle.NORMAL} isOutline={false} dropdownTitle={"Perform Actions"} dropdownItems={dropdownActionButtons()} direction={DropdownDirection.DOWN} disable={false} />
                    </Col>
                </Row>
                <Row>
                    <div style={{ marginTop: "0.5rem" }}>
                        <DataTable
                            title={props.tableProps.title}
                            data={props.tableProps.data}
                            isloading={props.tableProps.isloading}
                            isStriped={props.tableProps.isStriped}
                            isHover={props.tableProps.isHover}
                            tableStyle={props.tableProps.tableStyle}
                            responsive={props.tableProps.responsive}
                            columnWidth={props.tableProps.columnWidth}
                            subHeader={props.tableProps.subHeader}
                            rowBoldIndex={props.tableProps.rowBoldIndex}
                            concludeColumn={props.tableProps.concludeColumn}
                        />
                    </div>
                </Row>
                <Row>
                    {
                        props.tablePagination ?
                            <TablePagination
                                id={props.id}
                                maxPage={props.tablePagination.maxPage}
                                totalCount={props.tablePagination.totalCount}
                                onChangePage={props.tablePagination.onChangePage}
                                customTableMaxRow={props.tablePagination.customTableMaxRow}
                                currentPage={props.tablePagination.currentPage}
                            />
                            : ""
                    }
                </Row>
            </div>
        </Container>
    );
}

export default StandardReportContainer;