import { faGaugeHigh, faHouse } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Dashboard from "../containers/Common/Dashboard";
import "../styles/Sidebar.css";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const DASHBOARD_URL = "/";

export const CommonRoutesData: IRouteData[] = [
    {
        to: "/*",
        element: <h2>PAGE NOT FOUND.</h2>
    },
    {
        to: DASHBOARD_URL,
        element: <Dashboard />
    }
];

export const CommonNavigationData: INavigationData[] = [
    {
        requiredAccess: [0],
        icon: faGaugeHigh,
        title: "Home",
        path: "Home",
        searchKeyword: "Home",
        to: DASHBOARD_URL
    }
];

export function CommonSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(CommonNavigationData, "Home", faHouse, "#40967c", open, access, currentPath);
}