import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { UserProfileModel } from "../../../dto/models/UserAccessModels";
import { TRIGGER_BLUR } from "../../../libraries/Functions";
import { SET_PROFILE, TOKEN_CONST, UID_CONST } from "../../../libraries/Global";
import { IStore } from "../../../states/store/IStore";
import { ChangePassword } from "../../../states/User/UserActions";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";


interface IRemarkForm {
    loading: boolean;
    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordForm = (props: IRemarkForm) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordValid, setCurrentPasswordValid] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordValid, setNewPasswordValid] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

    const dispatch = useDispatch();

    const valid = () => {
        return currentPasswordValid && newPasswordValid && confirmPasswordValid;
    };

    useEffect(() => {
        if (props.saveTrigger)
            save();
    }, [props.saveTrigger])

    const save = () => {
        if (valid())
            dispatch(ChangePassword(currentPassword, newPassword, confirmPassword));
        else {
            TRIGGER_BLUR("currentPassword");
            TRIGGER_BLUR("newPassword");
            TRIGGER_BLUR("confirmPassword");
        }
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col>
                        <InputBox id="currentPassword" name="currentPassword" invalidMessage="Current Password cannot be blank." validState={setCurrentPasswordValid} inputState={setCurrentPassword} value={currentPassword} label="Current Password" isRequired={true} type={TextboxType.PASSWORD} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox id="newPassword" name="newPassword" invalidMessage="New Password cannot be blank." validState={setNewPasswordValid} inputState={setNewPassword} value={newPassword} label="New Password" isRequired={true} type={TextboxType.PASSWORD} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox id="confirmPassword" name="confirmPassword" invalidMessage="Confirm Password cannot be blank." validState={setConfirmPasswordValid} inputState={setConfirmPassword} value={confirmPassword} label="Confirm Password" isRequired={true} type={TextboxType.PASSWORD} />
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

const ChangePasswordModal = (props: { isOpen: boolean, setOpenState: any, disableCloseBtn: boolean}) => {
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileErr, userProfileSuc, userProfileWarning, loading } = userState;
    const [save, setSave] = useState(false);

    const [sucMsg, setSuccessMsg] = useState("");
    const [errMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setSuccessMsg(userProfileSuc);
    }, [userProfileSuc]);

    useEffect(() => {
        setErrorMsg(userProfileErr);
    }, [userProfileErr]);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        setSuccessMsg("");
        setErrorMsg("");
    }, [props.isOpen])


    return (
        <div>
            <ModalBox
                title="Change Password"
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={sucMsg}
                warning={userProfileWarning}
                disableCloseButton={props.disableCloseBtn}
                child={
                    <ChangePasswordForm loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={<Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button>}
            />
        </div>
    );
}

export default ChangePasswordModal;