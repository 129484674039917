import { GamingAreaType } from "../../dto/enums/GamingAreaType";
import { GamingAreaModel, IPAddressRange } from "../../dto/models/GamingAreaModels";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload } from "../store/IStore";
import { GamingAreaActionTypes } from "./GamingAreaActionTypes";

type GetGamingAreaRequest = { type: GamingAreaActionTypes.GET_GAMING_AREA_REQUEST, payload: IPayload };
type SelectGamingAreaRequest = { type: GamingAreaActionTypes.SELECT_GAMING_AREA_REQUEST, payload: IPayload };
type EditGamingAreaRequest = { type: GamingAreaActionTypes.EDIT_GAMING_AREA_REQUEST, payload: IPayload };
type AddGamingAreaRequest = { type: GamingAreaActionTypes.ADD_GAMING_AREA_REQUEST, payload: IPayload };
type SearchGamingAreaRequest = { type: GamingAreaActionTypes.SEARCH_GAMING_AREA_REQUEST, payload: IPayload };

export type ActionTypes = GetGamingAreaRequest | SelectGamingAreaRequest | EditGamingAreaRequest | AddGamingAreaRequest | SearchGamingAreaRequest;

export const GetGamingAreaRequest = (from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: take
        };

        PostRequest("BackOffice/GetGamingAreaMaintenance", request, GamingAreaActionTypes.GET_GAMING_AREA_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GamingAreaActionTypes.GET_GAMING_AREA_REQUEST, dispatch);
    }
}

export const SelectGamingAreaRequest = (id: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: id
        };

        PostRequest("BackOffice/SelectGamingAreaMaintenance", request, GamingAreaActionTypes.SELECT_GAMING_AREA_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GamingAreaActionTypes.SELECT_GAMING_AREA_REQUEST, dispatch);
    }
}

export const AddGamingAreaRequest = (name: string, areaType: GamingAreaType, nres_transferin: number, nres_transferout: number, nres_wallet: string,
    res_transferin: number, res_transferout: number, res_wallet: string,
    slotLocation: string, bigWinLimit: number, iprange: IPAddressRange[]) => async (dispatch: any) => {
        try {
            let request: GamingAreaModel = {
                gid: 0,
                gan: name,
                typ: areaType,
                nti: nres_transferin,
                nto: nres_transferout,
                nwt: nres_wallet,
                rti: res_transferin,
                rto: res_transferout,
                rwt: res_wallet,
                slt: slotLocation,
                bwl: bigWinLimit,
                ipr: iprange
            };

            PostRequest("BackOffice/AddGamingAreaMaintenance", request, GamingAreaActionTypes.ADD_GAMING_AREA_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, GamingAreaActionTypes.ADD_GAMING_AREA_REQUEST, dispatch);
        }
    }

export const EditGamingAreaRequest = (id: number, name: string, areaType: GamingAreaType, nres_transferin: number, nres_transferout: number, nres_wallet: string,
    res_transferin: number, res_transferout: number, res_wallet: string,
    slotLocation: string, bigWinLimit: number, iprange: IPAddressRange[]) => async (dispatch: any) => {
        try {
            let request: GamingAreaModel = {
                gid: id,
                gan: name,
                typ: areaType,
                nti: nres_transferin,
                nto: nres_transferout,
                nwt: nres_wallet,
                rti: res_transferin,
                rto: res_transferout,
                rwt: res_wallet,
                slt: slotLocation,
                bwl: bigWinLimit,
                ipr: iprange
            };

            PostRequest("BackOffice/EditGamingAreaMaintenance", request, GamingAreaActionTypes.EDIT_GAMING_AREA_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, GamingAreaActionTypes.EDIT_GAMING_AREA_REQUEST, dispatch);
        }
    }

export const SearchGamingArea = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };

        PostRequest("BackOffice/SearchGamingAreaMaintenance", request, GamingAreaActionTypes.SEARCH_GAMING_AREA_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GamingAreaActionTypes.SEARCH_GAMING_AREA_REQUEST, dispatch);
    }
}