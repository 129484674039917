import { NotificationModel } from "../../dto/models/NotificationModel";

export interface INotificationState {
    loading: boolean;
    err: string;
    suc: string;
    data: NotificationModel;
}

export const InitialNotificationState: INotificationState = {
    loading: false,
    err: "",
    suc: "",
    data: {} as NotificationModel
};