import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import AccessMatrixModal from '../../components/_modals/AccessControl/AccessMatrixModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { TableStatus } from '../../dto/enums/TableStatus';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetAccessMatrix, SearchAccessMatrix } from '../../states/AccessMatrix/AccessMatrixActions';
import { IStore } from '../../states/store/IStore';

const AccessMatrixMaintenance = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [canEdit, setCanEdit] = useState(false);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const amStates = useSelector((state: IStore) => state.accessMatrixState);
    const { loading, err, suc, roleList } = amStates;
    const [isSearch, setSearch] = useState(false);
    const prevIsSearch = useRef(false);

    const emptyTable: IDataTable = {
        title: ["No", "Role Name", "Description", "Status", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [amTable, setAmTable] = useState(emptyTable)

    useEffect(() => {
        if (!openModal) {
            dispatch(GetAccessMatrix(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    useEffect(() => {
        prevIsSearch.current = isSearch;
    }, [isSearch]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchAccessMatrix(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetAccessMatrix(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddRole = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditRole = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (roleList.rll && roleList.rll.length > 0 && roleList.rll[0].rnm) {

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = roleList.rll.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.rnm,
                    item.des,
                    TableStatus[item.stt],
                    <Button onClick={() => EditRole(item.rid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setAmTable(table)
    }, [roleList])

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.ACCESS_MATRIX_MAINTENANCE))
        }
    }, [])

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetAccessMatrix(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchAccessMatrix(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "rolePagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: roleList.ttc ?? 0
    }


    return (
        <div>
            <AccessMatrixModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="AccessMatrixMaintenanceContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                searchValue={searchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddRole : undefined}
                tableProps={amTable}
                tablePagination={pagination}
            />
        </div>
    );
};

export default AccessMatrixMaintenance;
