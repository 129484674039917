import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { TableStatus } from "../../../dto/enums/TableStatus";
import { AccumulatorModel } from "../../../dto/models/JackpotModels";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { GetAccumulatorList } from "../../../states/PAS/JackpotAccumulator/AccumulatorActions";
import { AddJackpotService, EditJackpotService, SelectJackpotService } from "../../../states/PAS/JackpotService/JackpotServiceActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import TextField from "../../InputBox/TextField";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IJackpotServiceForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const JackpotServiceForm = (props: IJackpotServiceForm) => {
    const [nameInput, setNameInput] = useState("");
    const [nameValid, setNameValid] = useState(props.isEdit);
    const [requestAddressInput, setRequestAddressInput] = useState("");
    const [requestAddressValid, setRequestAddressValid] = useState(props.isEdit);
    const [descriptionInput, setDescriptionInput] = useState("");

    const [selectedStatus, setStatus] = useState(TableStatus.ACTIVE);
    const [selectedAccumulator, setAccumulator] = useState(0);
    const [accumulatorOption, setAccumulatorOption] = useState([] as ISelectOption[]);
    const [accumulatorValid, setAccumulatorValid] = useState(props.isEdit);
    const statusOptions: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);

    const dispatch = useDispatch();
    const serviceStates = useSelector((state: IStore) => state.jackpotServiceState);
    const accumulatorStates = useSelector((state: IStore) => state.accumulatorState);
    const { jackpotServiceData } = serviceStates;
    const { accumulatorListData } = accumulatorStates;
    const [isLoaded, setLoaded] = useState(false);
    const serviceLoading = serviceStates.loading;
    const accumulatorLoading = accumulatorStates.loading;

    useEffect(() => {
        setLoaded(!serviceLoading && !accumulatorLoading);
    }, [serviceLoading, accumulatorLoading])

    useEffect(() => {
        if (accumulatorListData && accumulatorListData.acl) {
            const accumulator: ISelectOption[] = accumulatorListData.acl.map((item) => {
                return {
                    display: item.jan,
                    value: item.aid.toString()
                }
            });

            setAccumulatorOption(accumulator);
            if (accumulator.length != 0) {
                setAccumulator(accumulatorListData.acl[0].aid);
                setAccumulatorValid(true);
            }
        }
    }, [accumulatorListData]);

    useEffect(() => {
        dispatch(GetAccumulatorList());
        if (props.isEdit && props.editID) {
            dispatch(SelectJackpotService(props.editID));
        } else
            ClearFields();

    }, [props.isEdit]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (props.isEdit && jackpotServiceData.snm) {
            setNameInput(jackpotServiceData.snm);
            setRequestAddressInput(jackpotServiceData.pyl);
            setDescriptionInput(jackpotServiceData.des);
            setStatus(jackpotServiceData.stt);
            setAccumulator(jackpotServiceData.jam.aid);
        }
    }, [jackpotServiceData]);

    const ClearFields = () => {
        setNameInput("");
        setRequestAddressInput("");
        setDescriptionInput("");
        setStatus(TableStatus.ACTIVE);
        setAccumulator(0);
    }


    const Valid = (): boolean => {
        return accumulatorValid && requestAddressValid && nameValid
    }

    const save = () => {
        if (Valid() && requestAddressInput && nameInput) {
            var accumulator: AccumulatorModel = {
                aid: selectedAccumulator, apl: [], des: "", jan: "", thc: 0, cat: 0
            }
            if (!props.isEdit) {
                dispatch(
                    AddJackpotService(
                        nameInput,
                        requestAddressInput,
                        descriptionInput,
                        selectedStatus as number,
                        accumulator,
                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditJackpotService(
                            props.editID,
                            nameInput,
                            requestAddressInput,
                            descriptionInput,
                            selectedStatus as number,
                            accumulator,
                        ));
                }
            }
        } else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("ip");
            TRIGGER_BLUR("port");
            TRIGGER_BLUR("des");
            TRIGGER_BLUR("status");
            TRIGGER_BLUR("accumulator");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            {isLoaded ?
                <Form>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="name">
                                    Name
                                </Label>
                                <InputBox
                                    id="name"
                                    name="name"
                                    type={TextboxType.TEXT}
                                    inputState={setNameInput}
                                    placeholder={"Name"}
                                    validState={setNameValid}
                                    isRequired={true}
                                    value={nameInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="status" name="status" label="Status"
                                    options={statusOptions} value={props.isEdit ? jackpotServiceData.stt : TableStatus.ACTIVE}
                                    isDisabled={!props.isEdit || !props.canEdit}
                                    inputState={setStatus}
                                    isRequired={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="accumulator" name="accumulator" label="Accumulator"
                                    options={accumulatorOption} value={jackpotServiceData.jam ? jackpotServiceData.jam.aid : 0}
                                    inputState={setAccumulator} validState={setAccumulatorValid}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="ip">
                                    Request Address
                                </Label>
                                <InputBox
                                    id="ip"
                                    name="ip"
                                    type={TextboxType.URL}
                                    inputState={setRequestAddressInput}
                                    validState={setRequestAddressValid}
                                    placeholder={"Request Address"}
                                    isRequired={true}
                                    value={requestAddressInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <TextField id="des" name="des" label="Description"
                                    placeholder="Description" value={props.isEdit ? jackpotServiceData.des : ""}
                                    inputState={setDescriptionInput} maxLength={300} col={5} row={5}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                :
                <div className='text-center p-4'><Spinner key='1' /></div>
            }
        </Container>
    )
}

const JackpotServiceModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const serviceState = useSelector((state: IStore) => state.jackpotServiceState);
    const accState = useSelector((state: IStore) => state.accumulatorState);
    const { err, suc, loading } = serviceState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        if (accState.err !== "") {
            setErrMsg(accState.err)
            return;
        }

        if (err !== "") {
            setErrMsg(err)
            return;
        }

        setErrMsg("");
    }, [accState.err, err])

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit Jackpot Service" : "Add Jackpot Service"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={suc}
                child={
                    <JackpotServiceForm isEdit={props.isEdit} canEdit={canEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? <Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default JackpotServiceModal;

