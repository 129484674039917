import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload } from "../store/IStore";
import { DashboardActionTypes } from "./DashboardActionTypes";

type GetJackpotMeterRequest = { type: DashboardActionTypes.GET_JACKPOT_METER_REQUEST, payload: IPayload };
type GetServiceGameRtpRequest = { type: DashboardActionTypes.GET_GAME_SERVICE_RTP_REQUEST, payload: IPayload };

export type ActionTypes = GetJackpotMeterRequest | GetServiceGameRtpRequest;

export const GetJackpotMeter = () => async (dispatch: any) => {
    try {
        PostRequest("BackOffice/GetJackpotMeter", null, DashboardActionTypes.GET_JACKPOT_METER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, DashboardActionTypes.GET_JACKPOT_METER_REQUEST, dispatch);
    }
}

export const GetGameServiceInfo = () => async (dispatch: any) => {
    try {
        PostRequest("BackOffice/GetGameServiceInfo", null, DashboardActionTypes.GET_GAME_SERVICE_RTP_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, DashboardActionTypes.GET_GAME_SERVICE_RTP_REQUEST, dispatch);
    }
}

