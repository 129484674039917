import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import AlertMessage from "./AlertMessage/AlertMessage";
import { AlertSize, AlertType } from "./AlertMessage/IAlert";
import DataTable from "./DataTable/DataTable";
import { IDataTable } from "./DataTable/IDataTable";
import DropdownButton from "./Dropdown/DropdownButton";
import { DropDownButtonStyle, DropdownDirection } from "./Dropdown/IDropdownButton";
import { TextboxType } from "./InputBox/IInputBox";
import InputBox from "./InputBox/InputBox";
import { ITablePagination } from "./TablePagination/ITablePagination";
import TablePagination from "./TablePagination/TablePagination";

export interface IStandardContainer {
    id: string;
    loading: boolean;
    error: string | undefined;
    success: string | undefined;

    tableProps?: IDataTable;
    tablePagination?: ITablePagination;
    actionDropdownItems?: JSX.Element[]

    searchValue?: string
    searchEvent?: () => void;
    clearEvent?: () => void;
    searchInput?: React.Dispatch<React.SetStateAction<string>>
    isSearch?: boolean;
    addEvent?: () => void;
}

const StandardContainer = (props: IStandardContainer) => {
    const [dummy, setDummy] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        if (!isAlertOpen && alertMessage != "") {
            setAlertMessage("");
        }
        else {
            if (props.error != undefined && props.error != "") {
                setAlertMessage(props.error);
                setAlertOpen(true);
            }
            else if (props.success != undefined && props.success != "") {
                setAlertMessage(props.success);
                setAlertOpen(true);
            }
        }
        setDummy(!dummy);
    }, [props]);

    return (
        <Container fluid>
            <hr />
            <Row>
                <Col>
                    <AlertMessage
                        content={alertMessage}
                        alertType={AlertType.FAIL}
                        isOpen={isAlertOpen && props.error != undefined && props.error != ""}
                        openState={setAlertOpen}
                        alertSize={AlertSize.LONG}
                    />
                    <AlertMessage
                        content={alertMessage}
                        alertType={AlertType.SUCCESS}
                        isOpen={isAlertOpen && props.success != undefined && props.success != ""}
                        openState={setAlertOpen}
                        alertSize={AlertSize.LONG}
                    />
                </Col>
            </Row>
            <div>
                <Row>
                    {props.addEvent ?
                        <Col xs="auto">
                            <FormGroup>
                                <Button
                                    color="info"
                                    outline
                                    onClick={props.addEvent}
                                >
                                    <span><FontAwesomeIcon icon={faPlus} /> New Item</span>
                                </Button>
                            </FormGroup>
                        </Col>
                        : ""}
                    <Col>
                        {props.searchEvent && props.searchInput ?
                            <InputBox
                                type={TextboxType.TEXT}
                                id="container_search"
                                name="container_search"
                                placeholder="Search"
                                inputState={props.searchInput}
                                value={props.searchValue}
                            />
                            : ""}
                    </Col>
                    {props.searchEvent && props.searchInput ?
                        <Col>
                            <Button color="secondary" className={"me-1"} outline onClick={props.searchEvent}>Search</Button>
                            <Button color="danger" outline disabled={!props.isSearch} onClick={props.clearEvent}>Clear</Button>
                        </Col>
                        : ""}
                    {props.actionDropdownItems ?
                        <Col className="col-auto justify-content-end pl-5">
                            <DropdownButton dropdownButtonStyle={DropDownButtonStyle.NORMAL} isOutline={false} dropdownTitle={"Perform Actions"} dropdownItems={props.actionDropdownItems} direction={DropdownDirection.DOWN} disable={false} />
                        </Col> : ""
                    }
                </Row>
                <Row>
                    {
                        props.tableProps ?
                            <div>
                                <DataTable
                                    title={props.tableProps.title}
                                    data={props.tableProps.data}
                                    isloading={props.tableProps.isloading}
                                    isStriped={props.tableProps.isStriped}
                                    isHover={props.tableProps.isHover}
                                    tableStyle={props.tableProps.tableStyle}
                                    columnWidth={props.tableProps.columnWidth}
                                />
                            </div>
                            : ""
                    }
                </Row>
                <Row>
                    {
                        props.tablePagination ?
                            <TablePagination
                                id={props.id}
                                maxPage={props.tablePagination.maxPage}
                                totalCount={props.tablePagination.totalCount}
                                onChangePage={props.tablePagination.onChangePage}
                                isSearch={props.isSearch ? props.isSearch : undefined}
                            />
                            : ""
                    }
                </Row>
            </div>
        </Container>
    );
}


export default StandardContainer;