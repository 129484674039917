export enum PraCode {
    PRA_PTN = 1,
    PRA_ABT = 2,
    PRA_AWL = 4,
    PRA_ASO = 8,
    PRA_ABO = 16,
    PRA_PGP = 32,
    PRA_MASK = 63,
    PRA_TO_MVL = 256,
    PRA_MIN = 512,
    PRA_DRP = 1024,
    PRA_OVG = 2048,
    PRA_DLO = 4096,
    PRA_OTO = 8192,
}