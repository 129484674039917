import { BigWinPendingListModel } from "../../dto/models/BigWinPendingModels";

export interface IBigWinPendingState {
    loading: boolean;
    err: string;
    suc: string;
    bigWinList: BigWinPendingListModel;
}

export const InitialBigWinPendingState: IBigWinPendingState = {
    loading: false,
    err: "",
    suc: "",
    bigWinList: {} as BigWinPendingListModel
};