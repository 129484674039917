import { faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, FormText, Row, Spinner } from "reactstrap";
import { TierParameterType } from "../../../dto/enums/Jackpot/JackpotTierCondition";
import { PoolAdjustmentTransferType } from "../../../dto/enums/Jackpot/JackpotPoolAdjustmentTransferType";
import { CVT_TO_FLOAT_STRING } from "../../../libraries/FloatHelper";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { AdjustPASPool, PoolAdjustmentGetCreditRateRequest } from "../../../states/PAS/JackpotPoolAdjustment/PoolAdjustmentActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import TextField from "../../InputBox/TextField";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";
import { GET_PROFILE, UID_CONST } from "../../../libraries/Global";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";

interface IPoolAdjustmentForm {
    loading: boolean;
    poolID: number;
    tierType: TierParameterType;

    transferInTrigger: boolean;
    setTransferInTrigger: React.Dispatch<React.SetStateAction<boolean>>;

    transferOutTrigger: boolean;
    setTransferOutTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const PoolAdjustmentForm = (props: IPoolAdjustmentForm) => {
    const [remarkInput, setRemark] = useState("");
    const [remarkValid, setRemarkValid] = useState(false);
    const [adjustAmountInput, setAdjustAmount] = useState<string | undefined>(undefined);
    const [adjustAmountValid, setAdjustAmountValid] = useState(false);
    const [totalAmountInput, setTotalAmountInput] = useState("");

    const dispatch = useDispatch();
    const poolAdjustmentState = useSelector((state: IStore) => state.poolAdjustmentState);
    const { adjustmentData, poolData, creditRate, loading } = poolAdjustmentState;

    // confirm modal state
    const [dialog, setDialog] = useState(false);
    const [dialogBtn, setDialogBtn] = useState({} as JSX.Element);
    const [dialogContent, setDialogContent] = useState({} as React.ReactNode);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(!loading);
    }, [loading])

    useEffect(() => {
        dispatch(PoolAdjustmentGetCreditRateRequest());
    }, []);

    useEffect(() => {
        if (props.transferInTrigger) {
            if (Valid() && remarkInput && adjustAmountInput) {
                openDialog(PoolAdjustmentTransferType.TRANSFER_IN);
            } else {
                TRIGGER_BLUR("adjustmentAmount");
                TRIGGER_BLUR("remark");
                closeDialog();
            }
        }
    }, [props.transferInTrigger]);

    useEffect(() => {
        if (props.transferOutTrigger) {
            if (Valid() && remarkInput && adjustAmountInput) {
                openDialog(PoolAdjustmentTransferType.TRANSFER_OUT)
            } else {
                TRIGGER_BLUR("adjustmentAmount");
                TRIGGER_BLUR("remark");
                closeDialog();
            }
        }
    }, [props.transferOutTrigger]);

    useEffect(() => {
        if (props.transferInTrigger)
            props.setTransferInTrigger(dialog);
        else if (props.transferOutTrigger)
            props.setTransferOutTrigger(dialog);

    }, [dialog])

    useEffect(() => {
        if (poolData && poolData.ptt !== undefined)
            setTotalAmountInput(GetCurrentPoolAmount());
    }, [poolData])

    useEffect(() => {
        if (adjustmentData && adjustmentData.pca !== undefined)
            setTotalAmountInput(GetUpdatedPoolAmount());
    }, [adjustmentData])

    const openDialog = (type: PoolAdjustmentTransferType) => {
        switch (type) {
            case PoolAdjustmentTransferType.TRANSFER_IN:
                setDialogContent(<p>Are your sure to Transfer In {adjustAmountInput} into the selected distribution of the pool?</p>);
                break;
            case PoolAdjustmentTransferType.TRANSFER_OUT:
                setDialogContent(<p>Are your sure to Transfer Out {adjustAmountInput} from the selected distribution of the pool?</p>);
                break;
        }
        const buttons: JSX.Element = (
            <div>
                <Button
                    color="info"
                    onClick={() => {
                        save(type);
                        closeDialog();
                    }}
                    outline>
                    <FontAwesomeIcon icon={faFloppyDisk} /> Confirm
                </Button>
                {' '}
                <Button
                    color="secondary"
                    onClick={() => {
                        closeDialog();
                    }}
                    outline>
                    <FontAwesomeIcon icon={faXmark} /> Cancel
                </Button>
            </div>
        );

        setDialogBtn(buttons);
        setDialog(true);
    }

    const closeDialog = () => {
        setDialog(false);
        props.setTransferInTrigger(false);
        props.setTransferOutTrigger(false);
    }

    const Valid = (): boolean => {
        return remarkValid && adjustAmountValid;
    }

    const save = (transferType: PoolAdjustmentTransferType) => {
        if (adjustAmountInput) {
            dispatch(
                AdjustPASPool(
                    parseInt(localStorage.getItem(UID_CONST) as string),
                    remarkInput,
                    props.poolID,
                    transferType,
                    parseFloat(adjustAmountInput.replace(/,/g, '')),
                    props.tierType
                )
            )
        }

        setDialog(false);
        props.setTransferInTrigger(false);
        props.setTransferOutTrigger(false);
    }

    const GetCurrentPoolAmount = (): string => {
        if (poolData) {
            switch (props.tierType) {
                case TierParameterType.PROGRESSIVE:
                    return poolData.pga ? CVT_TO_FLOAT_STRING(poolData.pga) : "0.00";
                case TierParameterType.HOUSE:
                    return poolData.hsa ? CVT_TO_FLOAT_STRING(poolData.hsa) : "0.00";
                case TierParameterType.RESERVE:
                    return poolData.rsa ? CVT_TO_FLOAT_STRING(poolData.rsa) : "0.00";
                default:
                    return "0.00";
            }
        } else
            return "0.00";
    }

    const GetUpdatedPoolAmount = (): string => {
        if (adjustmentData) {
            switch (props.tierType) {
                case TierParameterType.PROGRESSIVE:
                    return adjustmentData.pca ? CVT_TO_FLOAT_STRING(adjustmentData.pca) : "0.00";
                case TierParameterType.HOUSE:
                    return adjustmentData.hca ? CVT_TO_FLOAT_STRING(adjustmentData.hca) : "0.00";
                case TierParameterType.RESERVE:
                    return adjustmentData.rca ? CVT_TO_FLOAT_STRING(adjustmentData.rca) : "0.00";
                default:
                    return "0.00";
            }
        } else
            return "0.00";
    }
    if (isLoaded) {
        return (
            <div>
                <ModalBox
                    title="Confirmation"
                    isOpen={dialog}
                    isFade={true}
                    isCentered={true}
                    isScrollable={true}
                    child={dialogContent}
                    type={ModalType.Dialog}
                    openState={setDialog}
                    footer={dialogBtn}
                    error={""}
                    success={""}
                />
                <Form className="pt-2">
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="distribution"
                                    name="distribution"
                                    label="Pool Distribution"
                                    type={TextboxType.TEXT}
                                    placeholder={"Pool Distribution"}
                                    value={TierParameterType[props.tierType].toString().replace("_", " ")}
                                    isDisabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="name"
                                    name="name"
                                    label="Pool Name"
                                    type={TextboxType.TEXT}
                                    placeholder={"Name"}
                                    value={poolData.pnm}
                                    isDisabled={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="totalAmount"
                                    name="totalAmount"
                                    label="Total Credit"
                                    type={TextboxType.CURRENCY}
                                    placeholder={"Pool Distribution"}
                                    value={totalAmountInput}
                                    isDisabled={true}
                                />
                                <FormText>{totalAmountInput ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(parseFloat(totalAmountInput.replace(/,/g, '')) * creditRate.crt / 100) : ""}</FormText>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="ceilling"
                                    name="ceilling"
                                    label="Jackpot Ceilling (Credit)"
                                    type={TextboxType.CURRENCY}
                                    placeholder={"Jackpot Ceilling"}
                                    value={poolData && poolData.cil ? CVT_TO_FLOAT_STRING(poolData.cil) : 0}
                                    isDisabled={true}
                                />
                                <FormText>{poolData && poolData.cil ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(poolData.cil * creditRate.crt / 100) : ""}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="adjustmentAmount"
                                    name="adjustmentAmount"
                                    label="Adjustment Amount (Credit)"
                                    type={TextboxType.CURRENCY}
                                    placeholder={"Adjustment Amount"}
                                    inputState={setAdjustAmount}
                                    validState={setAdjustAmountValid}
                                    value={adjustAmountInput}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                                <FormText>{adjustAmountInput ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(parseFloat(adjustAmountInput.replace(/,/g, '')) * creditRate.crt / 100) : ""}</FormText>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <TextField id="remark" name="remark" label="Remark"
                                placeholder="Remark" value={remarkInput}
                                inputState={setRemark} validState={setRemarkValid} maxLength={300} col={5} row={5}
                                isRequired={true}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    } else {
        return (<div className='text-center p-4'><Spinner key='1' /></div>);
    }
}

const PoolAdjustmentModal = (props: { poolID?: number, tierType: TierParameterType, isOpen: boolean, setOpenState: any }) => {
    const adjustmentState = useSelector((state: IStore) => state.poolAdjustmentState);
    const { err, suc, loading } = adjustmentState;
    const [transIn, setTransIn] = useState(false);
    const [transOut, setTransOut] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_ADJUSTMENT))
        }
    }, [])

    return (
        <div>
            <ModalBox
                title={"Pool Adjustment"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <PoolAdjustmentForm poolID={props.poolID as number} canEdit={canEdit} loading={loading} tierType={props.tierType} transferInTrigger={transIn} transferOutTrigger={transOut} setTransferInTrigger={setTransIn} setTransferOutTrigger={setTransOut} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ?
                    <div>
                        <Button color="secondary" onClick={e => setTransIn(true)}>Transfer In</Button>
                        {' '}
                        <Button color="secondary" onClick={e => setTransOut(true)}>Transfer Out</Button>
                    </div>
                    : <></>
                }
            />
        </div>
    );
}

export default PoolAdjustmentModal;