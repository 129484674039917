import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetGameServiceRequest } from "../../states/GameService/GameServiceActions";
import { GeneratePlayerPerformanceReportCSVRequest, GeneratePlayerPerformanceReportPDFRequest, GetPlayerPerformanceReportRequest } from "../../states/Reporting/PlayerPerformanceReport/PlayerPerformanceReportingActions";
import { IStore } from "../../states/store/IStore";

const PlayerPerformanceReporting = () => {
    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);
    const dispatch = useDispatch();
    const playerPerformanceReportState = useSelector((state: IStore) => state.playerPerformanceReportState);
    const { playerPerformanceListData, loading, err, suc } = playerPerformanceReportState;

    // Game Service Drop Down
    const gameServiceState = useSelector((state: IStore) => state.gameServiceState);
    const { gameServiceList } = gameServiceState;
    const [gameServiceOptionList, setGameServiceOptionList] = useState([] as ISelectOption[]);

    // Search Criteria
    const [userNameID, setUserNameID] = useState("");
    const [membershipID, setMembershipID] = useState("");
    const [selectedGame, setSelectedGame] = useState(0);

    const [fromTradingDate, setFromTradingDate] = useState("");
    const [toTradingDate, setToTradingDate] = useState("");
    const [toTradingDateValid, setToTradingDateValid] = useState(false);
    const [fromTradingDateValid, setFromTradingDateValid] = useState(false);

    const [fromTransactionDate, setFromTransactionDate] = useState("");
    const [toTransactionDate, setToTransactionDate] = useState("");
    const [toTransactionDateValid, setToTransactionDateValid] = useState(false);
    const [fromTransactionDateValid, setFromTransactionDateValid] = useState(false);

    const backOfficeModule: ISelectOption[] = LoadSelectOptionFromEnum(BackOfficeAccess);
    backOfficeModule[0].display = 'All';


    const emptyTable: IDataTable = {
        title: ["No", "Game", "Player Name", "Session ID", "Total Game", "Total Bet", "Total Payout", "Total Win / Lose", "Total Gamble Played", "Total Gamble Bet", "Total Gamble Payout", "Total Jackpot Strike", "Total Jackpot Amount", "Pending Game Payout", "Pending Gamble Payout", "Pending Jackpot Payout", "Trading Date" ],
        columnWidth: ["30px", "150px", "200px", "90px", "80px", "130px", "130px", "130px", "80px", "130px", "130px", "80px", "130px", "130px", "130px", "130px", "100px"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        var table: IDataTable = emptyTable
        if (playerPerformanceListData && playerPerformanceListData.rml != undefined && hasSearched) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            var subTotalGame: number = 0;
            var subTotalBet: number = 0;
            var subTotalPayout: number = 0;
            var subTotalWinLose: number = 0;
            var subTotalGambleGame: number = 0;
            var subTotalGambleBet: number = 0;
            var subTotalGamblePayout: number = 0;
            var subTotalJackpotStrike: number = 0;
            var subTotalJackpotAmount: number = 0;
            var totalPendingGamePayout: number = 0;
            var totalPendingGamblePayout: number = 0;
            var totalPendingJackpotPayout: number = 0;

            table.data = playerPerformanceListData.rml.map((x, index) => {
                subTotalGame += x.tgm;
                subTotalBet += x.tbt;
                subTotalPayout += x.tpo;
                subTotalWinLose += x.twl;
                subTotalGambleGame += x.tgg;
                subTotalGambleBet += x.tgb;
                subTotalGamblePayout += x.tgp;
                subTotalJackpotStrike += x.tjs;
                subTotalJackpotAmount += x.tja;
                totalPendingGamePayout += x.pgm;
                totalPendingGamblePayout += x.pga;
                totalPendingJackpotPayout += x.pjp;

                return [
                    startIndex + index + 1,
                    x.gme,
                    x.pnm + " (" + x.mid + ")",
                    x.sid,
                    x.tgm,
                    CVT_TO_FLOAT_STRING(x.tbt),
                    CVT_TO_FLOAT_STRING(x.tpo),
                    CVT_TO_FLOAT_STRING(x.twl),
                    x.tgg,
                    CVT_TO_FLOAT_STRING(x.tgb),
                    CVT_TO_FLOAT_STRING(x.tgp),
                    x.tjs,
                    CVT_TO_FLOAT_STRING(x.tja),
                    CVT_TO_FLOAT_STRING(x.pgm),
                    CVT_TO_FLOAT_STRING(x.pga),
                    CVT_TO_FLOAT_STRING(x.pjp),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.tdd), "yyyy-MM-dd")
                ]
            });

            table.concludeColumn = [
                { columnIndex: 4, content: subTotalGame },
                { columnIndex: 5, content: CVT_TO_FLOAT_STRING(subTotalBet) },
                { columnIndex: 6, content: CVT_TO_FLOAT_STRING(subTotalPayout) },
                { columnIndex: 7, content: CVT_TO_FLOAT_STRING(subTotalWinLose) },
                { columnIndex: 8, content: CVT_TO_FLOAT_STRING(subTotalGambleGame) },
                { columnIndex: 9, content: CVT_TO_FLOAT_STRING(subTotalGambleBet) },
                { columnIndex: 10, content: CVT_TO_FLOAT_STRING(subTotalGamblePayout) },
                { columnIndex: 11, content: subTotalJackpotStrike },
                { columnIndex: 12, content: CVT_TO_FLOAT_STRING(subTotalJackpotAmount) },
                { columnIndex: 13, content: CVT_TO_FLOAT_STRING(totalPendingGamePayout) },
                { columnIndex: 14, content: CVT_TO_FLOAT_STRING(totalPendingGamblePayout) },
                { columnIndex: 15, content: CVT_TO_FLOAT_STRING(totalPendingJackpotPayout) },
            ];

            /*setHasSearched(false);*/
        } else
            table.data = [];

        setReportTable(table);
    }, [playerPerformanceListData]);

    useEffect(() => {
        dispatch(GetGameServiceRequest(0, -1));
    }, []);

    useEffect(() => {
        if (gameServiceList && gameServiceList.sgl != undefined) {

            const gameService: ISelectOption[] = gameServiceList.sgl.map((item) => {
                return {
                    display: item.snm,
                    value: item.sid.toString()
                }
            });

            setGameServiceOptionList(gameService);
        }
    }, [gameServiceList]);

    const valid = (): boolean => {
        return fromTradingDate !== "" && toTradingDate !== "" && toTradingDateValid && fromTradingDateValid &&
            fromTransactionDate !== "" && toTransactionDate !== "" && toTransactionDateValid && fromTransactionDateValid;
    }

    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetPlayerPerformanceReportRequest(userNameID, membershipID, selectedGame, selectedGame == -1 || selectedGame == 0 ? "N/A" : gameServiceOptionList.find((gameService) => gameService.value === selectedGame.toString())?.display ?? "N/A", new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const pagination: ITablePagination = {
        id: "playerPerformanceReportPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? playerPerformanceListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setPage(0);
            setHasSearched(true);
            dispatch(GetPlayerPerformanceReportRequest(userNameID, membershipID, selectedGame, selectedGame == -1 || selectedGame == 0 ? "N/A" : gameServiceOptionList.find((gameService) => gameService.value === selectedGame.toString())?.display ?? "N/A", new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
        else {
            TRIGGER_BLUR("fromTradingDate");
            TRIGGER_BLUR("toTradingDate");
            TRIGGER_BLUR("fromTransactionDate");
            TRIGGER_BLUR("toTransactionDate");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            dispatch(GeneratePlayerPerformanceReportPDFRequest(userNameID, membershipID, selectedGame, selectedGame == -1 || selectedGame == 0 ? "N/A" : gameServiceOptionList.find((gameService) => gameService.value === selectedGame.toString())?.display ?? "N/A", new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const generateCSV = () => {
        if (valid()) {
            dispatch(GeneratePlayerPerformanceReportCSVRequest(userNameID, membershipID, selectedGame, selectedGame == -1 || selectedGame == 0 ? "N/A" : gameServiceOptionList.find((gameService) => gameService.value === selectedGame.toString())?.display ?? "N/A", new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="systemUser"
                                name="systemUser"
                                label="Player Name"
                                type={TextboxType.TEXT}
                                placeholder={"Name"}
                                isRequired={true}
                                inputState={setUserNameID}
                                value={userNameID}
                                HasCancelledButton={true}
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                id="membershipID"
                                name="membershipID"
                                label="Membership ID"
                                type={TextboxType.TEXT}
                                placeholder={"ID"}
                                isRequired={true}
                                inputState={setMembershipID}
                                value={membershipID}
                                HasCancelledButton={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <SelectBox id="gameServiceName" name="gameServiceName" label="Game"
                            options={gameServiceOptionList} value={selectedGame}
                            inputState={setSelectedGame}
                            allowEmptyData={true} placeHolderContent="---- All Game ----"
                        />
                    </Row>
                    <Row>
                        <DateTimeRangePicker isRequired fromDateinputState={setFromTradingDate} fromDatevalidState={setFromTradingDateValid}
                            toDateinputState={setToTradingDate} toDatevalidState={setToTradingDateValid} fromDatePickerID="fromTradingDate" toDatePickerID="toTradingDate" label="Trading Date" hideTime={true} />
                    </Row>
                    <Row>
                        <DateTimeRangePicker isRequired fromDateinputState={setFromTransactionDate} fromDatevalidState={setFromTransactionDateValid}
                            toDateinputState={setToTransactionDate} toDatevalidState={setToTransactionDateValid} fromDatePickerID="fromTransactionDate" toDatePickerID="toTransactionDate" label="Transaction Date" hideTime={false} />
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div>
            <StandardReportContainer
                id="PlayerPerformanceReporting"
                error={hasSearched ? err : ""}
                success={hasSearched ? suc : ""}
                loading={loading}
                tablePagination={pagination}
                tableProps={reportTable}
                searchCriteriaContent={SearchCriteriaContainer()}
                generateCSVEvent={generateCSV}
                generatePDFEvent={generatePDF}
            />
        </div>
    )
}



export default PlayerPerformanceReporting;