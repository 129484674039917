import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { DropDownButtonStyle, IDropdownButton } from "./IDropdownButton";

const DropdownButton = (props: IDropdownButton) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} disabled={props.disable}>
            <DropdownToggle style={props.dropdownButtonStyle == DropDownButtonStyle.ROUNDED || props.dropdownButtonStyle == DropDownButtonStyle.ROUNDED_WITHOUT_CARET ? { borderRadius: '50%' } : {}} caret={props.dropdownButtonStyle == DropDownButtonStyle.NORMAL || props.dropdownButtonStyle == DropDownButtonStyle.ROUNDED} color={props.color ? props.color : "primary"} outline={props.isOutline}>
                <span>{props.dropdownTitle}</span>
            </DropdownToggle>
            <DropdownMenu container="body">
                {props.dropdownItems.map((item, key) => (
                    item
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

export default DropdownButton;

