import { JackpotContributionReportCSVModel, JackpotContributionReportListModel, JackpotContributionReportPDFModel, JackpotContributionSummaryReportListModel } from "../../../dto/models/Reporting/JackpotContributionReportModel";

export interface IPoolContributionReportState {
    loading: boolean;
    err: string;
    suc: string;
    contributionReportListData: JackpotContributionReportListModel;
    contributionReportSummaryListData: JackpotContributionSummaryReportListModel;
    pdfData: JackpotContributionReportPDFModel;
    csvData: JackpotContributionReportCSVModel;
}

export const InitPoolContributionReportState: IPoolContributionReportState = {
    loading: false,
    err: "",
    suc: "",
    contributionReportListData: {} as JackpotContributionReportListModel,
    contributionReportSummaryListData: {} as JackpotContributionSummaryReportListModel,
    pdfData: {} as JackpotContributionReportPDFModel,
    csvData: {} as JackpotContributionReportCSVModel,
}
