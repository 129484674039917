import { ManualSubmitOLRRequestModel } from "../../../dto/models/Reporting/ManualSubmitOLRRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { ManualSubmitOLRReportingActionTypes } from "./ManualSubmitOLRReportingActionTypes";
type GetManualSubmitOLRReportRequest = { type: ManualSubmitOLRReportingActionTypes.GET_MANUAL_SUBMIT_OLR_REPORT_REQUEST, payload: IPayload };
type GenerateManualSubmitOLRPDFRequest = { type: ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_PDF_REQUEST, payload: IPayload };
type GenerateManualSubmitOLRCSVRequest = { type: ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetManualSubmitOLRReportRequest | GenerateManualSubmitOLRPDFRequest | GenerateManualSubmitOLRCSVRequest;

export const GetManualSubmitOLRReportRequest = (
    resolveStatus: number, systemResolveStatus: number, createFromDate: Date, createFromTime: string, createToDate: Date, createToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: ManualSubmitOLRRequestModel = {
            cms: 1,
            rsts: resolveStatus,
            sysrs: systemResolveStatus,
            crf: createFromDate,
            crt: createToDate,
            crft: createFromTime,
            crtt: createToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetManualSubmitOLRReport", request, ManualSubmitOLRReportingActionTypes.GET_MANUAL_SUBMIT_OLR_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualSubmitOLRReportingActionTypes.GET_MANUAL_SUBMIT_OLR_REPORT_REQUEST, dispatch);
    }
}

export const GenerateManualSubmitOLRPDFRequest = (
    resolveStatus: number, systemResolveStatus: number, createFromDate: Date, createFromTime: string, createToDate: Date, createToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: ManualSubmitOLRRequestModel = {
            cms: 1,
            rsts: resolveStatus,
            sysrs: systemResolveStatus,
            crf: createFromDate,
            crt: createToDate,
            crft: createFromTime,
            crtt: createToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateManualSubmitOLRPDF", request, ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_PDF_REQUEST, dispatch);
    }
}

export const GenerateManualSubmitOLRCSVRequest = (
    resolveStatus: number, systemResolveStatus: number, createFromDate: Date, createFromTime: string, createToDate: Date, createToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: ManualSubmitOLRRequestModel = {
            cms: 1,
            rsts: resolveStatus,
            sysrs: systemResolveStatus,
            crf: createFromDate,
            crt: createToDate,
            crft: createFromTime,
            crtt: createToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateManualSubmitOLRCSV", request, ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualSubmitOLRReportingActionTypes.GENERATE_MANUAL_SUBMIT_OLR_CSV_REQUEST, dispatch);
    }
}