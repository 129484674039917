export enum JackpotSubModule {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///     Jackpot Accumulator
    /// </summary>
    JACKPOT_ACCUMULATOR = 1,
    /// <summary>
    ///     Jackpot Approval
    /// </summary>
    JACKPOT_APPROVAL = 2,
    /// <summary>
    ///     Jackpot Pool
    /// </summary>
    JACKPOT_POOL = 3,
    /// <summary>
    ///     Jackpot Tier
    /// </summary>
    JACKPOT_TIER = 4,
    /// <summary>
    ///     Jackpot Service
    /// </summary>
    JACKPOT_SERVICE = 5,
}