export enum CreditTransferType {
    /// <summary>
    ///    None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///    Transfer In
    /// </summary>
    TRANSFER_IN = 1,
    /// <summary>
    ///    Transfer Out
    /// </summary>
    TRANSFER_OUT = 2,
    /// <summary>
    ///    Cash In
    /// </summary>
    //CASH_IN = 3,
    /// <summary>
    ///    Cash Out
    /// </summary>
    CASH_OUT = 4,
}