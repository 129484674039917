import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { SystemConfiguration } from "../../dto/models/ConfigurationModels";
import { ActionTypes } from "./SystemActions";
import { SystemActionTypes } from "./SystemActionTypes";
import { InitialSystemState, ISystemState } from "./SystemStates";

export const systemReducer = (state = InitialSystemState, action: ActionTypes): ISystemState => {
    switch (action.type) {
        case SystemActionTypes.GET_SYSTEM_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, data: {} as SystemConfiguration, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var systemConfig: SystemConfiguration = {} as SystemConfiguration;

                if (data && !data.err) {
                    systemConfig = data.res;
                }
                //  Set systemState for IStore--> systemState
                return { ...state, loading: false, data: systemConfig, err: action.payload.err ? action.payload.err : "", suc: "" };
            }
        }
        case SystemActionTypes.EDIT_SYSTEM_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, data: {} as SystemConfiguration, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var systemConfig: SystemConfiguration = {} as SystemConfiguration;

                if (data && !data.err) {
                    systemConfig = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "System Configuration Updated Successfully.";
                }

                //  Set systemState for IStore--> systemState
                return { ...state, loading: false, data: systemConfig, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
