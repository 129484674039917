import { CmsType } from "../enums/CmsType";
import { Currency } from "../enums/Currency";
import { DefaultLanguage } from "../enums/DefaultLanguage";
import { SessionMode } from "../enums/SessionMode";
import { SlotGambleGame, SlotGameRTP, SlotGameType } from "../enums/SlotGames";
import { TableStatus } from "../enums/TableStatus";
import { GamingAreaModel } from "./GamingAreaModels";
import { ResponseListModel } from "./GeneralModels";

export interface SystemConfiguration {
    /** Currency */
    cur: Currency;
    /** Credit rate*/
    crt: number;
    /** Service sync interval */
    ssi: number;
    /** Lobby sync interval */
    lsi: number;
    /** Jackpot sync interval */
    jsi: number;
    /** Back office session timeout */
    bst: number;
    /** Player Portal Session mode */
    psm: SessionMode;
    /** Player Portal idle timeout */
    pit: number;
    /** Player Portal timeout */
    pst: number;
    /** Password Expiry Month */
    pem: number;
    /** Password Expiry Notify Days */
    pen: number;
}

export interface CmsConfigurationModel {
    /** Cms Id */
    cid: number;
    /** Cms type */
    typ: CmsType;
    /** Domain */
    dom: string;
    /** Port number */
    prt: number;
    /** Default Language */
    dfl: DefaultLanguage
    /** Certificate file */
    cer: number[];
    /** Certificate password */
    cep: string;
    /** Username */
    usm: string;
    /** User password */
    usp: string;
    /** Cashier ID */
    csid: string;
    /** Vip gaming area */
    vga: GamingAreaModel;
    /** Non-vip gaming area */
    nga: GamingAreaModel;
    /** OLR retry count */
    orc: number;
    /** Eca Retry Count */
    erc: number;
    /** Olr Retry Interval */
    ori: number;
    /** Olr Currency */
    ocu: string;
    /** Profit Center */
    prc: string;
    /** Pra Code */
    pra: number;
    /** Updated at */
    upa?: Date;
}

export interface CmsConfigurationListModel extends ResponseListModel {
    /** Cms list */
    clt: CmsConfigurationModel[];
}

export interface SlotGameConfigurationModel {
    /** Game id */
    gid: number;
    /** Game name */
    gmn: string;
    /** Description */
    des: string;
    /** Slot game type */
    typ: SlotGameType;
    /** Slot RTP */
    rtp: SlotGameRTP;
    /** Denomination */
    dnm: number;
    /** Slot Gamble Games */
    gbg: SlotGambleGame;
    /** Max payout */
    max: number;
    /** Game url */
    gur: string;
    /** Game icon */
    gic: number[];
    /** Is allow auto spin */
    aas: boolean;
    /** Is allow show RTP */
    isr: boolean;
    /** Status */
    stt: TableStatus;
    /** Created at */
    cdt?: Date;
    /** Updated at */
    udt?: Date;
}

export interface SlotGameConfigurationListModel extends ResponseListModel {
    /** Slot Game list */
    glt: SlotGameConfigurationModel[];
}

export const InitSlotGameConfigurationModel: SlotGameConfigurationModel = {
    gid: 0,
    gmn: "",
    des: "",
    typ: 0,
    rtp: SlotGameRTP.DEFAULT,
    dnm: 0,
    gbg: SlotGambleGame.NONE,
    max: 0,
    gic: [],
    gur: "",
    aas: false,
    isr: false,
    stt: TableStatus.ACTIVE
}

export interface GameCreditCurrency {
    /** Credit Rate */
    crt: number;
    /** Currency */
    crc: string;
}
