import React, { useState, KeyboardEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, CardBody, CardFooter, CardImg, CardSubtitle, CardTitle, Col, Form, Row, Spinner } from "reactstrap";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import { AlertSize, AlertType } from "../../components/AlertMessage/IAlert";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { IStore } from "../../states/store/IStore";
import { UserLogin } from "../../states/User/UserActions";
import "../../styles/Common.css";

const Login = () => {
    const dispatch = useDispatch();
    const [usernameInput, setUsernameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [usernameValid, setUsernameValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);

    const [openReloginModal, setOpenReloginModal] = useState(false);
    const userState = useSelector((state: IStore) => state.userState);
    const { loading, userProfileErr, userProfileSuc } = userState;

    const submit = () => {
        if (usernameValid && passwordValid) {
            dispatch(UserLogin(usernameInput, passwordInput));
        }
        else {
            TRIGGER_BLUR("username");
            TRIGGER_BLUR("password");
        }
    }

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            submit();
        }
    }

    useEffect(() => {
        if (userProfileSuc != "")
            setOpenReloginModal(true);
    }, [userProfileSuc])

    return (
        <div>
            <Row className="width-100">
                <Card outline color="white">
                    <CardBody>
                        <CardTitle tag="h3">FSG-Slot</CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h4">Back Office Portal</CardSubtitle>
                    </CardBody>
                </Card>
            </Row>
            <Row className="width-100">
                <Col xs="6">
                    <Card outline color="white">
                        <CardBody>
                            <div className="text-center">
                                <CardImg src="images/logo.png" className="viewport-height-65" />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="5">
                    {loading ?
                        <Card className="text-center">
                            <CardBody>
                                <CardTitle tag="h1">Authenticating...</CardTitle>
                                <Spinner color="primary" type="grow" style={{height: '5rem', width: '5rem'}} ></Spinner>
                            </CardBody>
                        </Card>
                        :
                        <Card outline={false} color="light">
                            <CardBody>
                                <CardTitle tag="h1">Sign In</CardTitle>
                                {userProfileErr != "" ? <Alert color="danger">{userProfileErr}</Alert> : ""}
                                {userProfileSuc != "" ? <AlertMessage
                                    alertSize={AlertSize.LONG}
                                    isOpen={openReloginModal}
                                    openState={setOpenReloginModal}
                                    alertType={AlertType.SUCCESS}
                                    content={"Password changed successfully, please re-login to portal."}
                                /> : ""}
                                <Form onSubmit={submit}>
                                    <InputBox
                                        id="username"
                                        name="username"
                                        label="Username"
                                        placeholder="Username"
                                        invalidMessage="Please Enter Username."
                                        isRequired={true}
                                        inputState={setUsernameInput}
                                        value={usernameInput}
                                        validState={setUsernameValid}
                                        type={TextboxType.TEXT}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <InputBox
                                        id="password"
                                        name="password"
                                        label="Password"
                                        placeholder="Password"
                                        invalidMessage="Please Enter Password."
                                        isRequired={true}
                                        inputState={setPasswordInput}
                                        value={passwordInput}
                                        validState={setPasswordValid}
                                        type={TextboxType.PASSWORD}
                                        onKeyPress={handleKeyPress}
                                    />
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button onClick={submit} type="submit" color="secondary" disabled={loading} >Login</Button>
                            </CardFooter>
                        </Card>
                    }
                </Col>
            </Row>
        </div>
    );
};

export default Login;