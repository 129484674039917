import { UserListingCSVReportModel, UserListingPDFReportModel, UserListingReportListModel } from "../../../dto/models/Reporting/UserListingReportModel";

export interface IUserListingReportState {
    loading: boolean;
    err: string;
    suc: string;
    userListingReportListData: UserListingReportListModel;
    pdfData: UserListingPDFReportModel;
    csvData: UserListingCSVReportModel;
}

export const InitUserListingReportState: IUserListingReportState = {
    loading: false,
    err: "",
    suc: "",
    userListingReportListData: {} as UserListingReportListModel,
    pdfData: {} as UserListingPDFReportModel,
    csvData: {} as UserListingCSVReportModel,
}