export enum Currency {
    /// <summary>
    ///     AED
    /// </summary>
    AED,
    /// <summary>
    ///     AFN
    /// </summary>
    AFN,
    /// <summary>
    ///     ALL
    /// </summary>
    ALL,
    /// <summary>
    ///     AMD
    /// </summary>
    AMD,
    /// <summary>
    ///     ANG
    /// </summary>
    ANG,
    /// <summary>
    ///     AOA
    /// </summary>
    AOA,
    /// <summary>
    ///     ARS
    /// </summary>
    ARS,
    /// <summary>
    ///     AUD
    /// </summary>
    AUD,
    /// <summary>
    ///     AWG
    /// </summary>
    AWG,
    /// <summary>
    ///     AZN
    /// </summary>
    AZN,
    /// <summary>
    ///     BAM
    /// </summary>
    BAM,
    /// <summary>
    ///     BBD
    /// </summary>
    BBD,
    /// <summary>
    ///     BDT
    /// </summary>
    BDT,
    /// <summary>
    ///     BGN
    /// </summary>
    BGN,
    /// <summary>
    ///     BHD
    /// </summary>
    BHD,
    /// <summary>
    ///     BIF
    /// </summary>
    BIF,
    /// <summary>
    ///     BMD
    /// </summary>
    BMD,
    /// <summary>
    ///     BND
    /// </summary>
    BND,
    /// <summary>
    ///     BOB
    /// </summary>
    BOB,
    /// <summary>
    ///     BRL
    /// </summary>
    BRL,
    /// <summary>
    ///     BSD
    /// </summary>
    BSD,
    /// <summary>
    ///     BTN
    /// </summary>
    BTN,
    /// <summary>
    ///     BWP
    /// </summary>
    BWP,
    /// <summary>
    ///     BYR
    /// </summary>
    BYR,
    /// <summary>
    ///     BZD
    /// </summary>
    BZD,
    /// <summary>
    ///     CAD
    /// </summary>
    CAD,
    /// <summary>
    ///     CDF
    /// </summary>
    CDF,
    /// <summary>
    ///     CHF
    /// </summary>
    CHF,
    /// <summary>
    ///     CLP
    /// </summary>
    CLP,
    /// <summary>
    ///     CNY
    /// </summary>
    CNY,
    /// <summary>
    ///     COP
    /// </summary>
    COP,
    /// <summary>
    ///     CRC
    /// </summary>
    CRC,
    /// <summary>
    ///     CUC
    /// </summary>
    CUC,
    /// <summary>
    ///     CUP
    /// </summary>
    CUP,
    /// <summary>
    ///     CVE
    /// </summary>
    CVE,
    /// <summary>
    ///     CZK
    /// </summary>
    CZK,
    /// <summary>
    ///     DJF
    /// </summary>
    DJF,
    /// <summary>
    ///     DKK
    /// </summary>
    DKK,
    /// <summary>
    ///     DOP
    /// </summary>
    DOP,
    /// <summary>
    ///     DZD
    /// </summary>
    DZD,
    /// <summary>
    ///     EGP
    /// </summary>
    EGP,
    /// <summary>
    ///     ERN
    /// </summary>
    ERN,
    /// <summary>
    ///     ETB
    /// </summary>
    ETB,
    /// <summary>
    ///     EUR
    /// </summary>
    EUR,
    /// <summary>
    ///     FJD
    /// </summary>
    FJD,
    /// <summary>
    ///     FKP
    /// </summary>
    FKP,
    /// <summary>
    ///     GBP
    /// </summary>
    GBP,
    /// <summary>
    ///     GEL
    /// </summary>
    GEL,
    /// <summary>
    ///     GGP
    /// </summary>
    GGP,
    /// <summary>
    ///     GHS
    /// </summary>
    GHS,
    /// <summary>
    ///     GIP
    /// </summary>
    GIP,
    /// <summary>
    ///     GMD
    /// </summary>
    GMD,
    /// <summary>
    ///     GNF
    /// </summary>
    GNF,
    /// <summary>
    ///     GTQ
    /// </summary>
    GTQ,
    /// <summary>
    ///     GYD
    /// </summary>
    GYD,
    /// <summary>
    ///     HKD
    /// </summary>
    HKD,
    /// <summary>
    ///     
    /// </summary>
    HNL,
    /// <summary>
    ///     HRK
    /// </summary>
    HRK,
    /// <summary>
    ///     HTG
    /// </summary>
    HTG,
    /// <summary>
    ///     HUF
    /// </summary>
    HUF,
    /// <summary>
    ///     IDR
    /// </summary>
    IDR,
    /// <summary>
    ///     ILD
    /// </summary>
    ILS,
    /// <summary>
    ///     IMP
    /// </summary>
    IMP,
    /// <summary>
    ///     INR
    /// </summary>
    INR,
    /// <summary>
    ///     IQD
    /// </summary>
    IQD,
    /// <summary>
    ///     IRR
    /// </summary>
    IRR,
    /// <summary>
    ///     ISK
    /// </summary>
    ISK,
    /// <summary>
    ///     JEP
    /// </summary>
    JEP,
    /// <summary>
    ///     JMD
    /// </summary>
    JMD,
    /// <summary>
    ///     JOD
    /// </summary>
    JOD,
    /// <summary>
    ///     JYP
    /// </summary>
    JPY,
    /// <summary>
    ///     KES
    /// </summary>
    KES,
    /// <summary>
    ///     KGS
    /// </summary>
    KGS,
    /// <summary>
    ///     KHR
    /// </summary>
    KHR,
    /// <summary>
    ///     KMF
    /// </summary>
    KMF,
    /// <summary>
    ///     KPW
    /// </summary>
    KPW,
    /// <summary>
    ///     KRW
    /// </summary>
    KRW,
    /// <summary>
    ///     KWD
    /// </summary>
    KWD,
    /// <summary>
    ///     KYD
    /// </summary>
    KYD,
    /// <summary>
    ///     KZT
    /// </summary>
    KZT,
    /// <summary>
    ///     LAK
    /// </summary>
    LAK,
    /// <summary>
    ///     LBP
    /// </summary>
    LBP,
    /// <summary>
    ///     LKR
    /// </summary>
    LKR,
    /// <summary>
    ///     KRD
    /// </summary>
    LRD,
    /// <summary>
    ///     LSL
    /// </summary>
    LSL,
    /// <summary>
    ///     LYD
    /// </summary>
    LYD,
    /// <summary>
    ///     MAD
    /// </summary>
    MAD,
    /// <summary>
    ///     MDL
    /// </summary>
    MDL,
    /// <summary>
    ///     MGA
    /// </summary>
    MGA,
    /// <summary>
    ///     MKD
    /// </summary>
    MKD,
    /// <summary>
    ///     MMK
    /// </summary>
    MMK,
    /// <summary>
    ///     MNT
    /// </summary>
    MNT,
    /// <summary>
    ///     MOP
    /// </summary>
    MOP,
    /// <summary>
    ///     MRO
    /// </summary>
    MRO,
    /// <summary>
    ///     MUR
    /// </summary>
    MUR,
    /// <summary>
    ///     MVR
    /// </summary>
    MVR,
    /// <summary>
    ///     MWK
    /// </summary>
    MWK,
    /// <summary>
    ///     MXN
    /// </summary>
    MXN,
    /// <summary>
    ///     MYR
    /// </summary>
    MYR,
    /// <summary>
    ///     MZN
    /// </summary>
    MZN,
    /// <summary>
    ///     NAD
    /// </summary>
    NAD,
    /// <summary>
    ///     NGN
    /// </summary>
    NGN,
    /// <summary>
    ///     NIO
    /// </summary>
    NIO,
    /// <summary>
    ///     NOK
    /// </summary>
    NOK,
    /// <summary>
    ///     NPR
    /// </summary>
    NPR,
    /// <summary>
    ///     NZD
    /// </summary>
    NZD,
    /// <summary>
    ///     OMR
    /// </summary>
    OMR,
    /// <summary>
    ///     PAB
    /// </summary>
    PAB,
    /// <summary>
    ///     PEN
    /// </summary>
    PEN,
    /// <summary>
    ///     PGK
    /// </summary>
    PGK,
    /// <summary>
    ///     PHP
    /// </summary>
    PHP,
    /// <summary>
    ///     PKR
    /// </summary>
    PKR,
    /// <summary>
    ///     PLN
    /// </summary>
    PLN,
    /// <summary>
    ///     PYG
    /// </summary>
    PYG,
    /// <summary>
    ///     QAR
    /// </summary>
    QAR,
    /// <summary>
    ///     RON
    /// </summary>
    RON,
    /// <summary>
    ///     RSD
    /// </summary>
    RSD,
    /// <summary>
    ///     RUB
    /// </summary>
    RUB,
    /// <summary>
    ///     RWF
    /// </summary>
    RWF,
    /// <summary>
    ///     SAR
    /// </summary>
    SAR,
    /// <summary>
    ///     SBD
    /// </summary>
    SBD,
    /// <summary>
    ///     SCR
    /// </summary>
    SCR,
    /// <summary>
    ///     SDG
    /// </summary>
    SDG,
    /// <summary>
    ///     SEK
    /// </summary>
    SEK,
    /// <summary>
    ///     SGD
    /// </summary>
    SGD,
    /// <summary>
    ///     SHP
    /// </summary>
    SHP,
    /// <summary>
    ///     SLL
    /// </summary>
    SLL,
    /// <summary>
    ///     SOS
    /// </summary>
    SOS,
    /// <summary>
    ///     SPL
    /// </summary>
    SPL,
    /// <summary>
    ///     SRD
    /// </summary>
    SRD,
    /// <summary>
    ///     STD
    /// </summary>
    STD,
    /// <summary>
    ///     SVC
    /// </summary>
    SVC,
    /// <summary>
    ///     SYP
    /// </summary>
    SYP,
    /// <summary>
    ///     SZL
    /// </summary>
    SZL,
    /// <summary>
    ///     THB
    /// </summary>
    THB,
    /// <summary>
    ///     TJS
    /// </summary>
    TJS,
    /// <summary>
    ///     TMT
    /// </summary>
    TMT,
    /// <summary>
    ///     TND
    /// </summary>
    TND,
    /// <summary>
    ///     TOP
    /// </summary>
    TOP,
    /// <summary>
    ///     TRY
    /// </summary>
    TRY,
    /// <summary>
    ///     TTD
    /// </summary>
    TTD,
    /// <summary>
    ///     TVD
    /// </summary>
    TVD,
    /// <summary>
    ///     TWD
    /// </summary>
    TWD,
    /// <summary>
    ///     TZS
    /// </summary>
    TZS,
    /// <summary>
    ///     UAH
    /// </summary>
    UAH,
    /// <summary>
    ///     UGX
    /// </summary>
    UGX,
    /// <summary>
    ///     USD
    /// </summary>
    USD,
    /// <summary>
    ///     UYU
    /// </summary>
    UYU,
    /// <summary>
    ///     UZS
    /// </summary>
    UZS,
    /// <summary>
    ///     VEF
    /// </summary>
    VEF,
    /// <summary>
    ///     VND
    /// </summary>
    VND,
    /// <summary>
    ///     VUV
    /// </summary>
    VUV,
    /// <summary>
    ///     WST
    /// </summary>
    WST,
    /// <summary>
    ///     XAF
    /// </summary>
    XAF,
    /// <summary>
    ///     XCD
    /// </summary>
    XCD,
    /// <summary>
    ///     XDR
    /// </summary>
    XDR,
    /// <summary>
    ///     XOF
    /// </summary>
    XOF,
    /// <summary>
    ///     XPF
    /// </summary>
    XPF,
    /// <summary>
    ///     No Currency
    /// </summary>
    XXX,
    /// <summary>
    ///     YER
    /// </summary>
    YER,
    /// <summary>
    ///     ZAR
    /// </summary>
    ZAR,
    /// <summary>
    ///     ZMW
    /// </summary>
    ZMW,
    /// <summary>
    ///     ZWD
    /// </summary>
    ZWD,
}

export const CurrencyName: { [id: number]: string; } = {
    0: "United Arab Emirates Dirham",
    1: "Afghanistan Afghani",
    2: "Albania Lek",
    3: "Armenia Dram",
    4: "Netherlands Antilles Guilder",
    5: "Angola Kwanza",
    6: "Argentina Peso",
    7: "Australia Dollar",
    8: "Aruba Guilder",
    9: "Azerbaijan New Manat",
    10: "Bosnia and Herzegovina Convertible Marka",
    11: "Barbados Dollar",
    12: "Bangladesh Taka",
    13: "Bulgaria Lev",
    14: "Bahrain Dinar",
    15: "Burundi Franc",
    16: "Bermuda Dollar",
    17: "Brunei Darussalam Dollar",
    18: "Bolivia Boliviano",
    19: "Brazil Real",
    20: "Bahamas Dollar",
    21: "Bhutan Ngultrum",
    22: "Botswana Pula",
    23: "Belarus Ruble",
    24: "Belize Dollar",
    25: "Canada Dollar",
    26: "Congo/Kinshasa Franc",
    27: "Switzerland Franc",
    28: "Chile Peso",
    29: "China Yuan Renminbi",
    30: "Colombia Peso",
    31: "Costa Rica Colon",
    32: "Cuba Convertible Peso",
    33: "Cuba Peso",
    34: "Cape Verde Escudo",
    35: "Czech Republic Koruna",
    36: "Djibouti Franc",
    37: "Denmark Krone",
    38: "Dominican Republic Peso",
    39: "Algeria Dinar",
    40: "Egypt Pound",
    41: "Eritrea Nakfa",
    42: "Ethiopia Birr",
    43: "Euro Member Countries",
    44: "Fiji Dollar",
    45: "Falkland Islands (Malvinas) Pound",
    46: "United Kingdom Pound",
    47: "Georgia Lari",
    48: "Guernsey Pound",
    49: "Ghana Cedi",
    50: "Gibraltar Pound",
    51: "Gambia Dalasi",
    52: "Guinea Franc",
    53: "Guatemala Quetzal",
    54: "Guyana Dollar",
    55: "Hong Kong Dollar",
    56: "Honduras Lempira",
    57: "Croatia Kuna",
    58: "Haiti Gourde",
    59: "Hungary Forint",
    60: "Indonesia Rupiah",
    61: "Israel Shekel",
    62: "Isle of Man Pound",
    63: "India Rupee",
    64: "Iraq Dinar",
    65: "Iran Rial",
    66: "Iceland Krona",
    67: "Jersey Pound",
    68: "Jamaica Dollar",
    69: "Jordan Dinar",
    70: "Japan Yen",
    71: "Kenya Shilling",
    72: "Kyrgyzstan Som",
    73: "Cambodia Riel",
    74: "Comoros Franc",
    75: "Korea (North) Won",
    76: "Korea (South) Won",
    77: "Kuwait Dinar",
    78: "Cayman Islands Dollar",
    79: "Kazakhstan Tenge",
    80: "Laos Kip",
    81: "Lebanon Pound",
    82: "Sri Lanka Rupee",
    83: "Liberia Dollar",
    84: "Lesotho Loti",
    85: "Libya Dinar",
    86: "Morocco Dirham",
    87: "Moldova Leu",
    88: "Madagascar Ariary",
    89: "Macedonia Denar",
    90: "Myanmar (Burma) Kyat",
    91: "Mongolia Tughrik",
    92: "Macau Pataca",
    93: "Mauritania Ouguiya",
    94: "Mauritius Rupee",
    95: "Maldives (Maldive Islands) Rufiyaa",
    96: "Malawi Kwacha",
    97: "Mexico Peso",
    98: "Malaysia Ringgit",
    99: "Mozambique Metical",
    100: "Namibia Dollar",
    101: "Nigeria Naira",
    102: "Nicaragua Cordoba",
    103: "Norway Krone",
    104: "Nepal Rupee",
    105: "New Zealand Dollar",
    106: "Oman Rial",
    107: "Panama Balboa",
    108: "Peru Nuevo Sol",
    109: "Papua New Guinea Kina",
    110: "Philippines Peso",
    111: "Pakistan Rupee",
    112: "Poland Zloty",
    113: "Paraguay Guarani",
    114: "Qatar Riyal",
    115: "Romania New Leu",
    116: "Serbia Dinar",
    117: "Russia Ruble",
    118: "Rwanda Franc",
    119: "Saudi Arabia Riyal",
    120: "Solomon Islands Dollar",
    121: "Seychelles Rupee",
    122: "Sudan Pound",
    123: "Sweden Krona",
    124: "Singapore Dollar",
    125: "Saint Helena Pound",
    126: "Sierra Leone Leone",
    127: "Somalia Shilling",
    128: "Seborga Luigino",
    129: "Suriname Dollar",
    130: "São Tomé and Príncipe Dobra",
    131: "El Salvador Colon",
    132: "Syria Pound",
    133: "Swaziland Lilangeni",
    134: "Thailand Baht",
    135: "Tajikistan Somoni",
    136: "Turkmenistan Manat",
    137: "Tunisia Dinar",
    138: "Tonga Pa'anga",
    139: "Turkey Lira",
    140: "Trinidad and Tobago Dollar",
    141: "Tuvalu Dollar",
    142: "Taiwan New Dollar",
    143: "Tanzania Shilling",
    144: "Ukraine Hryvnia",
    145: "Uganda Shilling",
    146: "United States Dollar",
    147: "Uruguay Peso",
    148: "Uzbekistan Som",
    149: "Venezuela Bolivar",
    150: "Viet Nam Dong",
    151: "Vanuatu Vatu",
    152: "Samoa Tala",
    153: "Communauté Financière Africaine (BEAC) CFA Franc BEAC",
    154: "East Caribbean Dollar",
    155: "International Monetary Fund (IMF) Special Drawing Rights",
    156: "Communauté Financière Africaine (BCEAO) Franc",
    157: "Comptoirs Français du Pacifique (CFP) Franc",
    158: "No Currency",
    159: "Yemen Rial",
    160: "South Africa Rand",
    161: "Zambia Kwacha",
    162: "Zimbabwe Dollar"
};

export function GetCurrencyName(cur: Currency): string {
    let id: number = cur;
    return CurrencyName[id];
}