import { SlotGameConfigurationListModel } from "../../dto/models/ConfigurationModels";
import { JackpotServiceListModel, SlotGameServiceListModel, SlotGameServiceModel } from "../../dto/models/ServiceModels";

export interface IGameServiceState {
    requireReload: boolean;
    loading: boolean;
    err: string;
    suc: string;
    gameServiceList: SlotGameServiceListModel;
    gameServiceData: SlotGameServiceModel;
    gameList: SlotGameConfigurationListModel;
    jackpotServiceList: JackpotServiceListModel;
}

export const InitialGameServiceState: IGameServiceState = {
    requireReload: true,
    loading: false,
    err: "",
    suc: "",
    gameServiceList: {} as SlotGameServiceListModel,
    gameServiceData: {} as SlotGameServiceModel,
    gameList: {} as SlotGameConfigurationListModel,
    jackpotServiceList: {} as JackpotServiceListModel
}