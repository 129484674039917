import { TableStatus } from "../../../dto/enums/TableStatus";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../../dto/models/GeneralModels";
import { ApprovalTierModel } from "../../../dto/models/JackpotModels";
import { GetRequest, PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../../store/IStore";
import { ApprovalTierActionTypes } from "./ApprovalTierActionTypes";

type AddApprovalTierRequest = { type: ApprovalTierActionTypes.ADD_APPROVAL_TIER_REQUEST, payload: IPayload };
type EditApprovalTierRequest = { type: ApprovalTierActionTypes.EDIT_APPROVAL_TIER_REQUEST, payload: IPayload };
type GetApprovalTierListRequest = { type: ApprovalTierActionTypes.GET_APPROVAL_TIER_LIST_REQUEST, payload: IPayload };
type GetApprovalTierRequest = { type: ApprovalTierActionTypes.GET_APPROVAL_TIER_REQUEST, payload: IPayload };
type SelectApprovalTierRequest = { type: ApprovalTierActionTypes.SELECT_APPROVAL_TIER_REQUEST, payload: IPayload };
type SearchApprovalTierRequest = { type: ApprovalTierActionTypes.SEARCH_APPROVAL_TIER_REQUEST, payload: IPayload };
type GetCreditRateRequest = { type: ApprovalTierActionTypes.GET_CREDIT_RATE, payload: IPayload };

export type ActionTypes =
    AddApprovalTierRequest | EditApprovalTierRequest | GetApprovalTierListRequest | GetApprovalTierRequest | SelectApprovalTierRequest | SearchApprovalTierRequest | GetCreditRateRequest;

export const AddApprovalTier = (
    thereshold: number, status: TableStatus
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: ApprovalTierModel = {
            jid: 0,
            tsh: thereshold,
            stt: status
        };
        PostRequest("BackOffice/AddApprovalTier", request, ApprovalTierActionTypes.ADD_APPROVAL_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ApprovalTierActionTypes.ADD_APPROVAL_TIER_REQUEST, dispatch);
    }
}

export const EditApprovalTier = (
    id: number, thereshold: number, status: TableStatus
) => async (dispatch: any) => {

    try {
        let request: ApprovalTierModel = {
            jid: id,
            tsh: thereshold,
            stt: status
        };
        PostRequest("BackOffice/EditApprovalTier", request, ApprovalTierActionTypes.EDIT_APPROVAL_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ApprovalTierActionTypes.EDIT_APPROVAL_TIER_REQUEST, dispatch);
    }

}

export const GetApprovalTier = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetApprovalTier", request, ApprovalTierActionTypes.GET_APPROVAL_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ApprovalTierActionTypes.GET_APPROVAL_TIER_REQUEST, dispatch);
    }
}

export const SearchApprovalTier = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchApprovalTier", request, ApprovalTierActionTypes.SEARCH_APPROVAL_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ApprovalTierActionTypes.SEARCH_APPROVAL_TIER_REQUEST, dispatch);
    }
}

export const GetApprovalTierList = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetApprovalTierList", ApprovalTierActionTypes.GET_APPROVAL_TIER_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ApprovalTierActionTypes.GET_APPROVAL_TIER_LIST_REQUEST, dispatch);
    }
}

export const SelectApprovalTier = (id: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: id
        };
        PostRequest("BackOffice/SelectApprovalTier", request, ApprovalTierActionTypes.SELECT_APPROVAL_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ApprovalTierActionTypes.SELECT_APPROVAL_TIER_REQUEST, dispatch);
    }
}

export const ApprovalTierGetCreditRate = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetCreditRate", ApprovalTierActionTypes.GET_CREDIT_RATE, dispatch);
    }
    catch (error) {
        RequestException(error, ApprovalTierActionTypes.GET_CREDIT_RATE, dispatch);
    }
}