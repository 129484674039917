import { AuditRequestModel } from "../../../dto/models/Reporting/AuditRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { AuditReportingActionTypes } from "./AuditReportingActionTypes";

type GetAuditReportRequest = { type: AuditReportingActionTypes.GET_AUDIT_REPORT_REQUEST, payload: IPayload };
type GenerateAuditPDFRequest = { type: AuditReportingActionTypes.GENERATE_AUDIT_PDF_REQUEST, payload: IPayload };
type GenerateAuditCSVRequest = { type: AuditReportingActionTypes.GENERATE_AUDIT_CSV_REQUEST, payload: IPayload };

export type ActionTypes =
    GetAuditReportRequest | GenerateAuditPDFRequest | GenerateAuditCSVRequest;

export const GetAuditReportRequest = (
    userId: string, maintenanceModule: number, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: AuditRequestModel = {
            uid: userId,
            mmd: maintenanceModule,
            tfd: transFromDate,
            tft: transFromTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetAuditReport", request, AuditReportingActionTypes.GET_AUDIT_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AuditReportingActionTypes.GET_AUDIT_REPORT_REQUEST, dispatch);
    }
}

export const GenerateAuditReportPDFRequest = (
    userId: string, maintenanceModule: number, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: AuditRequestModel = {
            uid: userId,
            mmd: maintenanceModule,
            tfd: transFromDate,
            tft: transFromTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetAuditPDF", request, AuditReportingActionTypes.GENERATE_AUDIT_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AuditReportingActionTypes.GENERATE_AUDIT_PDF_REQUEST, dispatch);
    }
}

export const GenerateAuditReportCSVRequest = (
    userId: string, maintenanceModule: number, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: AuditRequestModel = {
            uid: userId,
            mmd: maintenanceModule,
            tfd: transFromDate,
            tft: transFromTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetAuditCSV", request, AuditReportingActionTypes.GENERATE_AUDIT_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AuditReportingActionTypes.GENERATE_AUDIT_CSV_REQUEST, dispatch);
    }
}