import { GameDetailResultReportRequestModel, GameResultReportRequestModel, HistoryGameReplayRequestModel } from "../../../dto/models/Reporting/GameResultRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { GameResultReportingActionTypes } from "./GameResultReportingActionTypes";

type GetGameResultReportRequest = { type: GameResultReportingActionTypes.GET_GAME_RESULT_REPORT_REQUEST, payload: IPayload };
type GenerateGameResultPDFRequest = { type: GameResultReportingActionTypes.GENERATE_GAME_RESULT_PDF_REQUEST, payload: IPayload };
type GenerateGameResultCSVRequest = { type: GameResultReportingActionTypes.GENERATE_GAME_RESULT_CSV_REQUEST, payload: IPayload };

type GetGameDetailResultReportRequest = { type: GameResultReportingActionTypes.GET_GAME_DETAIL_RESULT_REQUEST, payload: IPayload };
type GenerateGameDetailResultPDFRequest = { type: GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_PDF_REQUEST, payload: IPayload };
type GenerateGameDetailResultCSVRequest = { type: GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_CSV_REQUEST, payload: IPayload };

type GetGameReplayRequest = { type: GameResultReportingActionTypes.GET_GAME_REPLAY_REQUEST, payload: IPayload };


export type ActionTypes =
    GetGameResultReportRequest | GenerateGameResultPDFRequest | GenerateGameResultCSVRequest |
    GetGameDetailResultReportRequest | GenerateGameDetailResultPDFRequest | GenerateGameDetailResultCSVRequest |
    GetGameReplayRequest;

export const GetGameResultReportRequest = (
    gameServiceID: number, playerID: string, isCompleted: boolean, tradingDate: Date, transactionformTime: string, transactionToTime: string, from: number, takeCount: number, transactionFromDate?: Date , transactionToDate?: Date
) => async (dispatch: any) => {
    try {
        let request: GameResultReportRequestModel = {
            gsid: gameServiceID,
            pid: playerID,
            com: isCompleted,
            tdd: tradingDate,
            tfd: transactionFromDate,
            tft: transactionformTime,
            ttd: transactionToDate,
            ttt: transactionToTime,
            frm: from,
            tak: takeCount,
        };
        PostRequest("Reporting/GetGameResultReport", request, GameResultReportingActionTypes.GET_GAME_RESULT_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameResultReportingActionTypes.GET_GAME_RESULT_REPORT_REQUEST, dispatch);
    }
}

export const GenerateGameResultPDFRequest = (
    gameServiceID: number, playerID: string, isCompleted: boolean, tradingDate: Date, transactionformTime: string, transactionToTime: string, from: number, takeCount: number, transactionFromDate?: Date, transactionToDate?: Date
) => async (dispatch: any) => {
    try {
        let request: GameResultReportRequestModel = {
            gsid: gameServiceID,
            pid: playerID,
            com: isCompleted,
            tdd: tradingDate,
            tfd: transactionFromDate,
            tft: transactionformTime,
            ttd: transactionToDate,
            ttt: transactionToTime,
            frm: from,
            tak: takeCount,
        };
        PostRequest("Reporting/GenerateGameResultPDF", request, GameResultReportingActionTypes.GENERATE_GAME_RESULT_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameResultReportingActionTypes.GENERATE_GAME_RESULT_PDF_REQUEST, dispatch);
    }
}

export const GenerateGameResultCSVRequest = (
    gameServiceID: number, playerID: string, isCompleted: boolean, tradingDate: Date, transactionformTime: string, transactionToTime: string, from: number, takeCount: number, transactionFromDate?: Date, transactionToDate?: Date
) => async (dispatch: any) => {
    try {
        let request: GameResultReportRequestModel = {
            gsid: gameServiceID,
            pid: playerID,
            com: isCompleted,
            tdd: tradingDate,
            tfd: transactionFromDate,
            tft: transactionformTime,
            ttd: transactionToDate,
            ttt: transactionToTime,
            frm: from,
            tak: takeCount,
        };
        PostRequest("Reporting/GenerateGameResultCSV", request, GameResultReportingActionTypes.GENERATE_GAME_RESULT_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameResultReportingActionTypes.GENERATE_GAME_RESULT_CSV_REQUEST, dispatch);
    }
}

export const GetGameDetailResultReportRequest = (
    historyID: number, gameServiceID: number, playerID: string, playerName: string, isCompleted: boolean) => async (dispatch: any) => {
    try {
        let request: GameDetailResultReportRequestModel = {
            id: historyID,
            gsid: gameServiceID,
            com: isCompleted,
            pid: playerID,
            pnm: playerName
        };
        PostRequest("Reporting/GetGameDetailResultReport", request, GameResultReportingActionTypes.GET_GAME_DETAIL_RESULT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameResultReportingActionTypes.GET_GAME_DETAIL_RESULT_REQUEST, dispatch);
    }
}

export const GenerateGameDetailResultPDFRequest = (
    historyID: number, gameServiceID: number, playerID: string, playerName: string, isCompleted: boolean) => async (dispatch: any) => {
    try {
        let request: GameDetailResultReportRequestModel = {
            id: historyID,
            gsid: gameServiceID,
            com: isCompleted,
            pid: playerID,
            pnm: playerName
        };
        PostRequest("Reporting/GenerateGameDetailResultPDF", request, GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_PDF_REQUEST, dispatch);
    }
}

export const GenerateGameDetailResultCSVRequest = (
    historyID: number, gameServiceID: number, playerID: string, playerName: string, isCompleted: boolean) => async (dispatch: any) => {
    try {
        let request: GameDetailResultReportRequestModel = {
            id: historyID,
            gsid: gameServiceID,
            com: isCompleted,
            pid: playerID,
            pnm: playerName
        };
        PostRequest("Reporting/GenerateGameDetailResultCSV", request, GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_CSV_REQUEST, dispatch);
    }
}

export const GetReplay = (
    gameID: number, gameServiceID: number, cms: number, isCompleted: boolean) => async (dispatch: any) => {
        try {
            let request: HistoryGameReplayRequestModel = {
                hid: gameID,
                gsid: gameServiceID,
                cms: cms,
                iag: !isCompleted
            };
            PostRequest("Reporting/GetGameReplay", request, GameResultReportingActionTypes.GET_GAME_REPLAY_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, GameResultReportingActionTypes.GET_GAME_REPLAY_REQUEST, dispatch);
        }
    }



