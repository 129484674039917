import { faBank, faBriefcase, faCubes, faGears, faHourglassHalf, faLink, faServer } from "@fortawesome/free-solid-svg-icons";
import JackpotAccumulator from "../containers/PAS/JackpotAccumulator";
import JackpotApprovalMaintenance from "../containers/PAS/JackpotApproval";
import JackpotApprovalTier from "../containers/PAS/JackpotApprovalTier";
import JackpotPool from "../containers/PAS/JackpotPool";
import JackpotPoolAdjustment from "../containers/PAS/JackpotPoolAdjustment";
import JackpotService from "../containers/PAS/JackpotService";
import JackpotTier from "../containers/PAS/JackpotTier";
import { BackOfficeAccess } from "../dto/enums/AccessMatrix/BackOfficeAccess";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const JackpotService_URL = "/JackpotService";
const JackpotAccumulator_URL = "/JackpotAccumulator";
const JackpotPool_URL = "/JackpotPool";
const JackpotTier_URL = "/JackpotTier";
const JackpotApprovalTier_URL = "/JackpotApprovalTier";
const JackpotPoolAdjustment_URL = "/JackpotPoolAdjustment";
const JackpotPoolApproval_URL = "/JackpotPoolApproval";

export const PASRoutesData: IRouteData[] = [
    {
        to: JackpotService_URL,
        element: <JackpotService />
    },
    {
        to: JackpotAccumulator_URL,
        element: <JackpotAccumulator />
    },
    {
        to: JackpotPool_URL,
        element: <JackpotPool />
    },
    {
        to: JackpotTier_URL,
        element: <JackpotTier />
    },
    {
        to: JackpotApprovalTier_URL,
        element: <JackpotApprovalTier />
    },
    {
        to: JackpotPoolAdjustment_URL,
        element: <JackpotPoolAdjustment />
    },
    {
        to: JackpotPoolApproval_URL,
        element: <JackpotApprovalMaintenance />
    },
];

export const PASNavigationData: INavigationData[] = [
    {
        requiredAccess: [BackOfficeAccess.JACKPOT_APPROVAL],
        icon: faHourglassHalf,
        title: "Jackpot Approval",
        path: "Jackpot / Jackpot Approval",
        searchKeyword: "Jackpot Approval",
        to: JackpotPoolApproval_URL
    },
    {
        requiredAccess: [BackOfficeAccess.JACKPOT_ADJUSTMENT, BackOfficeAccess.JACKPOT_APPROVAL],
        icon: faGears,
        title: "Jackpot Pool Adjustment",
        path: "Jackpot / Jackpot Pool Adjustment",
        searchKeyword: "Jackpot Pool Adjustment",
        to: JackpotPoolAdjustment_URL
    },
    {
        requiredAccess: [BackOfficeAccess.JACKPOT_APPROVAL],
        icon: faLink,
        title: "Jackpot Approval Tier",
        path: "Jackpot / Jackpot Approval Tier",
        searchKeyword: "Jackpot Approval Tier",
        to: JackpotApprovalTier_URL
    },
    {
        requiredAccess: [BackOfficeAccess.JACKPOT_MAINTENANCE],
        icon: faServer,
        title: "Jackpot Service",
        path: "Jackpot / Jackpot Service",
        searchKeyword: "Jackpot Service Maintenance",
        to: JackpotService_URL
    },
    {
        requiredAccess: [BackOfficeAccess.JACKPOT_MAINTENANCE],
        icon: faBriefcase,
        title: "Jackpot Accumulator",
        path: "Jackpot / Jackpot Accumulator",
        searchKeyword: "Jackpot Accumulator Maintenance",
        to: JackpotAccumulator_URL
    },
    {
        requiredAccess: [BackOfficeAccess.JACKPOT_MAINTENANCE],
        icon: faCubes,
        title: "Jackpot Pool",
        path: "Jackpot / Jackpot Pool",
        searchKeyword: "Jackpot Pool Maintenance",
        to: JackpotPool_URL
    },
    {
        requiredAccess: [BackOfficeAccess.JACKPOT_MAINTENANCE],
        icon: faLink,
        title: "Jackpot Tier",
        path: "Jackpot / Jackpot Tier",
        searchKeyword: "Jackpot Tier Maintenance",
        to: JackpotTier_URL
    }
];

export function PASSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(PASNavigationData, "Jackpot", faBank, "#ab6152", open, access, currentPath);
}