import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { TrandingDateModel } from "../../dto/models/TradingDateModels";
import { ActionTypes } from "./TradingDateActions";
import { TradingDateActionTypes } from "./TradingDateActionTypes";
import { InitialTradingState, ITradingDateState } from "./TradingDateStates";

export const tradingReducer = (state = InitialTradingState, action: ActionTypes): ITradingDateState => {
    switch (action.type) {
        case TradingDateActionTypes.GET_TRADING_DATE_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, data: {} as TrandingDateModel, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var tradingDate: TrandingDateModel = {} as TrandingDateModel;

                if (data && !data.err) {
                    tradingDate = data.res;
                }
                //  Set tradingDateState for IStore--> tradingDateState
                return { ...state, loading: false, data: tradingDate, err: action.payload.err ? action.payload.err : "", suc: "" };
            }
        }
        case TradingDateActionTypes.EDIT_TRADING_DATE_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, data: {} as TrandingDateModel, err: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var tradingDate: TrandingDateModel = {} as TrandingDateModel;

                if (data && !data.err) {
                    tradingDate = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "Trading Date Updated Successfully.";
                }
                //  Set tradingDateState for IStore--> tradingDateState
                return { ...state, loading: false, data: tradingDate, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}
