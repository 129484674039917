import { faBars, faBurger, faDoorClosed, faLock, faPencil, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, JSXElementConstructor, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import { AlertSize, AlertType } from "../../components/AlertMessage/IAlert";
import DropdownButton from "../../components/Dropdown/DropdownButton";
import { DropDownButtonStyle, DropdownDirection } from "../../components/Dropdown/IDropdownButton";
import TopbarSearchInputBox from "../../components/InputBox/TopbarSearchInputBox";
import ChangePasswordModal from "../../components/_modals/UserProfile/ChangePasswordModal";
import EditProfileModal from "../../components/_modals/UserProfile/EditProfileModal";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { AccessControlNavigationData } from "../../navigation/AccessControlRoutes";
import { AccountingNavigationData } from "../../navigation/AccoutingRoutes";
import { CashierNavigationData } from "../../navigation/CashierRoutes";
import { CommonNavigationData } from "../../navigation/CommonRoutes";
import { GameNavigationData } from "../../navigation/GameRoutes";
import { GetAllRoutes, INavigationData } from "../../navigation/navData";
import { PASNavigationData } from "../../navigation/PASRoutes";
import { ReportingNavigationData } from "../../navigation/ReportingRoutes";
import { ServiceAuthenticationNavigationData } from "../../navigation/ServiceAuthenticationRoutes";
import { SystemNavigationData } from "../../navigation/SystemRoutes";
import { IStore } from "../../states/store/IStore";
import { ChangePassword, UserLogout } from "../../states/User/UserActions";
import '../../styles/Topbar.css';

export default function Topbar(prop: { setOpen: Dispatch<SetStateAction<boolean>> }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const paths: string[] = location.pathname.split("/");
    const [isOpenChangePassword, openChangePassword] = useState(false);
    const [openWarnignMsg, setOpenWarningMsg] = useState(false);
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;
    const [openSidebar, setOpenSideBar] = useState(true);

    const [isOpenEditProfile, openEditProfile] = useState(false);
    const [unClosable, setUnclosable] = useState(false);

    paths.shift();

    const [searchInput, setSearchInput] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const navigate = useNavigate();

    const logout = () => {
        dispatch(UserLogout());
    }

    const getBreadcrumbItem = (path: string): JSX.Element => {
        const routes = GetAllRoutes();
        const nav = routes.find(x => x.to == "/" + path);

        if (nav && !openSidebar) {
            return (
                <span className="breadcrumb-a">
                    {nav.path}
                </span>
            );
        }
        else {
            return <a></a>;
        }
    }

    const GetBreadcrumb: JSX.Element[] = paths.map((item, index) => (
        <BreadcrumbItem key={index}>
            {getBreadcrumbItem(item)}
        </BreadcrumbItem>
    ));

    const GetNavigationData = (): INavigationData[] => {
        var navData: INavigationData[] = [];
        navData = navData.concat(CommonNavigationData);
        navData = navData.concat(AccessControlNavigationData);
        navData = navData.concat(CashierNavigationData);
        navData = navData.concat(AccountingNavigationData);
        navData = navData.concat(GameNavigationData);
        navData = navData.concat(PASNavigationData);
        navData = navData.concat(ReportingNavigationData);
        navData = navData.concat(SystemNavigationData);
        navData = navData.concat(ServiceAuthenticationNavigationData);

        return navData;
    }

    const dropdwonItems = (): JSX.Element[] => {
        const result: JSX.Element[] = [];
        result.push(<DropdownItem key='0' onClick={e => openEditProfile(!isOpenEditProfile)}><FontAwesomeIcon icon={faPencil} /> Edit Profile</DropdownItem>);
        result.push(<DropdownItem key='1' onClick={e => openChangePassword(!isOpenChangePassword)}><FontAwesomeIcon icon={faLock} /> Change Password</DropdownItem>);
        result.push(<DropdownItem key='2' onClick={logout}><FontAwesomeIcon icon={faRightFromBracket} /> Logout</DropdownItem>);

        return result;
    }

    useEffect(() => {
        if (userProfileData) {
            if (userProfileData.isue)
                setOpenWarningMsg(true);

            if (userProfileData.fcp) {
                openChangePassword(true);
                setUnclosable(true);
            }
        }
    }, [userProfileData])

    const triggerSidebar = () => {
        prop.setOpen(!openSidebar);
        setOpenSideBar(!openSidebar);
    }

    return (
        <div className="topBarContainer">
            <AlertMessage
                content={"Your password will be expired soon on " + FORMAT_DATETIME_TO_VIEW(userProfileData.ped ?? new Date(), "yyyy-MM-dd") + ", Please change it before it gets expired."}
                alertType={AlertType.WARNING}
                alertSize={AlertSize.SHORT}
                isOpen={openWarnignMsg && userProfileData.isue}
                openState={setOpenWarningMsg}
            />
            <div className="title-div">
                <span className="title-text">
                    <Button onClick={triggerSidebar} className={"extbtn"}>
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                </span>
                <img src="images/topbar_logo.png" style={{ maxWidth: '40px', marginInline: '10px', marginBottom:'10px' }} className="img-fluid" alt="Responsive image" />
                <span style={{ fontSize: '20px', paddingTop: '10px' }}> <b>Back Office</b></span>
                <Breadcrumb className="breadcrumb">
                    {GetBreadcrumb}
                </Breadcrumb>
            </div>
            <div className="searchForm">
                <Row className="justify-content-end" xs="3">
                    <Col className="col-auto g-0">
                        <TopbarSearchInputBox
                            id="topBarSearch"
                            searchItems={GetNavigationData()}
                        />
                    </Col>
                    <Col className="col-auto justify-content-end pl-5">
                        <DropdownButton
                            dropdownTitle={<FontAwesomeIcon icon={faUser} style={{ fontSize: '17px' }} />}
                            dropdownItems={dropdwonItems()}
                            disable={false}
                            dropdownButtonStyle={DropDownButtonStyle.ROUNDED_WITHOUT_CARET}
                            isOutline={true}
                            color="primary"
                            direction={DropdownDirection.DOWN}
                        ></DropdownButton>
                    </Col>
                </Row>
            </div>
            <ChangePasswordModal
                isOpen={isOpenChangePassword}
                setOpenState={openChangePassword}
                disableCloseBtn={unClosable}
            />
            <EditProfileModal
                isOpen={isOpenEditProfile}
                setOpenState={openEditProfile}
            />
        </div>
    );
}
