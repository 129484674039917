import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Label, Row, Spinner, Tooltip } from "reactstrap";
import { HistoryGameReplayRequestModel, InitGameModel } from "../../../dto/models/Reporting/GameResultRequestModel";
import { GetReplay } from "../../../states/Reporting/GameResultReport/GameResultReportingActions";
import { IStore } from "../../../states/store/IStore";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

export interface IGameReplayModal {
    isCompleted: boolean;
    gameID: number;
    gameServiceID: number;
    cms: number;

    isOpen: boolean;
    setOpenState: any;
}

export const postToIframe = async (json: any) => {
    var iframe: HTMLIFrameElement = document.getElementById("gameFrame") as HTMLIFrameElement;
    if (iframe != null && iframe.contentWindow != null) {
        try {
            await iframe.contentWindow.postMessage(json, new URL(iframe.src).origin);
        }
        catch (error) {
            console.log(error)
        }
    }
}

export const GameFrame = (props: { loading: boolean, url: string }) => {
    const handleIFrameLoad = () => {
        postToIframe({
            type: "gameLoading",
            origin: window.origin,
            lan: 0
        });
    }


    return (
        props.loading ?
            <div className='text-center mt-4'><Spinner key='1' /></div>
            :
            <iframe id="gameFrame" className="w-100 h-100" src={props.url} onLoad={handleIFrameLoad} allowFullScreen />
    )

}

const GameReplayModal = (props: IGameReplayModal) => {
    const dispatch = useDispatch();
    const gameResultState = useSelector((state: IStore) => state.gameResultReportState);
    const { gameRoomData, loading, err, suc } = gameResultState;
    const [url, setURL] = useState("");
    const [replayModel, setReplayModel] = useState<string | undefined>(undefined);
    const [initListener, setInitListener] = useState(false);
    const [triggerInitGame, setTriggerInit] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        if (props.isOpen) {
            dispatch(GetReplay(props.gameID, props.gameServiceID, props.cms, props.isCompleted))

            if (!initListener) {
                window.addEventListener('message', gameFrameMessageEvents);
                setInitListener(true)
            }
        }

    }, [props.isOpen])

    useEffect(() => {
        if (gameRoomData && gameRoomData.url && gameRoomData.url !== "") {
            setURL(gameRoomData.url)
            setReplayModel(gameRoomData.rsm)
        }
    }, [gameRoomData])

    useEffect(() => {
        if (triggerInitGame) {
            sendInitGame()
            setTriggerInit(false);
        }
    }, [triggerInitGame])

    const sendInitGame = () => {
        if (replayModel !== undefined) {
            let initGame: InitGameModel = {
                gmd: 2,
                lan: 0,
                gid: props.gameServiceID,
                rsm: replayModel !== undefined ? replayModel : ""
            }

            postToIframe({
                type: "initGame",
                res: JSON.stringify(initGame)
            });
        }
    }

    const gameFrameMessageEvents = (event: MessageEvent) => {
        if (event.data["type"] == "gameReady") {
            console.log("initGame");
            triggerInit();
        }

        event.stopPropagation();
        event.preventDefault();
    }

    const header = () => {
            return (
                <Row>
                    <Col>
                        {props.isCompleted ? "Completed Game Replay" : "Incomplete Game Replay"}
                        <Button id="tips" type="button" className={"btn-sm btn-circle btnTooltip"}><FontAwesomeIcon className={"fa-xs btnIcon"} icon={faInfo} /></Button>
                        <Tooltip
                            placement={'right'}
                            isOpen={tooltipOpen}
                            target="tips"
                            toggle={toggle}
                        ><p className={"tipsContent"}>{props.isCompleted ? "Represents a fully game cycle including gamble and mini game have been completed." : "Represents incomplete game which minigame or gamble is not yet completed."}</p></Tooltip>
                    </Col>
                </Row>
                );
    }

    const triggerInit = () => {
        setTriggerInit(true);
    }

    return (
        <ModalBox
            child={<GameFrame loading={loading} url={url} />}
            headerChild={header()}
            type={ModalType.Window}
            isCentered={true}
            error={err}
            success={suc}
            openState={props.setOpenState}
            isOpen={props.isOpen}
        />
    );
}

export default GameReplayModal;