import { PlayerPerformanceRequestModel } from "../../../dto/models/Reporting/PlayerPerformanceRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { PlayerPerformanceReportingActionTypes } from "./PlayerPerformanceReportingActionTypes";

type GetPlayerPerformanceReportRequest = { type: PlayerPerformanceReportingActionTypes.GET_PLAYER_PERFORMANCE_REPORT_REQUEST, payload: IPayload };
type GeneratePlayerPerformancePDFRequest = { type: PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_PDF_REQUEST, payload: IPayload };
type GeneratePlayerPerformanceCSVRequest = { type: PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_CSV_REQUEST, payload: IPayload };

export type ActionTypes =
    GetPlayerPerformanceReportRequest | GeneratePlayerPerformancePDFRequest | GeneratePlayerPerformanceCSVRequest;

export const GetPlayerPerformanceReportRequest = (
    userName: string, membershipID: string, game: number, gameName: string, tradFromDate: Date, tradToDate: Date, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: PlayerPerformanceRequestModel = {
            unm: userName,
            mid: membershipID,
            gme: game,
            gnm: gameName,
            tdf: tradFromDate,
            tdt: tradToDate,
            tsf: transFromDate,
            tft: transFromTime,
            tst: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetPlayerPerformanceReport", request, PlayerPerformanceReportingActionTypes.GET_PLAYER_PERFORMANCE_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerPerformanceReportingActionTypes.GET_PLAYER_PERFORMANCE_REPORT_REQUEST, dispatch);
    }
}

export const GeneratePlayerPerformanceReportPDFRequest = (
    userName: string, membershipID: string, game: number, gameName: string, tradFromDate: Date, tradToDate: Date, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: PlayerPerformanceRequestModel = {
            unm: userName,
            mid: membershipID,
            gme: game,
            gnm: gameName,
            tdf: tradFromDate,
            tdt: tradToDate,
            tsf: transFromDate,
            tft: transFromTime,
            tst: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetPlayerPerformancePDF", request, PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_PDF_REQUEST, dispatch);
    }
}

export const GeneratePlayerPerformanceReportCSVRequest = (
    userName: string, membershipID: string, game: number, gameName: string, tradFromDate: Date, tradToDate: Date, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: PlayerPerformanceRequestModel = {
            unm: userName,
            mid: membershipID,
            gme: game,
            gnm: gameName,
            tdf: tradFromDate,
            tdt: tradToDate,
            tsf: transFromDate,
            tft: transFromTime,
            tst: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetPlayerPerformanceCSV", request, PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_CSV_REQUEST, dispatch);
    }
}