export enum JackpotPendingType {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///     Big win
    /// </summary>
    BIG_WIN = 1,
    /// <summary>
    ///     Jackpot over threshold
    /// </summary>
    JACKPOT_OVER_THRESHOLD = 2,
    /// <summary>
    ///     Jackpot strike failed
    /// </summary>
    JACKPOT_STRIKE_FAILED = 3
}