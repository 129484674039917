import { GamingAreaType } from "../../dto/enums/GamingAreaType";
import { CmsConfigurationModel, CmsConfigurationListModel } from "../../dto/models/ConfigurationModels";
import { GamingAreaModel, InitGamingArea, RequestGamingAreaListByTypeModel } from "../../dto/models/GamingAreaModels";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { CmsActionTypes } from "./CmsActionTypes";

type AddCmsRequest = { type: CmsActionTypes.ADD_CMS_REQUEST, payload: IPayload };
type EditCmsRequest = { type: CmsActionTypes.EDIT_CMS_REQUEST, payload: IPayload };
type GetCmsListRequest = { type: CmsActionTypes.GET_CMS_LIST_REQUEST, payload: IPayload };
type GetCmsRequest = { type: CmsActionTypes.GET_CMS_REQUEST, payload: IPayload };
type SelectCmsRequest = { type: CmsActionTypes.SELECT_CMS_REQUEST, payload: IPayload };
type SearchCmsRequest = { type: CmsActionTypes.SEARCH_CMS_REQUEST, payload: IPayload };
type GetVipGamingAreaRequest = { type: CmsActionTypes.GET_VIP_GAMING_AREA_REQUEST, payload: IPayload };
type GetNonVipGamingAreaRequest = { type: CmsActionTypes.GET_NONVIP_GAMING_AREA_REQUEST, payload: IPayload };

export type ActionTypes =
    AddCmsRequest | EditCmsRequest | GetCmsListRequest | GetCmsRequest | SelectCmsRequest | SearchCmsRequest |
    GetVipGamingAreaRequest | GetNonVipGamingAreaRequest;

export const AddCms = (
    cmsType: number,
    domain: string, port: number, defaultLanguage: number,
    cert: number[], certPassword: string,
    username: string, userPassword: string, cashierID: string, profitCenter: string, selectedPra: number[],
    olrRetryCount: number, ecaRetryCount: number, olrRetryInterval: number,
    olrCurrency: string,
    vipGamingAreaId: number, nonVipGamingAreaId: number
) => async (dispatch: any) => {

    try {
        let vipGamingArea = InitGamingArea;
        vipGamingArea.gid = vipGamingAreaId;

        let nonVipGamingArea = InitGamingArea;
        nonVipGamingArea.gid = nonVipGamingAreaId;

        const praCode = selectedPra.reduce((accumulator, currentValue) => accumulator |= currentValue, 0);

        let request: CmsConfigurationModel = {
            cid: 0,
            typ: cmsType,
            dom: domain,
            prt: port,
            cer: cert,
            dfl: defaultLanguage,
            cep: certPassword,
            usm: username,
            usp: userPassword,
            csid: cashierID,
            prc: profitCenter,
            orc: olrRetryCount,
            ocu: olrCurrency,
            erc: ecaRetryCount,
            ori: olrRetryInterval,
            nga: nonVipGamingArea,
            vga: vipGamingArea,
            pra: praCode
        };
        PostRequest("BackOffice/AddCms", request, CmsActionTypes.ADD_CMS_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.ADD_CMS_REQUEST, dispatch);
    }
}

export const EditCms = (
    cid: number, cmsType: number,
    domain: string, port: number, defaultLanguage: number,
    cert: number[], certPassword: string,
    username: string, userPassword: string, cashierID: string, profitCenter: string, selectedPra: number[],
    olrRetryCount: number, ecaRetryCount: number, olrRetryInterval: number,
    olrCurrency: string,
    vipGamingAreaId: number, nonVipGamingAreaId: number
) => async (dispatch: any) => {

    try {
        let vipGamingArea = { ...InitGamingArea };
        vipGamingArea.gid = vipGamingAreaId;

        let nonVipGamingArea = { ...InitGamingArea };
        nonVipGamingArea.gid = nonVipGamingAreaId;

        const praCode = selectedPra.reduce((accumulator, currentValue) => accumulator |= currentValue, 0);

        let request: CmsConfigurationModel = {
            cid: cid,
            typ: cmsType,
            dom: domain,
            prt: port,
            cer: cert,
            dfl: defaultLanguage,
            cep: certPassword,
            usm: username,
            usp: userPassword,
            csid: cashierID,
            prc: profitCenter,
            orc: olrRetryCount,
            ocu: olrCurrency,
            erc: ecaRetryCount,
            ori: olrRetryInterval,
            nga: nonVipGamingArea,
            vga: vipGamingArea,
            pra: praCode
        };
        PostRequest("BackOffice/EditCms", request, CmsActionTypes.EDIT_CMS_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.EDIT_CMS_REQUEST, dispatch);
    }

}

export const GetCms = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetCms", request, CmsActionTypes.GET_CMS_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.GET_CMS_REQUEST, dispatch);
    }
}

export const SearchCms = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchCms", request, CmsActionTypes.SEARCH_CMS_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.SEARCH_CMS_REQUEST, dispatch);
    }
}

export const GetCmsList = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetCmsList", CmsActionTypes.GET_CMS_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.GET_CMS_LIST_REQUEST, dispatch);
    }
}

export const SelectCms = (cid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: cid
        };
        PostRequest("BackOffice/SelectCms", request, CmsActionTypes.SELECT_CMS_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.SELECT_CMS_REQUEST, dispatch);
    }
}

export const GetNonVipGamingArea = () => async (dispatch: any) => {
    try {
        let request: RequestGamingAreaListByTypeModel = {
            gat: GamingAreaType.NON_VIP
        };

        PostRequest("BackOffice/GetGamingAreaByType", request, CmsActionTypes.GET_NONVIP_GAMING_AREA_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.GET_NONVIP_GAMING_AREA_REQUEST, dispatch);
    }
}

export const GetVipGamingArea = () => async (dispatch: any) => {
    try {
        let request: RequestGamingAreaListByTypeModel = {
            gat: GamingAreaType.VIP
        };

        PostRequest("BackOffice/GetGamingAreaByType", request, CmsActionTypes.GET_VIP_GAMING_AREA_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CmsActionTypes.GET_NONVIP_GAMING_AREA_REQUEST, dispatch);
    }
}