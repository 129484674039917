export enum SessionMode {
    /// <summary>
    ///     Single session per player, single game
    /// </summary>
    SINGLE_SESSION_SINGLE_GAME = 1,
    /// <summary>
    ///     Single session per player, able to play on multiple games
    /// </summary>
    SINGLE_SESSION_MULTI_GAME = 2,
    /// <summary>
    ///     Multiple session per player
    /// </summary>
    MULTI_SESSION = 3
}