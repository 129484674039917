import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import StandardContainer from "../../components/StandardContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import PoolModal from "../../components/_modals/PAS/PoolModal";
import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { JackpotPoolTitle } from "../../dto/enums/Jackpot/JackpotPoolTitle";
import { HASFLAG } from "../../libraries/Functions";
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetPool, SearchPool } from "../../states/PAS/JackpotPool/PoolActions";
import { IStore } from "../../states/store/IStore";

const JackpotPool = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const prevIsSearch = useRef(false);
    const poolState = useSelector((state: IStore) => state.poolState);
    const { loading, err, suc, poolListData } = poolState;

    const emptyTable: IDataTable = {
        title: ["No", "Name", "Title", "Description", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [poolTable, setPoolTable] = useState(emptyTable)
    const [isSearch, setSearch] = useState(false);


    useEffect(() => {
        if (!openModal) {
            dispatch(GetPool(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchPool(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetPool(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddPool = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditPool = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (poolListData.pll && poolListData.pll.length > 0) {

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = poolListData.pll.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.pon,
                    JackpotPoolTitle[item.til],
                    item.des,
                    <Button onClick={() => EditPool(item.jid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setPoolTable(table)
    }, [poolListData])

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetPool(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchPool(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "poolPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: poolListData.ttc ?? 0
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <PoolModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="PoolContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddPool : undefined}
                tableProps={poolTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default JackpotPool;
