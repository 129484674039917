import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { JackpotBigWinType } from "../../dto/enums/Jackpot/JackpotBigWinType";
import { JackpotPendingActionType } from "../../dto/enums/JackpotPendingActionType";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GenerateJackpotBigWinApprovalReportCSVRequest, GenerateJackpotBigWinApprovalReportPDFRequest, GetJackpotBigWinApprovalReportRequest } from "../../states/Reporting/JackpotBigWinApprovalReport/JackpotBigWinApprovalReportingActions";
import { IStore } from "../../states/store/IStore";

const JackpotBigWinApprovalReporting = () => {
    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);
    const dispatch = useDispatch();
    const jackpotBigWinApprovalReportState = useSelector((state: IStore) => state.jackpotBigWinApprovalReportState);
    const { jackpotBigWinApprovalListData, loading, err, suc } = jackpotBigWinApprovalReportState;

    // Search Criteria
    const [actionByName, setActionByName] = useState("");
    const [pendingType, setPendingType] = useState(1);

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const pendingTypeModule: ISelectOption[] = LoadSelectOptionFromEnum(JackpotBigWinType);

    const emptyTable: IDataTable = {
        title: ["No", "Name", "Game Service Name", "Game History ID", "Wallet Type", "Win Type", "Jackpot Pool", "Amount", "Pending Date Time", "Remarks", "Action By", "Status", "Action Date Time"],
        columnWidth: ["30px", "180px", "120px", "100px", "90px", "90px", "90px", "130px", "130px", "130px", "80px", "130px", "130px"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        var table: IDataTable = emptyTable
        if (jackpotBigWinApprovalListData && jackpotBigWinApprovalListData.rml != undefined && hasSearched) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            var totalAmount: number = 0;

            table.data = jackpotBigWinApprovalListData.rml.map((x, index) => {
                totalAmount += x.amt;

                return [
                    startIndex + index + 1,
                    x.pnm + " (" + x.pun + ")",
                    x.gsn == "" ? "N/A" : x.gsn,
                    x.ghi,
                    x.wap == "" ? "N/A" : x.wap,
                    x.wip == 1 ? "BIG WIN" : "JACKPOT",
                    x.jpo == "" ? "N/A" : x.jpo,
                    CVT_TO_FLOAT_STRING(x.amt),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.pdt), "yyyy-MM-dd hh:mm:ssa"),
                    x.rmk,
                    x.aby,
                    JackpotPendingActionType[x.stu],
                    FORMAT_DATETIME_TO_VIEW(new Date(x.adt), "yyyy-MM-dd hh:mm:ssa")]
            });

            table.concludeColumn = [
                { columnIndex: 7, content: CVT_TO_FLOAT_STRING(totalAmount) },
            ];

            /*setHasSearched(false);*/
        } else
            table.data = [];

        setReportTable(table);
    }, [jackpotBigWinApprovalListData]);


    const valid = (): boolean => {
        return fromDate !== "" && toDate !== "" && toDateValid && fromDateValid;
    }


    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetJackpotBigWinApprovalReportRequest(actionByName, pendingType, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const pagination: ITablePagination = {
        id: "jackpotBigWinApprovalPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? jackpotBigWinApprovalListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setPage(0);
            setHasSearched(true);
            dispatch(GetJackpotBigWinApprovalReportRequest(actionByName, pendingType, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
        else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            dispatch(GenerateJackpotBigWinApprovalReportPDFRequest(actionByName, pendingType, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const generateCSV = () => {
        if (valid()) {
            dispatch(GenerateJackpotBigWinApprovalReportCSVRequest(actionByName, pendingType, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="actionBy"
                                name="actionBy"
                                label="Action By"
                                type={TextboxType.TEXT}
                                placeholder={"Name"}
                                isRequired={true}
                                inputState={setActionByName}
                                value={actionByName}
                                HasCancelledButton={true}
                            />
                        </Col>
                        <Col md={6}>
                            <SelectBox id="pendingType" name="pendingType" label="Pending Type"
                                options={pendingTypeModule} value={pendingType}
                                inputState={setPendingType}
                                allowEmptyData={false} placeHolderContent="---- Select Pending Type ----"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <DateTimeRangePicker isRequired fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" label="Transaction Date" hideTime={false} />
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div>
            <StandardReportContainer
                id="JackpotBigWinApprovalReporting"
                error={hasSearched ? err : ""}
                success={hasSearched ? suc : ""}
                loading={loading}
                tablePagination={pagination}
                tableProps={reportTable}
                searchCriteriaContent={SearchCriteriaContainer()}
                generateCSVEvent={generateCSV}
                generatePDFEvent={generatePDF}
            />
        </div>
    )
}



export default JackpotBigWinApprovalReporting;