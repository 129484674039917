import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Row, Col, FormGroup, Label, Button, Container } from 'reactstrap';
import { TextboxType } from '../../InputBox/IInputBox';
import { DefaultLanguage, GetLanguageName } from '../../../dto/enums/DefaultLanguage'
import InputBox from '../../InputBox/InputBox';
import ModalBox from '../../ModalBox/ModalBox';
import { ModalType } from '../../ModalBox/IModalBox';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../states/store/IStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { TRIGGER_BLUR } from '../../../libraries/Functions';
import TextField from '../../InputBox/TextField';
import { ResolveManualCreditTransfer, SelectManualCreditTransfer } from '../../../states/ManualCreditTransfer/ManualCreditTransferActions';

interface IManualSubmitOLRForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManualSubmitOLRForm = (props: IManualSubmitOLRForm) => {
    const [transactionId, setTransactionId] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [amount, setAmount] = useState("");
    const [walletName, setWalletName] = useState("");
    const [resolvedBy, setResolvedBy] = useState("");
    const [userID, setUserID] = useState(0);
    const [remarks, setRemarks] = useState("");
    const [remarksValid, setRemarksValid] = useState(props.isEdit);

    const dispatch = useDispatch();
    const manualCreditTransferState = useSelector((state: IStore) => state.manualCreditTransferState);
    const { creditTransferDetails, err, suc } = manualCreditTransferState;
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;

    useEffect(() => {
        if (props.editID) {
            dispatch(SelectManualCreditTransfer(props.editID));
        }

        ClearFields();

    }, [props.isEdit]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (creditTransferDetails.cid) {
            setTransactionId(creditTransferDetails.txid);
            setCustomerId(creditTransferDetails.cid);
            setAmount(creditTransferDetails.amt.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }));
            setWalletName(creditTransferDetails.wal);

            if (!props.isEdit) {
                setRemarks(creditTransferDetails.rmk);
                setResolvedBy(creditTransferDetails.rsbyn);
            }
            else {
                setUserID(userProfileData.uid);
                setResolvedBy(userProfileData.fnm);
            }
        }
    }, [creditTransferDetails]);

    const ClearFields = () => {
        setRemarks("");
    }

    const Valid = (): boolean => {
        return remarksValid
    }

    const save = () => {
        if (Valid() && remarks) {
            if (props.editID) {
                dispatch(
                    ResolveManualCreditTransfer(
                        props.editID,
                        userID,
                        resolvedBy,
                        remarks
                    ));
            }
        } else {
            TRIGGER_BLUR("creditTransferRemarks");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="creditTransferTransactionID">
                                        Transaction ID
                                    </Label>
                                    <InputBox
                                        id="creditTransferTransactionID"
                                        name="creditTransferTransactionID"
                                        type={TextboxType.TEXT}
                                        value={transactionId}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="creditTransferCustomerID">
                                        Customer ID
                                    </Label>
                                    <InputBox
                                        id="creditTransferCustomerID"
                                        name="creditTransferCustomerID"
                                        type={TextboxType.TEXT}
                                        value={customerId}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="creditTransferAmount">
                                        Transfer Amount
                                    </Label>
                                    <InputBox
                                        id="creditTransferAmount"
                                        name="creditTransferAmount"
                                        type={TextboxType.TEXT}
                                        value={amount}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="creditTransferWalletName">
                                        Wallet Name
                                    </Label>
                                    <InputBox
                                        id="creditTransferWalletName"
                                        name="creditTransferWalletName"
                                        type={TextboxType.TEXT}
                                        value={walletName}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="creditTransferResolvedBy">
                                        Resolved By
                                    </Label>
                                    <InputBox
                                        id="creditTransferResolvedBy"
                                        name="creditTransferResolvedBy"
                                        type={TextboxType.TEXT}
                                        value={resolvedBy}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <TextField id="creditTransferRemarks" name="creditTransferRemarks" label="Remarks"
                                placeholder="Remarks" value={remarks}
                                validState={setRemarksValid}
                                isRequired={true}
                                inputState={setRemarks} maxLength={300} col={5} row={5}
                                isDisabled={suc != "" || !props.isEdit}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

const ManualCreditTransferModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const manualCreditTransferState = useSelector((state: IStore) => state.manualCreditTransferState);
    const { err, suc, loading } = manualCreditTransferState;
    const [save, setSave] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    return (
        <div>
            <ModalBox
                title={"Manual Credit Transfer"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <ManualSubmitOLRForm isEdit={props.isEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={props.isEdit ? <Button color="info" onClick={() => setSave(true)} outline disabled={suc != ""}><FontAwesomeIcon icon={faFloppyDisk} /><span className="m-2" >Submit</span></Button> : <></>}
            />
        </div>
    );
}

export default ManualCreditTransferModal;

