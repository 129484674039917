import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import TierModal from '../../components/_modals/PAS/TierModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetTier, SearchTier } from '../../states/PAS/JackpotTier/TierActions';
import { IStore } from '../../states/store/IStore';


const JackpotTier = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const prevIsSearch = useRef(false);
    const tierState = useSelector((state: IStore) => state.tierState);
    const { loading, err, suc, tierListData } = tierState;

    const emptyTable: IDataTable = {
        title: ["No", "Name", "Description", "Progessive Rate (%)", "House Rate (%)", "Reserved Rate (%)", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [tierTable, setTierTable] = useState(emptyTable)
    const [isSearch, setSearch] = useState(false);


    useEffect(() => {
        if (!openModal) {
            dispatch(GetTier(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchTier(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetTier(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddTier = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditTier = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (tierListData.trl && tierListData.trl.length > 0) {

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = tierListData.trl.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.tnm,
                    item.des,
                    item.tpr,
                    item.thr,
                    item.trr,
                    <Button onClick={() => EditTier(item.tid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setTierTable(table)
    }, [tierListData])

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetTier(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchTier(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "tierPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: tierListData.ttc ?? 0
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <TierModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="JackpotTierContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddTier : undefined}
                tableProps={tierTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default JackpotTier;