export enum SlotGameType {
    /// <summary>
    ///     Game theme: Hoseh Liao
    /// </summary>
    HOSEH_LIAO = 1
}

export enum SlotGameStatus {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///     Game payout failed
    /// </summary>
    PAYOUT_FAILED = 1,
    /// <summary>
    ///     Game payout pending
    /// </summary>
    PAYOUT_PENDING = 2,
    /// <summary>
    ///     Jackpot contribution failed
    /// </summary>
    JACKPOT_CONTRIBUTION_FAILED = 4,
    /// <summary>
    ///     Jackpot strike failed
    /// </summary>
    JACKPOT_STRIKE_FAILED = 8,
    /// <summary>
    ///     Refund failed
    /// </summary>
    REFUND_FAILED = 10,
    /// <summary>
    ///     Gamble payout failed
    /// </summary>
    GAMBLE_PAYOUT_FAILED = 20
}

export enum SlotGameRTP {
    /// <summary>
    ///     Default rtp
    /// </summary>
    DEFAULT = 0,
    /// <summary>
    ///     Rtp 1
    /// </summary>
    RTP_1 = 1,
    /// <summary>
    ///     Rtp 2
    /// </summary>
    RTP_2 = 2,
    /// <summary>
    ///     Rtp 3
    /// </summary>
    RTP_3 = 3,
    /// <summary>
    ///     Rtp 4
    /// </summary>
    RTP_4 = 4,
    /// <summary>
    ///     Rtp 5
    /// </summary>
    RTP_5 = 5,
    /// <summary>
    ///     Rtp 6
    /// </summary>
    RTP_6 = 6,
    /// <summary>
    ///     Rtp 7
    /// </summary>
    RTP_7 = 7,
    /// <summary>
    ///     Rtp 8
    /// </summary>
    RTP_8 = 8,
    /// <summary>
    ///     Rtp 9
    /// </summary>
    RTP_9 = 9,
    /// <summary>
    ///     Rtp 10
    /// </summary>
    RTP_10 = 10
}

export enum SlotGambleGame {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///     Gamble 1
    /// </summary>
    GAMBLE_1 = 1,
    /// <summary>
    ///     Gamble 2
    /// </summary>
    GAMBLE_2 = 2,
    /// <summary>
    ///     Gamble 3
    /// </summary>
    GAMBLE_3 = 4,
    /// <summary>
    ///     Gamble 4
    /// </summary>
    GAMBLE_4 = 8,
    /// <summary>
    ///     Gamble 5
    /// </summary>
    GAMBLE_5 = 16,
    /// <summary>
    ///     Gamble 6
    /// </summary>
    GAMBLE_6 = 32,
    /// <summary>
    ///     Gamble 7
    /// </summary>
    GAMBLE_7 = 64,
    /// <summary>
    ///     Gamble 8
    /// </summary>
    GAMBLE_8 = 128,
}