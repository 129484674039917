import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { JackpotPendingActionType } from "../../../dto/enums/JackpotPendingActionType";
import { TRIGGER_BLUR } from "../../../libraries/Functions";
import { ResolveBigWinRequest } from "../../../states/BigWinPending/BigWinPendingActions";
import { ResolveJackpotRequest } from "../../../states/PAS/JackpotApproval/JackpotApprovalActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import TextField from "../../InputBox/TextField";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IJackpotApprovalForm {
    id: number;
    player: string;
    amount: string;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const JackpotApporvalForm = (props: IJackpotApprovalForm) => {
    const [remark, setRemark] = useState("");
    const [remarkValid, setRemarkValid] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(false);
    const [action, setAction] = useState<JackpotPendingActionType>(JackpotPendingActionType.APPROVED);
    const actionOptions = LoadSelectOptionFromEnum(JackpotPendingActionType);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    const save = () => {
        if (remarkValid && passwordValid) {
            dispatch(ResolveJackpotRequest(props.id, password, remark, action));
        }
        else {
            TRIGGER_BLUR("rmk");
            TRIGGER_BLUR("password");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Form>
            <Row>
                <Col>
                    <InputBox
                        id="player"
                        name="player"
                        label="Player"
                        type={TextboxType.TEXT}
                        value={props.player}
                        isDisabled={true}
                    />
                </Col>
                <Col>
                    <InputBox
                        id="amount"
                        name="amount"
                        label="Amount"
                        type={TextboxType.TEXT}
                        value={props.amount}
                        isDisabled={true}
                    />
                </Col>
            </Row>
            <Row>
                <TextField
                    id="rmk" name="rmk" label="Remarks"
                    placeholder="Remarks" value={remark} inputState={setRemark}
                    validState={setRemarkValid} isRequired={true}
                    maxLength={200} col={10} row={3}
                />
            </Row>
            <Row>
                <Col>
                    <SelectBox id="action" name="action" label="Action"
                        options={actionOptions} value={action} inputState={setAction}
                        isRequired={true}
                    />
                </Col>
                <Col>
                    <InputBox
                        id="password"
                        name="password"
                        label="Password"
                        type={TextboxType.PASSWORD}
                        value={password}
                        inputState={setPassword}
                        validState={setPasswordValid}
                        isRequired={true}
                    />
                </Col>
            </Row>
        </Form>
    );
}

interface IJackpotApprovalModal {
    id: number;
    player: string;
    amount: string;
    isOpen: boolean;
    setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
}
const JackpotApprovalModal = (props: IJackpotApprovalModal) => {
    const jackpotApprovalState = useSelector((state: IStore) => state.jackpotApprovalState);
    const { loading, suc, err } = jackpotApprovalState;
    const [save, setSave] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    return (
        <ModalBox
            title="Jackpot Pending"
            isOpen={props.isOpen}
            isFade={true}
            isCentered={true}
            isScrollable={true}
            type={ModalType.Window}
            openState={props.setOpenState}
            error={err}
            footer={<Button color="info" onClick={() => setSave(true)} outline disabled={loading}><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button>}
            success={suc}
            child={
                <JackpotApporvalForm
                    id={props.id}
                    amount={props.amount}
                    player={props.player}
                    saveTrigger={save}
                    setSaveTrigger={setSave}
                />
            }
        />
    )
};

export default JackpotApprovalModal;