import React from "react";

export interface ICheckBoxGroup {
    id: string;
    label?: string;
    className?: string;

    values?: string[];
    options: ICheckBoxOption[];

    isDisabled?: boolean;
    isLimitHeight?: boolean;

    inputState: React.Dispatch<React.SetStateAction<string[]>>;
    validState?: React.Dispatch<React.SetStateAction<boolean>>;

    minChoice?: number;
    maxChoice?: number;

    singleChoice?: boolean;
    inline: boolean;
}

export interface ICheckBoxOption {
    display: any;
    value: string;
}

export function LoadCheckboxOptionFromEnum<E>(Enum: E): ICheckBoxOption[] {
    let options: ICheckBoxOption[] = Object.keys(Enum).filter(key => isNaN(Number(key)))
        .map(key => (
            {
                display: key.replace(/_/g, " "),
                value: '' + (Enum as any)[key] + ''
            }
        ));

    return options;
}