import { InitPylonAuthenticationState, IPylonAuthenticationState } from "./PylonAuthenticationStates";
import { ActionTypes } from "./PylonAuthenticationActions";
import { PylonAuthenticationActionTypes } from "./PylonAuthenticationActionTypes";
import { ServiceAuthenticationListModel, ServiceTokenResponseModel } from "../../dto/models/ServiceAuthenticationModels";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";

export const pylonAuthenticationReducers = (state = InitPylonAuthenticationState, action: ActionTypes): IPylonAuthenticationState => {
    switch (action.type) {
        case PylonAuthenticationActionTypes.GET_PYLON_AUTHENTICATION_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, serviceList: {} as ServiceAuthenticationListModel, tokenRequestData: {} as ServiceTokenResponseModel, err: "", suc: ""
                };
            } else {
                var data: PylonResponseModel = action.payload.data;
                var list: ServiceAuthenticationListModel = {} as ServiceAuthenticationListModel;

                if (data && !data.err) {
                    list = data.res;
                }

                return { ...state, loading: false, tokenLoading: false, serviceList: list, tokenRequestData: {} as ServiceTokenResponseModel, err: action.payload.err ? action.payload.err : "" , suc: "" };
            }
        }
        case PylonAuthenticationActionTypes.GENERATE_PYLON_AUTHENTICATION_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: false, tokenLoading: true, tokenRequestData: {} as ServiceTokenResponseModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var serviceToken: ServiceTokenResponseModel = {} as ServiceTokenResponseModel;

                if (data && !data.err) {
                    serviceToken = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "Token Generated Successfully.";
                }

                return { ...state, loading: false, tokenLoading: false, tokenRequestData: serviceToken, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}