import { JackpotStrikeRequestModel } from "../../../dto/models/Reporting/JackpotStrikeReportRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { JackpotStrikeReportingActionTypes } from "./JackpotStrikeReportingActionTypes";
type GetJackpotStrikeReportRequest = { type: JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_REPORT_REQUEST, payload: IPayload };
type GetJackpotStrikeSummaryReportRequest = { type: JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_SUMMARY_REPORT_REQUEST, payload: IPayload };
type GenerateJackpotStrikePDFRequest = { type: JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_PDF_REQUEST, payload: IPayload };
type GenerateJackpotStrikeCSVRequest = { type: JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetJackpotStrikeReportRequest | GetJackpotStrikeSummaryReportRequest | GenerateJackpotStrikePDFRequest | GenerateJackpotStrikeCSVRequest;

export const GetJackpotDetailStrikeReportRequest = (
    poolID: number[], gameID: number, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotStrikeRequestModel = {
            pid: poolID,
            gid: gameID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetStrikeReport", request, JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_REPORT_REQUEST, dispatch);
    }
}

export const GetJackpotStrikeSummaryReportRequest = (
    poolID: number[], gameID: number, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotStrikeRequestModel = {
            pid: poolID,
            gid: gameID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetStrikeSummaryReport", request, JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_SUMMARY_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotStrikeReportingActionTypes.GET_JACKPOT_STRIKE_SUMMARY_REPORT_REQUEST, dispatch);
    }
}

export const GenerateJackpotDetailStrikePDFRequest = (
    poolID: number[], gameID: number, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotStrikeRequestModel = {
            pid: poolID,
            gid: gameID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotStrikePDF", request, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_PDF_REQUEST, dispatch);
    }
}

export const GenerateJackpotDetailStrikeCSVRequest = (
    poolID: number[], gameID: number, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotStrikeRequestModel = {
            pid: poolID,
            gid: gameID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotStrikeCSV", request, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_CSV_REQUEST, dispatch);
    }
}

export const GenerateJackpotSummaryStrikePDFRequest = (
    poolID: number[], gameID: number, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotStrikeRequestModel = {
            pid: poolID,
            gid: gameID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotStrikeSummaryPDF", request, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_PDF_REQUEST, dispatch);
    }
}

export const GenerateJackpotSummaryStrikeCSVRequest = (
    poolID: number[], gameID: number, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotStrikeRequestModel = {
            pid: poolID,
            gid: gameID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotStrikeSummaryCSV", request, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotStrikeReportingActionTypes.GENERATE_JACKPOT_STRIKE_CSV_REQUEST, dispatch);
    }
}

