import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Progress, Row, Spinner } from "reactstrap";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import { AlertSize, AlertType } from "../../components/AlertMessage/IAlert";
import CardContainer from "../../components/CardContainer/CardContainer";
import CarouselContainer from "../../components/CarousellContainer/CarouselContainer";
import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { JackpotPoolTitle } from "../../dto/enums/Jackpot/JackpotPoolTitle";
import { GameRtpModel } from "../../dto/models/GameRtpModel";
import { JackpotMeterAccumulatorModel, JackpotMeterModel } from "../../dto/models/JackpotModels";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { HASFLAG } from "../../libraries/Functions";
import { GetGameServiceInfo, GetJackpotMeter } from "../../states/Dashboard/DashboardActions";
import { IStore } from "../../states/store/IStore";
import JackpotDetailsModal from "../../components/_modals/Dashboard/JackpotDetailsModel";
import { GameGetCreditRateRequest } from "../../states/Game/GameActions";

const Dashboard = () => {
    const dashboardState = useSelector((state: IStore) => state.dashboardState)
    const { err, loading, jackpotMeterAccumulatorList, gameServiceInfoList } = dashboardState;

    const [jackpotAccumulatorMeter, setJackpotAccumulatorMeter] = useState<JackpotMeterAccumulatorModel[] | undefined>(undefined);
    const [rtpList, setRtpList] = useState<GameRtpModel[] | undefined>(undefined);
    const dispatch = useDispatch();
    const titleFontSize: string = "23px";
    const subTitleFontSize: string = "15px";
    const valueFontSize: string = "20px";

    // To view jackpot details page
    const [jackpotDetailsOpenModel, setJackpotDetailsOpenModel] = useState(false);
    const [currentSelJackpot, setCurrentSelJackpot] = useState<JackpotMeterModel | undefined>(undefined);

    // To get user back office access
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;
    const backOfficeAccess = userProfileData.acc ? userProfileData.acc.bor : 0;

    // To get credit rate
    const gameState = useSelector((state: IStore) => state.gameState);
    const { creditRate } = gameState;
    const [gameCurrency, setGameCurrency] = useState("");

    useEffect(() => {
        dispatch(GetJackpotMeter());
        dispatch(GetGameServiceInfo());
        dispatch(GameGetCreditRateRequest());
    }, []);

    useEffect(() => {
        if (dashboardState && jackpotMeterAccumulatorList != undefined && jackpotMeterAccumulatorList.length > 0) {
            setJackpotAccumulatorMeter(jackpotMeterAccumulatorList);
        }
    }, [jackpotMeterAccumulatorList]);

    useEffect(() => {
        if (dashboardState && gameServiceInfoList != undefined && gameServiceInfoList.grs && gameServiceInfoList.grs.length > 0) {
            setRtpList(dashboardState.gameServiceInfoList.grs);
        }
    }, [gameServiceInfoList]);

    useEffect(() => {
        if (dashboardState && creditRate && creditRate != undefined) {
            setGameCurrency(creditRate.crc);
        }
    }, [creditRate]);

    const widgetContainerStyle = (backgroundColor: string, padding: string): React.CSSProperties => {
        return { backgroundColor: backgroundColor, padding: padding, border: 'solid 1px', borderRadius: "6px", boxShadow: '0.4px 0.8px 0.4px black' }
    }

    const gameServicesContent = (): any[] => {
        var containers: JSX.Element[] = [];
        if (rtpList != undefined && rtpList.length > 0) {
            rtpList.map((item, index) => {
                containers.push(
                    <div key={index}>
                        <Row>
                            <Col>
                                <span style={{ fontSize: titleFontSize }}><b>{item.gsn}</b></span>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Col>
                                <span style={{ fontSize: subTitleFontSize }}><b>Realtime RTP</b></span>
                            </Col>
                            <Col>
                                <span style={{ fontSize: subTitleFontSize }}><b>RTP Trading Date: {FORMAT_DATETIME_TO_VIEW(item.tdd, "dd-MM-yyyy")}</b></span>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Col>
                                <span style={{ fontSize: valueFontSize }}><b>{CVT_TO_FLOAT_STRING(item.rtp) + " %"}</b></span>
                            </Col>
                            <Col>
                                <span style={{ fontSize: valueFontSize }}><b>{CVT_TO_FLOAT_STRING(item.tdr) + " %"}</b></span>
                            </Col>
                        </Row>
                    </div>
                )
            });
        }
        return containers;
    }

    const jackpotContext = (): any[] => {
        var containers: JSX.Element[] = [];

        if (jackpotAccumulatorMeter != undefined && jackpotAccumulatorMeter.length > 0) {
            jackpotAccumulatorMeter.map((item, index) => {
                if (item.acc != null) {
                    containers.push(
                        <div key={index}>
                            <Row>
                                <Col>
                                    <span style={{ fontSize: titleFontSize }}><b>Progressive Jackpot Meter ({item.acc})</b></span>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                {!loading && item.jmp !== undefined ? item.jmp.map(x => (
                                    <Col key={JackpotPoolTitle[x.ttl].toString()} onClick={() => openJackpotDetails(x)}>
                                        <CardContainer
                                            color="light"
                                            outline={false}
                                            inverse={false}
                                            title={JackpotPoolTitle[x.ttl].toString().replace("_", " ")}
                                            content={
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <Progress
                                                                animated
                                                                color="info"
                                                                value={x.crt / x.crtc * 100}
                                                            />

                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <span style={{ fontSize: valueFontSize }}><b>{CVT_TO_FLOAT_STRING(x.crt)} / {CVT_TO_FLOAT_STRING(x.crtc)}</b></span>
                                                    </Row>
                                                    <Row>
                                                        <span style={{ fontSize: subTitleFontSize, color: 'grey' }}>&nbsp;&nbsp;&nbsp;{gameCurrency + CVT_TO_FLOAT_STRING(x.amt)} / {gameCurrency + CVT_TO_FLOAT_STRING(x.amtc)}</span>
                                                    </Row>
                                                </div>
                                            }
                                            loading={loading}
                                        />
                                    </Col>
                                )) : errHandlingComponent()}
                            </Row>
                        </div>
                    )
                }
            });
        }


        return containers;
    }

    const errHandlingComponent = (): any => {
        if (err != "")
            return (
                <AlertMessage
                    alertType={AlertType.FAIL}
                    isOpen={err != ""}
                    content={err}
                    alertSize={AlertSize.LONG}
                    disableCrossButon={true} />
            );

        return (<div className="text-center p-4"><Spinner /></div>);
    }

    const openJackpotDetails = (jackpotMeterModel: JackpotMeterModel): any => {
        jackpotMeterModel.crc = gameCurrency;
        setCurrentSelJackpot(jackpotMeterModel);
        setJackpotDetailsOpenModel(true);
    }

    return (
        <div>
            <Row style={widgetContainerStyle("#f8f8f8", "20px")} hidden={!HASFLAG(backOfficeAccess, BackOfficeAccess.JACKPOT_MAINTENANCE)}>
                <JackpotDetailsModal setOpenState={setJackpotDetailsOpenModel} isOpen={jackpotDetailsOpenModel} jackpotMeterModel={currentSelJackpot} />
                <Col className='g-0'>
                    <CarouselContainer
                        err={err}
                        dark={true}
                        fade={false}
                        slide={true}
                        items={jackpotContext()}
                        loading={loading}
                    />
                </Col>
            </Row>
            <Row className='w-50 mt-2' style={widgetContainerStyle("#f8f8f8", "10px")} hidden={!HASFLAG(backOfficeAccess, BackOfficeAccess.GAME_SERVICE_MAINTENANCE)}>
                <Col className='g-0'>
                    <CarouselContainer
                        err={err}
                        dark={true}
                        fade={false}
                        slide={true}
                        items={gameServicesContent()}
                        loading={loading}
                    />
                </Col>
            </Row>
        </div>
    );

}
export default Dashboard;