import { JackpotBigWinApprovalCSVReportModel, JackpotBigWinApprovalPDFReportModel, JackpotBigWinApprovalReportListModel } from "../../../dto/models/Reporting/JackpotBigWinApprovalReportModel";

export interface IJackpotBigWinApprovalReportState {
    loading: boolean;
    err: string;
    suc: string;
    jackpotBigWinApprovalListData: JackpotBigWinApprovalReportListModel;
    pdfData: JackpotBigWinApprovalPDFReportModel;
    csvData: JackpotBigWinApprovalCSVReportModel;
}

export const InitJackpotBigWinApprovalReportState: IJackpotBigWinApprovalReportState = {
    loading: false,
    err: "",
    suc: "",
    jackpotBigWinApprovalListData: {} as JackpotBigWinApprovalReportListModel,
    pdfData: {} as JackpotBigWinApprovalPDFReportModel,
    csvData: {} as JackpotBigWinApprovalCSVReportModel,
}