import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { GameResultReportCSVModel, GameResultReportListModel, GameResultReportModel, GameResultReportPDFModel, GameRoomModel } from "../../../dto/models/Reporting/GameResultReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";
import { ActionTypes } from "./GameResultReportingActions";
import { GameResultReportingActionTypes } from "./GameResultReportingActionTypes";
import { IGameResultReportState, InitGameResultReportState } from "./GameResultReportingStates";


export const GameResultReportingReducer = (state = InitGameResultReportState, action: ActionTypes): IGameResultReportState => {
    switch (action.type) {
        case GameResultReportingActionTypes.GET_GAME_RESULT_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, gameResultReportListData: {} as GameResultReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: GameResultReportListModel = {} as GameResultReportListModel;

                if (data && !data.err)
                    reportListModel = data.res;

                let errMsg: string = "";
                let sucMsg: string = ""

                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel && reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                    else
                        errMsg = "No Records Found"
                }
                return { ...state, loading: false, gameResultReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case GameResultReportingActionTypes.GET_GAME_DETAIL_RESULT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, gameDetailData: {} as GameResultReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportModel: GameResultReportModel = {} as GameResultReportModel;

                if (data && !data.err)
                    reportModel = data.res;

                let errMsg: string = "";
                let sucMsg: string = ""

                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportModel && reportModel.pnm == "")
                        errMsg = "No Record Found"
                }
                return { ...state, loading: false, gameDetailData: reportModel, err: errMsg, suc: sucMsg };
            }
        }
        case GameResultReportingActionTypes.GENERATE_GAME_RESULT_PDF_REQUEST:
        case GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_PDF_REQUEST:
            {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as GameResultReportPDFModel, csvData: {} as GameResultReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: GameResultReportPDFModel = {} as GameResultReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME(action.type == GameResultReportingActionTypes.GENERATE_GAME_RESULT_PDF_REQUEST ? "GameResultReport" : "GameDetailResultReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        }
        case GameResultReportingActionTypes.GENERATE_GAME_RESULT_CSV_REQUEST:
        case GameResultReportingActionTypes.GENERATE_GAME_DETAIL_RESULT_CSV_REQUEST:
            {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as GameResultReportPDFModel, csvData: {} as GameResultReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: GameResultReportCSVModel = {} as GameResultReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME(action.type == GameResultReportingActionTypes.GENERATE_GAME_RESULT_CSV_REQUEST ? "GameResultReport" : "GameDetailResultReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
            }
        case GameResultReportingActionTypes.GET_GAME_REPLAY_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, gameRoomData: {} as GameRoomModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var roomModel: GameRoomModel = {} as GameRoomModel;

                if (data && !data.err) {
                    const jsonData = JSON.parse(data.res);
                    const { url, rsm } = jsonData;

                    roomModel.url = url;
                    roomModel.rsm = rsm;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                }

                return { ...state, loading: false, gameRoomData: roomModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
