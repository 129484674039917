export namespace HosehLiao {
    export enum RTP {
        RTP_88 = 0,
        RTP_90 = 1,
        RTP_92 = 2,
        RTP_94 = 3,
        RTP_96 = 4,
        RTP_98 = 5
    }

    export enum Gamble {
        COIN_FLIP = 1,
        LUCKY_SPIN = 2
    }
}