import { GameCreditCurrency } from "../../../dto/models/ConfigurationModels";
import { ApprovalTierListModel, ApprovalTierModel } from "../../../dto/models/JackpotModels";
import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ActionTypes } from "./ApprovalTierActions";
import { ApprovalTierActionTypes } from "./ApprovalTierActionTypes";
import { IApprovalTierState, InitApprovalTierState } from "./ApprovalTierStates";

export const ApprovalTierReducer = (state = InitApprovalTierState, action: ActionTypes): IApprovalTierState => {
    switch (action.type) {
        case ApprovalTierActionTypes.SELECT_APPROVAL_TIER_REQUEST:
        case ApprovalTierActionTypes.ADD_APPROVAL_TIER_REQUEST:
        case ApprovalTierActionTypes.EDIT_APPROVAL_TIER_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, approvalTierData: {} as ApprovalTierModel, approvalTierListData: {} as ApprovalTierListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var approvalTierModel: ApprovalTierModel = {} as ApprovalTierModel;

                if (data && !data.err) {
                    approvalTierModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == ApprovalTierActionTypes.ADD_APPROVAL_TIER_REQUEST) {
                        sucMsg = "Approval Tier Added Successfully.";
                    }
                    else if (action.type == ApprovalTierActionTypes.EDIT_APPROVAL_TIER_REQUEST) {
                        sucMsg = "Approval Tier Updated Successfully.";
                    }
                }
                return { ...state, loading: false, approvalTierData: approvalTierModel, err: errMsg, suc: sucMsg };
            }
        }
        case ApprovalTierActionTypes.GET_APPROVAL_TIER_REQUEST:
        case ApprovalTierActionTypes.SEARCH_APPROVAL_TIER_REQUEST:
        case ApprovalTierActionTypes.GET_APPROVAL_TIER_LIST_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, approvalTierData: {} as ApprovalTierModel, approvalTierListData: {} as ApprovalTierListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var approvalTierListModel: ApprovalTierListModel = {} as ApprovalTierListModel;

                if (data && !data.err) {
                    approvalTierListModel = data.res;
                }
                return { ...state, loading: false, approvalTierListData: approvalTierListModel, err: action.payload.err ? action.payload.err : "" };
            }
        }
        case ApprovalTierActionTypes.GET_CREDIT_RATE: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return state;
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var rate: GameCreditCurrency = {} as GameCreditCurrency;

                if (data && !data.err) {
                    rate = data.res;
                }
                return { ...state, creditRate: rate };
            }
        }
        default:
            return state
    }
}
