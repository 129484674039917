import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { UserListingCSVReportModel, UserListingPDFReportModel, UserListingReportListModel } from "../../../dto/models/Reporting/UserListingReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";
import { ActionTypes } from "./UserListingReportingActions";
import { UserListingReportingActionTypes } from "./UserListingReportingActionTypes";
import { InitUserListingReportState, IUserListingReportState } from "./UserListingReportingStates";

export const UserListingReportingReducer = (state = InitUserListingReportState, action: ActionTypes): IUserListingReportState => {
    switch (action.type) {
        case UserListingReportingActionTypes.GET_USER_LISTING_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, userListingReportListData: {} as UserListingReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: UserListingReportListModel = {} as UserListingReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrived."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, userListingReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case UserListingReportingActionTypes.GENERATE_USER_LISTING_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as UserListingPDFReportModel, csvData: {} as UserListingCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: UserListingPDFReportModel = {} as UserListingPDFReportModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("UserListingReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case UserListingReportingActionTypes.GENERATE_USER_LISTING_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as UserListingPDFReportModel, csvData: {} as UserListingCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: UserListingCSVReportModel = {} as UserListingCSVReportModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("UserListingReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}