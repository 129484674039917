import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import DropdownSelectBox from "../../components/InputBox/DropdownSelectBox";
import { IDropdownSelectOption } from "../../components/InputBox/IDropdownSelectBox";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { JackpotPoolType } from "../../dto/enums/Jackpot/JackpotPoolType";
import { SlotGameType } from "../../dto/enums/SlotGames";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_ROW } from "../../libraries/Global";
import { GetPoolList } from "../../states/PAS/JackpotPool/PoolActions";
import { GenerateJackpotDetailStrikeCSVRequest, GenerateJackpotDetailStrikePDFRequest, GetJackpotDetailStrikeReportRequest } from "../../states/Reporting/JackpotStrikeReport/JackpotStrikeReportingActions";
import { IStore } from "../../states/store/IStore";


const JackpotStrikeReporting = () => {
    const dispatch = useDispatch();
    const poolStrikeReportState = useSelector((state: IStore) => state.poolStrikeReportState);
    const { strikeReportListData, loading, err, suc } = poolStrikeReportState;
    const poolStates = useSelector((state: IStore) => state.poolState);
    const { poolListData } = poolStates;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    // Search Criteria
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const [selectedPool, selectPool] = useState<number[]>([]);
    const [selectedPoolValid, setPoolValid] = useState(false);
    const [gameIDInput, setGameIDInput] = useState<number | undefined>(undefined);
    const [gameIDValid, setGameIDValid] = useState(false);

    const [poolOptions, setPoolOptions] = useState([] as IDropdownSelectOption[]);

    const emptyTable: IDataTable = {
        title: ["No.", "Pool Name", "Pool Type", "Game Type", "Game ID", "Open Progressive Amount", "Strike Amount", "Close Progressive Amount", "Denomination Tier", "Credit Rate", "Trading Date", "Transaction Date Time"],
        columnWidth: ["80px", "100px", "200px", "150px", "120px", "200px", "200px", "200px", "100px", "100px", "120px", "200px"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        dispatch(GetPoolList());
    }, []);

    useEffect(() => {
        var table: IDataTable = emptyTable
        var index: number = currentPage * TABLE_MAX_ROW;
        if (strikeReportListData && strikeReportListData.rls != undefined && hasSearched) {
            var totalStrike: number = 0;
            table.data = strikeReportListData.rls.map(x => {
                totalStrike += x.sam;
                return [
                    1 + index++,
                    x.pnm,
                    JackpotPoolType[x.typ].replace("_", " "),
                    SlotGameType[x.gty].replace("_", " "),
                    x.gid,
                    CVT_TO_FLOAT_STRING(x.opn),
                    CVT_TO_FLOAT_STRING(x.sam),
                    CVT_TO_FLOAT_STRING(x.cls),
                    CVT_TO_FLOAT_STRING(x.dtr * 100) + "%",
                    CVT_TO_FLOAT_STRING(x.cdr),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.tdd), "yyyy-MM-dd"),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.tdt), "yyyy-MM-dd hh:mm:ssa"),
                ]
            });

            table.concludeColumn = [{ columnIndex: 6, content: CVT_TO_FLOAT_STRING(totalStrike) }];
        } else
            table.data = [];

        setReportTable(table);
    }, [strikeReportListData]);

    useEffect(() => {
        if (poolListData && poolListData.pll) {
            const pools: IDropdownSelectOption[] = poolListData.pll.map((item) => {
                return {
                    label: item.pon,
                    value: item.jid.toString()
                }
            });

            setPoolOptions(pools);
        }
    }, [poolListData]);

    const take = (page: number) => {
        if (valid()) {
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            setPage(page);
            dispatch(GetJackpotDetailStrikeReportRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const valid = (): boolean => {
        return fromDate !== "" && toDate !== "" && toDateValid && fromDateValid && selectedPoolValid;
    }

    const pagination: ITablePagination = {
        id: "strikeReportPagination",
        maxPage: 8,
        onChangePage: take,
        totalCount: hasSearched ? strikeReportListData && strikeReportListData.ttc ? strikeReportListData.ttc : 0 : 0,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            setHasSearched(true);
            setPage(0);
            dispatch(GetJackpotDetailStrikeReportRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        } else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
            TRIGGER_BLUR("poolSelectDropdown");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            dispatch(GenerateJackpotDetailStrikePDFRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            dispatch(GenerateJackpotDetailStrikeCSVRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker label="Transaction Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} isRequired toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col>
                            <DropdownSelectBox allowSelectAll={true} id="poolSelectDropdown" name="poolSelectDropdown" options={poolOptions}
                                isRequired={true} inputState={selectPool} validState={setPoolValid} placeholder="----Select Pool----" label="Pool" />
                        </Col>
                    </Row>
                    <Row>
                        <InputBox
                            id="gameID"
                            name="gameID"
                            label="Game ID"
                            type={TextboxType.NUMBER}
                            min={0}
                            inputState={setGameIDInput}
                            placeholder={"Game ID"}
                            validState={setGameIDValid}
                            value={gameIDInput}
                        />
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="JackpotStrikeReporting"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}



export default JackpotStrikeReporting;