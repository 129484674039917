import { GameCreditCurrency } from "../../dto/models/ConfigurationModels";
import { DenominationListModel, DenominationModel } from "../../dto/models/DenominationModels";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./DenominationActions";
import { DenominationActionTypes } from "./DenominationActionTypes";
import { IDenominationState, InitialDenominationState } from "./DenominationStates";

export const denominationReducer = (state = InitialDenominationState, action: ActionTypes): IDenominationState => {
    switch (action.type) {
        case DenominationActionTypes.SEARCH_DENOMINATION_REQUEST:
        case DenominationActionTypes.GET_DENOMINATION_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, denominationList: {} as DenominationListModel, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var denominationList: DenominationListModel = {} as DenominationListModel;

                if (data && !data.err) {
                    denominationList = data.res;
                }

                let errMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }               

                return {
                    ...state, loading: false, denominationList: denominationList, denominationData: {} as DenominationModel, err: errMsg
                };
            }
        }
        case DenominationActionTypes.ADD_DENOMINATION_REQUEST:
        case DenominationActionTypes.EDIT_DENOMINATION_REQUEST:
        case DenominationActionTypes.SELECT_DENOMINATION_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, denominationData: {} as DenominationModel, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var denomination: DenominationModel = {} as DenominationModel;

                if (data && !data.err) {
                    denomination = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == DenominationActionTypes.ADD_DENOMINATION_REQUEST) {
                        sucMsg = "Denomination Added Successfully.";
                    }
                    else if (action.type == DenominationActionTypes.EDIT_DENOMINATION_REQUEST) {
                        sucMsg = "Denomination Updated Successfully."
                    }
                }

                return { ...state, loading: false, denominationData: denomination, err: errMsg, suc: sucMsg };
            }
        }
        case DenominationActionTypes.GET_CREDIT_RATE: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return state;
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var rate: GameCreditCurrency = {} as GameCreditCurrency;

                if (data && !data.err) {
                    rate = data.res;
                }
                return { ...state, creditRate: rate };
            }
        }
        default:
            return state;
    }
}