import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import DropdownSelectBox from "../../components/InputBox/DropdownSelectBox";
import { IDropdownSelectOption } from "../../components/InputBox/IDropdownSelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { TierParameterType } from "../../dto/enums/Jackpot/JackpotTierCondition";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { GROUP_TABLE_MAX_ROW, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetPoolList } from "../../states/PAS/JackpotPool/PoolActions";
import { GenerateJackpotAdjustmentCSVRequest, GenerateJackpotAdjustmentPDFRequest, GetJackpotAdjustmentReportRequest } from "../../states/Reporting/JackpotAdjustmentReport/JackpotAdjustmentReportingActions";
import { IStore } from "../../states/store/IStore";


const JackpotAdjustmentReporting = () => {
    const dispatch = useDispatch();
    const poolAdjustmentReportState = useSelector((state: IStore) => state.poolAdjustmentReportState);
    const { adjustmentReportListData, loading, err, suc } = poolAdjustmentReportState;
    const poolStates = useSelector((state: IStore) => state.poolState);
    const { poolListData } = poolStates;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    // Search Criteria
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const [selectedPool, selectPool] = useState<number[]>([]);
    const [selectedPoolValid, setPoolValid] = useState(false);

    const [poolOptions, setPoolOptions] = useState([] as IDropdownSelectOption[]);

    const emptyTable: IDataTable = {
        title: ["No.", "Pool Name", "Distribution", "Adjusted By", "Jackpot Ceilling", "Start Balance", "Adjusted Amount", "End Balance", "Credit Rate", "Transaction Date", "Trading Date", "Remark", "Status"],
        columnWidth: ["100px", "200px", "150px", "200px", "250px", "250px", "250px", "150px", "200px", "200px", "250px", "150px"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        dispatch(GetPoolList());
    }, []);

    useEffect(() => {
        var table: IDataTable = emptyTable
        var index: number = currentPage;
        var totalAdjustmentAmount: number = 0;
        if (adjustmentReportListData && adjustmentReportListData.rml != undefined && hasSearched) {
            table.data = adjustmentReportListData.rml.map(x => {
                totalAdjustmentAmount += x.aam;
                return [
                    1 + index++,
                    x.pnm,
                    TierParameterType[x.ttp],
                    x.rnm,
                    CVT_TO_FLOAT_STRING(x.jpc),
                    CVT_TO_FLOAT_STRING(x.sbl),
                    CVT_TO_FLOAT_STRING(x.aam),
                    CVT_TO_FLOAT_STRING(x.ebl),
                    CVT_TO_FLOAT_STRING(x.cdr),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.tdt), "yyyy-MM-dd hh:mm:ssa"),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.tdd), "yyyy-MM-dd"),
                    x.rmk,
                    "Approved"]
            });
        } else
            table.data = [];

        table.concludeColumn = [
            { columnIndex: 6, content: CVT_TO_FLOAT_STRING(totalAdjustmentAmount) },
        ];
        setReportTable(table);

    }, [adjustmentReportListData]);


    useEffect(() => {
        if (poolListData && poolListData.pll) {
            const pools: IDropdownSelectOption[] = poolListData.pll.map((item) => {
                return {
                    label: item.pon,
                    value: item.jid.toString()
                }
            });

            setPoolOptions(pools);
        }
    }, [poolListData]);

    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetJackpotAdjustmentReportRequest(selectedPool, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * GROUP_TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const valid = (): boolean => {
        return fromDate !== "" && toDate !== "" && toDateValid && fromDateValid && selectedPoolValid;
    }

    const pagination: ITablePagination = {
        id: "adjustmentReportPagination",
        maxPage: 8,
        onChangePage: take,
        totalCount: hasSearched ? adjustmentReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);
            dispatch(GetJackpotAdjustmentReportRequest(selectedPool, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * GROUP_TABLE_MAX_ROW, TABLE_MAX_ROW));
        } else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
            TRIGGER_BLUR("poolSelectDropdown");
        }
    }

    const generatePDF = () => {
        setHasSearched(true);
        if (valid()) {
            dispatch(GenerateJackpotAdjustmentPDFRequest(selectedPool, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * GROUP_TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const generateCSV = () => {
        setHasSearched(true);
        if (valid()) {
            dispatch(GenerateJackpotAdjustmentCSVRequest(selectedPool, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * GROUP_TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker isRequired label="Transaction Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col>
                            <DropdownSelectBox allowSelectAll={true} id="poolSelectDropdown" name="poolSelectDropdown" options={poolOptions}
                                isRequired={true} inputState={selectPool} validState={setPoolValid} placeholder="----Select Pool----" label="Pool" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="JackpotAdjustmentReporting"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}



export default JackpotAdjustmentReporting;