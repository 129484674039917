import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { ActionStatus } from "../../dto/enums/ActionStatus";
import { CreditTransferType } from "../../dto/enums/TransferMode";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetCreditTransactionReportRequest, GenerateCreditTransactionCSVRequest, GenerateCreditTransactionPDFRequest } from "../../states/Reporting/CreditTransactionReport/CreditTransactionReportingActions";
import { IStore } from "../../states/store/IStore";


const CreditTransactionReporting = () => {
    const dispatch = useDispatch();
    const creditTransactionReportState = useSelector((state: IStore) => state.creditTransactionReportState);
    const { creditTransactionReportListData, loading, err, suc } = creditTransactionReportState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    // Search Criteria
    const [fromTransactionDate, setFromTransactionDate] = useState("");
    const [toTransactionDate, setToTransactionDate] = useState("");
    const [toTransactionDateValid, setToTransactionDateValid] = useState(false);
    const [fromTransactionDateValid, setFromTransactionDateValid] = useState(false);
    const [fromTradingDate, setFromTradingDate] = useState("");
    const [toTradingDate, setToTradingDate] = useState("");
    const [toTradingDateValid, setToTradingDateValid] = useState(false);
    const [fromTradingDateValid, setFromTradingDateValid] = useState(false);
    const [customerID, setCustomerID] = useState("");
    const [customerIDValid, setCustomerIDValid] = useState(false);
    const creditTransactionType: ISelectOption[] = LoadSelectOptionFromEnum(CreditTransferType, true);
    const [transactionType, setTransactionType] = useState(CreditTransferType.NONE)

    const actionStatus: ISelectOption[] = [
        {
            display: "ALL",
            value: ActionStatus[ActionStatus.NONE]
        },
        {
            display: "SUCCESS",
            value: ActionStatus[ActionStatus.SUCCESS]
        },
        {
            display: "FAILED",
            value: ActionStatus[ActionStatus.FAILED]
        }
    ]
    const [status, setStatus] = useState(ActionStatus.NONE)


    const emptyTable: IDataTable = {
        title: ["No.", "Credit Transaction ID", "Customer ID", "Trading Date", "Transaction Date Time", "Transaction Type", "Wallet Type", "Opening Balance", "Transfer Amount", "Ending Balance", "Remarks", "Transaction Status", "Error Message"],
        columnWidth: ["50px", "200px", "120px", "120px", "200px", "150px", "120px", "150px", "150px", "150px", "150px", "130px", "150px"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        var table: IDataTable = emptyTable
        if (creditTransactionReportListData && creditTransactionReportListData.rml != undefined && hasSearched) {
            const dataList = creditTransactionReportListData.rml;

            const startIndex = currentPage * TABLE_MAX_ROW;
            var totalAmount = 0;
            table.data = dataList.map((x, index) => {
                totalAmount += x.amt
                return [
                    startIndex + index + 1,
                    x.cti,
                    x.csi,
                    FORMAT_DATETIME_TO_VIEW(new Date(x.trd), "yyyy-MM-dd"),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.tdt), "yyyy-MM-dd hh:mm:ssa"),
                    CreditTransferType[x.trt].replace('_', ' '),
                    x.wln,
                    CVT_TO_FLOAT_STRING(x.stb),
                    CVT_TO_FLOAT_STRING(x.amt),
                    CVT_TO_FLOAT_STRING(x.enb),
                    x.rmk,
                    ActionStatus[x.sts].replace('_', ' '),
                    x.err
                ]
            });
            table.concludeColumn = [{ columnIndex: 8, content: CVT_TO_FLOAT_STRING(totalAmount) }]
        } else
            table.data = [];

        setReportTable(table);

    }, [creditTransactionReportListData]);

    useEffect(() => {
        console.log(reportTable)
    }, [reportTable])


    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetCreditTransactionReportRequest(customerID, status, transactionType, new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1],
                new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), page * TABLE_MAX_PAGE, TABLE_MAX_PAGE));
        }
    }

    const valid = (): boolean => {
        return (fromTransactionDate !== "" && fromTransactionDateValid) && (toTransactionDate !== "" && toTransactionDateValid) &&
            (fromTradingDate !== "" && fromTradingDateValid) && (toTradingDate !== "" && toTradingDateValid) &&
            customerIDValid;
    }

    const pagination: ITablePagination = {
        id: "creditTransactionReportListPagination",
        maxPage: 8,
        onChangePage: take,
        totalCount: hasSearched ? creditTransactionReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);
            dispatch(GetCreditTransactionReportRequest(customerID, status, transactionType, new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1],
                new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), 0 * TABLE_MAX_PAGE, TABLE_MAX_PAGE));
        } else {
            TRIGGER_BLUR("fromTradingDate");
            TRIGGER_BLUR("toTradingDate");
            TRIGGER_BLUR("fromTransactionDate");
            TRIGGER_BLUR("toTransactionDate");
            TRIGGER_BLUR("customerID");
            TRIGGER_BLUR("transactionType");
            TRIGGER_BLUR("transactionStatus");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            dispatch(GenerateCreditTransactionPDFRequest(customerID, status, transactionType, new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1],
                new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), 0 * TABLE_MAX_PAGE, TABLE_MAX_PAGE))
        }
    }

    const generateCSV = () => {
        if (valid()) {
            dispatch(GenerateCreditTransactionCSVRequest(customerID, status, transactionType, new Date(fromTransactionDate.split("T")[0]), fromTransactionDate.split("T")[1], new Date(toTransactionDate.split("T")[0]), toTransactionDate.split("T")[1],
                new Date(fromTradingDate.split("T")[0]), new Date(toTradingDate.split("T")[0]), 0 * TABLE_MAX_PAGE, TABLE_MAX_PAGE))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker isRequired label="Trading Date" fromDateinputState={setFromTradingDate} fromDatevalidState={setFromTradingDateValid}
                            toDateinputState={setToTradingDate} toDatevalidState={setToTradingDateValid} fromDatePickerID="fromTradingDate" toDatePickerID="toTradingDate" hideTime={true} />
                    </Row>
                    <Row>
                        <DateTimeRangePicker isRequired label="Transaction Date" fromDateinputState={setFromTransactionDate} fromDatevalidState={setFromTransactionDateValid}
                            toDateinputState={setToTransactionDate} toDatevalidState={setToTransactionDateValid} fromDatePickerID="fromTransactionDate" toDatePickerID="toTransactionDate" />
                    </Row>
                    <Row>
                        <Col>
                            <InputBox
                                type={TextboxType.TEXT}
                                id="customerID"
                                name="customerID"
                                label="Customer ID"
                                inputState={setCustomerID}
                                value={customerID}
                                validState={setCustomerIDValid}
                            />
                        </Col>
                        <Col>
                            <SelectBox id="transactionType" name="transactionType" label="Transaction Type"
                                options={creditTransactionType} value={transactionType}
                                inputState={(e) => { setTransactionType(e) }}
                                isRequired={true}
                            />
                        </Col>
                        <Col>
                            <SelectBox id="transactionStatus" name="transactionStatus" label="Status"
                                options={actionStatus} value={status}
                                inputState={(e) => { setStatus(e) }}
                                isRequired={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="CreditTransactionReporting"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}


export default CreditTransactionReporting;