import { CssStyleClass } from '@fortawesome/fontawesome-svg-core';
import React, { HTMLAttributes, StyleHTMLAttributes, useEffect, useState } from 'react';
import { Spinner, Table } from 'reactstrap';
import { ConcludeColumn, IDataTable, TableStyle } from './IDataTable';

function GetStyle(style: TableStyle): boolean[] {
    switch (style) {
        case TableStyle.ALL_BORDER:
            return [false, true];
        case TableStyle.NO_BORDER:
            return [true, false];
        case TableStyle.ROW_BORDER:
            return [false, false];
        default:
            return [false, false];
    }
}

const TableHeader = (header: string[], width?: string[]) => {
    return (
        <thead>
            <tr className="align-top table-light">
                {
                    width != undefined && width.length > 0 ?
                        header.map((item, index) => (<th key={index} style={{ minWidth: width[index] }}>{item}</th>))
                        :
                        header.map((item, index) => (<th key={index}>{item}</th>))
                }
            </tr>
        </thead>
    )
}

const columnCell = (isSummary: boolean) => {
    if (isSummary)
        return ({ borderInline: 'hidden', borderTop: '3px double black', borderBottom: '3px double black' })
    else
        return ({ borderInline: 'hidden', borderBottom: 'hidden' })
}

const DataTable = (prop: IDataTable) => {
    const [concludeColumns, setConcludeColumn] = useState<ConcludeColumn[]>([]);

    useEffect(() => {
        const tempConcludeColumns: ConcludeColumn[] = [];
        if (prop.concludeColumn && prop.concludeColumn.length != 0) {
            var x: number = 0;
            const minIndex = prop.concludeColumn[0].columnIndex;
            while (x < minIndex) {
                tempConcludeColumns.push({ columnIndex: -1, content: "" } as ConcludeColumn);
                x++;
            }

            var y: number = tempConcludeColumns.length;
            var z: number = 0;
            while (tempConcludeColumns.length < prop.title.length) {
                if (prop.concludeColumn.filter(x => x.columnIndex == y).length == 0) {
                    tempConcludeColumns.push({ columnIndex: -1, content: "" } as ConcludeColumn);
                } else {
                    tempConcludeColumns.push({ columnIndex: prop.concludeColumn[z].columnIndex, content: prop.concludeColumn[z].content } as ConcludeColumn);
                    z++;
                }

                y++
            }

            setConcludeColumn(tempConcludeColumns);
        };
    }, [prop.concludeColumn])

    if (prop.isloading) {
        return (
            <Table borderless={GetStyle(prop.tableStyle)[0]} striped={prop.isStriped} hover={prop.isHover} bordered={GetStyle(prop.tableStyle)[1]} responsive={prop.responsive}>
                <tbody>
                    <tr>
                        <td colSpan={prop.title.length} align={'center'}>
                            <Spinner>Loading...</Spinner>
                        </td>
                    </tr>
                </tbody>
            </Table>
        )

    } else if (prop.rowBoldIndex == undefined && prop.data && prop.data.length > 0) { // if row bold index doesnt exist represents the table should show group per page
        return (
            <Table borderless={GetStyle(prop.tableStyle)[0]} striped={prop.isStriped} hover={prop.isHover} bordered={GetStyle(prop.tableStyle)[1]} responsive={prop.responsive}>
                {TableHeader(prop.title, prop.columnWidth)}
                <tbody>
                    {prop.subHeader ? prop.subHeader.map((item: any, index) => (
                        <tr key={index} style={{ borderBlock: 'none' }}>
                            <td key={index} colSpan={prop.title.length}><b>{item}</b></td>
                        </tr>
                    )) : undefined}

                    {prop.data.map((item: any[], index) => (
                        <tr key={index}>
                            {prop.data ? item.map((column, colIndex) => (
                                <td key={colIndex}>{column}</td>
                            )
                            ) : undefined}
                        </tr>

                    ))}

                    {concludeColumns.length > 0 ?
                        <tr key={0} style={{ borderBottom: 'none' }}>
                            {concludeColumns.map((conclude, colIndex) => (
                                <td key={colIndex} style={prop.concludeColumn && conclude.columnIndex != -1 ? columnCell(true) : columnCell(false)}>
                                    <b style={{ fontSize: '18px' }}>{conclude.content}</b>
                                </td>
                            ))}
                        </tr>
                        : undefined}

                </tbody>
            </Table>

        );
    } else if (prop.rowBoldIndex !== undefined && prop.data && prop.data.length > 0) { // if row bold index exists represents the table should show few group per page
        return (
            <Table borderless={GetStyle(prop.tableStyle)[0]} striped={prop.isStriped} hover={prop.isHover} bordered={GetStyle(prop.tableStyle)[1]} responsive={prop.responsive}>
                {TableHeader(prop.title, prop.columnWidth)}
                <tbody>
                    {prop.data.map((item: any, index) => (
                        prop.rowBoldIndex && prop.rowBoldIndex.includes(index) ?
                            <tr key={index} style={{ borderBlock: 'none' }}>
                                <td key={index} colSpan={prop.title.length}><b>{item}</b></td>
                            </tr>
                            :
                            <tr key={index}>
                                {
                                    item.map((column: any, colIndex: number) => (
                                        <td key={colIndex}>{column}</td>
                                    ))
                                }
                            </tr>
                    ))
                    }
                    <tr key={0} style={{ borderBottom: 'none' }}>
                        {concludeColumns.length > 0 ? concludeColumns.map((conclude, colIndex) => (
                            <td key={colIndex} style={prop.concludeColumn && conclude.columnIndex != -1 ? columnCell(true) : columnCell(false)}>
                                <b style={{ fontSize: '18px' }}>{conclude.content}</b>
                            </td>
                        )
                        ) : undefined}
                    </tr>
                </tbody>
            </Table>
        );
    }
    else
        return (
            <Table borderless={GetStyle(prop.tableStyle)[0]} striped={prop.isStriped} hover={prop.isHover} bordered={GetStyle(prop.tableStyle)[1]} responsive={prop.responsive}>
                {TableHeader(prop.title, prop.columnWidth)}
                <tbody>
                    <tr>
                        <td colSpan={prop.title.length} align={'center'}>
                            No Record Found
                        </td>
                    </tr>
                </tbody>
            </Table>
        )

}

export default DataTable;