import { SystemConfiguration } from "../../dto/models/ConfigurationModels";

export interface ISystemState {
    loading: boolean;
    err: string;
    suc: string;
    data: SystemConfiguration;
}

export const InitialSystemState: ISystemState = {
    loading: false,
    err: "",
    suc: "",
    data: {} as SystemConfiguration,
};
