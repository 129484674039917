import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ITextField } from './ITextField';


const TextField = (props: ITextField) => {
    const [invalidMsg, setInvalidMsg] = useState("");
    const [inputValue, setInputValue] = useState("");

    const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
       var value: string = e.target.value ?? "";
        var error: boolean = false;
        if (props.validState) {
            props.validState(true);

            if (props.isRequired) {
                if (value == "") {
                    props.validState(false);
                    setInvalidMsg("This field is required.");
                    error = true;
                }
            }
            if (props.maxLength && value.length > props.maxLength) {
                props.validState(false);
                setInvalidMsg("This field must be within " + props.maxLength + " characters.");
                error = true;
            }
        }
        if (!error) {
            props.inputState(value);
            setInvalidMsg("");
        }
    }

    const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
        onChangeEvent(e);
    }


    useEffect(() => {
        setInputValue(props.value)
    }, [props.value])

    return (
        <FormGroup>
            {props.label ? <Label for={props.id}>{props.label}</Label> : ""}
            <Input
                type="textarea"
                id={props.id}
                name={props.name}
                className={props.className}
                disabled={props.isDisabled}
                required={props.isRequired}
                invalid={invalidMsg != ""}
                onChange={onChangeEvent}
                onBlur={onBlurEvent}
                defaultValue={props.value}

                rows={props.row}
                cols={props.col}
            />
            <FormFeedback>{invalidMsg}</FormFeedback>
        </FormGroup>
    );
}

export default TextField;