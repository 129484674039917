import { JackpotAdjustmentReportCSVModel, JackpotAdjustmentReportListModel, JackpotAdjustmentReportPDFModel } from "../../../dto/models/Reporting/JackpotAdjustmentReportModel";

export interface IPoolAdjustmentReportState {
    loading: boolean;
    err: string;
    suc: string;
    adjustmentReportListData: JackpotAdjustmentReportListModel;
    pdfData: JackpotAdjustmentReportPDFModel;
    csvData: JackpotAdjustmentReportCSVModel;
}

export const InitPoolAdjustmentReportState: IPoolAdjustmentReportState = {
    loading: false,
    err: "",
    suc: "",
    adjustmentReportListData: {} as JackpotAdjustmentReportListModel,
    pdfData: {} as JackpotAdjustmentReportPDFModel,
    csvData: {} as JackpotAdjustmentReportCSVModel,
}
