import { faAddressBook, faBriefcase, faLocationDot, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import AccountingMaintenance from "../containers/Customer/AccountingMaintenance";
import CmsMaintenance from "../containers/Customer/CmsMaintenance";
import GamingAreaMaintenance from "../containers/Customer/GamingAreaMaintenance";
import { BackOfficeAccess } from "../dto/enums/AccessMatrix/BackOfficeAccess";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const Accounting_Service_Maintenance_URL = "/AccountingServiceMaintenance";
const Cms_Maintenance_URL = "/CMSMaintenance";
const GamingArea_Maintenance_URL = "/GamingAreaMaintenance";

export const AccountingRoutesData: IRouteData[] = [
    {
        to: Accounting_Service_Maintenance_URL,
        element: <AccountingMaintenance />
    },
    {
        to: Cms_Maintenance_URL,
        element: <CmsMaintenance />
    },
    {
        to: GamingArea_Maintenance_URL,
        element: <GamingAreaMaintenance />
    }
]

export const AccountingNavigationData: INavigationData[] = [
    {
        requiredAccess: [BackOfficeAccess.ACCOUNTING_SERVICE_MAINTENANCE],
        icon: faAddressBook,
        title: "Accounting Services",
        path: "Customers / Accounting Services Maintenance",
        searchKeyword: "Accounting Services Maintenance",
        to: Accounting_Service_Maintenance_URL
    },
    {
        requiredAccess: [BackOfficeAccess.CMS_MAINTENANCE],
        icon: faBriefcase,
        title: "CMS",
        path: "Customers / CMS Maintenance",
        searchKeyword: "CMS Maintenance",
        to: Cms_Maintenance_URL
    },
    {
        requiredAccess: [BackOfficeAccess.GAMING_AREA_MAINTENANCE],
        icon: faLocationDot,
        title: "Gaming Area",
        path: "Customers / Gaming Area",
        searchKeyword: "Gaming Area Maintenance",
        to: GamingArea_Maintenance_URL
    }
]

export function AccountingSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(AccountingNavigationData, "Customers", faUsersGear, "#ab9352", open, access, currentPath);
}