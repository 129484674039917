import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Container, Form, FormGroup, Row, Table } from 'reactstrap';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { AlertSize, AlertType } from '../../components/AlertMessage/IAlert';
import { TextboxType } from '../../components/InputBox/IInputBox';
import InputBox from '../../components/InputBox/InputBox';
import { SearchPlayer } from '../../states/PlayerLock/PlayerLockActions';
import { IStore } from '../../states/store/IStore';
import { PlayerStatus } from '../../dto/enums/PlayerStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import PlayerLockModal from '../../components/_modals/Cashier/PlayerLockModal';
import { CashierAction } from '../../dto/enums/CashierAction';

const PlayerLockMaintenance = () => {
    const dispatch = useDispatch();
    const playerLockState = useSelector((state: IStore) => state.playerLockState);
    const { playerLockStatus, err, suc, loading } = playerLockState
    const [openModal, setOpenModal] = useState(false);
    const [searchPlayerID, setSearchPlayerID] = useState("")
    const [isSearch, setIsSearch] = useState(false);
    const [playerName, setPlayerName] = useState("-")
    const [playerID, setPlayerID] = useState("")
    const [status, setStatus] = useState("-")
    const [isAlertOpen, setAlertOpen] = useState(true);
    const [alertMessage, setAlertMessage] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [action, setAction] = useState(CashierAction.LOCK);
    const [cmsLocked, setCmsLocked] = useState(false);

    const search = (input: string): void => {
        if (input != "") {
            dispatch(SearchPlayer(input))
            setIsSearch(true);
        }
    }

    const clear = (): void => {
        setSearchPlayerID("")
        setPlayerName("-")
        setStatus("-")
        setCmsLocked(false)
    }

    const lock = () => {
        setOpenModal(true)
        setAction(CashierAction.LOCK)
    }

    const unlock = () => {
        setOpenModal(true)
        setAction(CashierAction.UNLOCK)
    }

    useEffect(() => {
        if (searchPlayerID != "") {
            if (playerLockStatus && Object.keys(playerLockStatus).length > 0) {
                setPlayerName(playerLockStatus.fnm)
                setStatus(PlayerStatus[playerLockStatus.stt].replace('_', ' '))
                setPlayerID(playerLockStatus.usr)

                if (playerLockStatus.stt == PlayerStatus.NONE || playerLockStatus.stt == PlayerStatus.BARRED || playerLockStatus.stt == PlayerStatus.SUSPENDED || playerLockStatus.stt == PlayerStatus.ECASH_SUSPENDED)
                    setCmsLocked(true)
                if (isSearch)
                    setIsSearch(false)
            }
            else if (playerLockStatus == null)
                setIsSearch(false)
        }
    }, [playerLockStatus])

    return (
        <Container>
            <Form>
                <Row className="mb-3">
                    <Col sm={2}><h3>Member ID</h3></Col>
                    <Col>
                        <InputBox
                            type={TextboxType.TEXT}
                            id="container_search"
                            name="container_search"
                            placeholder="Search"
                            inputState={setSearchPlayerID}
                            value={searchPlayerID}
                        />
                    </Col>
                    <Col>
                        <Button color="secondary" className={"me-1"} outline onClick={(e) => search(searchPlayerID)}>Search</Button>
                        <Button color="danger" outline disabled={isSearch} onClick={clear}>Clear</Button>
                    </Col>
                </Row>
                <Row>
                    <AlertMessage
                        content="No member selected"
                        alertType={AlertType.WARNING}
                        alertSize={AlertSize.LONG}
                        isOpen={isAlertOpen && !isValid && searchPlayerID == ""}
                        openState={setAlertOpen}
                        disappearTime={5}
                    />
                </Row>
                <Card className="p-3">
                    <Table borderless>
                        <tbody>
                            <tr id="memberName" className=" d-flex">
                                <td className="member-name-label col-2">
                                    <h4>Member Name:</h4>
                                </td>
                                <td className="member-name col-10">
                                    <h5>{playerName}</h5>
                                </td>
                            </tr>
                            <tr className=" d-flex">
                                <td className="status-label col-2">
                                    <h4>Status:</h4>
                                </td>
                                <td className={status != "-" ? (status == PlayerStatus[PlayerStatus.ACTIVE] ? "status-active col-10" : "status-lock col-10") : "col-10"}>
                                    <h5>{status}</h5>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Row className="player-lock-button-row">
                        <PlayerLockModal setOpenState={setOpenModal} isOpen={openModal} player={playerID} action={action} />
                        <Col>
                            <Button className="w-100" color="success" disabled={playerName == "-" || status == PlayerStatus[PlayerStatus.ACTIVE] || cmsLocked} onClick={unlock}><FontAwesomeIcon icon={faLockOpen} /> Unlock</Button>
                        </Col>
                        <Col>
                            <Button className="w-100" color="danger" disabled={playerName == "-" || status != PlayerStatus[PlayerStatus.ACTIVE] || cmsLocked} onClick={lock}><FontAwesomeIcon icon={faLock} /> Lock</Button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Container>
    );
}
export default PlayerLockMaintenance;
