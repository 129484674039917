import { faCalendar, faGears, faSliders } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import SystemMaintenance from "../containers/System/SystemMaintenance";
import TradingDateMaintenance from "../containers/System/TradingDateMaintenance";
import { BackOfficeAccess } from "../dto/enums/AccessMatrix/BackOfficeAccess";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const SYSTEM_MAINTENANANCE_URL = "/SystemMaintenance";
const TRADINGDATE_MAINTENANCE_URL = "/TradingDateMaintenance";

export const SystemRoutesData: IRouteData[] = [
    {
        to: SYSTEM_MAINTENANANCE_URL,
        element: <SystemMaintenance />
    },
    {
        to: TRADINGDATE_MAINTENANCE_URL,
        element: <TradingDateMaintenance />
    }
]

export const SystemNavigationData: INavigationData[] = [
    {
        requiredAccess: [BackOfficeAccess.SYSTEM_MAINTENANCE],
        icon: faSliders,
        title: "System",
        searchKeyword: "System Settings",
        path: "Settings / System",
        to: SYSTEM_MAINTENANANCE_URL
    },
    {
        requiredAccess: [BackOfficeAccess.TRADING_DATE_MAINTENANCE],
        icon: faCalendar,
        title: "Trading Date",
        path: "Settings / Trading Date",
        searchKeyword: "Trading Date Settings",
        to: TRADINGDATE_MAINTENANCE_URL
    }
]

export function SystemSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(SystemNavigationData, "Settings", faGears, "#b48d8d", open, access, currentPath);
}