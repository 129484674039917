import React, { ChangeEvent, FocusEventHandler, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, NavigateProps, useNavigate } from 'react-router-dom';
import { Input, InputGroup, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { CheckAccess, INavigationData } from '../../navigation/navData';
import { IStore } from '../../states/store/IStore';

export interface ISearchInputBox {
    id: string
    searchItems: INavigationData[]
}

const TopbarSearchInputBox = (prop: ISearchInputBox) => {
    const [searchValue, setSearchValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [suggestions, setSuggestions] = useState<INavigationData[]>([]);
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;
    const backOfficeAccess = userProfileData.acc ? userProfileData.acc.bor : 0;
    const reportingAccess = userProfileData.acc ? userProfileData.acc.rpa : 0;
    const cashierAccess = userProfileData.acc ? userProfileData.acc.caa : 0;
    const navigate = useNavigate();

    const handleOutFocus = () => {
        setTimeout(() => {
            setDropdownOpen(false);
        }, 150)
    };

    const handleFocus = () => {
        setDropdownOpen(true);
        updateSuggestions(searchValue);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value);
        updateSuggestions(value);
    };

    const updateSuggestions = (value: string) => {
        const searchItems = prop.searchItems.filter(suggestion =>
            (suggestion.searchKeyword.toLowerCase().includes(value.toLowerCase()) || suggestion.title.toLowerCase().includes(value.toLowerCase()))
            && (CheckAccess(suggestion, backOfficeAccess) || CheckAccess(suggestion, reportingAccess) || CheckAccess(suggestion, cashierAccess))
        );
        setDropdownOpen(value != "" && searchItems.length != 0);
        setSuggestions(searchItems);
    };

    const handleSuggestionSelect = (to: string) => {
        navigate(to);
    };

    return (
        <div>
            <InputGroup>
                <Input
                    style={{ width: '250px' }}
                    id={prop.id}
                    type="text"
                    value={searchValue}
                    onChange={handleInputChange}
                    onBlurCapture={handleOutFocus}
                    onFocus={handleFocus}
                    placeholder="Search"
                    autoComplete="off"
                />
            </InputGroup>
            <div style={{ overflow: 'auto', maxHeight: '200px', width: '350px'}}>
                <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} style={{ zIndex: 100, position: 'fixed' }}>
                    <DropdownMenu style={{ maxHeight: '200px', overflow: 'auto', width:'350px' }}>
                        {
                            suggestions.map((suggestion, index) => (
                                <DropdownItem key={index} onClick={e => handleSuggestionSelect(suggestion.to)}>
                                    <span>{suggestion.searchKeyword}</span>
                                </DropdownItem>
                            ))
                        }
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default TopbarSearchInputBox;