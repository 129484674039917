import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from 'reactstrap';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { AlertSize, AlertType } from '../../components/AlertMessage/IAlert';
import { TextboxType } from '../../components/InputBox/IInputBox';
import InputBox from '../../components/InputBox/InputBox';
import { ISelectOption, LoadSelectOptionFromEnum } from '../../components/InputBox/ISelectBox';
import SelectBox from '../../components/InputBox/SelectBox';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { Currency } from '../../dto/enums/Currency';
import { SessionMode } from '../../dto/enums/SessionMode';
import { CVT_TO_FLOAT_STRING } from '../../libraries/FloatHelper';
import { HASFLAG, TRIGGER_BLUR } from '../../libraries/Functions';
import { IStore } from "../../states/store/IStore";
import { EditSystem, GetSystem } from "../../states/System/SystemActions";
import * as Global from "../../libraries/Global"


const SystemMaintenance = () => {
    const dispatch = useDispatch();

    const [selectedCurrency, setCurrency] = useState(Number);
    const [selectedPlayerPortalMode, setPlayerPortalMode] = useState(Number);

    const systemState = useSelector((state: IStore) => state.systemState);
    const { data, err, suc } = systemState;

    const [creditRate, setCreditRate] = useState<number | undefined>(undefined);
    const [creditRateValid, setCreditRateValid] = useState(false);
    const [serviceSyncInterval, setSSI] = useState<number | undefined>(undefined);
    const [serviceSyncIntervalValid, setSSIValid] = useState(false);
    const [lobbySyncInterval, setLSI] = useState<number | undefined>(undefined);
    const [lobbySyncIntervalValid, setLSIValid] = useState(false);
    const [jackpotSyncInterval, setJSI] = useState<number | undefined>(undefined);
    const [jackpotSyncIntervalValid, setJSIValid] = useState(true);

    const [backOfficeSessionTimeout, setBOST] = useState<number | undefined>(undefined);
    const [backOfficeSessionTimeoutValid, setBOSTValid] = useState(false);
    const [passwordExpiryMonths, setPEM] = useState<number | undefined>(undefined);
    const [passwordExpiryMonthsValid, setPEMV] = useState(false);
    const [passwordNotifyBefore, setPNB] = useState<number | undefined>(undefined);
    const [passwordNotifyBeforeValid, setPNBV] = useState(false);

    const [playerPortalSessionTimeout, setPPST] = useState<number | undefined>(undefined);
    const [playerPortalSessionTimeoutValid, setPPSTValid] = useState(false);
    const [playerPortalIdleTimeout, setPPIT] = useState<number | undefined>(undefined);
    const [playerPortalIdleTimeoutValid, setPPITValid] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const sessionMode: ISelectOption[] = LoadSelectOptionFromEnum(SessionMode);
    const currencyOption: ISelectOption[] = LoadSelectOptionFromEnum(Currency);

    const [isAlertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        const access: BackOfficeAccess = Global.GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.SYSTEM_MAINTENANCE))
        }

        dispatch(GetSystem());
    }, []);

    const submit = () => {
        if (Valid()) {
            const curr: Currency = Currency[selectedCurrency] as any
            const playerPortalMode: SessionMode = SessionMode[selectedPlayerPortalMode] as any

            dispatch(EditSystem(curr, creditRate as number, serviceSyncInterval as number, lobbySyncInterval as number, jackpotSyncInterval as number, backOfficeSessionTimeout as number, playerPortalMode, playerPortalSessionTimeout as number, playerPortalIdleTimeout as number, passwordExpiryMonths as number, passwordNotifyBefore as number));
        }
        else {
            TRIGGER_BLUR("creditRate")
            TRIGGER_BLUR("serviceSyncInterval");
            TRIGGER_BLUR("lobbySyncInterval");
            TRIGGER_BLUR("jackpotSyncInterval");
            TRIGGER_BLUR("gameSessionTimeOut");
            TRIGGER_BLUR("gameIdleTimeOut");
            TRIGGER_BLUR("backOfficeSessionTimeOut");
            TRIGGER_BLUR("playerPortalSessionTimeOut");
            TRIGGER_BLUR("playerPortalIdleTimeOut");
            TRIGGER_BLUR("passwordExpiry");
            TRIGGER_BLUR("passwordNotifyBefore");
        }
    }

    const Valid = (): boolean => {
        return creditRateValid && serviceSyncIntervalValid && lobbySyncIntervalValid && jackpotSyncIntervalValid && backOfficeSessionTimeoutValid && playerPortalSessionTimeoutValid && playerPortalIdleTimeoutValid && passwordExpiryMonthsValid && passwordNotifyBeforeValid;
    }

    useEffect(() => {
        if (data && !err && Object.keys(data).length > 0) {
            setCurrency(data.cur);
            setCreditRate(data.crt);
            setSSI(data.ssi);
            setLSI(data.lsi);
            setJSI(data.jsi);
            setBOST(data.bst);
            setPlayerPortalMode(data.psm);
            setPPST(data.pst);
            setPPIT(data.pit);

            setPEM(data.pem);
            setPNB(data.pen);

            setCreditRateValid(true);
            setSSIValid(true);
            setLSIValid(true);
            setBOSTValid(true);
            setPPSTValid(true);
            setPPITValid(true);
            setPEMV(true);
            setPNBV(true);
        }

        if (!isAlertOpen && alertMessage != "") {
            setAlertMessage("");
        }
        else {
            var isAlert = false
            if (err != undefined && err != "") {
                setAlertMessage(err);
                setAlertOpen(true);
                isAlert = true
            }
            else if (suc != undefined && suc != "") {
                setAlertMessage(suc);
                setAlertOpen(true);
                isAlert = true
            }

            if (isAlert) {
                var alert = document.getElementById('alertMessage')
                if (alert) {
                    var headerOffset = 65;
                    var elementPosition = alert.getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }
            }

        }
    }, [data])

    return (
        <Container>
            <Row id="alertMessage">
                <Col>
                    <AlertMessage
                        content={alertMessage}
                        alertType={AlertType.FAIL}
                        isOpen={isAlertOpen && err != undefined && err != ""}
                        openState={setAlertOpen}
                        disappearTime={5}
                        alertSize={AlertSize.LONG}
                    />
                    <AlertMessage
                        content={alertMessage}
                        alertType={AlertType.SUCCESS}
                        isOpen={isAlertOpen && suc != undefined && suc != ""}
                        openState={setAlertOpen}
                        disappearTime={5}
                        alertSize={AlertSize.LONG}
                    />
                </Col>
            </Row>
            <Form>
                <fieldset className="border p-2 mb-3">
                    <legend className="float-none w-auto">General</legend>
                    <Row>
                        <Col>
                            <SelectBox id="currency" name="currency" label="Currency"
                                options={currencyOption} value={selectedCurrency}
                                inputState={setCurrency}
                                isDisabled={!canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox
                                id="creditRate"
                                name="creditRate"
                                label={"1 Credit = " + Currency[selectedCurrency] + CVT_TO_FLOAT_STRING(0.01 * (creditRate as number))}
                                placeholder={"Credit Rate"}
                                type={TextboxType.NUMBER}
                                min={0.0001}
                                inputState={setCreditRate}
                                validState={setCreditRateValid}
                                isRequired={true}
                                value={creditRate}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputBox
                                id="serviceSyncInterval"
                                name="serviceSyncInterval"
                                label="Service Sync Interval (s)"
                                placeholder={"Service Sync Interval (s)"}
                                type={TextboxType.NUMBER}
                                min={1}
                                inputState={setSSI}
                                validState={setSSIValid}
                                isRequired={true}
                                value={serviceSyncInterval}
                                isDisabled={!canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox
                                id="lobbySyncInterval"
                                name="lobbySyncInterval"
                                label="Lobby Sync Interval (s)"
                                placeholder={"Lobby Sync Interval (s)"}
                                type={TextboxType.NUMBER}
                                min={1}
                                inputState={setLSI}
                                validState={setLSIValid}
                                isRequired={true}
                                value={lobbySyncInterval}
                                isDisabled={!canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox
                                id="jackpotSyncInterval"
                                name="jackpotSyncInterval"
                                label="Jackpot Sync Interval (s)"
                                placeholder={"Jackpot Sync Interval (s)"}
                                type={TextboxType.NUMBER}
                                min={1}
                                inputState={setJSI}
                                validState={setJSIValid}
                                isRequired={true}
                                value={jackpotSyncInterval}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className="border p-2 mb-3">
                    <legend className="float-none w-auto">BackOffice</legend>
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="backOfficeSessionTimeOut"
                                name="backOfficeSessionTimeOut"
                                label="Back Office Session Time Out (s)"
                                placeholder={"Back Office Session Time Out (s)"}
                                type={TextboxType.NUMBER}
                                min={60}
                                inputState={setBOST}
                                validState={setBOSTValid}
                                isRequired={true}
                                value={backOfficeSessionTimeout}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="passwordExpiry"
                                name="passwordExpiry"
                                label="Password Expiry Duration (Months)"
                                placeholder={"Password Expiry Duration (Months)"}
                                type={TextboxType.NUMBER}
                                inputState={setPEM}
                                validState={setPEMV}
                                isRequired={true}
                                value={passwordExpiryMonths}
                                isDisabled={!canEdit}
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                id="passwordNotifyBefore"
                                name="passwordNotifyBefore"
                                label="Days To Notify Before Expire"
                                placeholder={"Days To Notify Before Expire"}
                                type={TextboxType.NUMBER}
                                inputState={setPNB}
                                validState={setPNBV}
                                isRequired={true}
                                value={passwordNotifyBefore}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className="border p-2 mb-3">
                    <legend className="float-none w-auto">Player Portal</legend>
                    <Row>
                        <Col>
                            <InputBox
                                id="playerPortalSessionTimeOut"
                                name="playerPortalSessionTimeOut"
                                label="Player Portal Session Time Out (s)"
                                placeholder={"Player Portal Session Time Out (s)"}
                                type={TextboxType.NUMBER}
                                min={60}
                                inputState={setPPST}
                                validState={setPPSTValid}
                                isRequired={true}
                                value={playerPortalSessionTimeout}
                                isDisabled={!canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox
                                id="playerPortalIdleTimeOut"
                                name="playerPortalIdleTimeOut"
                                label="Player Portal Idle Time Out (s)"
                                placeholder={"Player Portal Idle Time Out (s)"}
                                type={TextboxType.NUMBER}
                                min={60}
                                inputState={setPPIT}
                                validState={setPPITValid}
                                isRequired={true}
                                value={playerPortalIdleTimeout}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectBox id="playerPortalSessionMode" name="playerPortalSessionMode" label="Player Portal Session Mode"
                                options={sessionMode} value={selectedPlayerPortalMode}
                                tipsID="playerPortalSessionTimeOutTips"
                                tips={<span>Single session single game: Players can only have one active game session at a time.<br /><br />
                                    Single session multiple game: Players can have multiple games opened at once with a single login.<br /><br />
                                    Multi session multiple game: Players can have multiple games opened simultaneously on different devices with the same account.</span>}
                                inputState={setPlayerPortalMode}
                                isDisabled={!canEdit}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <Row>
                    <Col>
                        {canEdit ? < Button color="info" onClick={submit} outline><FontAwesomeIcon icon={faFloppyDisk} />  Save</Button> : <></>}
                    </Col>
                </Row>


            </Form>
        </Container>
    );
};

export default SystemMaintenance;