export enum BackOfficeAccess {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///     User maintenance
    /// </summary>
    USER_MAINTENANCE = 0x000001,
    /// <summary>
    ///     Access matrix maintenance
    /// </summary>
    ACCESS_MATRIX_MAINTENANCE = 0x000002,
    /// <summary>
    ///     Trading date maintenance
    /// </summary>
    TRADING_DATE_MAINTENANCE = 0x000004,
    /// <summary>
    ///     Denomination maintenance
    /// </summary>
    DENOMINATION_MAINTENANCE = 0x000008,
    /// <summary>
    ///     Game service maintenance
    /// </summary>
    GAME_SERVICE_MAINTENANCE = 0x000010,
    /// <summary>
    ///     Accounting service maintenance
    /// </summary>
    ACCOUNTING_SERVICE_MAINTENANCE = 0x000020,
    /// <summary>
    ///     Game maintenance
    /// </summary>
    GAME_MAINTENANCE = 0x000040,
    /// <summary>
    ///     CMS maintenance
    /// </summary>
    CMS_MAINTENANCE = 0x000080,
    /// <summary>
    ///     System maintenance
    /// </summary>
    SYSTEM_MAINTENANCE = 0x000100,
    /// <summary>
    ///     Brutal prevention maintenance
    /// </summary>
    BRUTAL_PREVENTION_MAINTENANCE = 0x000200,
    /// <summary>
    ///     Blocked device maintenance
    /// </summary>
    BLOCKED_DEVICE_MAINTENANCE = 0x000400,
    /// <summary>
    ///     Scheduler maintenance
    /// </summary>
    SCHEDULER_MAINTENANCE = 0x000800,
    /// <summary>
    ///     Jackpot maintenance
    /// </summary>
    JACKPOT_MAINTENANCE = 0x001000,
    /// <summary>
    ///     Jackpot adjustment
    /// </summary>
    JACKPOT_ADJUSTMENT = 0x002000,
    /// <summary>
    ///     Jackpot approval
    /// </summary>
    JACKPOT_APPROVAL = 0x004000,
    /// <summary>
    ///     Service Authentication
    /// </summary>
    SERVICE_AUTHENTICATION = 0x008000,
    /// <summary>
    ///     Gaming area maintenance
    /// </summary>
    GAMING_AREA_MAINTENANCE = 0x010000,
    /// <summary>
    ///     Notification maintenance
    /// </summary>
    NOTIFICATION_MAINTENANCE = 0x020000,
    /// <summary>
    ///     Terminate game service
    /// </summary>
    TERMINATE_GAME_SERVICE = 0x040000,
    /// <summary>
    ///     Password Reset
    /// </summary>
    PASSWORD_RESET = 0x080000
}