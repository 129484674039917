import React, { useEffect, useState } from 'react';
import { Form, Row, Col, FormGroup, Label, Button, Container, Spinner } from 'reactstrap';
import { TextboxType } from '../../InputBox/IInputBox';
import { DefaultLanguage } from '../../../dto/enums/DefaultLanguage'
import { CmsType } from '../../../dto/enums/CmsType';
import InputBox from '../../InputBox/InputBox';
import ModalBox from '../../ModalBox/ModalBox';
import { ModalType } from '../../ModalBox/IModalBox';
import { GetCmsList } from '../../../states/Cms/CmsActions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../states/store/IStore';
import { ISelectOption, LoadSelectOptionFromEnum } from '../../InputBox/ISelectBox';
import SelectBox from '../../InputBox/SelectBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { HASFLAG, TRIGGER_BLUR } from '../../../libraries/Functions';
import { TableStatus } from '../../../dto/enums/TableStatus';
import { AddAccounting, EditAccounting, SelectAccounting } from '../../../states/Accounting/AccountingActions';
import { CmsConfigurationModel } from '../../../dto/models/ConfigurationModels';
import TextField from '../../InputBox/TextField';
import { GamingAreaModel } from '../../../dto/models/GamingAreaModels';
import { GET_PROFILE } from '../../../libraries/Global';
import { BackOfficeAccess } from '../../../dto/enums/AccessMatrix/BackOfficeAccess';

interface IAccountingForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const AccountingForm = (props: IAccountingForm) => {
    const [nameInput, setNameInput] = useState("");
    const [nameValid, setNameValid] = useState(props.isEdit);
    const [lighthouseInput, setLighthouseInput] = useState("");
    const [lighthouseValid, setLighthouseValid] = useState(props.isEdit);
    const [pylonInput, setPylonInput] = useState("");
    const [pylonValid, setPylonValid] = useState(props.isEdit);
    const [descriptionInput, setDescriptionInput] = useState("");

    const [selectedStatus, setStatus] = useState(TableStatus.ACTIVE);
    const [selectedCms, setCms] = useState(0);
    const [cmsOption, setCmsOption] = useState([] as ISelectOption[]);
    const [cmsValid, setCmsValid] = useState(props.isEdit);
    const statusOptions: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);

    const dispatch = useDispatch();
    const accStates = useSelector((state: IStore) => state.accountingServiceState);
    const cmsStates = useSelector((state: IStore) => state.cmsState);
    const { accData } = accStates;
    const { cmsListData, loading } = cmsStates;
    const accDataLoading = accStates.loading;
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loading && cmsListData && cmsListData.clt) {
            const cms: ISelectOption[] = cmsListData.clt.map((item) => {
                return {
                    display: item.dom,
                    value: item.cid.toString()
                }
            });

            setCmsOption(cms);
            if (cms.length != 0) {
                setCmsValid(true);
                setCms(parseInt(cms[0].value));
            }
        }
    }, [cmsListData]);

    useEffect(() => {
        dispatch(GetCmsList());
        if (props.isEdit && props.editID) {
            dispatch(SelectAccounting(props.editID));
        } else
            ClearFields();

    }, [props.isEdit]);

    useEffect(() => {
        setLoaded(!loading && !accDataLoading);   
    }, [loading, accDataLoading])

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (props.isEdit && accData.snm) {
            setNameInput(accData.snm);
            setLighthouseInput(accData.lil);
            setPylonInput(accData.pyl);
            setDescriptionInput(accData.des);
            setStatus(accData.stt);
            setCms(accData.cms.cid);
        }
    }, [accData]);

    const ClearFields = () => {
        setNameInput("");
        setLighthouseInput("");
        setPylonInput("");
        setDescriptionInput("");
        setStatus(TableStatus.ACTIVE);
    }


    const Valid = (): boolean => {
        return cmsValid && lighthouseValid && pylonValid && nameValid;
    }

    const save = () => {
        if (Valid() && lighthouseInput && pylonInput && nameInput) {
            var config: CmsConfigurationModel = {
                cid: selectedCms, typ: CmsType.FSG_DYNAMIQ, dom: "", prt: 0,
                dfl: DefaultLanguage.English, cer: [], cep: "", usm: "", usp: "", csid: "",
                orc: 0, erc: 0, ori: 0, nga: {} as GamingAreaModel, vga: {} as GamingAreaModel, upa: undefined, prc: "", pra: 0, ocu: ""
            }
            if (!props.isEdit) {
                dispatch(
                    AddAccounting(
                        nameInput,
                        lighthouseInput,
                        pylonInput,
                        descriptionInput,
                        selectedStatus as number,
                        config,
                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditAccounting(
                            props.editID,
                            nameInput,
                            lighthouseInput,
                            pylonInput,
                            descriptionInput,
                            selectedStatus as number,
                            config,
                        ));
                }
            }
        } else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("lighthouse");
            TRIGGER_BLUR("pylon");
            TRIGGER_BLUR("des");
            TRIGGER_BLUR("status");
            TRIGGER_BLUR("config");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            {isLoaded ?
                <Form>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="name">
                                    Name
                                </Label>
                                <InputBox
                                    id="name"
                                    name="name"
                                    type={TextboxType.TEXT}
                                    inputState={setNameInput}
                                    placeholder={"Name"}
                                    validState={setNameValid}
                                    isRequired={true}
                                    value={nameInput}
                                    HasCancelledButton={true && props.canEdit}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="status" name="status" label="Status"
                                    options={statusOptions} value={props.isEdit ? accData.stt : TableStatus.ACTIVE}
                                    isDisabled={!props.isEdit || !props.canEdit}
                                    inputState={setStatus}
                                    isRequired={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="lighthouse"
                                name="lighthouse"
                                label="External Gateway Listener"
                                type={TextboxType.URL}
                                inputState={setLighthouseInput}
                                validState={setLighthouseValid}
                                placeholder={"External Gateway Listener"}
                                isRequired={true}
                                value={lighthouseInput}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                id="pylon"
                                name="pylon"
                                label="Internal Gateway Listener"
                                type={TextboxType.URL}
                                inputState={setPylonInput}
                                validState={setPylonValid}
                                placeholder={"Internal Gateway Listener"}
                                isRequired={true}
                                value={pylonInput}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="config" name="config" label="Cms (Domain)"
                                    options={cmsOption} value={selectedCms}
                                    inputState={setCms} validState={setCmsValid}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <TextField id="des" name="des" label="Description"
                                    placeholder="Description" value={props.isEdit ? accData.des : ""}
                                    inputState={setDescriptionInput} maxLength={300} col={5} row={5}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                :
                <div className='text-center p-4'><Spinner key='1' /></div>
            }
        </Container>
    )
}

const AccountingModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const accState = useSelector((state: IStore) => state.accountingServiceState);
    const cmsState = useSelector((state: IStore) => state.cmsState);
    const { err, suc, loading } = accState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.ACCOUNTING_SERVICE_MAINTENANCE))
        }
    }, [])

    useEffect(() => {
        if (cmsState.err !== "") {
            setErrMsg(cmsState.err)
            return;
        }

        if (err !== "") {
            setErrMsg(err)
            return;
        }

        setErrMsg("");
    }, [cmsState.err, err])

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit Accounting Service" : "Add Accounting Service"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={suc}
                child={
                    <AccountingForm isEdit={props.isEdit} canEdit={canEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? < Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default AccountingModal;

