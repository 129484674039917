import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import CmsModal from '../../components/_modals/CMS/CmsModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { CmsType } from '../../dto/enums/CmsType';
import { DefaultLanguage } from '../../dto/enums/DefaultLanguage';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetCms, SearchCms } from '../../states/Cms/CmsActions';
import { IStore } from '../../states/store/IStore';

const CmsMaintenance = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const cmsStates = useSelector((state: IStore) => state.cmsState);
    const { loading, err, suc, cmsListData } = cmsStates;
    const [isSearch, setSearch] = useState(false);
    const prevIsSearch = useRef(false);

    const emptyTable: IDataTable = {
        title: ["No", "Type", "Default Language", "Domain", "Port", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [cmsTable, setCmsTable] = useState(emptyTable)

    useEffect(() => {
        if (!openModal) {
            dispatch(GetCms(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    useEffect(() => {
        prevIsSearch.current = isSearch;
    }, [isSearch]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchCms(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetCms(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddCms = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditCms = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (cmsListData && cmsListData.clt && cmsListData.clt.length > 0 && cmsListData.clt[0].typ) {

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = cmsListData.clt.map((item, index) => {
                return [
                    startIndex + index + 1,
                    CmsType[item.typ] ? CmsType[item.typ].toString().replace("_", " ") : "",
                    DefaultLanguage[item.dfl] ? DefaultLanguage[item.dfl].toString().replace("_", " ") : "",
                    item.dom,
                    item.prt,
                    <Button onClick={() => EditCms(item.cid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setCmsTable(table)
    }, [cmsListData])

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetCms(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchCms(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "cmsPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: cmsListData?.ttc ?? 0
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.CMS_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <CmsModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="CmsMaintenanceContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddCms : undefined}
                tableProps={cmsTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default CmsMaintenance;
