import React, { useState } from "react";
import { Alert, Carousel, CarouselControl, CarouselIndicators, CarouselItem, Spinner } from "reactstrap";
import ICarouSelContainer from "./ICarouselContainer";
import '../../styles/Carousel.css';
import AlertMessage from "../AlertMessage/AlertMessage";
import { AlertSize, AlertType } from "../AlertMessage/IAlert";

const CarouselContainer = (props: ICarouSelContainer) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };


    const contentItems = props.items.map((items, index) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
            >
                {items}
            </CarouselItem>
        );
    });


    if (props.loading) {
        return (<div className='text-center p-4'><Spinner key='1' /></div>);
    } else {
        return (
            props.err == "" ?
                <Carousel
                    dark={props.dark}
                    fade={props.fade}
                    slide={props.slide}
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    pause={'hover'}
                    style={{ paddingInline: '50px', paddingBottom: '50px' }}
                >
                    <CarouselIndicators
                        items={contentItems}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                    />

                    {contentItems}
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                    />
                </Carousel>
                :
                <AlertMessage
                    alertType={AlertType.FAIL}
                    isOpen={props.err != ""}
                    content={props.err}
                    alertSize={AlertSize.LONG}
                    disableCrossButon={true} />

        );
    }

}

export default CarouselContainer;