import React, { useEffect, useRef, useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { IDropdownSelectBox, IDropdownSelectOption } from "./IDropdownSelectBox";

import Select, { ActionMeta, Options } from 'react-select';
import makeAnimated from 'react-select/animated';

const DropdownSelectBox = (props: IDropdownSelectBox) => {
    const [invalidMsg, setInvalidMsg] = useState("");
    const [selectedValue, selectValue] = useState<string[]>([]);
    const [selectedOptions, selectOption] = useState<Options<IDropdownSelectOption>>([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const firstRender = useRef(true);

    useEffect(() => {
        firstRender.current = false;
    }, [])

    useEffect(() => {
        if (props.inputState != null)
            props.inputState(selectedValue);

        if (!firstRender)
            validateInput(selectedValue);
    }, [selectedValue])

    const validateInput = (values: string[]) => {
        if (props.validState) {
            props.validState(true);
            setInvalidMsg("");

            if (props.isRequired) {
                if (values.length == 0) {
                    props.validState(false);
                    setInvalidMsg("This field is required.");
                }
            }
        }
    }

    const onChange = (option: Options<IDropdownSelectOption>, actionMeta: ActionMeta<IDropdownSelectOption>) => {
        var selectedValues: string[] = [];
        option.map(value => {
            selectedValues = selectedValues.concat(value.value);
        })

        var options: Options<IDropdownSelectOption> = []
        if (props.allowSelectAll && option.filter(x => x.value === "-1").length > 0) {
            selectedValues = [];
            setSelectedAll(true);
            props.options.map(x => {
                options = options.concat(x)
                selectedValues = selectedValues.concat(x.value)
            });

            selectOption(options);
        } else if (option.length == props.options.length)
            setSelectedAll(true);
        else
            setSelectedAll(false);

        if (options.length == 0) {
            selectOption(option)
        }
        selectValue(selectedValues);
        validateInput(selectedValues);
    }

    const onBlur = () => {
        validateInput(selectedValue);
    }

    useEffect(() => {
        if (props.value != undefined && selectedOptions.length != props.value.length) {
            var selectedValues: string[] = [];
            props.value.map(value => {
                selectedValues = selectedValues.concat(value.value);
            })

            selectOption(props.value)
            selectValue(selectedValues);
        }

    }, [props.value])

    return (
        <FormGroup>
            <Row>
                <Col>
                    {props.label ? <Label for={props.id}>{props.label}</Label> : ""}
                </Col>
            </Row>
            <Select
                inputId={props.id}
                value={selectedOptions}
                closeMenuOnSelect={false}
                components={makeAnimated()}
                placeholder={props.placeholder}
                isMulti
                options={props.allowSelectAll && !selectedAll ? [{ label: "All", value: "-1" } as IDropdownSelectOption].concat(props.options) : props.options}
                onChange={onChange}
                onBlur={onBlur}
                isDisabled={props.isDisabled}
                className={props.className}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: invalidMsg != "" ? "1px solid #DC3545" : (state.isFocused ? "4px solid #C2DBFE" : "1px solid #DEE2E6"),
                        boxShadow: state.isFocused && invalidMsg == "" ? "0px 0px 6px #C2DBFE" : "none",
                        "&:hover": {
                        },
                    }),
                }}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#212529',
                    },
                })}
            />
            <p style={{ color: "#DC3545", fontSize: "14px" }}>{invalidMsg}</p>
        </FormGroup>
    );
}

export default DropdownSelectBox;