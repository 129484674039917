import { PoolAdjustmentTransferType } from "../../../dto/enums/Jackpot/JackpotPoolAdjustmentTransferType";
import { TierParameterType } from "../../../dto/enums/Jackpot/JackpotTierCondition";
import { RequestItemModel } from "../../../dto/models/GeneralModels";
import { PASPoolAdjustmentModel } from "../../../dto/models/JackpotModels";
import { GetRequest, PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../../store/IStore";
import { PoolAdjustmentActionTypes } from "./PoolAdjustmentActionTypes";

type AdjustPoolRequest = { type: PoolAdjustmentActionTypes.ADJUST_PAS_POOL_REQUEST, payload: IPayload };
type GetPoolInfoRequest = { type: PoolAdjustmentActionTypes.GET_PAS_POOL_INFO_REQUEST, payload: IPayload };
type GetPoolListRequest = { type: PoolAdjustmentActionTypes.GET_PAS_POOL_LIST_REQUEST, payload: IPayload };
type GetCreditRateRequest = { type: PoolAdjustmentActionTypes.GET_CREDIT_RATE, payload: IPayload };


export type ActionTypes =
    AdjustPoolRequest | GetPoolInfoRequest | GetPoolListRequest | GetCreditRateRequest;

export const AdjustPASPool = (
    userID: number, remark: string, poolID: number, transferType: PoolAdjustmentTransferType, amount: number, poolTierType: TierParameterType
) => async (dispatch: any) => {

    try {
        // ask reducer set loading state
        dispatch({
            type: PoolAdjustmentActionTypes.ADJUST_PAS_POOL_REQUEST,
            payload: LoadingPayload
        });

        // ask reducer handle response data
        let request: PASPoolAdjustmentModel = {
            uid: userID,
            rmk: remark,
            pid: poolID,
            tst: transferType,
            amt: amount,
            ptt: poolTierType,
            hca: 0,
            pca: 0,
            rca: 0
        };
        PostRequest("BackOffice/AdjustPASPool", request, PoolAdjustmentActionTypes.ADJUST_PAS_POOL_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolAdjustmentActionTypes.ADJUST_PAS_POOL_REQUEST, dispatch);
    }
}

export const GetPASPoolInfo = (
    poolID: number
) => async (dispatch: any) => {

    try {
        dispatch({
            type: PoolAdjustmentActionTypes.GET_PAS_POOL_INFO_REQUEST,
            payload: LoadingPayload
        });

        let request: RequestItemModel = {
            id: poolID
        };
        PostRequest("BackOffice/GetPASPoolInfo", request, PoolAdjustmentActionTypes.GET_PAS_POOL_INFO_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolAdjustmentActionTypes.GET_PAS_POOL_INFO_REQUEST, dispatch);
    }
}

export const GetPASPoolList = () => async (dispatch: any) => {
    try {
        dispatch({
            type: PoolAdjustmentActionTypes.GET_PAS_POOL_LIST_REQUEST,
            payload: LoadingPayload
        });

        GetRequest("BackOffice/GetPASPoolList", PoolAdjustmentActionTypes.GET_PAS_POOL_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolAdjustmentActionTypes.GET_PAS_POOL_LIST_REQUEST, dispatch);
    }
}

export const PoolAdjustmentGetCreditRateRequest = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetCreditRate", PoolAdjustmentActionTypes.GET_CREDIT_RATE, dispatch);
    }
    catch (error) {
        RequestException(error, PoolAdjustmentActionTypes.GET_CREDIT_RATE, dispatch);
    }
}

