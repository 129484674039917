import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { PlayerPerformanceCSVReportModel, PlayerPerformancePDFReportModel, PlayerPerformanceReportListModel } from "../../../dto/models/Reporting/PlayerPerformanceReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";
import { ActionTypes } from "./PlayerPerformanceReportingActions";
import { PlayerPerformanceReportingActionTypes } from "./PlayerPerformanceReportingActionTypes";
import { InitPlayerPerformanceReportState, IPlayerPerformanceReportState } from "./PlayerPerformanceReportingStates";

export const PlayerPerformanceReportingReducer = (state = InitPlayerPerformanceReportState, action: ActionTypes): IPlayerPerformanceReportState => {
    switch (action.type) {
        case PlayerPerformanceReportingActionTypes.GET_PLAYER_PERFORMANCE_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, playerPerformanceListData: {} as PlayerPerformanceReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: PlayerPerformanceReportListModel = {} as PlayerPerformanceReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrived."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, playerPerformanceListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as PlayerPerformancePDFReportModel, csvData: {} as PlayerPerformanceCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: PlayerPerformancePDFReportModel = {} as PlayerPerformancePDFReportModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("PlayerPerformanceReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case PlayerPerformanceReportingActionTypes.GENERATE_PLAYER_PERFORMANCE_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as PlayerPerformancePDFReportModel, csvData: {} as PlayerPerformanceCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: PlayerPerformanceCSVReportModel = {} as PlayerPerformanceCSVReportModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("PlayerPerformanceReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}