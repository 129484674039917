import { OLRReportListModel, OLRReportPDFModel, OLRReportCSVModel } from "../../../dto/models/Reporting/OLRReportModel";

export interface IOLRReportState {
    loading: boolean;
    err: string;
    suc: string;
    olrReportListData: OLRReportListModel;
    pdfData: OLRReportPDFModel;
    csvData: OLRReportCSVModel;
}

export const InitOLRReportState: IOLRReportState = {
    loading: false,
    err: "",
    suc: "",
    olrReportListData: {} as OLRReportListModel,
    pdfData: {} as OLRReportPDFModel,
    csvData: {} as OLRReportCSVModel,
}