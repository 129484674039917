import { PlayerLeftOverBalanceCSVReportModel, PlayerLeftOverBalanceListModel, PlayerLeftOverBalancePDFReportModel } from "../../../dto/models/Reporting/PlayerLeftOverBalanceReportModel";

export interface IPlayerLeftOverBalanceReportState {
    loading: boolean;
    err: string;
    suc: string;
    leftOverBalanceReportListData: PlayerLeftOverBalanceListModel;
    pdfData: PlayerLeftOverBalancePDFReportModel;
    csvData: PlayerLeftOverBalanceCSVReportModel;
}

export const InitPlayerLeftOverBalanceReportState: IPlayerLeftOverBalanceReportState = {
    loading: false,
    err: "",
    suc: "",
    leftOverBalanceReportListData: {} as PlayerLeftOverBalanceListModel,
    pdfData: {} as PlayerLeftOverBalancePDFReportModel,
    csvData: {} as PlayerLeftOverBalanceCSVReportModel,
}
