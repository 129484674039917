import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import DropdownSelectBox from "../../components/InputBox/DropdownSelectBox";
import { IDropdownSelectOption, LoadDropdownOptionFromEnum } from "../../components/InputBox/IDropdownSelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { CmsType } from "../../dto/enums/CmsType";
import { GamingAreaType } from "../../dto/enums/GamingAreaType";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetGameServiceListRequest } from "../../states/GameService/GameServiceActions";
import { GenerateGameWinLoseCSVRequest, GenerateGameWinLosePDFRequest, GetGameWinLoseReportRequest } from "../../states/Reporting/GameWinLoseReport/GameWinLoseReportingActions";
import { IStore } from "../../states/store/IStore";

const GameWinLoseReporting = () => {
    const dispatch = useDispatch();
    const gameWinLoseReportState = useSelector((state: IStore) => state.gameWinLoseReportState);
    const { gameWinLoseReportListData, loading, err, suc } = gameWinLoseReportState;
    const gameServiceState = useSelector((state: IStore) => state.gameServiceState);
    const { gameServiceList } = gameServiceState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    const [selectedGameService, selectGameServices] = useState<number[]>([]);
    const [selectedGameServiceValid, setGameServiceValid] = useState(false);

    const [gameServiceOption, setGameServiceOptions] = useState([] as IDropdownSelectOption[]);
    const [cmsOption, setCmsOption] = useState(LoadDropdownOptionFromEnum(CmsType));

    // Search Criteria
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const take = (page: number) => {
        // TODO: dispatch change page, provide current page
        if (valid()) {
            setPage(page);
            dispatch(GetGameWinLoseReportRequest(selectedGameService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    useEffect(() => {
        dispatch(GetGameServiceListRequest())
    }, []);

    useEffect(() => {
        if (gameServiceList && gameServiceList.sgl) {
            const services: IDropdownSelectOption[] = gameServiceList.sgl.map((item) => {
                return {
                    label: item.snm,
                    value: item.sid.toString()
                }
            });

            setGameServiceOptions(services);
        }
    }, [gameServiceList]);


    useEffect(() => {
        setPagination(winLosePagination);
    }, [gameWinLoseReportListData.ttc])

    const winLosePagination: ITablePagination = {
        id: "gameWinLoseReportSummaryPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? gameWinLoseReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const emptyTableSummary: IDataTable = {
        title: ["No.", "Game Service", "Gaming Area", "Total Game", "Total EC Wager", "Total NN Wager", "Total Payout", "Total Win Lose", "Total Player Count", "Average Bet", "RTP"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTableSummary)
    const [pagination, setPagination] = useState(winLosePagination)

    useEffect(() => {
        var table: IDataTable = emptyTableSummary;
        var index: number = currentPage * TABLE_MAX_ROW;
        if (gameWinLoseReportListData && gameWinLoseReportListData.rls != undefined && hasSearched) {
            var totalGame: number = 0;
            var totalEC: number = 0;
            var totalNN: number = 0;
            var totalPayout: number = 0;
            var totalWinLose: number = 0;
            var totalPlayer: number = 0;

            table.data = gameWinLoseReportListData.rls.map(x => {
                totalGame += x.ttg;
                totalEC += x.ecw;
                totalNN += x.nnw;
                totalPayout += x.ttp;
                totalWinLose += x.ttw;
                totalPlayer += x.ttpc;

                return [
                    1 + index++,
                    x.gnm,
                    GamingAreaType[x.gat].replace('_', ' '),
                    x.ttg,
                    CVT_TO_FLOAT_STRING(x.ecw),
                    CVT_TO_FLOAT_STRING(x.nnw),
                    CVT_TO_FLOAT_STRING(x.ttp),
                    CVT_TO_FLOAT_STRING(x.ttw),
                    x.ttpc,
                    CVT_TO_FLOAT_STRING(x.avg),
                    CVT_TO_FLOAT_STRING(x.rtp) + "%",
                ]
            });

            table.concludeColumn = [{ columnIndex: 3, content: totalGame }, { columnIndex: 4, content: CVT_TO_FLOAT_STRING(totalEC) }, { columnIndex: 5, content: CVT_TO_FLOAT_STRING(totalNN) }, { columnIndex: 6, content: CVT_TO_FLOAT_STRING(totalPayout) }, { columnIndex: 7, content: CVT_TO_FLOAT_STRING(totalWinLose) }, { columnIndex: 8, content: totalPlayer }]
        } else
            table.data = [];
        setReportTable(table);
    }, [gameWinLoseReportListData])

    const valid = (): boolean => {
        return selectedGameServiceValid && fromDate !== "" && toDate !== "" && toDateValid && fromDateValid;
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);

            setReportTable(emptyTableSummary)
            dispatch(GetGameWinLoseReportRequest(selectedGameService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));

        } else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
            TRIGGER_BLUR("gameServicesSelectDropdown");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateGameWinLosePDFRequest(selectedGameService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateGameWinLoseCSVRequest(selectedGameService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker hideTime isRequired label="Trading Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col>
                            <DropdownSelectBox allowSelectAll={true} id="gameServicesSelectDropdown" name="gameServicesSelectDropdown" options={gameServiceOption}
                                isRequired={true} inputState={selectGameServices} validState={setGameServiceValid} placeholder="----Select Game Service----" label="Service" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="GameWinLose"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}

export default GameWinLoseReporting;
