import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row } from 'reactstrap';
import { GamingAreaType } from '../../../dto/enums/GamingAreaType';
import { JackpotPoolTitle } from '../../../dto/enums/Jackpot/JackpotPoolTitle';
import { SlotGameStatus, SlotGameType } from '../../../dto/enums/SlotGames';
import { CVT_TO_FLOAT_STRING } from '../../../libraries/FloatHelper';
import { GenerateGameDetailResultCSVRequest, GenerateGameDetailResultPDFRequest, GetGameDetailResultReportRequest } from '../../../states/Reporting/GameResultReport/GameResultReportingActions';
import { IStore } from '../../../states/store/IStore';
import { TextboxType } from '../../InputBox/IInputBox';
import InputBox from '../../InputBox/InputBox';
import { ModalType } from '../../ModalBox/IModalBox';
import ModalBox from '../../ModalBox/ModalBox';

interface IGameDetailResultForm {
    historyID: number;
    gameServiceID: number;
    isCompleted: boolean;
    playerID: string;
    playerName: string;

    generatePDFTrigger: boolean;
    setGeneratePDFTrigger: React.Dispatch<React.SetStateAction<boolean>>;

    generateCSVTrigger: boolean;
    setGenerateCSVTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const GameDetailResultForm = (props: IGameDetailResultForm) => {
    const dispatch = useDispatch();
    const gameResultState = useSelector((state: IStore) => state.gameResultReportState);
    const { gameDetailData } = gameResultState;

    // Player/Game Info
    const [gameID, setGameID] = useState<number | undefined>(undefined);
    const [playerName, setPlayerName] = useState("");
    const [gameType, setGameType] = useState("");
    const [gamingArea, setGamingArea] = useState("");
    const [status, setStatus] = useState("");

    // Wallet Type
    const [ecType, setECType] = useState("");
    const [nnType, setNNType] = useState("");

    // Wager/Bet Info
    const [bet, setBet] = useState<string | undefined>(undefined);
    const [denominationTier, setDenominationTier] = useState<string | undefined>(undefined);
    const [lines, setLines] = useState<number | undefined>(undefined);
    const [beforeEC, setBFEC] = useState<string | undefined>(undefined);
    const [afterEC, setATEC] = useState<string | undefined>(undefined);
    const [wagerEC, setWEC] = useState<string | undefined>(undefined);
    const [wagerNN, setWNN] = useState<string | undefined>(undefined);
    const [payout, setPayout] = useState<string | undefined>(undefined);
    const [handPay, setHandPay] = useState<string | undefined>(undefined);
    const [winLose, setWinLose] = useState<string | undefined>(undefined);
    const [beforeNN, setBFNN] = useState<string | undefined>(undefined);
    const [afterNN, setATNN] = useState<string | undefined>(undefined);

    // Minigame/Gamble Payout
    const [numberGamble, setNumberGamble] = useState<number | undefined>(undefined);
    const [gambleTotalBet, setGambleTotalBet] = useState<string | undefined>(undefined);
    const [gambleTotalPayout, setGambleTotalPayout] = useState<string | undefined>(undefined);
    const [miniGameType, setMiniGameType] = useState<string | undefined>(undefined);
    const [miniGamePayout, setMiniGamePayout] = useState<string | undefined>(undefined);

    // Jackpot Payout
    const [jackpotStrikePool, setJackpotStrikePool] = useState<JackpotPoolTitle | string | undefined>(undefined);
    const [jackpotStrikeAmount, setJackpotStrikeAmount] = useState<string | undefined>(undefined);

    useEffect(() => {
        dispatch(GetGameDetailResultReportRequest(props.historyID, props.gameServiceID, props.playerID, props.playerName, props.isCompleted));
    }, [])

    useEffect(() => {
        if (gameDetailData.pnm) {
            setGameID(gameDetailData.gid);
            setPlayerName(gameDetailData.pnm + " (" + gameDetailData.pid + ")");
            setGameType(SlotGameType[gameDetailData.gdt.typ].toString().replace("_", " "));
            setGamingArea(GamingAreaType[gameDetailData.gdt.gaty].toString().replace("_", " "));
            setStatus(SlotGameStatus[gameDetailData.stt].toString().replace("_", " "))
            setPayout(CVT_TO_FLOAT_STRING(gameDetailData.pay));
            setHandPay(CVT_TO_FLOAT_STRING(gameDetailData.gdt.hpt));

            setECType(gameDetailData.gdt.ecty);
            setNNType(gameDetailData.gdt.nnty);

            setBet(CVT_TO_FLOAT_STRING(gameDetailData.bet));
            setDenominationTier(gameDetailData.gdt.dnt.toString());
            setLines(gameDetailData.lin);
            setBFEC(CVT_TO_FLOAT_STRING(gameDetailData.gdt.bfec));
            setATEC(CVT_TO_FLOAT_STRING(gameDetailData.gdt.afec));
            setWEC(CVT_TO_FLOAT_STRING(gameDetailData.gdt.wgre));
            setWNN(CVT_TO_FLOAT_STRING(gameDetailData.gdt.wgrn));
            setBFNN(CVT_TO_FLOAT_STRING(gameDetailData.gdt.bfnn));
            setATNN(CVT_TO_FLOAT_STRING(gameDetailData.gdt.afnn));
            setWinLose(CVT_TO_FLOAT_STRING(gameDetailData.wls));

            gameDetailData.gdt.ngb != undefined ? setNumberGamble(gameDetailData.gdt.ngb) : setNumberGamble(0);
            gameDetailData.gdt.gbb != undefined ? setGambleTotalBet(CVT_TO_FLOAT_STRING(gameDetailData.gdt.gbb)) : setGambleTotalBet("N/A");
            gameDetailData.gdt.gbp != undefined ? setGambleTotalPayout(CVT_TO_FLOAT_STRING(gameDetailData.gdt.gbp)) : setGambleTotalPayout("N/A");
            gameDetailData.gdt.mtyp != undefined ? setMiniGameType(gameDetailData.gdt.mtyp.toString()) : setMiniGameType("N/A");
            gameDetailData.gdt.mpy != undefined ? setMiniGamePayout(CVT_TO_FLOAT_STRING(gameDetailData.gdt.mpy)) : setMiniGamePayout("N/A");

            gameDetailData.gdt.jps != undefined ? setJackpotStrikePool(gameDetailData.gdt.jps) : setJackpotStrikePool("N/A");
            gameDetailData.gdt.sam != undefined ? setJackpotStrikeAmount(CVT_TO_FLOAT_STRING(gameDetailData.gdt.sam)) : setJackpotStrikeAmount("N/A");
        }
    }, [gameDetailData]);

    useEffect(() => {
        if (props.generatePDFTrigger)
            dispatch(GenerateGameDetailResultPDFRequest(props.historyID, props.gameServiceID, props.playerID, props.playerName, props.isCompleted));

        props.setGeneratePDFTrigger(false);
    }, [props.generatePDFTrigger])

    useEffect(() => {
        if (props.generateCSVTrigger)
            dispatch(GenerateGameDetailResultCSVRequest(props.historyID, props.gameServiceID, props.playerID, props.playerName, props.isCompleted));

        props.setGenerateCSVTrigger(false);
    }, [props.generateCSVTrigger])

    return (
        <Form className="pt-2">
            <fieldset className="border p-2">
                <legend className="float-none w-auto">Player/Game Info</legend>
                <Row>
                    <Col>
                        <InputBox
                            id="gameID"
                            name="gameID"
                            label="Game ID"
                            type={TextboxType.TEXT}
                            inputState={setGameID}
                            placeholder={"Game ID"}
                            value={gameID}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="name"
                            name="name"
                            label="Player Name/ID"
                            type={TextboxType.TEXT}
                            inputState={setPlayerName}
                            placeholder={"Player Name"}
                            value={playerName}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox
                            id="gameType"
                            name="gameType"
                            label="Game Type"
                            type={TextboxType.TEXT}
                            inputState={setGameType}
                            placeholder={"Game Type"}
                            value={gameType.toString().replace(/_/g, " ") }
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="gamingArea"
                            name="gamingArea"
                            label="Gaming Area"
                            type={TextboxType.TEXT}
                            inputState={setGamingArea}
                            placeholder={"Gaming Area"}
                            value={gamingArea.toString().replace("_", " ")}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox
                            id="status"
                            name="status"
                            label="Game Status"
                            type={TextboxType.TEXT}
                            inputState={setStatus}
                            placeholder={"Game Status"}
                            value={status.toString().replace("_", " ")}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <InputBox
                            id="payout"
                            name="payout"
                            label="Payout"
                            type={TextboxType.CURRENCY}
                            inputState={setPayout}
                            placeholder={"Payout"}
                            value={payout}
                            isDisabled={true}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            id="handPay"
                            name="handPay"
                            label="Hand Pay"
                            type={TextboxType.CURRENCY}
                            inputState={setHandPay}
                            placeholder={"Hand Pay"}
                            value={handPay}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
            </fieldset>
            <fieldset className="border p-2 mt-3">
                <legend className="float-none w-auto">Wallet Type</legend>
                <Row>
                    <Col>
                        <InputBox
                            id="ecType"
                            name="ecType"
                            label="EC Type"
                            type={TextboxType.TEXT}
                            inputState={setGameType}
                            placeholder={"EC Type"}
                            value={ecType}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="nnType"
                            name="nnType"
                            label="NN Type"
                            type={TextboxType.TEXT}
                            inputState={setGamingArea}
                            placeholder={"NN Type"}
                            value={nnType}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
            </fieldset>
            <fieldset className="border p-2 mt-3">
                <legend className="float-none w-auto">Wager/Payout Info</legend>
                <Row>
                    <Col>
                        <InputBox
                            id="bet"
                            name="bet"
                            label="Bet Per Line"
                            type={TextboxType.CURRENCY}
                            inputState={setBet}
                            placeholder={"Bet Per Line"}
                            value={bet}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="denominationTier"
                            name="denominationTier"
                            label="Denomination Tier"
                            type={TextboxType.TEXT}
                            inputState={setDenominationTier}
                            placeholder={"Denomination Tier"}
                            value={denominationTier + "%"}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="lines"
                            name="lines"
                            label="Lines"
                            type={TextboxType.NUMBER}
                            inputState={setLines}
                            placeholder={"Lines"}
                            value={lines}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox
                            id="beforeEC"
                            name="beforeEC"
                            label="Before EC"
                            type={TextboxType.CURRENCY}
                            inputState={setBFEC}
                            placeholder={"Before EC"}
                            value={beforeEC}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="afterEC"
                            name="afterEC"
                            label="After EC"
                            type={TextboxType.CURRENCY}
                            inputState={setATEC}
                            placeholder={"After EC"}
                            value={afterEC}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox
                            id="beforeNN"
                            name="beforeNN"
                            label="Before NN"
                            type={TextboxType.CURRENCY}
                            inputState={setBFNN}
                            placeholder={"Before NN"}
                            value={beforeNN}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="afterNN"
                            name="afterNN"
                            label="After NN"
                            type={TextboxType.CURRENCY}
                            inputState={setATNN}
                            placeholder={"After NN"}
                            value={afterNN}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <InputBox
                            id="wagerEC"
                            name="wagerEC"
                            label="Wager EC"
                            type={TextboxType.CURRENCY}
                            inputState={setBFEC}
                            placeholder={"Wager EC"}
                            value={wagerEC}
                            isDisabled={true}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            id="wagerNN"
                            name="wagerNN"
                            label="Wager NN"
                            type={TextboxType.CURRENCY}
                            inputState={setATEC}
                            placeholder={"Wager NN"}
                            value={wagerNN}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox
                            id="winlose"
                            name="winlose"
                            label="Win Lose"
                            type={TextboxType.CURRENCY}
                            inputState={setWinLose}
                            placeholder={"Win Lose"}
                            value={winLose}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
            </fieldset>
            <fieldset className="border p-2 mt-3">
                <legend className="float-none w-auto">Minigame/Gamble Payout</legend>
                <Row>
                    <Col>
                        <InputBox
                            id="gambleNumber"
                            name="gambleNumber"
                            label="Number of Gambles"
                            type={TextboxType.NUMBER}
                            inputState={setNumberGamble}
                            placeholder={"Number of Gambles"}
                            value={numberGamble}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="gambleTotalBet"
                            name="gambleTotalBet"
                            label="Gamble Total Bet"
                            type={TextboxType.CURRENCY}
                            inputState={setGambleTotalBet}
                            placeholder={"Gamble Total Bet"}
                            value={gambleTotalBet}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="gambleTotalPayout"
                            name="gambleTotalPayout"
                            label="Gamble Total Payout"
                            type={TextboxType.CURRENCY}
                            inputState={setGambleTotalPayout}
                            placeholder={"Gamble Total Payout"}
                            value={gambleTotalPayout}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox
                            id="miniGameType"
                            name="miniGameType"
                            label="Mini Game Type"
                            type={TextboxType.TEXT}
                            inputState={setMiniGameType}
                            placeholder={"Mini Game Type"}
                            value={miniGameType}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="miniGameTotalPayout"
                            name="miniGameTotalPayout"
                            label="Mini Game Total Payout"
                            type={TextboxType.CURRENCY}
                            inputState={setMiniGamePayout}
                            placeholder={"Mini Game Total Payout"}
                            value={miniGamePayout}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
            </fieldset>
            <fieldset className="border p-2 mt-3">
                <legend className="float-none w-auto">Jackpot Payout</legend>
                <Row>
                    <Col>
                        <InputBox
                            id="poolStrike"
                            name="poolStrike"
                            label="Jackpot Pool Strike"
                            type={TextboxType.TEXT}
                            inputState={setMiniGameType}
                            placeholder={"Jackpot Pool Strike"}
                            value={jackpotStrikePool != "N/A" ? JackpotPoolTitle[jackpotStrikePool as JackpotPoolTitle]?.toString().replace(/_/g, " ") : jackpotStrikePool}
                            isDisabled={true}
                        />
                    </Col>
                    <Col>
                        <InputBox
                            id="jackpotStrikeAmount"
                            name="jackpotStrikeAmount"
                            label="Jackpot Strike Amount"
                            type={TextboxType.CURRENCY}
                            inputState={setJackpotStrikeAmount}
                            placeholder={"Jackpot Strike Amount"}
                            value={jackpotStrikeAmount}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
            </fieldset>
        </Form>
    )
}

const GameDetailResultModal = (props: { gameServiceID: number, gameHistoryID: number, playerID: string, playerName: string, isCompleted: boolean, isOpen: boolean, setOpenState: any }) => {
    const gameResultState = useSelector((state: IStore) => state.gameResultReportState);
    const { err, suc, loading } = gameResultState;
    const [generatePDF, setGeneratePDF] = useState(false);
    const [generateCSV, setGenerateCSV] = useState(false);

    useEffect(() => {
        setGeneratePDF(false);
        setGenerateCSV(false);
    }, [loading]);

    const resultModalButtons = () => {
        return (
            <div>
                <Button
                    color="info"
                    onClick={() => {
                        setGeneratePDF(true);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faDownload} /> Generate PDF
                </Button>
                {' '}
                <Button
                    color="info"
                    onClick={() => {
                        setGenerateCSV(true);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faDownload} /> Generate CSV
                </Button>
            </div>
        );
    }

    return (
        <div>
            <ModalBox
                title={"Game Detail"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <GameDetailResultForm gameServiceID={props.gameServiceID} isCompleted={props.isCompleted} historyID={props.gameHistoryID} playerID={props.playerID} playerName={props.playerName} generateCSVTrigger={generateCSV} generatePDFTrigger={generatePDF} setGenerateCSVTrigger={setGenerateCSV} setGeneratePDFTrigger={setGeneratePDF} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={resultModalButtons()}
            />
        </div>
    );
}

export default GameDetailResultModal;

