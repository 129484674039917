import { BigWinPendingListModel } from "../../../dto/models/BigWinPendingModels";

export interface IJackpotApprovalState {
    loading: boolean;
    err: string;
    suc: string;
    jackpotPendingList: BigWinPendingListModel;
}

export const InitialJackpotApprovalState: IJackpotApprovalState = {
    loading: false,
    err: "",
    suc: "",
    jackpotPendingList: {} as BigWinPendingListModel
}
