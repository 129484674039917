import { faCircleCheck, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import ManualSubmitOLRModal from '../../components/_modals/Cashier/ManualSubmitOLRModal';
import { ActionStatus } from '../../dto/enums/ActionStatus';
import { FORMAT_DATETIME_TO_VIEW } from '../../libraries/DateHelper';
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetUnresolvedManualSubmitOLR } from '../../states/ManualSubmitOLR/ManualSubmitOLRActions';
import { IStore } from '../../states/store/IStore';

const ManualSubmitOLRMaintenance = () => {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const manualSubmitOLRState = useSelector((state: IStore) => state.manualSubmitOLRState);
    const { loading, err, suc, manualSubmitOLRListData } = manualSubmitOLRState;

    const emptyUnresolvedTable: IDataTable = {
        title: ["No", "Transaction ID", "Created At", "Number of Retries", "Submit Retry Status", ""],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };

    const [olrTable, setOLRTable] = useState(emptyUnresolvedTable)

    useEffect(() => {
        if (!openModal) {
            dispatch(GetUnresolvedManualSubmitOLR(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const Resolve = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    const SeeDetails = (id: number): void => {
        setOpenModal(true);
        setEdit(false);
        setEditID(id);
    }

    const Status = (status: ActionStatus) => {
        switch (status) {
            case ActionStatus.NONE:
                return (
                    <span className="status-none">None</span>
                )
            case ActionStatus.PENDING:
                return (
                    <span className="status-pending"><Spinner size="sm"></Spinner> Pending</span>
                )
            case ActionStatus.SUCCESS:
                return (
                    <span className="status-success"><FontAwesomeIcon icon={faCircleCheck} /> Success</span>
                )
            case ActionStatus.FAILED:
                return (
                    <span className="status-failed"><FontAwesomeIcon icon={faXmarkCircle} /> Failed</span>
                )
            default:
                return (<></>)
        }
    }

    useEffect(() => {
        var table: IDataTable = emptyUnresolvedTable
        if (manualSubmitOLRListData.olrl && manualSubmitOLRListData.olrl.length > 0) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = manualSubmitOLRListData.olrl.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.txid,
                    FORMAT_DATETIME_TO_VIEW(item.cra, "yyyy-MM-dd hh:mm:ss"),
                    item.nor,
                    Status(item.stt),
                    <Button onClick={() => Resolve(item.oid)} disabled={item.stt == ActionStatus.PENDING}>Resolve</Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setOLRTable(table)
    }, [manualSubmitOLRListData])

    const take = (page: number) => {
        setPage(page);
        dispatch(GetUnresolvedManualSubmitOLR(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "manualSubmitOLRPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: manualSubmitOLRListData.ttc ?? 0
    }

    return (
        <>
            <Row>
                <Col sm="12">
                    <ManualSubmitOLRModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
                    <StandardContainer
                        id="ManualSubmitOLRMaintenanceContainer"
                        loading={loading}
                        error={err}
                        success={suc}
                        tableProps={olrTable}
                        tablePagination={pagination}
                    />
                </Col>
            </Row>
        </>
    );
};
export default ManualSubmitOLRMaintenance;
