import { ServiceType } from "../../dto/enums/ServiceType";
import { ServiceTokenRequestModel } from "../../dto/models/ServiceAuthenticationModels";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { PylonAuthenticationActionTypes } from "./PylonAuthenticationActionTypes";

type GetPylonAuthenticationRequest = { type: PylonAuthenticationActionTypes.GET_PYLON_AUTHENTICATION_REQUEST, payload: IPayload };
type GeneratePylonAuthenticationRequest = { type: PylonAuthenticationActionTypes.GENERATE_PYLON_AUTHENTICATION_REQUEST, payload: IPayload };

export type ActionTypes = GetPylonAuthenticationRequest | GeneratePylonAuthenticationRequest;

export const GetPylonAuthentication = () => async (dispatch: any) => {
    try {
        PostRequest("BackOffice/GetPylonAuthentication", null, PylonAuthenticationActionTypes.GET_PYLON_AUTHENTICATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PylonAuthenticationActionTypes.GET_PYLON_AUTHENTICATION_REQUEST, dispatch);
    }
}

export const GeneratePylonAuthenticationToken = (service: ServiceType) => async (dispatch: any) => {
    try {
        let request: ServiceTokenRequestModel = {
            sst: service
        };

        PostRequest("BackOffice/GeneratePylonAuthentication", request, PylonAuthenticationActionTypes.GENERATE_PYLON_AUTHENTICATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PylonAuthenticationActionTypes.GENERATE_PYLON_AUTHENTICATION_REQUEST, dispatch);
    }
}