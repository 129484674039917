import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { WinLoseCSVReportModel, WinLosePDFReportModel, WinLoseReportListModel } from "../../../dto/models/Reporting/WinLoseReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";
import { ActionTypes } from "./GameWinLoseReportingActions";
import { WinLoseReportingActionTypes } from "./GameWinLoseReportingActionTypes";
import { IGameWinLoseReportState, InitGameWinLoseReportState } from "./GameWinLoseReportingStates";

export const GameWinLoseReportingReducer = (state = InitGameWinLoseReportState, action: ActionTypes): IGameWinLoseReportState => {
    switch (action.type) {
        case WinLoseReportingActionTypes.GET_WIN_LOSE_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, gameWinLoseReportListData: {} as WinLoseReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: WinLoseReportListModel = {} as WinLoseReportListModel;

                if (data && !data.err)
                    reportListModel = data.res;

                let errMsg: string = "";
                let sucMsg: string = ""

                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                    else
                        errMsg = "No Records Found"
                }
                return { ...state, loading: false, gameWinLoseReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case WinLoseReportingActionTypes.GENERATE_WIN_LOSE_PDF_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as WinLosePDFReportModel, csvData: {} as WinLoseCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: WinLosePDFReportModel = {} as WinLosePDFReportModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("GameWinLoseReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        }
        case WinLoseReportingActionTypes.GENERATE_WIN_LOSE_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as WinLosePDFReportModel, csvData: {} as WinLoseCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: WinLoseCSVReportModel = {} as WinLoseCSVReportModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("GameWinLoseReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
