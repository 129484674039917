import { TrandingDateModel } from "../../dto/models/TradingDateModels";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { TradingDateActionTypes } from "./TradingDateActionTypes";

type GetTradingDateRequest = { type: TradingDateActionTypes.GET_TRADING_DATE_REQUEST, payload: IPayload };
type EditTradingDateRequest = { type: TradingDateActionTypes.EDIT_TRADING_DATE_REQUEST, payload: IPayload };

export type ActionTypes = GetTradingDateRequest | EditTradingDateRequest;

export const GetTradingDate = () => async (dispatch: any) => {
        try {
            GetRequest("BackOffice/GetTradingDate", TradingDateActionTypes.GET_TRADING_DATE_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, TradingDateActionTypes.GET_TRADING_DATE_REQUEST, dispatch);
        }
    }

export const EditTradingDate = (tradingDate: Date, isAutoMode: boolean,
    lastUpdatedAt: Date, lastUpdatedBy: number, updatedTime: string) => async (dispatch: any) => {
        try {
            // ask reducer handle response data
            let request: TrandingDateModel = {
                tdd: tradingDate,
                udt: updatedTime,
                iam: isAutoMode,
                lua: lastUpdatedAt,
                lub: lastUpdatedBy,
            };

            PostRequest("BackOffice/EditTradingDate", request, TradingDateActionTypes.EDIT_TRADING_DATE_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, TradingDateActionTypes.EDIT_TRADING_DATE_REQUEST, dispatch);
        }
    }