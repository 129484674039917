import React, { useEffect, useState } from 'react';
import { Form, Row, Col, FormGroup, Button, Container } from 'reactstrap';
import { TextboxType } from '../../InputBox/IInputBox';
import InputBox from '../../InputBox/InputBox';
import ModalBox from '../../ModalBox/ModalBox';
import { ModalType } from '../../ModalBox/IModalBox';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../states/store/IStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { TRIGGER_BLUR } from '../../../libraries/Functions';
import { EditProfile } from '../../../states/User/UserActions';
import { FORMAT_DATETIME_TO_VIEW } from '../../../libraries/DateHelper';

interface IEditProfileForm {
    loading: boolean;
    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProfileForm = (props: IEditProfileForm) => {
    const [nameInput, setNameInput] = useState("");
    const [nameValid, setNameValid] = useState(true);
    const [cardNumberInput, setCardNumberInput] = useState("");
    const [cardNumberValid, setCardNumberValid] = useState(true);
    const [emailInput, setEmailInput] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [dobInput, setDOBInput] = useState<undefined | Date>(undefined);

    const dispatch = useDispatch();
    const userStates = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userStates;

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (userProfileData.fnm) {
            setNameInput(userProfileData.fnm);
            setCardNumberInput(userProfileData.cno);
            setEmailInput(userProfileData.eml);
            if (userProfileData.dob)
                setDOBInput(FORMAT_DATETIME_TO_VIEW(userProfileData.dob, "yyyy-MM-dd") as any as Date);
        }
    }, [userProfileData]);

    const Valid = (): boolean => {
        return nameValid && cardNumberValid && emailValid;
    }

    const save = () => {
        if (Valid() && nameInput && cardNumberInput && emailInput) {
            dispatch(
                EditProfile(
                    cardNumberInput,
                    emailInput
                ));

        } else {
            TRIGGER_BLUR("cardNo");
            TRIGGER_BLUR("email");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <InputBox
                                id="name"
                                name="name"
                                label="Full Name"
                                type={TextboxType.TEXT}
                                inputState={setNameInput}
                                validState={setNameValid}
                                placeholder={"Full Name"}
                                value={nameInput}
                                isDisabled={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <InputBox
                            id="dob"
                            name="dob"
                            label="Birth Date"
                            type={TextboxType.DATE}
                            inputState={setDOBInput}
                            placeholder={"Date of Birth"}
                            isRequired={true}
                            value={dobInput}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <InputBox
                                id="email"
                                name="email"
                                label="Email"
                                type={TextboxType.EMAIL}
                                inputState={setEmailInput}
                                placeholder={"Email"}
                                validState={setEmailValid}
                                value={emailInput}
                                isDisabled={userProfileData.uid == 1}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <InputBox
                                id="cardNo"
                                name="cardNo"
                                label="Card Number"
                                type={TextboxType.TEXT}
                                inputState={setCardNumberInput}
                                placeholder={"Card Number"}
                                validState={setCardNumberValid}
                                isRequired={true}
                                value={cardNumberInput}
                                isDisabled={userProfileData.uid == 1}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

const EditProfileModal = (props: { isOpen: boolean, setOpenState: any }) => {
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileSuc, userProfileErr, loading } = userState;
    const [save, setSave] = useState(false);

    const [sucMsg, setSuccessMsg] = useState("");
    const [errMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setSuccessMsg(userProfileSuc);
    }, [userProfileSuc]);

    useEffect(() => {
        setErrorMsg(userProfileErr);
    }, [userProfileErr]);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        setSuccessMsg("");
        setErrorMsg("");
    }, [props.isOpen])


    useEffect(() => {
        setSave(false);
    }, [loading]);

    return (
        <div>
            <ModalBox
                title="Edit Profile"
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={sucMsg}
                child={
                    <EditProfileForm loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={<Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button>}
            />
        </div>
    );
}

export default EditProfileModal;

