import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import ToggleButton from "../../components/InputBox/ToggleButton";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import GameDetailResultModal from "../../components/_modals/Reporting/GameDetailResultModal";
import GameReplayModal from "../../components/_modals/Reporting/GameReplayModal";
import { SlotGameStatus } from "../../dto/enums/SlotGames";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetGameServiceListRequest } from "../../states/GameService/GameServiceActions";
import { GenerateGameResultCSVRequest, GenerateGameResultPDFRequest, GetGameResultReportRequest } from "../../states/Reporting/GameResultReport/GameResultReportingActions";
import { IStore } from "../../states/store/IStore";

const GameResultReporting = () => {
    const dispatch = useDispatch();
    const gameResultReportState = useSelector((state: IStore) => state.gameResultReportState);
    const { gameResultReportListData, loading, err, suc } = gameResultReportState;
    const gameServiceState = useSelector((state: IStore) => state.gameServiceState);
    const { gameServiceList } = gameServiceState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);
    const [gameServiceOption, setGameServiceOptions] = useState([] as ISelectOption[]);

    const [gameID, setGameID] = useState(0);
    const [playerID, setPlayerID] = useState("");
    const [playerName, setPlayerName] = useState("");
    // Detail Modal
    const [openDetail, setOpenDetail] = useState(false);

    // Replay Modal
    const [openReplay, setOpenReplay] = useState(false);
    const [gameServiceID, setGameServiceID] = useState(0);
    const [cms, setCms] = useState(0);

    // Search Criteria
    const [selectedGameService, selectGameService] = useState(0);
    const [selectedGameServiceValid, setGameServiceValid] = useState(false);
    const [membershipID, setMembershipID] = useState("");
    const [membershipIDValid, setMembershipIDValid] = useState(false);
    const [tradingDate, setTradingDate] = useState("");
    const [tradingDateValid, setTradingDateValid] = useState(false);
    const [isCompleted, setCompleted] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetGameResultReportRequest(selectedGameService, membershipID, isCompleted, new Date(tradingDate), fromDate.split("T")[1] ?? "", toDate.split("T")[1] ?? "", page * TABLE_MAX_ROW, TABLE_MAX_ROW, fromDate.split("T")[0] != "" ? new Date(fromDate.split("T")[0]) : undefined, toDate.split("T")[0] != "" ? new Date(toDate.split("T")[0]) : undefined));
        }
    }

    useEffect(() => {
        dispatch(GetGameServiceListRequest())
    }, []);

    useEffect(() => {
        if (gameServiceList && gameServiceList.sgl) {
            const services: ISelectOption[] = gameServiceList.sgl.map((item) => {
                return {
                    display: item.snm,
                    value: item.sid.toString()
                }
            });

            setGameServiceOptions(services);

            if (services.length > 0) {
                selectGameService(parseInt(services[0].value));
                setGameServiceValid(true);
            }
        }
    }, [gameServiceList]);


    useEffect(() => {
        if (gameResultReportListData && gameResultReportListData.ttc)
            setPagination(gameResultPagination);
    }, [gameResultReportListData])

    const gameResultPagination: ITablePagination = {
        id: "gameResultReportPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched && gameResultReportListData !== undefined && gameResultReportListData.ttc !== undefined ? gameResultReportListData.ttc : 0,
        currentPage: currentPage,
    }

    const emptyTable: IDataTable = {
        title: ["No.", "Game ID", "Player Name/ID", "Bets", "Lines", "Payout", "Status", "Win Lose", "Trading Date", "Transaction Date Time", "Game Detail", "Replay"],
        columnWidth: ["80px", "100px", "200px", "150px", "100px", "150px", "130px", "150px", "150px", "200px", "130px", "100px"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)
    const [pagination, setPagination] = useState(gameResultPagination)

    useEffect(() => {
        var table: IDataTable = emptyTable;
        var index: number = currentPage * TABLE_MAX_ROW;
        if (gameResultReportListData && gameResultReportListData.rml != undefined && hasSearched) {
            table.data = gameResultReportListData.rml.map(x => {
                return [
                    1 + index++,
                    x.gid,
                    x.pnm + " (" + x.pid + ")",
                    CVT_TO_FLOAT_STRING(x.bet),
                    x.lin,
                    CVT_TO_FLOAT_STRING(x.pay),
                    SlotGameStatus[x.stt].toString().replace(/_/g, " "),
                    CVT_TO_FLOAT_STRING(x.wls),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.tdd), "yyyy-MM-dd"),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.ttd), "yyyy-MM-dd hh:mm:ssa"),
                    <Button color="secondary" outline onClick={e => triggerOpenDetail(x.gid, x.pid, x.pnm)}>Game Detail</Button>,
                    <Button color="warning" outline onClick={e => triggerOpenReplay(x.gid, x.gsid, x.cms)}> Replay</Button >
                ]
            });
        } else
            table.data = [];
        setReportTable(table);
    }, [gameResultReportListData])

    const triggerOpenDetail = (gameID: number, playerID: string, playerName: string) => {
        setGameID(gameID);
        setPlayerID(playerID);
        setPlayerName(playerName);
        setOpenDetail(true);
    }

    const triggerOpenReplay = (gameID: number, gameServiceID: number, cms: number) => {
        setGameID(gameID);
        setGameServiceID(gameServiceID)
        setCms(cms);
        setOpenReplay(true);
    }

    const valid = (): boolean => {
        return selectedGameServiceValid && membershipIDValid && tradingDateValid;
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);

            setReportTable(emptyTable)
            dispatch(GetGameResultReportRequest(selectedGameService, membershipID, isCompleted, new Date(tradingDate), fromDate.split("T")[1] ?? "", toDate.split("T")[1] ?? "", 0 * TABLE_MAX_ROW, TABLE_MAX_ROW, fromDate.split("T")[0] != "" ? new Date(fromDate.split("T")[0]) : undefined, toDate.split("T")[0] != "" ? new Date(toDate.split("T")[0]) : undefined));

        } else {
            TRIGGER_BLUR("tradingDatePicker");
            TRIGGER_BLUR("gameServicesSelectDropdown");
            TRIGGER_BLUR("membershipID");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateGameResultPDFRequest(selectedGameService, membershipID, isCompleted, new Date(tradingDate), fromDate.split("T")[1] ?? "", toDate.split("T")[1] ?? "", 0 * TABLE_MAX_ROW, TABLE_MAX_ROW, fromDate.split("T")[0] != "" ? new Date(fromDate.split("T")[0]) : undefined, toDate.split("T")[0] != "" ? new Date(toDate.split("T")[0]) : undefined));
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateGameResultCSVRequest(selectedGameService, membershipID, isCompleted, new Date(tradingDate), fromDate.split("T")[1] ?? "", toDate.split("T")[1] ?? "", 0 * TABLE_MAX_ROW, TABLE_MAX_ROW, fromDate.split("T")[0] != "" ? new Date(fromDate.split("T")[0]) : undefined, toDate.split("T")[0] != "" ? new Date(toDate.split("T")[0]) : undefined));
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <InputBox id="tradingDatePicker" name="tradingDatePicker" key="tradingDatePicker" isRequired={true} HasCancelledButton={true} invalidMessage="This field is required."
                            validState={setTradingDateValid} inputState={setTradingDate} value={tradingDate} type={TextboxType.DATE} label="Trading Date" />
                    </Row>
                    <Row>
                        <DateTimeRangePicker label="Transaction Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col>
                            <InputBox
                                type={TextboxType.TEXT}
                                id="membershipID"
                                name="membershipID"
                                label="Membership ID"
                                inputState={setMembershipID}
                                value={membershipID}
                                validState={setMembershipIDValid}
                                isRequired
                            />
                        </Col>
                        <Col>
                            <SelectBox id="gameServicesSelectDropdown" name="gameServicesSelectDropdown" options={gameServiceOption}
                                isRequired={true} inputState={selectGameService} validState={setGameServiceValid} label="Service" />
                        </Col>
                    </Row>
                    <Row className="pb-2 pt-2">
                        <Col>
                            <ToggleButton id="isCompleted" name="isCompleted" label="Is Completed Game"
                                value={isCompleted} inputState={setCompleted} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div>
            <GameReplayModal
                cms={cms}
                gameID={gameID}
                gameServiceID={gameServiceID}
                isOpen={openReplay}
                isCompleted={isCompleted}
                setOpenState={setOpenReplay}
            />
            <GameDetailResultModal
                gameHistoryID={gameID}
                gameServiceID={selectedGameService}
                playerID={playerID}
                playerName={playerName}
                isOpen={openDetail}
                isCompleted={isCompleted}
                setOpenState={setOpenDetail}
            />
            <StandardReportContainer
                id="GameResult"
                error={hasSearched ? err : ""}
                success={hasSearched ? suc : ""}
                loading={loading}
                tablePagination={pagination}
                tableProps={reportTable}
                searchCriteriaContent={SearchCriteriaContainer()}
                generateCSVEvent={generateCSV}
                generatePDFEvent={generatePDF}
            />
        </div>
    )
}

export default GameResultReporting;
