import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Form, Table, Tooltip } from 'reactstrap';
import { JackpotPoolResetMode } from '../../../dto/enums/Jackpot/JackpotPoolResetMode';
import { JackpotPoolTitle } from '../../../dto/enums/Jackpot/JackpotPoolTitle';
import { JackpotMeterModel } from '../../../dto/models/JackpotModels';
import { CVT_TO_FLOAT_STRING } from '../../../libraries/FloatHelper';
import { SelectAccumulator } from '../../../states/PAS/JackpotAccumulator/AccumulatorActions';
import { IStore } from '../../../states/store/IStore';
import { ModalType } from '../../ModalBox/IModalBox';
import ModalBox from '../../ModalBox/ModalBox';

interface IJackpotDetailForm {
    jackpotMeterModel: JackpotMeterModel
}

const JackpotDetailsForm = (props: IJackpotDetailForm) => {
    const dispatch = useDispatch();

    const [detailTableList, setDetailTableList] = useState<JackpotTableView[][]>([[]]);

    // Accumulator
    const accumulatorStates = useSelector((state: IStore) => state.accumulatorState);
    const { accumulatorData } = accumulatorStates;
    const [accumulatorTitle, setAccumulatorTitle] = useState("");

    // Tool tip
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    interface JackpotTableView {
        colTitle: string,
        colValue: string,
        colID: string,
        tooltipIsOpen: boolean,
        tooltipLabel: string
    }

    const setToggle = (index: number) => {
        if (detailTableList != undefined) {
            const newDetailTableList = detailTableList.slice();
            const mainIndex = index < 10 ? 0 : Math.floor(index / 10);

            newDetailTableList[mainIndex][index % 10].tooltipIsOpen = !newDetailTableList[mainIndex][index % 10].tooltipIsOpen;
            setDetailTableList(newDetailTableList);
        }
    }

    useEffect(() => {
        var displayTable: JackpotTableView[] = [];
        var displayTable2: JackpotTableView[] = [];
        var displayTable3: JackpotTableView[] = [];
        var displayTable4: JackpotTableView[] = [];
        var displayTableList: JackpotTableView[][] = ([[]]);

        displayTable.push({
            colTitle: "Current Value", colValue: props.jackpotMeterModel.crc + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.amt) + " | " + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.crt) + " credits", colID: "currentVal",
            tooltipIsOpen: false, tooltipLabel: "Current Prize Amount"
        });
        displayTable.push({
            colTitle: "Overflow", colValue: props.jackpotMeterModel.crc + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.ofa) + " | " + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.ofc) + " credits", colID: "overflow",
            tooltipIsOpen: false, tooltipLabel: "Amount Exceeding Limit"
        });
        displayTable.push({
            colTitle: "Reserve", colValue: props.jackpotMeterModel.crc + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.rao) + " | " + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.rcr) + " credits", colID: "reserve", tooltipIsOpen: false,
            tooltipLabel: "Current Reserve Amount"
        });

        displayTable2.push({
            colTitle: "Hits", colValue: props.jackpotMeterModel.hit.toString(), colID: "hit",
            tooltipIsOpen: false, tooltipLabel: "Number of times this progressive was won"
        });
        displayTable2.push({
            colTitle: "Wins", colValue: props.jackpotMeterModel.crc + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.wmt) + " | " + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.wcd) + " credits", colID: "win",
            tooltipIsOpen: false, tooltipLabel: "Total value of wins for this progressive or a history of the last 25 progressive hits"
        });

        displayTable3.push({
            colTitle: "Base", colValue: props.jackpotMeterModel.crc + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.smt) + " | " + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.scd) + " credits", colID: "baseID",
            tooltipIsOpen: false, tooltipLabel: "Starting Value"
        });
        displayTable3.push({
            colTitle: "Limit", colValue: props.jackpotMeterModel.crc + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.amtc) + " | " + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.crtc) + " credits", colID: "limit",
            tooltipIsOpen: false, tooltipLabel: "Jackpot Limit Value (if the jackpot is capped at a maximum limit, this standard does not require to add the overflow amounts to the next starting value and will be determined on a casino-by-casino basis)"
        });
        displayTable3.push({
            colTitle: "Increment", colValue: props.jackpotMeterModel.icm.toString() + "%", colID: "increment",
            tooltipIsOpen: false, tooltipLabel: "Percentage of contribution going into Progressive"
        });
        displayTable3.push({
            colTitle: "Hidden Increment", colValue: props.jackpotMeterModel.hic.toString() + "%", colID: "hiddenIncrement",
            tooltipIsOpen: false, tooltipLabel: "Percentage of contribution going into Reserve"
        });
        displayTable3.push({
            colTitle: "Reset Value", colValue: props.jackpotMeterModel.crc + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.rsv) + " | " + CVT_TO_FLOAT_STRING(props.jackpotMeterModel.rcr) + " credits", colID: "resetVal",
            tooltipIsOpen: false, tooltipLabel: "The amount the progressive resets to after the progressive is won"
        });

        displayTable4.push({
            colTitle: "Jackpot Type", colValue: "Condition Progressive", colID: "jackpotType",
            tooltipIsOpen: false, tooltipLabel: "Jackpot Type"
        });
        displayTable4.push({
            colTitle: "Jackpot Reset Mode", colValue: JackpotPoolResetMode[props.jackpotMeterModel.jrm].replace("_", " "), colID: "jackpotResetMode",
            tooltipIsOpen: false, tooltipLabel: "Is a reset of the jackpot pool amount depend on the reset mode is configure"
        });
        displayTable4.push({
            colTitle: "Jackpot Accumulator", colValue: "N/A", colID: "jackpotAcc",
            tooltipIsOpen: false, tooltipLabel: "Jackpot Accumulator"
        });

        displayTableList.push(displayTable);
        displayTableList.push(displayTable2);
        displayTableList.push(displayTable3);
        displayTableList.push(displayTable4);

        setDetailTableList(displayTableList);

        dispatch(SelectAccumulator(props.jackpotMeterModel.jam));
    }, [])

    useEffect(() => {
        if (accumulatorData && accumulatorData.jan !== undefined && detailTableList[4] !== undefined) {
            setAccumulatorTitle(accumulatorData.jan);

            const newDetailTableList = detailTableList.slice();
            newDetailTableList[4][2].colValue = accumulatorData.jan;

            setDetailTableList(newDetailTableList);
        }

    }, [accumulatorData]);

    return (
        <Form className="pt-2">
            {detailTableList !== undefined ? detailTableList.map((listValue, listIndex) => (
                <Table className="table table-bordered" key={"display_table_" + listIndex}>
                    <tbody>
                        {listValue !== undefined ? listValue.map((value, index) => (
                            <tr key={"display_table_row_" + index}>
                                <td className="col-md-5">{value.colTitle} <Badge color="primary" id={value.colID} pill>i</Badge>
                                    <Tooltip isOpen={value.tooltipIsOpen} target={value.colID} toggle={() => setToggle((listIndex * 10) + index)} placement="right">
                                        {value.tooltipLabel}
                                    </Tooltip>
                                </td>
                                <td className="text-end col-md-7">{value.colValue}</td>
                            </tr>
                        )) : <tr></tr>}
                    </tbody>
                </Table>
            )) : <Table></Table>}
        </Form>
    )
}

const JackpotDetailsModal = (props: { isOpen: boolean, setOpenState: any, jackpotMeterModel?: JackpotMeterModel }) => {
    const amState = useSelector((state: IStore) => state.accessMatrixState);
    const { err, suc, loading } = amState;
    const [save, setSave] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    if (props.jackpotMeterModel != null) {
        return (
            <div>
                <ModalBox
                    title={JackpotPoolTitle[props.jackpotMeterModel.ttl].toString().replace("_", " ")}
                    isOpen={props.isOpen}
                    isFade={true}
                    isCentered={true}
                    isScrollable={true}
                    error={err}
                    success={suc}
                    child={
                        <JackpotDetailsForm jackpotMeterModel={props.jackpotMeterModel} key="JackpotDetailsForm" />
                    }
                    type={ModalType.Detail}
                    openState={props.setOpenState}
                />
            </div>
        );
    }
    else
        return null;
}

export default JackpotDetailsModal;

