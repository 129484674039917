export enum TierParameterType {
    PROGRESSIVE = 0,
    HOUSE = 1,
    RESERVE = 2,
    OVERFLOW = 3
}

export enum TierOperatorType {
    GREATER_THAN = 0,
    LESS_THAN = 1,
    GREATER_OR_EQUAL = 2,
    LESS_OR_EQUAL = 3
}

export enum TierConnectorType {
    AND = 0,
    OR = 1,
    END = 2
}
