import { CreditTransferResponseModel, PlayerWalletResponseModel } from "../../dto/models/CreditTransactionModels";

export interface ICreditTransactionState {
    loading: boolean;
    err: string;
    creditTransferData: CreditTransferResponseModel;
    playerWalletData: PlayerWalletResponseModel;
}

export const InitialCreditTransactionState: ICreditTransactionState = {
    loading: false,
    err: "",
    creditTransferData: {} as CreditTransferResponseModel,
    playerWalletData: {} as PlayerWalletResponseModel,
};
