import { GameRtpResponseModel } from "../../dto/models/GameRtpModel";
import { JackpotMeterAccumulatorModel } from "../../dto/models/JackpotModels";

export interface IDashboardState {
    loading: boolean;
    err: string;
    suc: string;
    jackpotMeterAccumulatorList: JackpotMeterAccumulatorModel[];
    gameServiceInfoList: GameRtpResponseModel;
}

export const InitialDashboardState: IDashboardState = {
    loading: false,
    err: "",
    suc: "",
    jackpotMeterAccumulatorList: [] as JackpotMeterAccumulatorModel[],
    gameServiceInfoList: {} as GameRtpResponseModel
};