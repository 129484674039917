import { CmsConfigurationModel, CmsConfigurationListModel } from "../../dto/models/ConfigurationModels";
import { GamingAreaListModel } from "../../dto/models/GamingAreaModels";

export interface ICmsState {
    loading: boolean;
    err: string;
    suc: string;
    cmsListData: CmsConfigurationListModel;
    cmsData: CmsConfigurationModel;
    vipGamingArea: GamingAreaListModel;
    nonVipGamingArea: GamingAreaListModel;
}


export const InitCmsState: ICmsState = {
    loading: false,
    err: "",
    suc: "",
    cmsListData: {} as CmsConfigurationListModel,
    cmsData: {} as CmsConfigurationModel,
    vipGamingArea: {} as GamingAreaListModel,
    nonVipGamingArea: {} as GamingAreaListModel
}
