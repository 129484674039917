import { ServiceAuthenticationListModel, ServiceTokenResponseModel } from "../../dto/models/ServiceAuthenticationModels";

export interface IPylonAuthenticationState {
    loading: boolean;
    tokenLoading: boolean;
    err: string;
    suc: string;
    serviceList: ServiceAuthenticationListModel;
    tokenRequestData: ServiceTokenResponseModel;
}

export const InitPylonAuthenticationState: IPylonAuthenticationState = {
    loading: false,
    tokenLoading: false,
    err: "",
    suc: "",
    serviceList: {} as ServiceAuthenticationListModel,
    tokenRequestData: {} as ServiceTokenResponseModel
}