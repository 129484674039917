import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { JackpotAdjustmentReportCSVModel, JackpotAdjustmentReportListModel, JackpotAdjustmentReportPDFModel } from "../../../dto/models/Reporting/JackpotAdjustmentReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";

import { ActionTypes } from "./JackpotAdjustmentReportingActions";
import { JackpotAdjustmentReportingActionTypes } from "./JackpotAdjustmentReportingActionTypes";
import { InitPoolAdjustmentReportState, IPoolAdjustmentReportState } from "./JackpotAdjustmentReportingStates";


export const JackpotAdjustmentReportingReducer = (state = InitPoolAdjustmentReportState, action: ActionTypes): IPoolAdjustmentReportState => {
    switch (action.type) {
        case JackpotAdjustmentReportingActionTypes.GET_JACKPOT_ADJUSTMENT_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, adjustmentReportListData: {} as JackpotAdjustmentReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: JackpotAdjustmentReportListModel = {} as JackpotAdjustmentReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, adjustmentReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotAdjustmentReportPDFModel, csvData: {} as JackpotAdjustmentReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: JackpotAdjustmentReportPDFModel = {} as JackpotAdjustmentReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("JackpotAdjusmentReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotAdjustmentReportPDFModel, csvData: {} as JackpotAdjustmentReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: JackpotAdjustmentReportCSVModel = {} as JackpotAdjustmentReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("JackpotAdjusmentReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
