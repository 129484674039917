import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, FormText, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { TableStatus } from "../../../dto/enums/TableStatus";
import { CVT_TO_FLOAT_STRING } from "../../../libraries/FloatHelper";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { AddDenominationRequest, DenominationGetCreditRate, EditDenomination, SelectDenominationRequest } from "../../../states/Denomination/DenominationActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IDenominationMaintenanceForm {
    isEdit: boolean;
    loading: boolean;
    id?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const DenominationMaintenanceForm = (props: IDenominationMaintenanceForm) => {
    const dispatch = useDispatch();
    const denominationState = useSelector((state: IStore) => state.denominationState);
    const { denominationData, creditRate, loading } = denominationState;

    // input & validation
    const [value, setValue] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState(TableStatus.ACTIVE);
    const [valueValid, setValueValid] = useState(props.isEdit);
    const [statusValid, setStatusValid] = useState(true);

    // status dropdown
    const statusOption: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);
    const [isLoaded, setLoaded] = useState(false);

    // save button    
    const save = () => {
        if (valueValid && statusValid) {
            if (props.isEdit) {
                if (props.id) {
                    dispatch(EditDenomination(props.id, value as number, status));
                }
            }
            else {
                dispatch(AddDenominationRequest(value as number));
            }
        }
        else {
            TRIGGER_BLUR("value");
            TRIGGER_BLUR("status");
        }
        props.setSaveTrigger(false);
    }

    useEffect(() => {
        setLoaded(!loading);
    }, [loading])

    // on state changed
    useEffect(() => {
        dispatch(DenominationGetCreditRate());
        if (props.isEdit && props.id) {
            dispatch(SelectDenominationRequest(props.id));
        }
    }, []);

    useEffect(() => {
        if (denominationData.did) {
            setValue(denominationData.dva);
            setStatus(denominationData.stt);
        }
    }, [denominationData])

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    if (isLoaded) {
        return (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <InputBox id="value" name="value" label="Denomination Value (Credit)" placeholder="Denomination Value (Credit)"
                                type={TextboxType.NUMBER} maxlength={10} min={5} integer={true}
                                value={value} inputState={setValue} validState={setValueValid}
                                isRequired={true} isDisabled={props.loading || !props.canEdit}
                            />
                            <FormText>{value ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(value * creditRate.crt / 100) : ""}</FormText>
                        </FormGroup>
                    </Col>
                    <Col>
                        <SelectBox id="status" name="status" label="Status"
                            options={statusOption} value={denominationData.stt ?? TableStatus.ACTIVE}
                            inputState={setStatus} validState={setStatusValid}
                            isRequired={true} isDisabled={true}
                        />
                    </Col>
                </Row>
            </Form>
        );
    } else {
        return (<div className='text-center p-4'><Spinner key='1' /></div>);
    }
}

const DenominationMaintenanceModal = (props: { isEdit: boolean, id?: number, isOpen: boolean, setOpenState: any }) => {
    const denominationState = useSelector((state: IStore) => state.denominationState);
    const { err, suc, loading } = denominationState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.DENOMINATION_MAINTENANCE))
        }

    }, [])

    return (
        <ModalBox
            title={props.isEdit ? "Edit Denomination" : "Add New Denomination"}
            isOpen={props.isOpen}
            child={<DenominationMaintenanceForm isEdit={props.isEdit} canEdit={canEdit} id={props.id} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />}
            type={ModalType.Window}
            openState={props.setOpenState}
            error={err}
            success={suc}
            footer={canEdit ? < Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</ Button> : <></>}
        />
    );
}

export default DenominationMaintenanceModal;