import { ManualSubmitOLRListModel, OLRDetailsModel } from "../../dto/models/ManualOLRModel";

export interface IManualSubmitOLRStates {
    loading: boolean;
    err: string;
    suc: string;
    manualSubmitOLRListData: ManualSubmitOLRListModel;
    OLRDetails: OLRDetailsModel;
}


export const InitManualSubmitOLRState: IManualSubmitOLRStates = {
    loading: false,
    err: "",
    suc: "",
    manualSubmitOLRListData: {} as ManualSubmitOLRListModel,
    OLRDetails: {} as OLRDetailsModel,
}
