import { TableStatus } from "../../dto/enums/TableStatus";
import { InitSlotGameConfigurationModel } from "../../dto/models/ConfigurationModels";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { GameServiceRefreshRequestModel } from "../../dto/models/RefreshServiceModel";
import { InitJackpotServiceModel, SlotGameServiceModel } from "../../dto/models/ServiceModels";
import { ServiceStatusUpdateModel } from "../../dto/models/ServiceStatusUpdateModel";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload } from "../store/IStore";
import { GameServiceActionTypes } from "./GameServiceActionTypes";

type GetGameServiceRequest = { type: GameServiceActionTypes.GET_GAME_SERVICE_REQUEST, payload: IPayload };
type GetGameServiceListRequest = { type: GameServiceActionTypes.GET_GAME_SERVICE_LIST_REQUEST, payload: IPayload };
type SelectGameServiceRequest = { type: GameServiceActionTypes.SELECT_GAME_SERVICE_REQUEST, payload: IPayload };
type EditGameServiceRequest = { type: GameServiceActionTypes.EDIT_GAME_SERVICE_REQUEST, payload: IPayload };
type AddGameServiceRequest = { type: GameServiceActionTypes.ADD_GAME_SERVICE_REQUEST, payload: IPayload };
type GetGameListRequest = { type: GameServiceActionTypes.GET_GAME_LIST_REQUEST, payload: IPayload };
type GetJackpotServiceListRequest = { type: GameServiceActionTypes.GET_JACKPOT_SERVICE_LIST_REQUEST, payload: IPayload };
type SearchGameRequest = { type: GameServiceActionTypes.SEARCH_GAME_REQUEST, payload: IPayload };
type RefreshGameServiceRequest = { type: GameServiceActionTypes.REFRESH_GAME_SERVICE, payload: IPayload };
type UpdateGameServiceStatusRequest = { type: GameServiceActionTypes.UPDATE_GAME_SERVICE_STATUS, payload: IPayload };
type UpdateAllGameServiceStatusRequest = { type: GameServiceActionTypes.UPDATE_ALL_GAME_SERVICE_STATUS, payload: IPayload };

export type ActionTypes = GetGameServiceRequest | GetGameServiceListRequest | SelectGameServiceRequest | EditGameServiceRequest | AddGameServiceRequest |
    GetGameListRequest | GetJackpotServiceListRequest | SearchGameRequest | RefreshGameServiceRequest |
    UpdateGameServiceStatusRequest | UpdateAllGameServiceStatusRequest;

export const GetGameServiceRequest = (from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: take
        };

        PostRequest("BackOffice/GetGameServiceMaintenance", request, GameServiceActionTypes.GET_GAME_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.GET_GAME_SERVICE_REQUEST, dispatch);
    }
}

export const GetGameServiceListRequest = () => async (dispatch: any) => {
    try {
        PostRequest("BackOffice/GetGameServiceList", null, GameServiceActionTypes.GET_GAME_SERVICE_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.GET_GAME_SERVICE_LIST_REQUEST, dispatch);
    }
}

export const SelectGameServiceRequest = (id: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: id
        };

        PostRequest("BackOffice/SelectGameServiceMaintenance", request, GameServiceActionTypes.SELECT_GAME_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.SELECT_GAME_SERVICE_REQUEST, dispatch);
    }
}

export const AddGameServiceRequest = (name: string, lighthouse: string, pylon: string,
    gameId: number, status: TableStatus, desc: string, jackpotServiceId: number) => async (dispatch: any) => {
    try {
        let slot = InitSlotGameConfigurationModel;
        slot.gid = gameId;
        let jackpot = InitJackpotServiceModel;
        jackpot.sid = jackpotServiceId;

        let request: SlotGameServiceModel = {
            sid: 0,
            snm: name,
            lil: lighthouse,
            pyl: pylon,
            stt: status,
            des: desc,
            sgc: slot,
            jps: jackpot
        };

        PostRequest("BackOffice/AddGameServiceMaintenance", request, GameServiceActionTypes.ADD_GAME_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.ADD_GAME_SERVICE_REQUEST, dispatch);
    }
}

export const EditGameServiceRequest = (id: number, name: string, lighthouse: string, pylon: string,
    gameId: number, status: TableStatus, desc: string, jackpotServiceId: number) => async (dispatch: any) => {
    try {
        let slot = InitSlotGameConfigurationModel;
        slot.gid = gameId;
        let jackpot = InitJackpotServiceModel;
        jackpot.sid = jackpotServiceId;

        let request: SlotGameServiceModel = {
            sid: id,
            snm: name,
            lil: lighthouse,
            pyl: pylon,
            stt: status,
            des: desc,
            sgc: slot,
            jps: jackpot
        };

        PostRequest("BackOffice/EditGameServiceMaintenance", request, GameServiceActionTypes.EDIT_GAME_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.EDIT_GAME_SERVICE_REQUEST, dispatch);
    }
}

export const GetGameListRequest = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetGameList", GameServiceActionTypes.GET_GAME_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.GET_GAME_LIST_REQUEST, dispatch);
    }
}

export const SearchGameService = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchGameService", request, GameServiceActionTypes.SEARCH_GAME_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.SEARCH_GAME_REQUEST, dispatch);
    }
}

export const GetJackpotServiceList = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetJackpotServiceList", GameServiceActionTypes.GET_JACKPOT_SERVICE_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.GET_JACKPOT_SERVICE_LIST_REQUEST, dispatch);
    }
}

export const RefreshGameService = (id: number) => async (dispatch: any) => {
    try {
        let request: GameServiceRefreshRequestModel = {
            sid: id
        };
        PostRequest("BackOffice/RefreshGameService", request, GameServiceActionTypes.REFRESH_GAME_SERVICE, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.REFRESH_GAME_SERVICE, dispatch);
    }
}

export const UpdateGameServiceStatus = (id: number, isStop: boolean) => async (dispatch: any) => {
    try {
        let request: ServiceStatusUpdateModel = {
            uid: id,
            stp: isStop,
            uas: false
        };
        PostRequest("BackOffice/UpdateGameServiceStatus", request, GameServiceActionTypes.UPDATE_GAME_SERVICE_STATUS, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.UPDATE_GAME_SERVICE_STATUS, dispatch);
    }
}

export const UpdateAllGameServiceStatus = (isStop: boolean) => async (dispatch: any) => {
    try {
        let request: ServiceStatusUpdateModel = {
            uid: 0,
            stp: isStop,
            uas: true,
        };
        PostRequest("BackOffice/UpdateGameServiceStatus", request, GameServiceActionTypes.UPDATE_ALL_GAME_SERVICE_STATUS, dispatch);
    }
    catch (error) {
        RequestException(error, GameServiceActionTypes.UPDATE_ALL_GAME_SERVICE_STATUS, dispatch);
    }
}