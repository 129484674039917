import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/Sidebar.css";

var _allRoutes: INavigationData[] = [];

export interface INavigationData {
    requiredAccess: number[];
    icon?: IconDefinition;
    title: string;
    path: string;
    searchKeyword: string;
    to: string;
}

export interface IRouteData {
    to: string;
    element: JSX.Element;
}

export function GetAllRoutes() {
    return _allRoutes;
}

export function UpdateRoutes() {
    _allRoutes = [];
}

export function CheckAccess(item: INavigationData, access: number): boolean {
    var hasAccess = false
    item.requiredAccess.forEach((reqAcc) => {
        if ((access & reqAcc) == reqAcc) {
            hasAccess = true
        }
    })

    return hasAccess;
}

export function AccessContained(item: INavigationData[], access: number): number {
    for (var i = 0; i < item.length; i++) {
        if (CheckAccess(item[i], access)) {
            return i;
        }
    }
    return -1;
}

export function IsGroupActive(item: INavigationData[], path: string): boolean {
    const currentNav = item.find(x => x.to == path);
    return currentNav != undefined;
}

export function GetSideBar(navData: INavigationData[],
    mainTitle: string, mainIcon: IconDefinition, mainColor: string,
    isOpen: boolean, access: number, currentPath: string): JSX.Element {
    const defaultPage = AccessContained(navData, access);
    const isActive = IsGroupActive(navData, currentPath);

    if (defaultPage >= 0) {
        if (navData.length == 1) {
            _allRoutes.push(navData[0]);

            return (
                <div>
                    <NavLink
                        key={0}
                        className={isActive ? isOpen ? "sideitem sideitem-active" : "sideitemclose sideitem-active" : isOpen ? "sideitem" : "sideitemclose"}
                        to={navData[0].to}
                    >
                        <div className="link-item">
                            <FontAwesomeIcon color={mainColor} size="xl" icon={mainIcon} fixedWidth />
                            <span className="link-item-font"><b>{isOpen ? " " + mainTitle : ""}</b></span>
                        </div>
                    </NavLink>
                </div>
            );
        }
        else {
            return (
                <div>
                    <NavLink
                        key={0}
                        className={isActive ? isOpen ? "sideitem-child" : "sideitem-child-close" : isOpen ? "sideitem" : "sideitemclose"}
                        to={navData[defaultPage].to}
                    >
                        <div className="link-item">
                            <FontAwesomeIcon color={mainColor} size="xl" icon={mainIcon} fixedWidth />
                            <span className="link-item-font"><b> {isOpen ? " " + mainTitle : ""}</b></span>
                        </div>
                    </NavLink>
                    {
                        navData.map((item, index) => {
                            _allRoutes.push(item);

                            return (
                                isActive && CheckAccess(item, access) ?
                                    <NavLink
                                        key={++index}
                                        className={currentPath == item.to ? isOpen ? "sideitem sideitem-active" : "sideitemclose sideitem-active" : isOpen ? "sideitem-child" : "sideitem-child-close"}
                                        to={item.to}
                                    >
                                        <div className="link-item" style={{ paddingLeft: '8px' }}>
                                            {item.icon ?
                                                <FontAwesomeIcon size="lg" icon={item.icon} fixedWidth />
                                                : undefined
                                            }
                                            <span className="link-item-font">{isOpen ? " " + item.title : ""}</span>
                                        </div>
                                    </NavLink>
                                    : ""
                            )
                        })
                    }
                </div>
            )
        }


    }
    return (<div></div>);
}