import { AccountingServiceListModel, AccountingServiceModel } from "../../dto/models/ServiceModels";

export interface IAccountingStates {
    loading: boolean;
    err: string;
    suc: string;
    accListData: AccountingServiceListModel;
    accData: AccountingServiceModel;
}


export const InitAccountingState: IAccountingStates = {
    loading: false,
    err: "",
    suc: "",
    accListData: {} as AccountingServiceListModel,
    accData: {} as AccountingServiceModel,
}
