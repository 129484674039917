import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Row, Col, FormGroup, Label, Button, Container } from 'reactstrap';
import { TextboxType } from '../../InputBox/IInputBox';
import { DefaultLanguage, GetLanguageName } from '../../../dto/enums/DefaultLanguage'
import InputBox from '../../InputBox/InputBox';
import ModalBox from '../../ModalBox/ModalBox';
import { ModalType } from '../../ModalBox/IModalBox';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../states/store/IStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { TRIGGER_BLUR } from '../../../libraries/Functions';
import TextField from '../../InputBox/TextField';
import { ResolveManualSubmitOLR, SelectManualSubmitOLR } from '../../../states/ManualSubmitOLR/ManualSubmitOLRActions';
import { FORMAT_DATETIME_TO_VIEW } from '../../../libraries/DateHelper';

interface IManualSubmitOLRForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManualSubmitOLRForm = (props: IManualSubmitOLRForm) => {
    const [transactionId, setTransactionId] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [sessionStartTime, setSessionStartTime] = useState("");
    const [sessionEndTime, setSessionEndTime] = useState("");
    const [slotLocation, setSlotLocation] = useState("");
    const [currencyCode, setCurrencyCode] = useState("");
    const [gamingDivision, setGamingDivision] = useState("");
    const [actualWinLossEc, setActualWinLossEc] = useState("");
    const [actualWinLossNn, setActualWinLossNn] = useState("");
    const [playTypeEc, setPlayTypeEc] = useState("");
    const [playTypeNn, setPlayTypeNn] = useState("");
    const [slotTurnoverEc, setSlotTurnoverEc] = useState("");
    const [slotTurnoverNn, setSlotTurnoverNn] = useState("");
    const [resolvedBy, setResolvedBy] = useState("");
    const [userID, setUserID] = useState(0);
    const [remarks, setRemarks] = useState("");
    const [remarksValid, setRemarksValid] = useState(props.isEdit);

    const dispatch = useDispatch();
    const manualSubmitOLRState = useSelector((state: IStore) => state.manualSubmitOLRState);
    const { OLRDetails, err, suc } = manualSubmitOLRState;
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;

    useEffect(() => {
        if (props.editID) {
            dispatch(SelectManualSubmitOLR(props.editID));
        }

        ClearFields();

    }, [props.isEdit]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (OLRDetails.cid) {
            setTransactionId(OLRDetails.txid);
            setCustomerId(OLRDetails.cid);
            setSessionStartTime(FORMAT_DATETIME_TO_VIEW(new Date(OLRDetails.sst), "yyyy-MM-dd hh:mm:ssa"));
            setSessionEndTime(FORMAT_DATETIME_TO_VIEW(new Date(OLRDetails.set), "yyyy-MM-dd hh:mm:ssa"));
            setSlotLocation(OLRDetails.sloc);
            setCurrencyCode(OLRDetails.curc);
            setGamingDivision(OLRDetails.gamd);
            setActualWinLossEc(OLRDetails.awlec);
            setActualWinLossNn(OLRDetails.awlnn);
            setPlayTypeEc(OLRDetails.ptec);
            setPlayTypeNn(OLRDetails.ptnn);
            setSlotTurnoverEc(OLRDetails.stec);
            setSlotTurnoverNn(OLRDetails.stnn);

            if (!props.isEdit) {
                setRemarks(OLRDetails.rmk);
                setResolvedBy(OLRDetails.rsbyn);
            }
            else {
                setUserID(userProfileData.uid);
                setResolvedBy(userProfileData.fnm);
            }
        }
    }, [OLRDetails]);

    const ClearFields = () => {
        setRemarks("");
    }

    const Valid = (): boolean => {
        return remarksValid
    }

    const save = () => {
        if (Valid() && remarks) {
            if (props.editID) {
                dispatch(
                    ResolveManualSubmitOLR(
                        props.editID,
                        userID,
                        resolvedBy,
                        remarks
                    ));
            }
        } else {
            TRIGGER_BLUR("olrRemarks");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrTransactionID">
                                        Transaction ID
                                    </Label>
                                    <InputBox
                                        id="olrTransactionID"
                                        name="olrTransactionID"
                                        type={TextboxType.TEXT}
                                        value={transactionId}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrCustomerID">
                                        Customer ID
                                    </Label>
                                    <InputBox
                                        id="olrCustomerID"
                                        name="olrCustomerID"
                                        type={TextboxType.TEXT}
                                        value={customerId}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrSessionStartTime">
                                        Session Start Time
                                    </Label>
                                    <InputBox
                                        id="olrSessionStartTime"
                                        name="olrSessionStartTime"
                                        type={TextboxType.TEXT}
                                        value={sessionStartTime}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrSessionEndTime">
                                        Session End Time
                                    </Label>
                                    <InputBox
                                        id="olrSessionEndTime"
                                        name="olrSessionEndTime"
                                        type={TextboxType.TEXT}
                                        value={sessionEndTime}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrSlotLocation">
                                        Slot Location
                                    </Label>
                                    <InputBox
                                        id="olrSlotLocation"
                                        name="olrSlotLocation"
                                        type={TextboxType.TEXT}
                                        value={slotLocation}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrCurrencyCode">
                                        Currency Code
                                    </Label>
                                    <InputBox
                                        id="olrCurrencyCode"
                                        name="olrCurrencyCode"
                                        type={TextboxType.TEXT}
                                        value={currencyCode}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrGamingDivision">
                                        Gaming Division
                                    </Label>
                                    <InputBox
                                        id="olrGamingDivision"
                                        name="olrGamingDivision"
                                        type={TextboxType.TEXT}
                                        value={gamingDivision}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <FormGroup>
                                    <Label>EC</Label>
                                    <FormGroup>
                                        <Label for="olrActualWinLossEc">
                                            Actual Win
                                        </Label>
                                        <InputBox
                                            id="olrActualWinLossEc"
                                            name="olrActualWinLossEc"
                                            type={TextboxType.TEXT}
                                            value={actualWinLossEc}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="olrPlayTypeEc">
                                            Play Type
                                        </Label>
                                        <InputBox
                                            id="olrPlayTypeEc"
                                            name="olrPlayTypeEc"
                                            type={TextboxType.TEXT}
                                            value={playTypeEc}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="olrSlotTurnoverEc">
                                            Slot Turnover
                                        </Label>
                                        <InputBox
                                            id="olrSlotTurnoverEc"
                                            name="olrSlotTurnoverEc"
                                            type={TextboxType.TEXT}
                                            value={slotTurnoverEc}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>NN</Label>
                                    <FormGroup>
                                        <Label for="olrActualWinLossNn">
                                            Actual Win
                                        </Label>
                                        <InputBox
                                            id="olrActualWinLossNn"
                                            name="olrActualWinLossNn"
                                            type={TextboxType.TEXT}
                                            value={actualWinLossNn}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="olrPlayTypeNn">
                                            Play Type
                                        </Label>
                                        <InputBox
                                            id="olrPlayTypeNn"
                                            name="olrPlayTypeNn"
                                            type={TextboxType.TEXT}
                                            value={playTypeNn}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="olrSlotTurnoverNn">
                                            Slot Turnover
                                        </Label>
                                        <InputBox
                                            id="olrSlotTurnoverNn"
                                            name="olrSlotTurnoverNn"
                                            type={TextboxType.TEXT}
                                            value={slotTurnoverNn}
                                            isDisabled={true}
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="olrResolvedBy">
                                        Resolved By
                                    </Label>
                                    <InputBox
                                        id="olrResolvedBy"
                                        name="olrResolvedBy"
                                        type={TextboxType.TEXT}
                                        value={resolvedBy}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <TextField id="olrRemarks" name="olrRemarks" label="Remarks"
                                placeholder="Remarks" value={remarks}
                                validState={setRemarksValid}
                                isRequired={true}
                                inputState={setRemarks} maxLength={300} col={5} row={5}
                                isDisabled={suc != "" || !props.isEdit}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

const ManualSubmitOLRModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const manualSubmitOLRState = useSelector((state: IStore) => state.manualSubmitOLRState);
    const { err, suc, loading } = manualSubmitOLRState;
    const [save, setSave] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    return (
        <div>
            <ModalBox
                title={"Manual Submit OLR"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <ManualSubmitOLRForm isEdit={props.isEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={props.isEdit ? <Button color="info" onClick={() => setSave(true)} outline disabled={suc != ""}><FontAwesomeIcon icon={faFloppyDisk} /><span className="m-2" >Submit</span></Button> : <></>}
            />
        </div>
    );
}

export default ManualSubmitOLRModal;

