export interface IDropdownButton {
    disable: boolean,
    direction: DropdownDirection,
    dropdownItems: JSX.Element[]
    dropdownButtonStyle: DropDownButtonStyle,
    isOutline: boolean
    className?: string,
    dropdownTitle?: any
    color?: string,

}

export enum DropdownDirection {
    UP = 'up',
    DOWN = 'down',
    START = 'start',
    END = 'end',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum DropDownButtonStyle {
    ROUNDED,
    ROUNDED_WITHOUT_CARET,
    NORMAL,
}