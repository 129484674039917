import { GameResultDetailCSVReportModel, GameResultDetailPDFReportModel, GameResultReportCSVModel, GameResultReportListModel, GameResultReportModel, GameResultReportPDFModel, GameRoomModel } from "../../../dto/models/Reporting/GameResultReportModel";


export interface IGameResultReportState {
    loading: boolean;
    err: string;
    suc: string;
    gameResultReportListData: GameResultReportListModel;
    pdfData: GameResultReportPDFModel;
    csvData: GameResultReportCSVModel;
    gameDetailData: GameResultReportModel;
    pdfDetailData: GameResultDetailPDFReportModel;
    csvDetailData: GameResultDetailCSVReportModel;
    gameRoomData: GameRoomModel;
}

export const InitGameResultReportState: IGameResultReportState = {
    loading: false,
    err: "",
    suc: "",
    gameResultReportListData: {} as GameResultReportListModel,
    pdfData: {} as GameResultReportPDFModel,
    csvData: {} as GameResultReportCSVModel,
    gameDetailData: {} as GameResultReportModel,
    pdfDetailData: {} as GameResultDetailPDFReportModel,
    csvDetailData: {} as GameResultDetailCSVReportModel,
    gameRoomData: {} as GameRoomModel
}