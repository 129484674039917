import { WalletType } from "../../dto/enums/WalletType";
import { CreditTransferRequestModel, PlayerWalletRequestModel } from "../../dto/models/CreditTransactionModels";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { CreditTransactionActionTypes } from "./CreditTransactionActionTypes";

type TransferInRequest = { type: CreditTransactionActionTypes.TRANSFER_IN_REQUEST, payload: IPayload };
type TransferOutRequest = { type: CreditTransactionActionTypes.TRANSFER_OUT_REQUEST, payload: IPayload };
type CashOutRequest = { type: CreditTransactionActionTypes.CASH_OUT_REQUEST, payload: IPayload };
type CashInRequest = { type: CreditTransactionActionTypes.CASH_IN_REQUEST, payload: IPayload };
type GetPlayerWalletRequest = { type: CreditTransactionActionTypes.GET_PLAYER_WALLET_REQUEST, payload: IPayload };

export type ActionTypes =
    TransferInRequest |
    TransferOutRequest |
    CashOutRequest |
    CashInRequest |
    GetPlayerWalletRequest;

export const TransferIn = (user: string, amount: number, wallet: WalletType, remarks: string) => async (dispatch: any) => {
    try {
        let request: CreditTransferRequestModel = {
            usr: user,
            amt: amount,
            wltt: wallet,
            rmk: remarks
        };
        PostRequest("Cashier/TransferIn", request, CreditTransactionActionTypes.TRANSFER_IN_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionActionTypes.TRANSFER_IN_REQUEST, dispatch);
    }
}

export const TransferOut = (user: string, amount: number, wallet: WalletType, remarks: string) => async (dispatch: any) => {
    try {
        let request: CreditTransferRequestModel = {
            usr: user,
            amt: amount,
            wltt: wallet,
            rmk: remarks
        };
        PostRequest("Cashier/TransferOut", request, CreditTransactionActionTypes.TRANSFER_OUT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionActionTypes.TRANSFER_OUT_REQUEST, dispatch);
    }
}

export const CashOut = (user: string, amount: number, wallet: WalletType, remarks: string) => async (dispatch: any) => {
    try {
        let request: CreditTransferRequestModel = {
            usr: user,
            amt: amount,
            wltt: wallet,
            rmk: remarks
        };
        PostRequest("Cashier/CashOut", request, CreditTransactionActionTypes.CASH_OUT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionActionTypes.CASH_OUT_REQUEST, dispatch);
    }
}

export const CashIn = (user: string, amount: number, wallet: WalletType, remarks: string) => async (dispatch: any) => {
    try {
        let request: CreditTransferRequestModel = {
            usr: user,
            amt: amount,
            wltt: wallet,
            rmk: remarks
        };
        PostRequest("Cashier/CashIn", request, CreditTransactionActionTypes.CASH_IN_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionActionTypes.CASH_IN_REQUEST, dispatch);
    }
}

export const GetPlayerWallet = (user: string) => async (dispatch: any) => {
    try {
        let request: PlayerWalletRequestModel = {
            usr: user
        };
        PostRequest("Cashier/GetPlayerWallet", request, CreditTransactionActionTypes.GET_PLAYER_WALLET_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionActionTypes.GET_PLAYER_WALLET_REQUEST, dispatch);
    }
}