import { PylonResponseModel } from '../../dto/models/PylonResponseModel';
import { IRoleState } from '../AccessMatrix/AccessMatrixStates';
import { IAccountingStates } from '../Accounting/AccountingStates';
import { IBigWinPendingState } from '../BigWinPending/BigWinPendingStates';
import { ICmsState } from '../Cms/CmsStates';
import { ICreditTransactionState } from '../CreditTransaction/CreditTransactionStates';
import { IDashboardState } from '../Dashboard/DashboardStates';
import { IDenominationState } from '../Denomination/DenominationStates';
import { IGameState } from '../Game/GameStates';
import { IGameServiceState } from '../GameService/GameServiceStates';
import { IGamingAreaState } from '../GamingArea/GamingAreaStates';
import { IManualCreditTransferStates } from '../ManualCreditTransfer/ManualCreditTransferStates';
import { IManualSubmitOLRStates } from '../ManualSubmitOLR/ManualSubmitOLRStates';
import { INotificationState } from '../Notification/NotificationStates';
import { IAccumulatorState } from '../PAS/JackpotAccumulator/AccumulatorStates';
import { IJackpotApprovalState } from '../PAS/JackpotApproval/JackpotApprovalStates';
import { IApprovalTierState } from '../PAS/JackpotApprovalTier/ApprovalTierStates';
import { IPoolState } from '../PAS/JackpotPool/PoolStates';
import { IPoolAdjustmentState } from '../PAS/JackpotPoolAdjustment/PoolAdjustmentStates';
import { IJackpotServiceStates } from '../PAS/JackpotService/JackpotServiceStates';
import { ITierState } from '../PAS/JackpotTier/TierStates';
import { IPlayerLockStates } from '../PlayerLock/PlayerLockStates';
import { IPylonAuthenticationState } from '../PylonAuthentication/PylonAuthenticationStates';
import { IAuditReportState } from '../Reporting/AuditReporting/AuditReportingStates';
import { ICreditTransactionReportState } from '../Reporting/CreditTransactionReport/CreditTransactionReportingStates';
import { IGambleWinLoseReportState } from '../Reporting/GambleWinLoseReport/GambleWinLoseReportingStates';
import { IGameResultReportState } from '../Reporting/GameResultReport/GameResultReportingStates';
import { IGameWinLoseReportState } from '../Reporting/GameWinLoseReport/GameWinLoseReportingStates';
import { IPoolAdjustmentReportState } from '../Reporting/JackpotAdjustmentReport/JackpotAdjustmentReportingStates';
import { IJackpotBigWinApprovalReportState } from '../Reporting/JackpotBigWinApprovalReport/JackpotBigWinApprovalReportingStates';
import { IPoolContributionReportState } from '../Reporting/JackpotContributionReport/JackpotContributionReportingStates';
import { IPoolStrikeReportState } from '../Reporting/JackpotStrikeReport/JackpotStrikeReportingStates';
import { IManualCreditTransactionReportState } from '../Reporting/ManualCreditTransactionReport/ManualCreditTransactionReportingStates';
import { IManualSubmitOLRReportState } from '../Reporting/ManualSubmitOLRReport/ManualSubmitOLRReportingStates';
import { IOLRReportState } from '../Reporting/OLRReport/OLRReportingStates';
import { IPlayerLeftOverBalanceReportState } from '../Reporting/PlayerLeftOverBalanceReport/PlayerLeftOverBalanceReportStates';
import { IPlayerLockReportState } from '../Reporting/PlayerLockReport/PlayerLockReportStates';
import { IPlayerPerformanceReportState } from '../Reporting/PlayerPerformanceReport/PlayerPerformanceReportingStates';
import { IUserListingReportState } from '../Reporting/UserListingReport/UserListingReportingStates';
import { ISystemState } from '../System/SystemStates';
import { ITradingDateState } from '../TradingDate/TradingDateStates';
import { IUserState } from '../User/UserStates';

export interface IStore {
    userState: IUserState
    systemState: ISystemState
    tradingDateState: ITradingDateState
    cmsState: ICmsState
    gameState: IGameState
    accountingServiceState: IAccountingStates
    gameServiceState: IGameServiceState
    denominationState: IDenominationState
    accessMatrixState: IRoleState
    accumulatorState: IAccumulatorState
    poolState: IPoolState
    tierState: ITierState
    approvalTierState: IApprovalTierState
    poolAdjustmentState: IPoolAdjustmentState
    jackpotServiceState: IJackpotServiceStates
    creditTransactionState: ICreditTransactionState
    manualSubmitOLRState: IManualSubmitOLRStates
    manualCreditTransferState: IManualCreditTransferStates
    playerLockState: IPlayerLockStates
    pylonAuthenticationState: IPylonAuthenticationState
    gamingAreaState: IGamingAreaState
    notificationState: INotificationState
    bigWinPendingState: IBigWinPendingState
    jackpotApprovalState: IJackpotApprovalState
    dashboardState: IDashboardState

    // Report
    poolAdjustmentReportState: IPoolAdjustmentReportState
    poolContributionReportState: IPoolContributionReportState
    poolStrikeReportState: IPoolStrikeReportState
    userListingReportState: IUserListingReportState
    creditTransactionReportState: ICreditTransactionReportState,
    manualCreditTransactionReportState: IManualCreditTransactionReportState,
    manualSubmitOLRReportState: IManualSubmitOLRReportState,
    olrReportState: IOLRReportState,
    playerLeftOverBalanceReportState: IPlayerLeftOverBalanceReportState,
    auditReportState: IAuditReportState,
    playerPerformanceReportState: IPlayerPerformanceReportState
    gameWinLoseReportState: IGameWinLoseReportState,
    gambleWinLoseReportState: IGambleWinLoseReportState,
    jackpotBigWinApprovalReportState: IJackpotBigWinApprovalReportState,
    gameResultReportState: IGameResultReportState,
    playerLockReportState: IPlayerLockReportState,


        // TODO: (MAV) check is this needed
    //notificationState: INotificationState
}

export interface IPayload {
    data: PylonResponseModel;
    err: string | false;
    loading: boolean;
}

export const LoadingPayload: IPayload = {
    data: {} as PylonResponseModel,
    err: false,
    loading: true
}