import React from "react";
import "../styles/Loader.css";

const DotsLoader = () => {
    return (
        <div className="dots-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default DotsLoader;