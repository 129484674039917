import { TableStatus } from "../enums/TableStatus";
import { CmsConfigurationModel, SlotGameConfigurationModel } from "./ConfigurationModels";
import { ResponseListModel } from "./GeneralModels";
import { AccumulatorModel } from "./JackpotModels";

export interface ServiceModel {
    /** Service id */
    sid: number;
    /** Service name */
    snm: string;
    /** Lighthouse request address */
    lil: string;
    /** Pylon request address */
    pyl: string;
    /** Description */
    des: string;
    /** Status */
    stt: TableStatus;
    /** Update at */
    udt?: Date;
}

export interface ConfigurationRequestModel {
    /** MAC address */
    mac: string;
}

export interface AccountingServiceModel extends ServiceModel {
    /** Cms binded */
    cms: CmsConfigurationModel;
}

export interface AccountingServiceListModel extends ResponseListModel {
    /** Accounting service list */
    acl: AccountingServiceModel[];
}

export interface JackpotServiceModel extends ServiceModel {
    /** Accumulator binded */
    jam: AccumulatorModel;
}

export interface JackpotServiceListModel extends ResponseListModel {
    /** Jackpot service list */
    jpl: JackpotServiceModel[];
}

export interface SlotGameServiceModel extends ServiceModel {
    /** Slot game configuration */
    sgc: SlotGameConfigurationModel;
    /** Jackpot service configuration*/
    jps: JackpotServiceModel;
}

export interface SlotGameServiceListModel extends ResponseListModel {
    /** Slot service list */
    sgl: SlotGameServiceModel[];
}

export const InitJackpotServiceModel: JackpotServiceModel = {
    sid: 0,
    snm: "",
    stt: TableStatus.ACTIVE,
    des: "",
    pyl: "",
    lil: "",
    jam: {
        aid: 0,
        apl: [],
        des: "",
        jan: "",
        thc: 0,
        cat: 0,
    }
};