import { faChartLine, faChartSimple, faCheckToSlot, faCircleDollarToSlot, faCircleExclamation, faCoins, faFileInvoiceDollar, faFileLines, faLock, faMagnifyingGlass, faMoneyCheckDollar, faRightLeft, faSliders, faSquarePollHorizontal, faStar, faTableList, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import CreditTransactionReporting from "../containers/Reporting/CreditTransactionReporting";
import JackpotAdjustmentReporting from "../containers/Reporting/JackpotAdjustmentReporting";
import JackpotContributionDetailReporting from "../containers/Reporting/JackpotContributionDetailReporting";
import JackpotContributionSummaryReporting from "../containers/Reporting/JackpotContributionSummaryReporting";
import JackpotStrikeReporting from "../containers/Reporting/JackpotStrikeDetailReporting";
import JackpotStrikeSummaryReporting from "../containers/Reporting/JackpotStrikeSummaryReporting";
import ManualCreditTransactionReporting from "../containers/Reporting/ManualCreditTransferReporting";
import ManualSubmitOLRReporting from "../containers/Reporting/ManualSubmitOLRReporting";
import OLRReporting from "../containers/Reporting/OLRReporting";
import PlayerLeftOverBalanceReporting from "../containers/Reporting/PlayerLeftOverBalanceReporting";
import UserListingReporting from "../containers/Reporting/UserListingReporting";
import AuditReporting from "../containers/Reporting/AuditReporting";
import PlayerPerformanceReporting from "../containers/Reporting/PlayerPerformanceReporting";
import JackpotBigWinApprovalReporting from "../containers/Reporting/JackpotBigWinApprovalReporting";
import { ReportingAccess } from "../dto/enums/AccessMatrix/ReportingAccess";
import { GetSideBar, INavigationData, IRouteData } from "./navData";
import GameWinLoseReporting from "../containers/Reporting/GameWinLoseReporting";
import GambleWinLoseReporting from "../containers/Reporting/GambleWinLoseReporting";
import GameResultReporting from "../containers/Reporting/GameResultReporting";
import PlayerLockReporting from "../containers/Reporting/PlayerLockReporting";

const DailySalesRPT_URL = "/DailySalesRPT";
const WinLoseRPT_URL = "/WinLoseRPT";
const GambleWinLoseRPT_URL = "/GambleWinLoseRPT";
const PlayerPerformanceRPT_URL = "/PlayerPerformanceRPT";
const SignificantEventRPT_URL = "/SignificantEventRPT";
const AuditRPT_URL = "/AuditRPT";
const UserListingRPT_URL = "/UserListingRPT";
const BrutalPreventionRPT_URL = "/BrutalPreventionRPT";
const GameResultRPT_URL = "/GameResultRPT";

const CreditTransactionRPT_URL = "/CreditTransactionRPT";
const ManualCreditTransactionRPT_URL = "/ManualCreditTransactionRPT";
const ManualSubmitOLRRPT_URL = "/ManualSubmitOLRRPT";
const OLRRPT_URL = "/OLRRPT";
const PlayerLeftOverBalanceRPT_URL = "/PlayerLeftOverBalanceRPT";
const PlayerLockRPT_URL = "/PlayerLockRPT";
const JackpotPoolAdjustmentRPT_URL = "/JackpotPoolAdjustmentRPT";
const JackpotSummaryStrikeRPT_URL = "/JackpotSummaryStrikeRPT";
const JackpotDetailStrikeRPT_URL = "/JackpotStrikeRPT";
const JackpotSummaryContributionRPT_URL = "/JackpotSummaryContributionRPT";
const JackpotDetailContributionRPT_URL = "/JackpotContributionRPT";
const JackpotBigWinApprovalRPT_URL = "/JackpotBigWinApprovalRPT";

export const ReportingRoutesData: IRouteData[] = [
    {
        to: UserListingRPT_URL,
        element: <UserListingReporting />
    },
    {
        to: JackpotPoolAdjustmentRPT_URL,
        element: <JackpotAdjustmentReporting />
    },
    {
        to: JackpotSummaryContributionRPT_URL,
        element: <JackpotContributionSummaryReporting />
    },
    {
        to: JackpotDetailContributionRPT_URL,
        element: <JackpotContributionDetailReporting />
    },
    {
        to: JackpotSummaryStrikeRPT_URL,
        element: <JackpotStrikeSummaryReporting />
    },
    {
        to: JackpotDetailStrikeRPT_URL,
        element: <JackpotStrikeReporting />
    },
    {
        to: CreditTransactionRPT_URL,
        element: <CreditTransactionReporting />
    },
    {
        to: ManualCreditTransactionRPT_URL,
        element: <ManualCreditTransactionReporting />
    },
    {
        to: ManualSubmitOLRRPT_URL,
        element: <ManualSubmitOLRReporting />
    },
    {
        to: OLRRPT_URL,
        element: <OLRReporting />
    },
    {
        to: PlayerLeftOverBalanceRPT_URL,
        element: <PlayerLeftOverBalanceReporting />
    },
    {
        to: AuditRPT_URL,
        element: <AuditReporting />
    },
    {
        to: PlayerPerformanceRPT_URL,
        element: <PlayerPerformanceReporting />
    },
    {
        to: WinLoseRPT_URL,
        element: <GameWinLoseReporting />
    },
    {
        to: GambleWinLoseRPT_URL,
        element: <GambleWinLoseReporting />
    },
    {
        to: JackpotBigWinApprovalRPT_URL,
        element: <JackpotBigWinApprovalReporting />
    },
    {
        to: GameResultRPT_URL,
        element: <GameResultReporting />
    },
    {
        to: PlayerLockRPT_URL,
        element: <PlayerLockReporting />
    },
];

export const ReportingNavigationData: INavigationData[] = [
    {
        requiredAccess: [ReportingAccess.PLAYER_PERFORMANCE_REPORT],
        icon: faChartLine,
        title: "Player Performance",
        path: "Reporting / Player Performance",
        searchKeyword: "Player Performance Report",
        to: PlayerPerformanceRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.PLAYER_LOCK_REPORT],
        icon: faLock,
        title: "Player Lock / Unlock",
        path: "Reporting / Player Lock or Unlock",
        searchKeyword: "Player Lock Report",
        to: PlayerLockRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.PLAYER_RATING_SUBMISSION_REPORT],
        icon: faStar,
        title: "Player Rating Submission",
        path: "Reporting / Player Rating Submission",
        searchKeyword: "Player Rating Submission Report",
        to: OLRRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.PLAYER_RATING_SUBMISSION_REPORT],
        icon: faStar,
        title: "Manual Player Rating Submission",
        path: "Reporting / Manual Player Rating Submission",
        searchKeyword: "Manual Player Rating Submission Report",
        to: ManualSubmitOLRRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.PLAYER_LEFT_OVER_BALANCE_REPORT],
        icon: faCoins,
        title: "Player Left Over Balance",
        path: "Reporting / Player Left Over Balance",
        searchKeyword: "Player Left Over Balance Report",
        to: PlayerLeftOverBalanceRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.CREDIT_TRANSACTION_REPORT],
        icon: faRightLeft,
        title: "Credit Transaction",
        path: "Reporting / Credit Transaction",
        searchKeyword: "Credit Transaction Report",
        to: CreditTransactionRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.CREDIT_TRANSACTION_REPORT],
        icon: faRightLeft,
        title: "Manual Credit Transaction",
        path: "Reporting / Manual Credit Transaction",
        searchKeyword: "Manual Credit Transaction Report",
        to: ManualCreditTransactionRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.GAME_RESULT_REPORT],
        icon: faSquarePollHorizontal,
        title: "Game Result",
        path: "Reporting / Game Result",
        searchKeyword: "Game Result Report",
        to: GameResultRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.WIN_LOSE_REPORT],
        icon: faChartSimple,
        title: "Win Lose",
        path: "Reporting / Win Lose",
        searchKeyword: "Win Lose Report",
        to: WinLoseRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.WIN_LOSE_REPORT],
        icon: faChartSimple,
        title: "Gamble Win Lose",
        path: "Reporting / Gamble Win Lose",
        searchKeyword: "Gamble Win Lose Report",
        to: GambleWinLoseRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.JACKPOT_POOL_ADJUSTMENT_REPORT],
        icon: faSliders,
        title: "Jackpot Pool Adjustment",
        path: "Reporting / Jackpot Pool Adjustment",
        searchKeyword: "Jackpot Pool Adjustment Report",
        to: JackpotPoolAdjustmentRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.JACKPOT_STRIKE_REPORT],
        icon: faCircleDollarToSlot,
        title: "Jackpot Detail Strike",
        path: "Reporting / Jackpot Detail Strike",
        searchKeyword: "Jackpot Detail Strike Report",
        to: JackpotDetailStrikeRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.JACKPOT_STRIKE_REPORT],
        icon: faCircleDollarToSlot,
        title: "Jackpot Summary Strike",
        path: "Reporting / Jackpot Summary Strike",
        searchKeyword: "Jackpot Summary Strike Report",
        to: JackpotSummaryStrikeRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.JACKPOT_CONTRIBUTION_REPORT],
        icon: faMoneyCheckDollar,
        title: "Jackpot Detail Contribution",
        path: "Reporting / Jackpot Detail Contribution",
        searchKeyword: "Jackpot Detail Contribution Report",
        to: JackpotDetailContributionRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.JACKPOT_CONTRIBUTION_REPORT],
        icon: faMoneyCheckDollar,
        title: "Jackpot Summary Contribution",
        path: "Reporting / Jackpot Summary Contribution",
        searchKeyword: "Jackpot Summary Contribution Report",
        to: JackpotSummaryContributionRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.JACKPOT_BIG_WIN_APPROVAL_REPORT],
        icon: faCheckToSlot,
        title: "Jackpot / Big Win Approval",
        path: "Reporting / Jackpot or Big Win Approval",
        searchKeyword: "Jackpot / Big Win Approval Report",
        to: JackpotBigWinApprovalRPT_URL
    }
    ,
    {
        requiredAccess: [ReportingAccess.AUDIT_REPORT],
        icon: faMagnifyingGlass,
        title: "Audit",
        path: "Reporting / Audit",
        searchKeyword: "Audit",
        to: AuditRPT_URL
    },
    {
        requiredAccess: [ReportingAccess.USER_LISTING_REPORT],
        icon: faTableList,
        title: "User Listing",
        path: "Reporting / User Listing",
        searchKeyword: "User Listing",
        to: UserListingRPT_URL
    },
/*    {
        requiredAccess: [ReportingAccess.SIGNIFICANT_EVENT_REPORT],
        icon: faCircleExclamation,
        title: "Significant Event",
        to: SignificantEventRPT_URL
    },*/
/*    {
        requiredAccess: [ReportingAccess.BRUTAL_PREVENTION_REPORT],
        icon: faTriangleExclamation,
        title: "Brutal Prevention",
        to: BrutalPreventionRPT_URL
    },*/
];

export function ReportingSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(ReportingNavigationData, "Reporting", faFileLines, "#5296ab", open, access, currentPath);
}