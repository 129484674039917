import React, { useEffect, useState } from 'react';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IModalBox, ModalType } from './IModalBox';
import '../../styles/Modal.css'
import AlertMessage from '../AlertMessage/AlertMessage';
import { AlertSize, AlertType } from '../AlertMessage/IAlert';

function ModalBox(prop: IModalBox) {
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (!isAlertOpen && alertMessage != "") {
            setAlertMessage("");
        }
        else {
            if (prop.error != undefined && prop.error != "") {
                setAlertMessage(prop.error);
                setAlertOpen(true);
            }
            else if (prop.success != undefined && prop.success != "") {
                setAlertMessage(prop.success);
                setAlertOpen(true);
            }
            else if (prop.warning != undefined && prop.warning != "") {
                setAlertMessage(prop.warning);
                setAlertOpen(true);
            }
        }
    }, [prop]);

    const Close = () => { prop.openState(false) };
    const WindowsSize = prop.type == ModalType.Dialog ? "lg" : (prop.type == ModalType.Window ? "xl" : "lg");

    const disableBtn = (
        <div></div>
    );

    return (
        <div>
            {prop.hasCancelBtn ?
                <Modal isOpen={prop.isOpen} fade={prop.isFade} centered={prop.isCentered || prop.type == ModalType.Dialog} scrollable={prop.isScrollable} size={WindowsSize} backdrop="static">
                    <ModalHeader close={prop.disableCloseButton ? disableBtn : undefined}>{prop.headerChild}</ModalHeader>
                    <AlertMessage
                        alertSize={AlertSize.LONG}
                        content={alertMessage}
                        alertType={AlertType.FAIL}
                        isOpen={isAlertOpen && prop.error != undefined && prop.error != ""}
                        openState={setAlertOpen}
                    />
                    <AlertMessage
                        alertSize={AlertSize.LONG}
                        content={alertMessage}
                        alertType={AlertType.SUCCESS}
                        isOpen={isAlertOpen && prop.success != undefined && prop.success != ""}
                        openState={setAlertOpen}
                    />
                    <AlertMessage
                        alertSize={AlertSize.LONG}
                        content={alertMessage}
                        alertType={AlertType.WARNING}
                        isOpen={isAlertOpen && prop.warning != undefined && prop.warning != ""}
                        openState={setAlertOpen}
                    />
                    <ModalBody className={prop.type == ModalType.Dialog ? "dialogBody" : "windowBody"}>
                        {prop.child}
                    </ModalBody>
                    <ModalFooter>
                        {prop.footer}
                    </ModalFooter>
                </Modal>
                :
                <Modal isOpen={prop.isOpen} toggle={Close} fade={prop.isFade} centered={prop.isCentered || prop.type == ModalType.Dialog} scrollable={prop.isScrollable} size={WindowsSize} backdrop="static">
                    <ModalHeader close={prop.disableCloseButton ? disableBtn : undefined} toggle={Close}>{prop.headerChild}</ModalHeader>
                    <AlertMessage
                        alertSize={AlertSize.LONG}
                        content={alertMessage}
                        alertType={AlertType.FAIL}
                        isOpen={isAlertOpen && prop.error != undefined && prop.error != ""}
                        openState={setAlertOpen}
                    />
                    <AlertMessage
                        alertSize={AlertSize.LONG}
                        content={alertMessage}
                        alertType={AlertType.SUCCESS}
                        isOpen={isAlertOpen && prop.success != undefined && prop.success != ""}
                        openState={setAlertOpen}
                    />
                    <AlertMessage
                        alertSize={AlertSize.LONG}
                        content={alertMessage}
                        alertType={AlertType.WARNING}
                        isOpen={isAlertOpen && prop.warning != undefined && prop.warning != ""}
                        openState={setAlertOpen}
                    />
                    <ModalBody className={prop.type == ModalType.Dialog ? "dialogBody" : "windowBody"}>

                        {prop.child}
                    </ModalBody>
                    <ModalFooter>
                        {prop.footer}
                    </ModalFooter>
                </Modal>
            }
        </div>
    );
}

export default ModalBox;