import { faArrowsRotate, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import AccountingModal from '../../components/_modals/CMS/AccountingModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { TableStatus } from '../../dto/enums/TableStatus';
import { FORMAT_DATETIME_TO_VIEW } from '../../libraries/DateHelper';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetAccounting, RefreshAccounting, SearchAccounting } from '../../states/Accounting/AccountingActions';
import { IStore } from '../../states/store/IStore';

const AccountingMaintenance = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const prevIsSearch = useRef(false);
    const accountingServiceState = useSelector((state: IStore) => state.accountingServiceState);
    const { loading, err, suc, accListData } = accountingServiceState;

    const emptyTable: IDataTable = {
        title: ["No", "Name", "CMS", "External Gateway Listener", "Internal Gateway Listener", "Status", "View", "Refresh"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [accTable, setAccTable] = useState(emptyTable)
    const [isSearch, setSearch] = useState(false);


    useEffect(() => {
        if (!openModal) {
            dispatch(GetAccounting(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchAccounting(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetAccounting(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddAccounting = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditAccounting = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    const RefreshAccountingService = (id: number, cmsType: number): void => {
        var refreshButton = document.getElementById(id + "RefreshButton")
        if (refreshButton) {
            (refreshButton as HTMLButtonElement).disabled = true
        }

        var refreshButtonIcon = document.getElementById(id + "RefreshButtonIcon")
        if (refreshButtonIcon) {
            (refreshButtonIcon as HTMLButtonElement).classList.add("rotate")
        }

        dispatch(RefreshAccounting(cmsType))
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (accListData.acl && accListData.acl.length > 0) {

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = accListData.acl.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.snm,
                    item.cms.dom,
                    item.lil,
                    item.pyl,
                    TableStatus[item.stt],
                    <Button onClick={() => EditAccounting(item.sid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>,
                    <Button id={item.sid + "RefreshButton"} name="refresh-button" onClick={() => { RefreshAccountingService(item.sid, item.cms.typ) }} color="primary" disabled={loading} > <FontAwesomeIcon id={item.sid + "RefreshButtonIcon"} name="refresh-button-icon" icon={faArrowsRotate} /></ Button >
                ];
            });
        }
        else {
            table.data = [];
        }

        setAccTable(table)
    }, [accListData])

    useEffect(() => {
        if (!loading) {
            var refreshButtons = document.getElementsByName("refresh-button")
            for (let i = 0; i < refreshButtons.length; i++) {
                (refreshButtons[i] as HTMLButtonElement).disabled = false
            }

            var refreshButtonIcons = document.getElementsByName("refresh-button-icon")
            for (let i = 0; i < refreshButtonIcons.length; i++) {
                refreshButtonIcons[i].classList.remove("rotate")
            }
        }
    }, [loading])

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetAccounting(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchAccounting(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "accPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: accListData.ttc ?? 0
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.ACCOUNTING_SERVICE_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <AccountingModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="AccountingServiceMaintenanceContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddAccounting : undefined}
                tableProps={accTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default AccountingMaintenance;
