import { ReactNode } from "react";

export interface IModalBox {
    title?: string;
    headerChild?: any;
    isOpen?: boolean;
    isFade?: boolean;
    isCentered?: boolean;
    isScrollable?: boolean;
    child?: ReactNode;
    type: ModalType;
    DialogContent?: string;
    hasCancelBtn?: boolean;
    disableCloseButton?: boolean;

    openState: React.Dispatch<React.SetStateAction<boolean>>

    footer?: JSX.Element;
    error: string | undefined;
    success: string | undefined;
    warning?: string | undefined;
}

export enum ModalType {
    Dialog = 0,
    Window = 1,
    Detail = 2,
}