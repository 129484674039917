import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { AuditCSVReportModel, AuditPDFReportModel, AuditReportListModel } from "../../../dto/models/Reporting/AuditReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";
import { ActionTypes } from "./AuditReportingActions";
import { AuditReportingActionTypes } from "./AuditReportingActionTypes";
import { IAuditReportState, InitAuditReportState } from "./AuditReportingStates";

export const AuditReportingReducer = (state = InitAuditReportState, action: ActionTypes): IAuditReportState => {
    switch (action.type) {
        case AuditReportingActionTypes.GET_AUDIT_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, auditReportListData: {} as AuditReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: AuditReportListModel = {} as AuditReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrived."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, auditReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case AuditReportingActionTypes.GENERATE_AUDIT_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as AuditPDFReportModel, csvData: {} as AuditCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: AuditPDFReportModel = {} as AuditPDFReportModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("AuditReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case AuditReportingActionTypes.GENERATE_AUDIT_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as AuditPDFReportModel, csvData: {} as AuditCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: AuditCSVReportModel = {} as AuditCSVReportModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("AuditReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}