import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import StandardContainer from "../../components/StandardContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import BigWinPendingModal from "../../components/_modals/Cashier/BigWinPendingModal";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetBigWinPendingRequest } from "../../states/BigWinPending/BigWinPendingActions";
import { IStore } from "../../states/store/IStore";

const BigWinPendingMaintenance = () => {
    const dispatch = useDispatch();
    const bigWinPendingState = useSelector((state: IStore) => state.bigWinPendingState);
    const { loading, err, suc, bigWinList } = bigWinPendingState;

    const [currentPage, setCurrentPage] = useState(0);
    const [resolveModal, setResolveModal] = useState(false);
    const [resolveID, setResolveID] = useState(0);
    const [resolvePlayer, setResolvePlayer] = useState("");
    const [resolveAmount, setResolveAmount] = useState("");
    const emptyTable: IDataTable = {
        title: ["No.", "Player", "History ID", "Is Gamble", "Amount", "Wallet Type", "Pending Date", "Action"],
        data: [],
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };

    useEffect(() => {
        if (!resolveModal) {
            dispatch(GetBigWinPendingRequest(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [resolveModal]);

    const Resolved = (id: number, player: string, amount: string): void => {
        setResolveModal(true);
        setResolveID(id);
        setResolvePlayer(player);
        setResolveAmount(amount);
    }

    const take = (page: number) => {
        setCurrentPage(page);
        dispatch(GetBigWinPendingRequest(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
    };

    const pagination: ITablePagination = {
        id: "BigWinPendingTablePagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: bigWinList.ttc ?? 0
    };

    const bigWinListTable = (): IDataTable => {
        var table = emptyTable;

        if (bigWinList.bpl) {
            if (bigWinList.bpl.length > 0) {
                const startIndex = currentPage * TABLE_MAX_ROW;
                table.data = bigWinList.bpl.map((item, index) => {
                    return [
                        startIndex + index + 1,
                        item.pnm,
                        item.gid,
                        item.pgm.toString().toUpperCase(),
                        CVT_TO_FLOAT_STRING(item.amt),
                        item.wlt,
                        FORMAT_DATETIME_TO_VIEW(item.pat, "yyyy-MM-dd hh:mm:ssa"),
                        <Button onClick={() => Resolved(item.bid, item.pnm, CVT_TO_FLOAT_STRING(item.amt))} >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                    ];
                })
            }
        }
        else {
            table.data = [];
        }

        return table;
    }

    return (
        <div>
            <BigWinPendingModal
                id={resolveID}
                amount={resolveAmount}
                player={resolvePlayer}
                isOpen={resolveModal}
                setOpenState={setResolveModal}
            />
            <StandardContainer
                id="BigWinPendingMaintenance"
                loading={loading}
                error={err}
                success={suc}
                tableProps={bigWinListTable()}
                tablePagination={pagination}
            />
        </div>
    )
}

export default BigWinPendingMaintenance;