import { TableStatus } from "../../../dto/enums/TableStatus";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../../dto/models/GeneralModels";
import { AccumulatorModel } from "../../../dto/models/JackpotModels";
import { JackpotServiceModel } from "../../../dto/models/ServiceModels";
import { GetRequest, PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../../store/IStore";
import { JackpotServiceActionTypes } from "./JackpotServiceActionTypes";


type AddJackpotServiceRequest = { type: JackpotServiceActionTypes.ADD_JACKPOT_SERVICE_REQUEST, payload: IPayload };
type EditJackpotServiceRequest = { type: JackpotServiceActionTypes.EDIT_JACKPOT_SERVICE_REQUEST, payload: IPayload };
type GetJackpotServiceRequest = { type: JackpotServiceActionTypes.GET_JACKPOT_SERVICE_REQUEST, payload: IPayload };
type SelectJackpotServiceRequest = { type: JackpotServiceActionTypes.SELECT_JACKPOT_SERVICE_REQUEST, payload: IPayload };
type SearchJackpotServiceRequest = { type: JackpotServiceActionTypes.SEARCH_JACKPOT_SERVICE_REQUEST, payload: IPayload };

export type ActionTypes =
    AddJackpotServiceRequest | EditJackpotServiceRequest | GetJackpotServiceRequest | SelectJackpotServiceRequest | SearchJackpotServiceRequest;

export const AddJackpotService = (
    name: string, requestAddress: string, 
    description: string, status: TableStatus, accumulator: AccumulatorModel
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: JackpotServiceModel = {
            sid: 0,
            snm: name,
            pyl: requestAddress,
            lil: "",
            des: description,
            stt: status,
            jam: accumulator,
        };
        PostRequest("BackOffice/AddJackpotService", request, JackpotServiceActionTypes.ADD_JACKPOT_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotServiceActionTypes.ADD_JACKPOT_SERVICE_REQUEST, dispatch);
    }
}

export const EditJackpotService = (
    aid: number, name: string, requestAddress: string, 
    description: string, status: TableStatus, accumulator: AccumulatorModel
) => async (dispatch: any) => {

    try {
        let request: JackpotServiceModel = {
            sid: aid,
            snm: name,
            pyl: requestAddress,
            lil: "",
            des: description,
            stt: status,
            jam: accumulator,
        };
        PostRequest("BackOffice/EditJackpotService", request, JackpotServiceActionTypes.EDIT_JACKPOT_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotServiceActionTypes.EDIT_JACKPOT_SERVICE_REQUEST, dispatch);
    }

}

export const GetJackpotService = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetJackpotService", request, JackpotServiceActionTypes.GET_JACKPOT_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotServiceActionTypes.GET_JACKPOT_SERVICE_REQUEST, dispatch);
    }
}

export const SearchJackpotService = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchJackpotService", request, JackpotServiceActionTypes.SEARCH_JACKPOT_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotServiceActionTypes.SEARCH_JACKPOT_SERVICE_REQUEST, dispatch);
    }
}

export const SelectJackpotService = (sid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: sid
        };
        PostRequest("BackOffice/SelectJackpotService", request, JackpotServiceActionTypes.SELECT_JACKPOT_SERVICE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotServiceActionTypes.SELECT_JACKPOT_SERVICE_REQUEST, dispatch);
    }
}