import { GameCreditCurrency, SlotGameConfigurationListModel, SlotGameConfigurationModel } from "../../dto/models/ConfigurationModels";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./GameActions";
import { GameActionTypes } from "./GameActionTypes";
import { IGameState, InitialGameState } from "./GameStates";

export const gameReducer = (state = InitialGameState, action: ActionTypes): IGameState => {
    switch (action.type) {
        case GameActionTypes.GET_GAME_REQUEST:
        case GameActionTypes.SEARCH_GAME_REQUEST:
            {
                var isloading = action.payload.loading;
                if (isloading) {
                    return { ...state, loading: true, gameList: {} as SlotGameConfigurationListModel, err: "", suc: "" };
                }
                else {
                    var data: PylonResponseModel = action.payload.data;
                    var slotConfigurationList: SlotGameConfigurationListModel = {} as SlotGameConfigurationListModel;

                    if (data && !data.err) {
                        slotConfigurationList = data.res;
                    }

                    let errMsg: string = "";
                    let sucMsg: string = "";

                    if (action.payload.err) {
                        errMsg = action.payload.err;
                    }

                    return {
                        ...state, loading: false, gameList: slotConfigurationList, gameData: {} as SlotGameConfigurationModel, err: errMsg, suc: sucMsg
                    };
                }
            }
        case GameActionTypes.ADD_GAME_REQUEST:
        case GameActionTypes.EDIT_GAME_REQUEST:
        case GameActionTypes.SELECT_GAME_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, gameData: {} as SlotGameConfigurationModel, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var slotGameConfiguration: SlotGameConfigurationModel = {} as SlotGameConfigurationModel;
                var slotConfigurationList: SlotGameConfigurationListModel = {} as SlotGameConfigurationListModel;

                if (data && !data.err) {
                    slotGameConfiguration = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == GameActionTypes.ADD_GAME_REQUEST)
                        sucMsg = "Game Added Successfully.";
                    else if (action.type == GameActionTypes.EDIT_GAME_REQUEST) {
                        sucMsg = "Game Updated Successfully.";
                    }
                }
                return { ...state, loading: false, gameData: slotGameConfiguration, err: errMsg, suc: sucMsg };
            }
        }
        case GameActionTypes.GET_CREDIT_RATE: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return state;
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var rate: GameCreditCurrency = {} as GameCreditCurrency;

                if (data && !data.err) {
                    rate = data.res;
                }
                return { ...state, creditRate: rate };
            }
        }
        default:
            return state;
    }
}