import { faAdd, faFloppyDisk, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, ListGroup, ListGroupItem, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { TierConnectorType, TierOperatorType, TierParameterType } from "../../../dto/enums/Jackpot/JackpotTierCondition";
import { JackpotTierType } from "../../../dto/enums/Jackpot/JackpotTierType";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { ITierConditions, JackpotTierCoreLib } from "../../../libraries/JackpotTierCoreLib";
import { AddTier, EditTier, SelectTier } from "../../../states/PAS/JackpotTier/TierActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import TextField from "../../InputBox/TextField";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface ITierForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const TieForm = (props: ITierForm) => {
    const [nameInput, setNameInput] = useState("");
    const [nameValid, setNameValid] = useState(props.isEdit);
    const [descriptionInput, setDescriptionInput] = useState("");
    const [progessiveRateInput, setProgessiveRateInput] = useState<Number | undefined>(undefined);
    const [progessiveRateValid, setProgessiveRateValid] = useState(props.isEdit);
    const [houseRateInput, setHouseRateInput] = useState<Number | undefined>(undefined);
    const [houseRateValid, setHouseRateValid] = useState(props.isEdit);
    const [reserveRateInput, setReserveRateInput] = useState<Number | undefined>(undefined);
    const [reserveRateValid, setReserveRateValid] = useState(props.isEdit);

    const conditionTypes: ISelectOption[] = LoadSelectOptionFromEnum(JackpotTierType);
    const [selectedCondition, setSelectedCondition] = useState(JackpotTierType.DEFAULT);

    // Conditions
    const [selectedParam, setSelectedParam] = useState(TierParameterType.PROGRESSIVE);
    const [selectedOperator, setSelectedOperator] = useState(TierOperatorType.GREATER_THAN);
    const [selectedConnector, setSelectedConnector] = useState(TierConnectorType.AND);
    const conditionParams: ISelectOption[] = LoadSelectOptionFromEnum(TierParameterType).filter(x => x.value != '3');
    const conditionOperators: ISelectOption[] = LoadSelectOptionFromEnum(TierOperatorType);
    const conditionConnectors: ISelectOption[] = LoadSelectOptionFromEnum(TierConnectorType);
    const [progessiveValue, setProgessiveValue] = useState<Number | undefined>(undefined);
    const [progessiveValid, setProgessiveValid] = useState(false);
    const [tierConditions, setTierConditions] = useState([] as ITierConditions[]);
    const [enablePlusBtn, setEnablePlusBtn] = useState(!props.isEdit);

    const dispatch = useDispatch();
    const tierStates = useSelector((state: IStore) => state.tierState);
    const { tierData, loading } = tierStates;
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(!loading);
    }, [loading])

    useEffect(() => {
        if (props.isEdit && props.editID) {
            dispatch(SelectTier(props.editID));
        } else
            ClearFields();
    }, [props.isEdit]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        CheckIfEndConditions();
    }, [tierConditions]);

    useEffect(() => {
        if (props.isEdit && tierData.tnm) {
            setNameInput(tierData.tnm);
            setDescriptionInput(tierData.des);
            setProgessiveRateInput(tierData.tpr);
            setHouseRateInput(tierData.thr);
            setReserveRateInput(tierData.trr);
            setSelectedCondition(tierData.typ);
            if (tierData.tcd) {
                setTierConditions(JackpotTierCoreLib.Convertor.toFormParam(tierData.tcd));
            }
        }
    }, [tierData]);

    const ClearFields = () => {
        setProgessiveRateInput(undefined);
        setHouseRateInput(undefined);
        setReserveRateInput(undefined);
    }

    const CheckIfEndConditions = () => {
        if (tierConditions.filter(x => x.connector == TierConnectorType.END.toString()).length > 0)
            setEnablePlusBtn(false);
        else
            setEnablePlusBtn(true);
    }

    const AddCondition = () => {
        const conditions: ITierConditions[] = tierConditions.concat();
        if (progessiveValue == 0 || !progessiveValid) {
            TRIGGER_BLUR("tier_progessive_rate");
            return;
        }

        var newCondition: ITierConditions = {
            connector: selectedConnector.toString(),
            operator: selectedOperator.toString(),
            parameter: selectedParam.toString(),
            value: progessiveValue as number,
            connectorString: "",
            connectorSymbol: "",
            operatorString: "",
            operatorSymbol: "",
            parameterString: "",
            parameterSymbol: ""
        }
        conditions.push(newCondition);

        setTierConditions(conditions);
    }

    const RemoveCondition = (index: number) => {
        const conditions: ITierConditions[] = tierConditions.concat();
        tierConditions.map((x, i) => {
            if (i == index)
                conditions.splice(i, 1);
        });
        setTierConditions(conditions);
    }

    const GetConditionsList = () => {
        var listItems: JSX.Element[] = [];
        if (tierConditions.length > 0) {
            listItems = tierConditions.map((item, index) => (
                <ListGroupItem key={index}>
                    <Row>
                        <b>Condition {index + 1}</b>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <SelectBox id="param" name="param" label="Paramater"
                                options={conditionParams} value={item.parameter} isRequired={true} isDisabled={true}
                            />
                        </Col>
                        <Col md={3}>
                            <SelectBox id="operator" name="operator" label="Operator"
                                options={conditionOperators} value={item.operator} isRequired={true} isDisabled={true}
                            />
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <InputBox
                                    id="p_rate"
                                    name="p_rate"
                                    label={"Progessive Rate"}
                                    type={TextboxType.NUMBER}
                                    value={item.value}
                                    isDisabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <SelectBox id="connector" name="connector" label="Connector"
                                    options={conditionConnectors} value={item.connector} isRequired={true} isDisabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <Button style={{ marginTop: '50%' }} color="danger" outline onClick={() => RemoveCondition(index)} ><FontAwesomeIcon icon={faTrashCan} /></Button>
                        </Col>
                    </Row>
                </ListGroupItem>
            ));
        }
        else {
            listItems.push(
                <ListGroupItem>
                    <p style={{ color: 'red' }}>** At least one condition need to be added</p>
                </ListGroupItem>
            );
        }

        return (
            <ListGroup style={{ marginLeft: '1%' }}>
                {listItems}
            </ListGroup>
        );
    }

    const Valid = (): boolean => {
        return progessiveRateValid && houseRateValid && reserveRateValid && nameValid;
    }

    const save = () => {
        if (Valid() && nameInput && progessiveRateInput && houseRateInput && reserveRateInput && (selectedCondition == JackpotTierType.DEFAULT || selectedCondition == JackpotTierType.CUSTOMIZED && tierConditions.length > 0)) {
            if (!props.isEdit) {
                dispatch(
                    AddTier(
                        selectedCondition == JackpotTierType.DEFAULT ? "P >= 0 || H >= 0 || R >= 0" : JackpotTierCoreLib.Convertor.toParam(tierConditions),
                        selectedCondition,
                        nameInput,
                        descriptionInput,
                        progessiveRateInput as number,
                        houseRateInput as number,
                        reserveRateInput as number
                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditTier(
                            props.editID,
                            selectedCondition,
                            selectedCondition == JackpotTierType.DEFAULT ? "P >= 0 || H >= 0 || R >= 0" : JackpotTierCoreLib.Convertor.toParam(tierConditions),
                            nameInput,
                            descriptionInput,
                            progessiveRateInput as number,
                            houseRateInput as number,
                            reserveRateInput as number
                        ));
                }
            }
        } else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("des");
            TRIGGER_BLUR("conditionType");
            TRIGGER_BLUR("p_rate");
            TRIGGER_BLUR("h_rate");
            TRIGGER_BLUR("r_rate");

        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            {isLoaded ?
                <Form>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="name"
                                    name="name"
                                    label="Name"
                                    type={TextboxType.TEXT}
                                    inputState={setNameInput}
                                    placeholder={"Name"}
                                    validState={setNameValid}
                                    isRequired={true}
                                    value={nameInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="p_rate"
                                    name="p_rate"
                                    label={"Progessive Rate (%)"}
                                    type={TextboxType.NUMBER}
                                    inputState={setProgessiveRateInput}
                                    placeholder={"Progessive Rate (%)"}
                                    validState={setProgessiveRateValid}
                                    isRequired={true}
                                    value={progessiveRateInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="h_rate"
                                    name="h_rate"
                                    label={"House Rate (%)"}
                                    type={TextboxType.NUMBER}
                                    inputState={setHouseRateInput}
                                    placeholder={"House Rate (%)"}
                                    validState={setHouseRateValid}
                                    isRequired={true}
                                    value={houseRateInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="r_rate"
                                    name="r_rate"
                                    label={"Reserve Rate (%)"}
                                    type={TextboxType.NUMBER}
                                    inputState={setReserveRateInput}
                                    placeholder={"Progessive Rate (%)"}
                                    validState={setReserveRateValid}
                                    isRequired={true}
                                    value={reserveRateInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <TextField id="des" name="des" label="Description"
                                    placeholder="Description" value={props.isEdit ? tierData.des : ""}
                                    inputState={setDescriptionInput} maxLength={300} col={5} row={5}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectBox id="conditionType" name="conditionType" label="Condtion Type"
                                options={conditionTypes} value={selectedCondition}
                                inputState={setSelectedCondition} isRequired={true}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                    </Row>

                    {selectedCondition == JackpotTierType.CUSTOMIZED ? (
                        <div>
                            <Row>
                                <Col md={3}>
                                    <SelectBox id="param" name="param" label="Paramater"
                                        options={conditionParams} value={selectedParam}
                                        inputState={setSelectedParam} isRequired={true}
                                        isDisabled={!props.canEdit}
                                    />
                                </Col>
                                <Col md={3}>
                                    <SelectBox id="operator" name="operator" label="Operator"
                                        options={conditionOperators} value={selectedOperator}
                                        inputState={setSelectedOperator} isRequired={true}
                                        isDisabled={!props.canEdit}
                                    />
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <InputBox
                                            id="tier_progessive_rate"
                                            name="tier_progessive_rate"
                                            label={"Tier Progessive Value"}
                                            type={TextboxType.NUMBER}
                                            inputState={setProgessiveValue}
                                            value={progessiveValue}
                                            validState={setProgessiveValid}
                                            placeholder={"Tier Progessive Rate"}
                                            isRequired={true}
                                            isDisabled={!props.canEdit}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <SelectBox id="connector" name="connector" label="Connector"
                                            options={conditionConnectors} value={selectedConnector}
                                            inputState={setSelectedConnector} isRequired={true}
                                            isDisabled={!props.canEdit}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={1}>
                                    <Col><Button color="primary" style={{ marginTop: '50%' }} disabled={!enablePlusBtn || !props.canEdit} outline onClick={() => AddCondition()} ><FontAwesomeIcon icon={faAdd} /></Button></Col>
                                </Col>
                            </Row>
                            <Row>
                                {GetConditionsList()}
                            </Row>
                        </div>
                    ) :
                        <div></div>
                    }

                </Form>
                :
                <div className='text-center p-4'><Spinner key='1' /></div>
            }
        </Container>
    )
}


const TierModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const tierState = useSelector((state: IStore) => state.tierState);
    const { err, suc, loading } = tierState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit Tier" : "Add Tier"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <TieForm isEdit={props.isEdit} canEdit={canEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? < Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default TierModal;

