import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { JackpotPoolResetMode } from "../../../dto/enums/Jackpot/JackpotPoolResetMode";
import { JackpotPoolTitle } from "../../../dto/enums/Jackpot/JackpotPoolTitle";
import { JackpotPoolType } from "../../../dto/enums/Jackpot/JackpotPoolType";
import { JackpotCategory } from "../../../dto/enums/Jackpot/JackpotCategory";
import { AccumulatorPoolModel, PoolModel } from "../../../dto/models/JackpotModels";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { AddAccumulator, EditAccumulator, SelectAccumulator } from "../../../states/PAS/JackpotAccumulator/AccumulatorActions";
import { GetPoolList } from "../../../states/PAS/JackpotPool/PoolActions";
import { IStore } from "../../../states/store/IStore";
import DataTable from "../../DataTable/DataTable";
import { TableStyle } from "../../DataTable/IDataTable";
import CheckBoxGroup from "../../InputBox/CheckBoxGroup";
import { ICheckBoxOption } from "../../InputBox/ICheckBoxGroup";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import TextField from "../../InputBox/TextField";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IAccumulatorForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

interface PoolRateModel {
    poolID: string,
    poolrate: number
}

const AccumulatorForm = (props: IAccumulatorForm) => {
    const [nameInput, setNameInput] = useState("");
    const [nameValid, setNameValid] = useState(props.isEdit);
    const [descriptionInput, setDescriptionInput] = useState("");

    //Pool Configuration
    const [PoolOptions, setPoolOptions] = useState([] as ICheckBoxOption[]);
    const [selectedPools, setPools] = useState([] as string[]);
    const [poolRateInput, setPoolRates] = useState([] as PoolRateModel[]);

    const [tierTable, setTierTable] = useState<any[][]>([]);

    const dispatch = useDispatch();
    const accumulatorStates = useSelector((state: IStore) => state.accumulatorState);
    const poolStates = useSelector((state: IStore) => state.poolState);
    const { accumulatorData } = accumulatorStates;
    const { poolListData } = poolStates;
    const [isLoaded, setLoaded] = useState(false);
    const accLoading = accumulatorStates.loading;
    const poolLoading = poolStates.loading;
    const categoryOptions: ISelectOption[] = LoadSelectOptionFromEnum(JackpotCategory);
    const [selectedCategory, setCategory] = useState(JackpotCategory.SMALL);

    useEffect(() => {
        setLoaded(!accLoading && !poolLoading);
    }, [accLoading, poolLoading])

    useEffect(() => {
        if (poolListData && poolListData.pll) {
            const pools: ICheckBoxOption[] = poolListData.pll
                .map((item) => {
                    return {
                        display: item.pon,
                        value: item.jid.toString()
                    }

                });
            setPoolOptions(pools);
        }
    }, [poolListData]);

    useEffect(() => {
        dispatch(GetPoolList());
        if (props.isEdit && props.editID) {
            dispatch(SelectAccumulator(props.editID));
        } else
            ClearFields();

    }, [props.isEdit]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (props.isEdit && accumulatorData.jan) {
            setNameInput(accumulatorData.jan);
            setDescriptionInput(accumulatorData.des);
            setCategory(accumulatorData.cat);

            const pools: string[] = [];
            const poolRates: PoolRateModel[] = [];
            accumulatorData.apl.map(item => {
                pools.push(item.pol.jid.toString());
                poolRates.push(
                    {
                        poolID: item.pol.jid.toString(),
                        poolrate: item.dtr
                    });
            });
            setPools(pools);
            setPoolRates(poolRates);
        }
    }, [accumulatorData]);

    useEffect(() => {
        if (poolListData.pll && poolListData.pll.length > 0 && accumulatorData.jan != "") {
            MapPoolRate();
            GetPoolListData();
        }

    }, [accumulatorData, poolListData, selectedPools, poolRateInput]);

    const MapPoolRate = () => {
        poolRateInput.map((poolRate, i) => {
            if (selectedPools.filter(x => x == poolRate.poolID).length == 0) {
                poolRateInput.splice(i, 1);
            }
        })
    }

    const ClearFields = () => {
        setNameInput("");
        setDescriptionInput("");
        setCategory(JackpotCategory.SMALL)
    }

    const GetPoolListData = () => {
        const data: any[][] = [];
        var i: number = 0;

        if (poolListData && poolListData.pll) {
            selectedPools.map((pool, index) => {
                const poolRates = poolRateInput.concat();
                poolListData.pll.filter(x => x.jid == pool as any as number).map(x => {
                    const input: JSX.Element =
                        <Input
                            id={x.jid + "_input"}
                            name={x.jid + "_input"}
                            placeholder="Rate (%)"
                            value={poolRateInput.length > 0 && poolRateInput[index] ? poolRateInput[index].poolrate : 0}
                            type="number"
                            max={100}
                            onChange={e => {
                                var value = e.target.value as any as number;
                                if (poolRates[index]) {
                                    poolRates[index].poolrate = value;
                                } else {
                                    poolRates.push({ poolID: x.jid, poolrate: value } as unknown as PoolRateModel)
                                }
                                setPoolRates(poolRates);
                            }}
                            disabled={!props.canEdit || props.isEdit}
                        />
                    data.push([++i, x.pon, JackpotPoolTitle[x.til], JackpotPoolType[x.typ], input])
                });
            });
        }

        setTierTable(data);
    }

    const Valid = (): boolean => {
        return nameValid
    }

    const GetAccumulatorPools = (): AccumulatorPoolModel[] => {
        const accumulatorPools: AccumulatorPoolModel[] = [];
        selectedPools.map(x => {
            var input = document.getElementById(x + "_input") as HTMLInputElement;
            accumulatorPools.push(
                {
                    pid: x as any as number,
                    dtr: input.value == "" ? 0 : input.value,
                    pol: {
                        jid: x as any as number,
                        pon: "",
                        til: JackpotPoolTitle.GRAND,
                        des: "",
                        typ: JackpotPoolType.CONDITION_FIXED,
                        rsm: JackpotPoolResetMode.DEFAULT_MODE,
                        tsh: 0,
                        sed: 0,
                        cel: 0,
                        poa: 0,
                    } as PoolModel
                } as AccumulatorPoolModel
            );
        });
        return accumulatorPools;
    }

    const save = () => {
        if (Valid() && nameInput) {
            if (!props.isEdit) {
                dispatch(
                    AddAccumulator(
                        nameInput,
                        descriptionInput,
                        selectedCategory,
                        GetAccumulatorPools()
                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditAccumulator(
                            props.editID,
                            nameInput,
                            descriptionInput,
                            selectedCategory,
                            GetAccumulatorPools()
                        ));
                }
            }
        } else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("des");
            TRIGGER_BLUR("thresholdCash");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            {isLoaded ?
                <Form>
                    <Row>
                        <Col>
                            <InputBox
                                id="name"
                                name="name"
                                label="Name"
                                type={TextboxType.TEXT}
                                inputState={setNameInput}
                                placeholder={"Name"}
                                validState={setNameValid}
                                isRequired={true}
                                value={nameInput}
                                isDisabled={!props.canEdit || props.isEdit}
                            />
                        </Col>
                        <Col>
                            <SelectBox id="jackpotCategory" name="jackpotCategory" label="Jackpot Category"
                                options={categoryOptions} value={selectedCategory}
                                inputState={setCategory}
                                isRequired={true}
                                isDisabled={!props.canEdit || props.isEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Col>
                                <FormGroup>
                                    <TextField id="des" name="des" label="Description"
                                        placeholder="Description" value={props.isEdit ? accumulatorData.des : ""}
                                        inputState={setDescriptionInput} maxLength={300} col={5} row={5}
                                        isDisabled={!props.canEdit}
                                    />
                                </FormGroup>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <CheckBoxGroup id="poolchk" label="Pools" values={selectedPools}
                                options={PoolOptions} inputState={setPools}
                                isLimitHeight={true} inline={false}
                                isDisabled={!props.canEdit || props.isEdit}
                            />
                        </Col>
                        <Col md={9}>
                            <Label for={"pool"}>Selected Pools</Label>
                            <DataTable
                                title={["NO.", "Name", "Title", "Type", "Rate (%)"]}
                                data={tierTable}
                                isloading={false}
                                isStriped={true}
                                isHover={false}
                                tableStyle={TableStyle.ALL_BORDER}
                            />
                        </Col>
                    </Row>
                </Form>
                :
                <div className='text-center p-4'><Spinner key='1' /></div>
            }
        </Container>
    )
}

const AccumulatorModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const accumulatorState = useSelector((state: IStore) => state.accumulatorState);
    const poolState = useSelector((state: IStore) => state.poolState);
    const { err, suc, loading } = accumulatorState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        if (poolState.err !== "") {
            setErrMsg(poolState.err)
            return;
        }

        if (err !== "") {
            setErrMsg(err)
            return;
        }

        setErrMsg("");
    }, [poolState.err, err])

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit Accumulator" : "Add Accumulator"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={suc}
                child={
                    <AccumulatorForm isEdit={props.isEdit} canEdit={canEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? < Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default AccumulatorModal;