export enum ReportingAccess {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0x000000,
    ///// <summary>
    /////     Daily sales report
    ///// </summary>
    //DAILY_SALES_REPORT = 0x000001,
    /// <summary>
    ///     Win lose report
    /// </summary>
    WIN_LOSE_REPORT = 0x000002,
    /// <summary>
    ///     Player performance report
    /// </summary>
    PLAYER_PERFORMANCE_REPORT = 0x000004,
    ///// <summary>
    /////     Significant event report
    ///// </summary>
    //SIGNIFICANT_EVENT_REPORT = 0x000008,
    /// <summary>
    ///     Audit report
    /// </summary>
    AUDIT_REPORT = 0x000010,
    /// <summary>
    ///     User listing report
    /// </summary>
    USER_LISTING_REPORT = 0x000020,
    ///// <summary>
    /////     Brutal prevention report
    ///// </summary>
    //BRUTAL_PREVENTION_REPORT = 0x000040,
    /// <summary>
    ///     Game result report
    /// </summary>
    GAME_RESULT_REPORT = 0x000080,
    /// <summary>
    ///     Credit transaction report
    /// </summary>
    CREDIT_TRANSACTION_REPORT = 0x000100,
    /// <summary>
    ///     Player rating submission report
    /// </summary>
    PLAYER_RATING_SUBMISSION_REPORT = 0x000200,
    /// <summary>
    ///     Player left over balance report
    /// </summary>
    PLAYER_LEFT_OVER_BALANCE_REPORT = 0x000400,
    /// <summary>
    ///     Jackpot pool adjustment report
    /// </summary>
    JACKPOT_POOL_ADJUSTMENT_REPORT = 0x000800,
    /// <summary>
    ///     Jackpot strike report
    /// </summary>
    JACKPOT_STRIKE_REPORT = 0x001000,
    /// <summary>
    ///     Jackpot contribution report
    /// </summary>
    JACKPOT_CONTRIBUTION_REPORT = 0x002000,
    /// <summary>
    ///     Jackpot / Big Win approval report
    /// </summary>
    JACKPOT_BIG_WIN_APPROVAL_REPORT = 0x004000,
    /// <summary>
    ///     Player lock report
    /// </summary>
    PLAYER_LOCK_REPORT = 0x008000
}