import { faFloppyDisk, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, ListGroup, ListGroupItem, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { GamingAreaType } from "../../../dto/enums/GamingAreaType";
import { IPAddressRange } from "../../../dto/models/GamingAreaModels";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { AddGamingAreaRequest, EditGamingAreaRequest, SelectGamingAreaRequest } from "../../../states/GamingArea/GamingAreaActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IGamingAreaMaintenanceForm {
    isEdit: boolean;
    loading: boolean;
    id?: number;

    isSaveTrigger: boolean;
    canEdit?: boolean;
}

const GamingAreaMaintenanceForm = (props: IGamingAreaMaintenanceForm) => {
    const dispatch = useDispatch();
    const gamingAreaState = useSelector((state: IStore) => state.gamingAreaState);
    const { gamingAreaData, loading } = gamingAreaState;
    const [isLoaded, setLoaded] = useState(false);
    // input & validation
    const [name, setName] = useState("");
    const [areatype, setAreaType] = useState(GamingAreaType.NON_VIP);
    const [nonrestrictedWallet, setNonrestrictedWallet] = useState("");
    const [nonrestrictedTrasnferIn, setNonrestrictedTransferIn] = useState<undefined | number>(undefined);
    const [nonrestrictedTrasnferOut, setNonrestrictedTransferOut] = useState<undefined | number>(undefined);
    const [restrictedWallet, setRestrictedWallet] = useState("");
    const [restrictedTrasnferIn, setRestrictedTransferIn] = useState<undefined | number>(undefined);
    const [restrictedTrasnferOut, setRestrictedTransferOut] = useState<undefined | number>(undefined);
    const [slotLocation, setSlotLocation] = useState("");
    const [bigWinLimit, setBigWinLimit] = useState<undefined | number>(undefined);
    const [ipAddressRange, setIpAddressRange] = useState([] as IPAddressRange[]);
    const [startIP, setStartIP] = useState("");
    const [endIP, setEndIP] = useState("");

    const [nameValid, setNameValid] = useState(props.isEdit);
    const [nonrestrictedTrasnferInValid, setNonrestrictedTransferInValid] = useState(props.isEdit);
    const [nonrestrictedTrasnferOutValid, setNonrestrictedTransferOutValid] = useState(props.isEdit);
    const [restrictedTrasnferInValid, setRestrictedTransferInValid] = useState(props.isEdit);
    const [restrictedTrasnferOutValid, setRestrictedTransferOutValid] = useState(props.isEdit);
    const [bigWinLimitValid, setBigWinLimitValid] = useState(props.isEdit);
    const [startIPValid, setStartIPValid] = useState(false);
    const [endIPValid, setEndIPValid] = useState(false);

    // dropdown option 
    const areatypeOption: ISelectOption[] = LoadSelectOptionFromEnum(GamingAreaType);

    // save button
    const save = () => {
        if (nameValid && nonrestrictedTrasnferInValid && nonrestrictedTrasnferOutValid
            && restrictedTrasnferInValid && restrictedTrasnferOutValid && bigWinLimitValid) {
            if (props.isEdit && props.id) {
                dispatch(EditGamingAreaRequest(props.id, name, areatype,
                    nonrestrictedTrasnferIn as number, nonrestrictedTrasnferOut as number, nonrestrictedWallet,
                    restrictedTrasnferIn as number, restrictedTrasnferOut as number, restrictedWallet,
                    slotLocation, bigWinLimit as number, ipAddressRange));
            }
            else {
                dispatch(AddGamingAreaRequest(name, areatype,
                    nonrestrictedTrasnferIn as number, nonrestrictedTrasnferOut as number, nonrestrictedWallet,
                    restrictedTrasnferIn as number, restrictedTrasnferOut as number, restrictedWallet,
                    slotLocation, bigWinLimit as number, ipAddressRange));
            }
        }
        else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("bigwinlimit");
            TRIGGER_BLUR("nonrestrictedtransferin");
            TRIGGER_BLUR("nonrestrictedtransferout");
            TRIGGER_BLUR("restrictedtransferin");
            TRIGGER_BLUR("restrictedtransferout");
        }
    }

    useEffect(() => {
        setLoaded(!loading);
    }, [loading])

    // states
    useEffect(() => {
        if (props.isEdit && props.id) {
            dispatch(SelectGamingAreaRequest(props.id));
        }
    }, []);

    useEffect(() => {
        if (gamingAreaData && gamingAreaData.gid) {
            setName(gamingAreaData.gan);
            setAreaType(gamingAreaData.typ);
            setNonrestrictedWallet(gamingAreaData.nwt ?? "");
            setNonrestrictedTransferIn(gamingAreaData.nti);
            setNonrestrictedTransferOut(gamingAreaData.nto);
            setRestrictedWallet(gamingAreaData.rwt ?? "");
            setRestrictedTransferIn(gamingAreaData.rti);
            setRestrictedTransferOut(gamingAreaData.rto);
            setSlotLocation(gamingAreaData.slt);
            setBigWinLimit(gamingAreaData.bwl);
            setIpAddressRange(gamingAreaData.ipr ? gamingAreaData.ipr : [] as IPAddressRange[]);
        }
    }, [gamingAreaData]);

    useEffect(() => {
        if (props.isSaveTrigger) {
            save();
        }
    }, [props.isSaveTrigger]);

    //events
    const AddIPAddressRange = () => {
        if (startIPValid && endIPValid) {
            var existing: boolean = false;

            for (let i = 0; i < ipAddressRange.length; i++) {
                if (ipAddressRange[i].ist == startIP && ipAddressRange[i].ien == endIP) {
                    existing = true;
                    break;
                }
            }

            if (!existing) {
                ipAddressRange.push({
                    ist: startIP,
                    ien: endIP
                });
                setIpAddressRange(ipAddressRange);
            }

            setStartIP("");
            setEndIP("");
            (document.getElementById("start") as HTMLInputElement).value = "";
            (document.getElementById("end") as HTMLInputElement).value = "";
        }
        else {
            TRIGGER_BLUR("start");
            TRIGGER_BLUR("end");
        }
    }

    const RemoveIPAddressRange = (start: string, end: string) => {
        var newIPAddressRange: IPAddressRange[] = [];
        for (let i = 0; i < ipAddressRange.length; i++) {
            if (ipAddressRange[i].ist == start && ipAddressRange[i].ien == end) {
                continue;
            }
            newIPAddressRange.push(ipAddressRange[i]);
        }

        setIpAddressRange(newIPAddressRange);
    }

    const GetIPAddressRangeList = () => {
        var list: JSX.Element[] = [];

        if (ipAddressRange && ipAddressRange.length > 0) {
            list = ipAddressRange.map((item, index) => (
                <ListGroupItem key={index}>
                    <Row>
                        <Col xs="3">{item.ist}</Col>
                        <Col xs="1">-</Col>
                        <Col xs="3">{item.ien}</Col>
                        <Col xs="auto">{props.canEdit ? < Button color="danger" outline onClick={() => RemoveIPAddressRange(item.ist, item.ien)}><FontAwesomeIcon icon={faTrashCan} />Remove</Button> : <></>}</Col>
                    </Row>
                </ListGroupItem>
            ));
        }
        else {
            list.push(
                <ListGroupItem key={0}>
                    No IP Address Range Found.
                </ListGroupItem>
            )
        }

        return (
            <ListGroup>
                {list}
            </ListGroup>
        );
    }

    if (isLoaded) {
        return (
            <Form>
                <Row>
                    <Col>
                        <InputBox id="name" name="name" label="Name" placeholder="Gaming Area Name"
                            type={TextboxType.TEXT} maxlength={50}
                            value={name} inputState={setName} validState={setNameValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                    <Col>
                        <SelectBox id="type" name="type" label="Area Type"
                            options={areatypeOption} value={gamingAreaData?.typ}
                            inputState={setAreaType} isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputBox id="slotlocation" name="slotlocation" placeholder="Gaming Area" label="Gaming Area"
                            type={TextboxType.TEXT} maxlength={3}
                            value={slotLocation} inputState={setSlotLocation}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                    <Col>
                        <InputBox id="bigwinlimit" name="bigwinlimit" placeholder="Big Win Limit" label="Big Win Limit"
                            type={TextboxType.CURRENCY}
                            value={bigWinLimit} inputState={setBigWinLimit} validState={setBigWinLimitValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <fieldset className="border p-2">
                    <legend className="float-none w-auto">Non Restricted</legend>
                    <Row>
                        <Col>
                            <InputBox id="nonrestrictedwallet" name="nonrestrictedwallet" placeholder="Wallet Name"
                                type={TextboxType.TEXT} maxlength={4}
                                value={nonrestrictedWallet} inputState={setNonrestrictedWallet}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox id="nonrestrictedtransferin" name="nonrestrictedtransferin" placeholder="Transfer In Limit"
                                type={TextboxType.CURRENCY}
                                value={nonrestrictedTrasnferIn} inputState={setNonrestrictedTransferIn} validState={setNonrestrictedTransferInValid}
                                isRequired={true}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox id="nonrestrictedtransferout" name="nonrestrictedtransferout" placeholder="Transfer Out Limit"
                                type={TextboxType.CURRENCY}
                                value={nonrestrictedTrasnferOut} inputState={setNonrestrictedTransferOut} validState={setNonrestrictedTransferOutValid}
                                isRequired={true}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className="border p-2">
                    <legend className="float-none w-auto">Restricted</legend>
                    <Row>
                        <Col>
                            <InputBox id="restrictedwallet" name="restrictedwallet" placeholder="Wallet Name"
                                type={TextboxType.TEXT} maxlength={4}
                                value={restrictedWallet} inputState={setRestrictedWallet}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox id="restrictedtransferin" name="restrictedtransferin" placeholder="Transfer In Limit"
                                type={TextboxType.CURRENCY}
                                value={restrictedTrasnferIn} inputState={setRestrictedTransferIn} validState={setRestrictedTransferInValid}
                                isRequired={true}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col>
                            <InputBox id="restrictedtransferout" name="restrictedtransferout" placeholder="Transfer Out Limit"
                                type={TextboxType.CURRENCY}
                                value={restrictedTrasnferOut} inputState={setRestrictedTransferOut} validState={setRestrictedTransferOutValid}
                                isRequired={true}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className="border p-2">
                    <legend className="float-none w-auto">IP Address Range</legend>
                    <Row>
                        <Col xs="4">
                            <InputBox id="start" name="start" label="IP Address - Start"
                                type={TextboxType.IP_ADDRESS} isRequired={true}
                                inputState={setStartIP} validState={setStartIPValid} value={startIP}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col xs="4">
                            <InputBox id="end" name="end" label="IP Address - End"
                                type={TextboxType.IP_ADDRESS} isRequired={true}
                                inputState={setEndIP} validState={setEndIPValid}
                                value={endIP}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col xs="auto" style={{ paddingTop: "30px" }}>
                            <Button color="info" disabled={!props.canEdit} onClick={() => AddIPAddressRange()} outline><FontAwesomeIcon icon={faPlus} /> Add</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {GetIPAddressRangeList()}
                        </Col>
                    </Row>
                </fieldset>
            </Form>
        );
    } else {
        return (<div className='text-center p-4'><Spinner key='1' /></div>);
    }
}

const GamingAreaMaintenanceModal = (props: { isEdit: boolean, id?: number, isOpen: boolean, setOpenState: any }) => {
    const gamingAreaState = useSelector((state: IStore) => state.gamingAreaState);
    const { err, suc, loading } = gamingAreaState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.GAMING_AREA_MAINTENANCE))
        }
    }, [])

    return (
        <ModalBox
            title={props.isEdit ? "Edit Gaming Area" : "Add Gaming Area"}
            isOpen={props.isOpen}
            child={<GamingAreaMaintenanceForm isEdit={props.isEdit} canEdit={canEdit} id={props.id} loading={loading} isSaveTrigger={save} />}
            type={ModalType.Window}
            openState={props.setOpenState}
            error={err}
            success={suc}
            footer={canEdit ? <Button color="info" onClick={() => setSave(true)} outline disabled={loading}><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            isScrollable={true}
        />
    );
}

export default GamingAreaMaintenanceModal;