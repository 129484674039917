import { faCaretDown, faCaretUp, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import { ICollapseContainer } from './ICollapseContainer';

const CollapseContainer = (props: ICollapseContainer) => {
    const [open, setOpen] = useState("1");
    const toggle = () => {
        props.setOpenState(!props.isOpen)

        if (props.isOpen) {
            setOpen("1");
        } else
            setOpen("0");
    };

    return (
        <div>
            <React.StrictMode>
                <span style={{ fontSize: '20px', fontStyle: 'bold' }}><strong>{props.title}</strong></span>
                <Button color={"link"} onClick={toggle} style={{ marginBottom: '0.4rem', boxShadow: 'none', color: 'black' }}>
                    <FontAwesomeIcon icon={props.isOpen ? faCaretUp : faCaretDown} />
                </Button>
                <Collapse isOpen={props.isOpen}>
                    <Card>
                        <CardBody>
                            {props.content}
                        </CardBody>
                    </Card>
                </Collapse>
            </React.StrictMode>
        </div>
    );
}

export default CollapseContainer;