import { TierListModel, TierModel } from "../../../dto/models/JackpotModels";
import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ActionTypes } from "./TierActions";
import { TierActionTypes } from "./TierActionTypes";
import { InitTierState, ITierState } from "./TierStates";

export const TierReducer = (state = InitTierState, action: ActionTypes): ITierState => {
    switch (action.type) {
        case TierActionTypes.SELECT_TIER_REQUEST:
        case TierActionTypes.ADD_TIER_REQUEST:
        case TierActionTypes.EDIT_TIER_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, tierData: {} as TierModel, tierListData: {} as TierListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var tierModel: TierModel = {} as TierModel;

                if (data && !data.err) {
                    tierModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == TierActionTypes.ADD_TIER_REQUEST) {
                        sucMsg = "Tier Added Successfully.";
                    }
                    else if (action.type == TierActionTypes.EDIT_TIER_REQUEST) {
                        sucMsg = "Tier Updated Successfully.";
                    }
                }
                return { ...state, loading: false, tierData: tierModel, err: errMsg, suc: sucMsg };
            }
        }
        case TierActionTypes.GET_TIER_REQUEST:
        case TierActionTypes.SEARCH_TIER_REQUEST:
        case TierActionTypes.GET_TIER_LIST_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, tierData: {} as TierModel, tierListData: {} as TierListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var tierListModel: TierListModel = {} as TierListModel;

                if (data && !data.err) {
                    tierListModel = data.res;
                }
                return { ...state, loading: false, tierListData: tierListModel, err: action.payload.err ? action.payload.err : "" };
            }
        }
        default:
            return state
    }
}
