import { FORMAT_DATETIME_TO_VIEW } from "./DateHelper";

export const GET_FILE_DATA = (list?: FileList): Promise<number[]> => {
  return new Promise((resolve, reject) => {
    if (list && list.length > 0) {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);
        const fileBytes = Array.from(uint8Array);
        resolve(fileBytes);
      };
        reader.readAsArrayBuffer(list[0]);

    } else
      resolve([]);
    
  });
}

export const DECODE_STR_TO_ARR = (str?: string): number[] => {
    if (str) {
        const byteArray = Uint8Array.from(atob(str), c => c.charCodeAt(0));
        const numberArray = Array.from(byteArray);
        return numberArray;

    }

    return []
}

export const DOWNLOAD_BYTES_FILE = (data: number[], filename: string) => {
    const blob = new Blob([new Uint8Array(DECODE_STR_TO_ARR(data as unknown as string))], { type: 'application/plain' });
    const url = URL.createObjectURL(blob);

    const element: HTMLAnchorElement = document.createElement('a');
    element.href = url;
    element.download = filename;
    document.body.appendChild(element);
    element.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(element);
}

export const DOWNLOAD_STRING_FILE = (data: string, filename: string) => {
    const blob = new Blob([data], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const element: HTMLAnchorElement = document.createElement('a');
    element.href = url;
    element.download = filename;
    document.body.appendChild(element);
    element.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(element);
}

export const GET_REPORT_FILENAME = (filename: string, fileType: string): string  => {
    const currentDate = new Date();
    return (filename + "_" + FORMAT_DATETIME_TO_VIEW(currentDate, "yyyyMMdd") + "." + fileType);
}