import { JackpotTierType } from "../../../dto/enums/Jackpot/JackpotTierType";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../../dto/models/GeneralModels";
import { TierModel } from "../../../dto/models/JackpotModels";
import { GetRequest, PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../../store/IStore";
import { TierActionTypes } from "./TierActionTypes";

type AddTierRequest = { type: TierActionTypes.ADD_TIER_REQUEST, payload: IPayload };
type EditTierRequest = { type: TierActionTypes.EDIT_TIER_REQUEST, payload: IPayload };
type GetTierListRequest = { type: TierActionTypes.GET_TIER_LIST_REQUEST, payload: IPayload };
type GetTierRequest = { type: TierActionTypes.GET_TIER_REQUEST, payload: IPayload };
type SelectTierRequest = { type: TierActionTypes.SELECT_TIER_REQUEST, payload: IPayload };
type SearchTierRequest = { type: TierActionTypes.SEARCH_TIER_REQUEST, payload: IPayload };

export type ActionTypes =
    AddTierRequest | EditTierRequest | GetTierListRequest | GetTierRequest | SelectTierRequest | SearchTierRequest;

export const AddTier = (
    condition: string, type: JackpotTierType, name: string, description: string, progressiveRate: number, progressiveHouse: number, reserveRate: number
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: TierModel = {
            tid: 0,
            typ: type,
            tnm: name,
            des: description,
            tcd: condition,
            tpr: progressiveRate,
            thr: progressiveHouse,
            trr: reserveRate,
        };
        PostRequest("BackOffice/AddTier", request, TierActionTypes.ADD_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, TierActionTypes.ADD_TIER_REQUEST, dispatch);
    }
}

export const EditTier = (
    id: number, type: JackpotTierType, condition: string, name: string, description: string, progressiveRate: number, progressiveHouse: number, reserveRate: number
) => async (dispatch: any) => {

    try {
        let request: TierModel = {
            tid: id,
            typ: type,
            tnm: name,
            des: description,
            tcd: condition,
            tpr: progressiveRate,
            thr: progressiveHouse,
            trr: reserveRate,
        };
        PostRequest("BackOffice/EditTier", request, TierActionTypes.EDIT_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, TierActionTypes.EDIT_TIER_REQUEST, dispatch);
    }

}

export const GetTier = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetTier", request, TierActionTypes.GET_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, TierActionTypes.GET_TIER_REQUEST, dispatch);
    }
}

export const SearchTier = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchTier", request, TierActionTypes.SEARCH_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, TierActionTypes.SEARCH_TIER_REQUEST, dispatch);
    }
}

export const GetTierList = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetTierList", TierActionTypes.GET_TIER_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, TierActionTypes.GET_TIER_LIST_REQUEST, dispatch);
    }
}

export const SelectTier = (id: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: id
        };
        PostRequest("BackOffice/SelectTier", request, TierActionTypes.SELECT_TIER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, TierActionTypes.SELECT_TIER_REQUEST, dispatch);
    }
}