import { faFloppyDisk, faKey, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import { ModalType } from "../../components/ModalBox/IModalBox";
import ModalBox from "../../components/ModalBox/ModalBox";
import StandardContainer from "../../components/StandardContainer";
import { ServiceType } from "../../dto/enums/ServiceType";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { DOWNLOAD_STRING_FILE } from "../../libraries/FileHelper";
import { GeneratePylonAuthenticationToken, GetPylonAuthentication } from "../../states/PylonAuthentication/PylonAuthenticationActions";
import { IStore } from "../../states/store/IStore";

const PylonAuthentication = () => {
    const dispatch = useDispatch();
    const pylonAuthenticationState = useSelector((state: IStore) => state.pylonAuthenticationState);
    const { loading, tokenLoading, serviceList, tokenRequestData, err, suc } = pylonAuthenticationState;

    const initTableData: any[][] = [
        ["Accounting Service", "N/A", <Button onClick={() => generate(ServiceType.FSG_ACCOUNTING_SERVICE)} disabled={tokenLoading} > <FontAwesomeIcon icon={faKey} /></Button>],
        ["Jackpot Service", "N/A", <Button onClick={() => generate(ServiceType.FSG_PAS_SERVICE)} disabled={tokenLoading} ><FontAwesomeIcon icon={faKey} /></Button>],
        ["Game Service", "N/A", <Button onClick={() => generate(ServiceType.FSG_GAME_SERVICE)} disabled={tokenLoading} ><FontAwesomeIcon icon={faKey} /></Button>],
        ["Reporting Service", "N/A", <Button onClick={() => generate(ServiceType.FSG_REPORTING_SERVICE)} disabled={tokenLoading} ><FontAwesomeIcon icon={faKey} /></Button>],
        ["Light House Gateway", "N/A", <Button onClick={() => generate(ServiceType.FSG_LIGHTHOUSE)} disabled={tokenLoading} ><FontAwesomeIcon icon={faKey} /></Button>]
    ];
    const initTable: IDataTable = {
        title: ["Services", "Last Generated At", "Generate & Download"],
        data: initTableData,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.NO_BORDER,
        isloading: loading
    };
    const [table, setTable] = useState(initTable);
    const [dialog, setDialog] = useState(false);
    const [service, setService] = useState(ServiceType.OTHER);

    const generate = (selectedService: ServiceType) => {
        setService(selectedService);
        setDialog(true);
    }

    const dialogBoxContent = () => {
        return <p>Generate a new service certificate will requires a restart of the FSG-Pylon and the particular services.<br/>Are you sure?</p>
    }

    const dialogBoxButton = () => {
        return (
            <div>
                <Button
                    color="info" 
                    onClick={() => {
                        dispatch(GeneratePylonAuthenticationToken(service));
                        setDialog(false);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faFloppyDisk} /> Confirm
                </Button>
                {' '}
                <Button
                    color="secondary"
                    onClick={() => {
                        setDialog(false);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faXmark} /> Cancel
                </Button>
            </div>
        );
    }

    useEffect(() => {
        dispatch(GetPylonAuthentication());
    }, []);

    useEffect(() => {
        if (tokenRequestData && tokenRequestData.stk) {
            // download file
            DOWNLOAD_STRING_FILE(tokenRequestData.stk, tokenRequestData.scn);

            dispatch(GetPylonAuthentication());
        }
    }, [tokenRequestData]);

    useEffect(() => {
        var temp_table: IDataTable = table;

        if (!loading && serviceList && serviceList.sam && temp_table.data) {
            if (serviceList.sam.length > 0) {
                serviceList.sam.map(item => {
                    switch (item.sst) {
                        case ServiceType.FSG_ACCOUNTING_SERVICE: {
                            initTableData[0] = [
                                "Accounting Service",
                                FORMAT_DATETIME_TO_VIEW(item.lga, "yyyy-MM-dd hh:mm:ss"),
                                <Button onClick={() => generate(ServiceType.FSG_ACCOUNTING_SERVICE)} disabled={tokenLoading} > <FontAwesomeIcon icon={faKey} /></Button>
                            ];
                            break;
                        }
                        case ServiceType.FSG_PAS_SERVICE: {
                            initTableData[1] = [
                                "Jackpot Service",
                                FORMAT_DATETIME_TO_VIEW(item.lga, "yyyy-MM-dd hh:mm:ss"),
                                <Button onClick={() => generate(ServiceType.FSG_PAS_SERVICE)} disabled={tokenLoading} > <FontAwesomeIcon icon={faKey} /></Button>
                            ];
                            break;
                        }
                        case ServiceType.FSG_GAME_SERVICE: {
                            initTableData[2] = [
                                "Game Service",
                                FORMAT_DATETIME_TO_VIEW(item.lga, "yyyy-MM-dd hh:mm:ss"),
                                <Button onClick={() => generate(ServiceType.FSG_GAME_SERVICE)} disabled={tokenLoading} > <FontAwesomeIcon icon={faKey} /></Button>
                            ];
                            break;
                        }
                        case ServiceType.FSG_REPORTING_SERVICE: {
                            initTableData[3] = [
                                "Reporting Service",
                                FORMAT_DATETIME_TO_VIEW(item.lga, "yyyy-MM-dd hh:mm:ss"),
                                <Button onClick={() => generate(ServiceType.FSG_REPORTING_SERVICE)} disabled={tokenLoading} > <FontAwesomeIcon icon={faKey} /></Button>
                            ];
                            break;
                        }
                        case ServiceType.FSG_LIGHTHOUSE: {
                            initTableData[4] = [
                                "LightHouse Gateway",
                                FORMAT_DATETIME_TO_VIEW(item.lga, "yyyy-MM-dd hh:mm:ss"),
                                <Button onClick={() => generate(ServiceType.FSG_LIGHTHOUSE)} disabled={tokenLoading} > <FontAwesomeIcon icon={faKey} /></Button>
                            ];
                            break;
                        }
                    }
                });

                temp_table.data = initTableData;
            }
        }
        setTable(temp_table);
    }, [serviceList]);

    return (
        <div>
            <ModalBox
                title={"Warning"}
                isOpen={dialog}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                child={dialogBoxContent()}
                type={ModalType.Dialog}
                openState={setDialog}
                footer={dialogBoxButton()}
                error={""}
                success={""}
            />
            <StandardContainer
                id="PylonAuthenticationMaintenance"
                loading={loading}
                error={err}
                success={suc}
                tableProps={table}
            />
        </div>
    );
}

export default PylonAuthentication;