import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { AccountingServiceListModel, AccountingServiceModel } from "../../dto/models/ServiceModels";
import { ActionTypes } from "./AccountingActions";
import { AccountingActionTypes } from "./AccountingActionTypes";
import { InitAccountingState, IAccountingStates } from "./AccountingStates";

export const AccountingReducers = (state = InitAccountingState, action: ActionTypes): IAccountingStates => {
    switch (action.type) {
        case AccountingActionTypes.SELECT_ACCOUNTING_REQUEST:
        case AccountingActionTypes.ADD_ACCOUNTING_REQUEST:
        case AccountingActionTypes.EDIT_ACCOUNTING_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, accData: {} as AccountingServiceModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var accConfigModel: AccountingServiceModel = {} as AccountingServiceModel;

                if (data && !data.err) {
                    accConfigModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == AccountingActionTypes.ADD_ACCOUNTING_REQUEST) {
                        sucMsg = "Account Service Added Successfully.";
                    }
                    else if (action.type == AccountingActionTypes.EDIT_ACCOUNTING_REQUEST) {
                        sucMsg = "Account Service Updated Successfully.";
                    }
                }
                //  Set accState for IStore--> accState
                return { ...state, loading: false, accData: accConfigModel, err: errMsg, suc: sucMsg };
            }
        }
        case AccountingActionTypes.GET_ACCOUNTING_REQUEST:
        case AccountingActionTypes.SEARCH_ACCOUNTING_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, accListData: {} as AccountingServiceListModel, accData: {} as AccountingServiceModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var accConfigListModel: AccountingServiceListModel = {} as AccountingServiceListModel;

                if (data && !data.err) {
                    accConfigListModel = data.res;
                }
                return { ...state, loading: false, accListData: accConfigListModel, err: action.payload.err ? action.payload.err : "" };
            }
        case AccountingActionTypes.REFRESH_ACCOUNTING_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, err: "", suc: ""
                };
            }
            else {
                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    sucMsg = "Accounting Service Refreshed!";
                }

                return { ...state, loading: false, err: errMsg, suc: sucMsg };
            }
        default:
            return state
    }
}
