import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import StandardReportContainer from "../../components/StandardReportContainer"
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { JackpotPoolType } from "../../dto/enums/Jackpot/JackpotPoolType";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GenerateJackpotSummaryContributionCSVRequest, GenerateJackpotSummaryContributionPDFRequest, GetJackpotContributionSummaryReportRequest } from "../../states/Reporting/JackpotContributionReport/JackpotContributionReportingActions";
import { IStore } from "../../states/store/IStore";

const JackpotContributionSummaryReporting = () => {
    const dispatch = useDispatch();
    const poolContributionReportState = useSelector((state: IStore) => state.poolContributionReportState);
    const { contributionReportSummaryListData, loading, err, suc } = poolContributionReportState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    // Search Criteria
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const take = (page: number) => {
        // TODO: dispatch change page, provide current page
        if (valid()) {
            setPage(page);
            dispatch(GetJackpotContributionSummaryReportRequest(new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    useEffect(() => {
        setPagination(summaryPagination);
    }, [contributionReportSummaryListData.ttc])

    const summaryPagination: ITablePagination = {
        id: "contributionReportSummaryPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? contributionReportSummaryListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const emptyTableSummary: IDataTable = {
        title: ["No.", "Pool Name", "Pool Type", "Start Amount", "Jackpot Contribution (+)", "Jackpot Strike (-)", "Jackpot Startout (+)", "Adjustment (+/-)", "End Amount"],
        columnWidth: ["50px", "130px", "150px", "200px", "220px", "200px", "200px", "200px", "200px"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTableSummary)
    const [pagination, setPagination] = useState(summaryPagination)

    useEffect(() => {
        var table: IDataTable = emptyTableSummary;
        var index: number = currentPage * TABLE_MAX_ROW;
        var totalJackpotContribution: number = 0;
        var totalJackpotStrike: number = 0;
        var totalJackpotStartout: number = 0;
        var totalAdjustment: number = 0;

        if (contributionReportSummaryListData && contributionReportSummaryListData.srml != undefined && hasSearched) {
            table.data = contributionReportSummaryListData.srml.map(x => {
                totalJackpotContribution += x.cam;
                totalJackpotStrike += x.sam;
                totalJackpotStartout += x.soam;
                totalAdjustment += x.aam;

                return [
                    1 + index++,
                    x.pnm,
                    JackpotPoolType[x.typ] ? JackpotPoolType[x.typ].toString().replace("_", " ") : "",
                    CVT_TO_FLOAT_STRING(x.str),
                    CVT_TO_FLOAT_STRING(x.cam),
                    CVT_TO_FLOAT_STRING(x.sam),
                    CVT_TO_FLOAT_STRING(x.soam),
                    CVT_TO_FLOAT_STRING(x.aam),
                    CVT_TO_FLOAT_STRING(x.end)
                ]
            });

            table.concludeColumn = [
                { columnIndex: 4, content: CVT_TO_FLOAT_STRING(totalJackpotContribution) },
                { columnIndex: 5, content: CVT_TO_FLOAT_STRING(totalJackpotStrike) },
                { columnIndex: 6, content: CVT_TO_FLOAT_STRING(totalJackpotStartout) },
                { columnIndex: 7, content: CVT_TO_FLOAT_STRING(totalAdjustment) },
            ];

        } else
            table.data = [];
        setReportTable(table);
    }, [contributionReportSummaryListData])

    const valid = (): boolean => {
        return fromDate !== "" && toDate !== "" && toDateValid && fromDateValid;
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);

            setReportTable(emptyTableSummary)
            dispatch(GetJackpotContributionSummaryReportRequest(new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));

        } else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
            TRIGGER_BLUR("poolSelect");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateJackpotSummaryContributionPDFRequest(new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateJackpotSummaryContributionCSVRequest(new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker isRequired label="Transaction Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col>
                            <Button className="mt-3" color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="JackpotContribution"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}

export default JackpotContributionSummaryReporting;
