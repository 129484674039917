import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import DropdownSelectBox from "../../components/InputBox/DropdownSelectBox";
import { IDropdownSelectOption, LoadDropdownOptionFromEnum } from "../../components/InputBox/IDropdownSelectBox";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { CmsType } from "../../dto/enums/CmsType";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_ROW } from "../../libraries/Global";
import { GeneratePlayerLeftOverBalanceCSVRequest, GeneratePlayerLeftOverBalancePDFRequest, GetPlayerLeftOverBalanceReportRequest } from "../../states/Reporting/PlayerLeftOverBalanceReport/PlayerLeftOverBalanceReportActions";
import { GeneratePlayerLockCSVRequest, GeneratePlayerLockPDFRequest, GetPlayerLockReportRequest } from "../../states/Reporting/PlayerLockReport/PlayerLockReportActions";
import { IStore } from "../../states/store/IStore";


const PlayerLockReporting = () => {
    const dispatch = useDispatch();
    const playerLockReportState = useSelector((state: IStore) => state.playerLockReportState);
    const { playerLockReportListData, loading, err, suc } = playerLockReportState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    const [cmsOption] = useState(LoadDropdownOptionFromEnum(CmsType));
    const [actionOption, setActionOption] = useState([] as ISelectOption[]);

    // Search Criteria
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [membershipID, setMembershipID] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);
    const [selectedCms, selectCms] = useState<number[]>([]);
    const [selectedCmsValid, setCmsValid] = useState(false);
    const [selectedAction, selectAction] = useState(-1);

    // Search Criteria
    const emptyTable: IDataTable = {
        title: ["No.", "CMS Type", "Player Name/ID", "Action", "Remark", "Action By", "Transaction Date Time"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        const actions: ISelectOption[] =
            [
                { display: "Lock/Unlock", value: "-1" } as ISelectOption,
                { display: "Lock", value: "0" } as ISelectOption,
                { display: "Unlock", value: "1" } as ISelectOption
            ]

        setActionOption(actions);
    }, []);

    useEffect(() => {
        var table: IDataTable = emptyTable
        var index: number = currentPage * TABLE_MAX_ROW;
        if (playerLockReportListData && playerLockReportListData.rml != undefined && hasSearched) {
            table.data = playerLockReportListData.rml.map(x => {
                return [
                    1 + index++,
                    CmsType[x.cms].replace("_", " "),
                    x.pnm + " (" + x.pid + ")",
                    x.act == 0 ? "Lock" : "Unlock",
                    x.rmk,
                    x.acb,
                    FORMAT_DATETIME_TO_VIEW(new Date(x.aca), "yyyy-MM-dd hh:mm:ssa")
                ]
            });
        } else
            table.data = [];

        setReportTable(table);

    }, [playerLockReportListData]);

    const valid = (): boolean => {
        return fromDateValid && toDateValid && selectedCmsValid;
    }

    const take = (page: number) => {
        setPage(page);
        dispatch(GetPlayerLockReportRequest(selectedCms, selectedAction, membershipID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "playerLockPagination",
        maxPage: 8,
        onChangePage: take,
        totalCount: hasSearched ? playerLockReportListData && playerLockReportListData.ttc ? playerLockReportListData.ttc : 0 : 0,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);
            dispatch(GetPlayerLockReportRequest(selectedCms, selectedAction, membershipID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        } else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
            TRIGGER_BLUR("cmsSelectDropdown");
            TRIGGER_BLUR("actionDropdown");
        }
    }

    const generatePDF = () => {
        setHasSearched(true);
        dispatch(GeneratePlayerLockPDFRequest(selectedCms, selectedAction, membershipID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const generateCSV = () => {
        setHasSearched(true);
        dispatch(GeneratePlayerLockCSVRequest(selectedCms, selectedAction, membershipID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker isRequired label="Action Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col md={4}>
                            <DropdownSelectBox allowSelectAll={true} id="cmsSelectDropdown" name="cmsSelectDropdown" options={cmsOption}
                                isRequired={true} inputState={selectCms} validState={setCmsValid} placeholder="----Select Cms Type----" label="Cms Type" />
                        </Col>
                        <Col md={4}>
                            <SelectBox id="actionDropdown" name="actionDropdown" options={actionOption}
                                isRequired={true} inputState={selectAction} label="Action" />
                        </Col>
                        <Col md={4}>
                            <InputBox
                                type={TextboxType.TEXT}
                                id="membershipID"
                                name="membershipID"
                                label="Membership ID"
                                inputState={setMembershipID}
                                value={membershipID}
                                HasCancelledButton={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="PlayerLockReporting"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}



export default PlayerLockReporting;