import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { TextboxType } from "../InputBox/IInputBox";
import InputBox from "../InputBox/InputBox";
import { IDateTimeRangePicker } from "./IDateTimeRangePicker";

const DateTimeRangePicker = (props: IDateTimeRangePicker) => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    useEffect(() => {
        props.fromDateinputState(fromDate);
    }, [fromDate]);

    useEffect(() => {
        props.toDateinputState(toDate);
    }, [toDate]);

    useEffect(() => {
        props.toDatevalidState(toDateValid);
    }, [toDateValid]);

    useEffect(() => {
        props.fromDatevalidState(fromDateValid);
    }, [fromDateValid]);


    useEffect(() => {
        if (fromDate !== "" && toDate !== "") {
            const fromDate1 = new Date(fromDate);
            const toDate1 = new Date(toDate);
            const input: HTMLInputElement = document.getElementById(props.fromDatePickerID) as HTMLInputElement;

            if (toDate1 < fromDate1) {
                setFromDate(toDate);
                input.value = toDate;
            }
        }
    }, [toDate])


    useEffect(() => {
        if (fromDate !== "" && toDate !== "") {
            const fromDate1 = new Date(fromDate);
            const toDate1 = new Date(toDate);
            const input: HTMLInputElement = document.getElementById(props.toDatePickerID) as HTMLInputElement;
            
            if (fromDate1 > toDate1) {
                setToDate(fromDate);
                input.value = fromDate;
            }
        }
    }, [fromDate])

    return (
        <div>
            {props.hideTime ? <Row>
                <Col md={6}>
                    <InputBox
                        id={props.fromDatePickerID}
                        name="fromDate"
                        label={props.label + " From"}
                        type={TextboxType.DATE}
                        placeholder={"From Date"}
                        isRequired={props.isRequired}
                        inputState={setFromDate}
                        validState={setFromDateValid}
                        value={fromDate}
                        HasCancelledButton={true}
                    />
                </Col>
                <Col md={6}>
                    <InputBox
                        id={props.toDatePickerID}
                        name="toDate"
                        label={props.label + " To"}
                        type={TextboxType.DATE}
                        min={fromDate ? new Date(fromDate).toISOString().slice(0, 10) : undefined}
                        placeholder={"To Date"}
                        isRequired={props.isRequired}
                        inputState={setToDate}
                        validState={setToDateValid}
                        value={toDate}
                        HasCancelledButton={true}
                    />
                </Col>
            </Row> :
                <Row>
                    <Col md={6}>
                        <InputBox
                            id={props.fromDatePickerID}
                            name="fromDate"
                            label={props.label + " From"}
                            type={TextboxType.DATE_TIME}
                            placeholder={"From Date"}
                            isRequired={props.isRequired}
                            inputState={setFromDate}
                            validState={setFromDateValid}
                            value={fromDate}
                            HasCancelledButton={true}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            id={props.toDatePickerID}
                            name="toDate"
                            label={props.label + " To"}
                            type={TextboxType.DATE_TIME}
                            placeholder={"To Date"}
                            isRequired={props.isRequired}
                            min={fromDate ? new Date(fromDate).toISOString().slice(0, 16) : undefined}
                            inputState={setToDate}
                            validState={setToDateValid}
                            value={toDate}
                            HasCancelledButton={true}
                        />
                    </Col>
                </Row>}      
        </div>
    )
}

export default DateTimeRangePicker;