import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { CashierAccess } from "../../dto/enums/AccessMatrix/CashierAccess";
import { ReportingAccess } from "../../dto/enums/AccessMatrix/ReportingAccess";
import { TableStatus } from "../../dto/enums/TableStatus";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { ApprovalTierModel } from "../../dto/models/JackpotModels";
import { RoleModel } from "../../dto/models/UserAccessModels";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { AccessMatrixActionTypes } from "./AccessMatrixActionTypes";

type GetAccessMatrixRequest = { type: AccessMatrixActionTypes.GET_ACCESS_MATRIX_REQUEST, payload: IPayload };
type SelectAccessMatrixRequest = { type: AccessMatrixActionTypes.SELECT_ACCESS_MATRIX_REQUEST, payload: IPayload };
type EditAccessMatrixRequest = { type: AccessMatrixActionTypes.EDIT_ACCESS_MATRIX_REQUEST, payload: IPayload };
type AddAccessMatrixRequest = { type: AccessMatrixActionTypes.ADD_ACCESS_MATRIX_REQUEST, payload: IPayload };
type SearchAccessMatrixRequest = { type: AccessMatrixActionTypes.SEARCH_ACCESS_MATRIX_REQUEST, payload: IPayload };
type ReadAccessMatrixListRequest = { type: AccessMatrixActionTypes.READ_ACCESS_MATRIX_LIST_REQUEST, payload: IPayload };

export type ActionTypes =
    GetAccessMatrixRequest | SelectAccessMatrixRequest | EditAccessMatrixRequest | AddAccessMatrixRequest | SearchAccessMatrixRequest | ReadAccessMatrixListRequest;

export const AddAccessMatrix = (
    roleName: string, desc: string, status: TableStatus, approvalTier: ApprovalTierModel,
    backOfficeReadAccess: BackOfficeAccess, backOfficeWriteAccess: BackOfficeAccess,
    reportingAccess: ReportingAccess, cashierAccess: CashierAccess
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: RoleModel = {
            rid: 0,
            rnm: roleName,
            des: desc,
            stt: status,
            apt: approvalTier,
            bor: backOfficeReadAccess,
            bow: backOfficeWriteAccess,
            rpa: reportingAccess,
            caa: cashierAccess,
            
        };
        PostRequest("BackOffice/AddAccessMatrix", request, AccessMatrixActionTypes.ADD_ACCESS_MATRIX_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccessMatrixActionTypes.ADD_ACCESS_MATRIX_REQUEST, dispatch);
    }
}

export const EditAccessMatrix = (
    rid: number, roleName: string, desc: string, status: TableStatus, approvalTier: ApprovalTierModel,
    backOfficeReadAccess: BackOfficeAccess, backOfficeWriteAccess: BackOfficeAccess,
    reportingAccess: ReportingAccess, cashierAccess: CashierAccess
) => async (dispatch: any) => {

    try {
        let request: RoleModel = {
            rid: rid,
            rnm: roleName,
            des: desc,
            stt: status,
            apt: approvalTier,
            bor: backOfficeReadAccess,
            bow: backOfficeWriteAccess,
            rpa: reportingAccess,
            caa: cashierAccess,

        };
        PostRequest("BackOffice/EditAccessMatrix", request, AccessMatrixActionTypes.EDIT_ACCESS_MATRIX_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccessMatrixActionTypes.EDIT_ACCESS_MATRIX_REQUEST, dispatch);
    }

}

export const GetAccessMatrix = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetAccessMatrix", request, AccessMatrixActionTypes.GET_ACCESS_MATRIX_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccessMatrixActionTypes.GET_ACCESS_MATRIX_REQUEST, dispatch);
    }
}

export const SearchAccessMatrix = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchAccessMatrix", request, AccessMatrixActionTypes.SEARCH_ACCESS_MATRIX_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccessMatrixActionTypes.SEARCH_ACCESS_MATRIX_REQUEST, dispatch);
    }
}

export const SelectAccessMatrix = (rid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: rid
        };
        PostRequest("BackOffice/SelectAccessMatrix", request, AccessMatrixActionTypes.SELECT_ACCESS_MATRIX_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccessMatrixActionTypes.SELECT_ACCESS_MATRIX_REQUEST, dispatch);
    }
}

export const GetAccessMatrixList = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetAccessMatrixList", AccessMatrixActionTypes.READ_ACCESS_MATRIX_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccessMatrixActionTypes.READ_ACCESS_MATRIX_LIST_REQUEST, dispatch);
    }
}