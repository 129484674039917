import { PlayerLeftOverBalanceRequestModel } from "../../../dto/models/Reporting/PlayerLeftOverBalanceRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { PlayerLeftOverBalanceReportingActionTypes } from "./PlayerLeftOverBalanceReportActionTypes";

type GetPlayerLeftOverBalanceReportRequest = { type: PlayerLeftOverBalanceReportingActionTypes.GET_PLAYER_LEFT_OVER_BALANCE_REPORT_REQUEST, payload: IPayload };
type GeneratePlayerLeftOverBalancePDFRequest = { type: PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_PDF_REQUEST, payload: IPayload };
type GeneratePlayerLeftOverBalanceCSVRequest = { type: PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetPlayerLeftOverBalanceReportRequest | GeneratePlayerLeftOverBalancePDFRequest | GeneratePlayerLeftOverBalanceCSVRequest;

export const GetPlayerLeftOverBalanceReportRequest = (
    from: number, take: number, username?: string
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: PlayerLeftOverBalanceRequestModel = {
            unm: username,
            ign: false,
            frm: from,
            tak: take,
        };
        PostRequest("Reporting/GetPlayerLeftOverBalanceReport", request, PlayerLeftOverBalanceReportingActionTypes.GET_PLAYER_LEFT_OVER_BALANCE_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLeftOverBalanceReportingActionTypes.GET_PLAYER_LEFT_OVER_BALANCE_REPORT_REQUEST, dispatch);
    }
}

export const GeneratePlayerLeftOverBalancePDFRequest = (
    from: number, take: number, username?: string
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: PlayerLeftOverBalanceRequestModel = {
            unm: username,
            ign: true,
            frm: from,
            tak: take,
        };
        PostRequest("Reporting/GetPlayerLeftOverBalancePDF", request, PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_PDF_REQUEST, dispatch);
    }
}

export const GeneratePlayerLeftOverBalanceCSVRequest = (
    from: number, take: number, username?: string
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: PlayerLeftOverBalanceRequestModel = {
            unm: username,
            ign: true,
            frm: from,
            tak: take,
        };
        PostRequest("Reporting/GetPlayerLeftOverBalanceCSV", request, PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLeftOverBalanceReportingActionTypes.GENERATE_PLAYER_LEFT_OVER_BALANCE_CSV_REQUEST, dispatch);
    }
}

