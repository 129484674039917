import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { SlotGameRTP, SlotGameType } from "../../../dto/enums/SlotGames";
import { DECODE_STR_TO_ARR, GET_FILE_DATA } from "../../../libraries/FileHelper";
import { CVT_TO_FLOAT_STRING } from "../../../libraries/FloatHelper";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { HosehLiao } from "../../../libraries/GameConstant";
import { GET_PROFILE } from "../../../libraries/Global";
import { GetDenominationRequest } from "../../../states/Denomination/DenominationActions";
import { AddGameRequest, EditGameRequest, GameGetCreditRateRequest, SelectGameRequest } from "../../../states/Game/GameActions";
import { IStore } from "../../../states/store/IStore";
import CheckBoxGroup from "../../InputBox/CheckBoxGroup";
import { ICheckBoxOption, LoadCheckboxOptionFromEnum } from "../../InputBox/ICheckBoxGroup";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import TextField from "../../InputBox/TextField";
import ToggleButton from "../../InputBox/ToggleButton";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IGameMaintenanceForm {
    isEdit: boolean;
    loading: boolean;
    id?: number;

    isSaveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const GameMaintenanceForm = (props: IGameMaintenanceForm) => {
    const dispatch = useDispatch();
    const [isLoaded, setLoaded] = useState(false);
    const gameState = useSelector((state: IStore) => state.gameState);
    const { gameData, creditRate } = gameState;
    const denominationState = useSelector((state: IStore) => state.denominationState);
    const { denominationList } = denominationState;
    const gameLoading = gameState.loading;
    const denomLoading = denominationState.loading;

    // input & validation
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState(SlotGameType.HOSEH_LIAO);
    const [rtp, setRtp] = useState(SlotGameRTP.DEFAULT);
    const [gamble, setGamble] = useState([] as string[]);
    const [denomination, setDenomination] = useState(0);
    const [gameUrl, setGameUrl] = useState("");
    const [gameIcon, setGameIcon] = useState([] as number[]);
    const [allowAutoSpin, setAllowAutoSpin] = useState(false);
    const [allowShowRtp, setAllowShowRtp] = useState(false);

    const [nameValid, setNameValid] = useState(props.isEdit);
    const [descriptionValid, setDescriptionValid] = useState(true);
    const [typeValid, setTypeValid] = useState(props.isEdit);
    const [rtpValid, setRtpValid] = useState(props.isEdit);
    const [denominationValid, setDenominationValid] = useState(props.isEdit);
    const [gameUrlValid, setGameUrlValid] = useState(props.isEdit);
    const [gameIconValid, setGameIconValid] = useState("");

    // dropdown, checkbox options
    const [rtpOption, setRtpOption] = useState([] as ISelectOption[]);
    const [gambleOption, setGambleOption] = useState([] as ICheckBoxOption[]);
    const [denominationOption, setDenominationOption] = useState([] as ISelectOption[]);
    const gameOptions: ISelectOption[] = LoadSelectOptionFromEnum(SlotGameType);
    const initializeOption = (): void => {
        switch (type) {
            case SlotGameType.HOSEH_LIAO: {
                const rtps: ISelectOption[] = [
                    { display: "RTP 88% ±", value: HosehLiao.RTP.RTP_88.toString() },
                    { display: "RTP 90% ±", value: HosehLiao.RTP.RTP_90.toString() },
                    { display: "RTP 92% ±", value: HosehLiao.RTP.RTP_92.toString() },
                    { display: "RTP 94% ±", value: HosehLiao.RTP.RTP_94.toString() },
                    { display: "RTP 96% ±", value: HosehLiao.RTP.RTP_96.toString() },
                    { display: "RTP 98% ±", value: HosehLiao.RTP.RTP_98.toString() },
                ]
                const gambles: ICheckBoxOption[] = LoadCheckboxOptionFromEnum(HosehLiao.Gamble);

                setRtpOption(rtps);
                setGambleOption(gambles);
                break;
            }
            default:
                break;
        }
    }

    useEffect(() => {
        setLoaded(!gameLoading && !denomLoading);
    }, [gameLoading, denomLoading])

    // save button
    const save = () => {
        if (nameValid && descriptionValid && typeValid && rtpValid && denominationValid && gameUrlValid && gameIcon.length > 0) {
            let gmb: number = 0;
            gamble.forEach((item) => {
                gmb += Number.parseInt(item.toString());
            });

            if (props.isEdit && props.id) {
                dispatch(EditGameRequest(props.id, name, description, type, rtp, gmb, denomination, gameIcon, gameUrl, allowAutoSpin, allowShowRtp));
            }
            else {
                dispatch(AddGameRequest(name, description, type, rtp, gmb, denomination, gameIcon, gameUrl, allowAutoSpin, allowShowRtp));
            }
        }
        else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("type");
            TRIGGER_BLUR("rtp");
            TRIGGER_BLUR("gamble");
            TRIGGER_BLUR("description");
            TRIGGER_BLUR("denomination");
            TRIGGER_BLUR("url");

            if (gameIcon == [] || gameIcon.length == 0 || !gameIcon) {
                setGameIconValid("Game Icon is required.");
            }

            props.setSaveTrigger(false);
        }
    }

    // game icon upload
    const onFileChange = async (files?: FileList) => {
        if (files) {
            if (files && files.length > 0) {
                var file = files[0];
                if (!file.type.startsWith("image/")) {
                    setGameIcon([]);
                    setGameIconValid("Invalid File Type. The file must be image.");
                }
                else {
                    if (file.size > 3000000) {
                        setGameIcon([]);
                        setGameIconValid("The size of image file cannot be more than 3000kb.");
                    }
                    else {
                        var x = await GET_FILE_DATA(files);
                        await setGameIcon(x);
                        setGameIconValid("");
                    }
                }
            }
        }
    }

    // on state changed
    useEffect(() => {
        if (props.isEdit && props.id) {
            dispatch(SelectGameRequest(props.id));
        }

        dispatch(GameGetCreditRateRequest());
        dispatch(GetDenominationRequest(0, 0));
    }, []);

    useEffect(() => {
        if (denominationList.dnl) {
            const denoms: ISelectOption[] = denominationList.dnl.map((item) => {
                return {
                    display: CVT_TO_FLOAT_STRING(item.dva),
                    value: item.dva.toString()
                }
            });

            setDenominationOption(denoms);
        }
    }, [denominationList]);

    useEffect(() => {
        if (gameData.gid) {

            setName(gameData.gmn);
            setDescription(gameData.des);
            setType(gameData.typ);
            setRtp(gameData.rtp);
            setGameUrl(gameData.gur);
            setGameIcon(DECODE_STR_TO_ARR(gameData.gic as unknown as string));
            setAllowAutoSpin(gameData.aas);
            setAllowShowRtp(gameData.isr);
            setDenomination(gameData.dnm);
        }

        if (props.isEdit) {
            switch (gameData.typ) {
                case SlotGameType.HOSEH_LIAO: {
                    const gambles: string[] = [];
                    if (HASFLAG(gameData.gbg, HosehLiao.Gamble.LUCKY_SPIN)) {
                        gambles.push(HosehLiao.Gamble.LUCKY_SPIN.toString());
                    }

                    if (HASFLAG(gameData.gbg, HosehLiao.Gamble.COIN_FLIP)) {
                        gambles.push(HosehLiao.Gamble.COIN_FLIP.toString());
                    }

                    setGamble(gambles);
                    break;
                }
                default:
                    return;
            }
        }
        initializeOption();
    }, [gameData, type]);

    useEffect(() => {
        if (props.isSaveTrigger) {
            save();
        }
    }, [props.isSaveTrigger]);

    if (isLoaded) {
        return (
            <Form>
                <Row>
                    <Col>
                        <InputBox id="name" name="name" label="Name" placeholder="Game Name"
                            type={TextboxType.TEXT} maxlength={50}
                            value={name} inputState={setName} validState={setNameValid}
                            isDisabled={props.isEdit || !props.canEdit} isRequired={true}
                        />
                    </Col>
                    <Col>
                        <SelectBox id="type" name="type" label="Game Type"
                            options={gameOptions} value={gameData.typ}
                            inputState={setType} validState={setTypeValid}
                            isDisabled={props.isEdit || !props.canEdit} isRequired={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ToggleButton id="autoSpin" name="autoSpin" label="Allow Auto Spin"
                            value={gameData.aas} inputState={setAllowAutoSpin} isDisabled={!props.canEdit} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <InputBox id="url" name="url" label="Game URL"
                            type={TextboxType.URL} maxlength={100}
                            value={gameUrl} inputState={setGameUrl} validState={setGameUrlValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="gameicon">
                                Game Icon
                            </Label>
                            <Input
                                id="gameicon"
                                name="gameicon"
                                type='file'
                                onChange={e => onFileChange(e.target.files ? e.target.files : undefined)}
                                invalid={gameIconValid != ""}
                                disabled={!props.canEdit}
                            />
                            <FormFeedback>{gameIconValid}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SelectBox id="rtp" name="rtp" label="RTP (%)"
                            options={rtpOption} value={gameData.rtp}
                            inputState={setRtp} validState={setRtpValid}
                            isRequired={true}
                            isDisabled={!props.canEdit}
                        />
                        <ToggleButton id="showRtp" name="showRtp" label="Show RTP"
                            value={gameData.isr} inputState={setAllowShowRtp} isDisabled={!props.canEdit} />
                    </Col>
                    <Col md={6}>
                        <SelectBox id="denomination" name="denomination" label="Chip Denomination"
                            options={denominationOption} value={gameData.dnm}
                            inputState={setDenomination} validState={setDenominationValid}
                            isDisabled={!props.canEdit} isRequired={true}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <TextField id="description" name="description" label="Description"
                            placeholder="Description" value={gameData.des}
                            inputState={setDescription} maxLength={200} col={5} row={2}
                            validState={setDescriptionValid}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                    <Col>
                        <CheckBoxGroup id="gamble" label="Gamble Games" values={gamble}
                            options={gambleOption} inputState={setGamble}
                            inline={false}
                            isDisabled={!props.canEdit}
                        />
                    </Col>
                </Row>
            </Form>
        );

    } else {
        return (<div className='text-center p-4'><Spinner key='1' /></div>);
    }
}

const GameMaintenanceModal = (props: { isEdit: boolean, id?: number, isOpen: boolean, setOpenState: any }) => {
    const gameState = useSelector((state: IStore) => state.gameState);
    const denomState = useSelector((state: IStore) => state.denominationState);
    const { err, suc, loading } = gameState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        setSave(false);
    }, [loading]);


    useEffect(() => {
        if (denomState.err !== "") {
            setErrMsg(denomState.err)
            return;
        }

        if (err !== "") {
            setErrMsg(err)
            return;
        }

        setErrMsg("");
    }, [denomState.err, err])

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.GAME_MAINTENANCE))
        }

    }, [])

    return (
        <ModalBox
            title={props.isEdit ? "Edit Game" : "Add New Game"}
            isOpen={props.isOpen}
            isScrollable={true}
            child={<GameMaintenanceForm isEdit={props.isEdit} canEdit={canEdit} id={props.id} loading={loading} isSaveTrigger={save} setSaveTrigger={setSave} />}
            type={ModalType.Window}
            openState={props.setOpenState}
            error={errMsg}
            success={suc}
            footer={canEdit ? < Button color="info" onClick={() => setSave(true)} outline disabled={loading}><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
        />
    );
}

export default GameMaintenanceModal;