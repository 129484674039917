import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { LockPlayer, UnlockPlayer } from "../../../states/PlayerLock/PlayerLockActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import TextField from "../../InputBox/TextField";
import { CashierAction } from "../../../dto/enums/CashierAction";
import ModalBox from "../../ModalBox/ModalBox";
import { ModalType } from "../../ModalBox/IModalBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

interface IPlayerLockForm {
    loading: boolean;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    player: string;
    action: CashierAction;
}

const PlayerLockForm = (props: IPlayerLockForm) => {
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;
    const [userID, setUserID] = useState(0);
    const [userName, setUserName] = useState("");
    const [remarks, setRemarks] = useState("");
    const [remarksValid, setRemarksValid] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setUserID(userProfileData.uid);
        setUserName(userProfileData.fnm);
    }, [])

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    const Valid = (): boolean => {
        return remarksValid
    }

    const save = () => {
        if (Valid() && remarks) {
            if (props.action == CashierAction.LOCK)
                dispatch(LockPlayer(props.player, userID, remarks))
            else if (props.action == CashierAction.UNLOCK)
                dispatch(UnlockPlayer(props.player, userID, remarks))
        }

        props.setSaveTrigger(false);
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col>
                        <Row className="mb-2">
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="actionPerformedBy">
                                        Resolved By
                                    </Label>
                                    <InputBox
                                        id="actionPerformedBy"
                                        name="actionPerformedBy"
                                        type={TextboxType.TEXT}
                                        value={userName}
                                        isDisabled={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <TextField id="playerLockRemarks" name="playerLockRemarks" label="Remarks"
                                placeholder="Remarks" value={remarks}
                                validState={setRemarksValid}
                                isRequired={true}
                                inputState={setRemarks} maxLength={300} col={5} row={5}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>

        </Container>
    );
}

const PlayerLockModal = (props: { isOpen: boolean, setOpenState: any, player: string, action: CashierAction }) => {
    const playerLockState = useSelector((state: IStore) => state.playerLockState);
    const { err, suc, loading } = playerLockState;
    const [save, setSave] = useState(false);

    useEffect(() => {
        props.setOpenState(false)
    }, [save]);

    return (
        <div>
            <ModalBox
                title={props.action == CashierAction.LOCK ? "Lock Player" : "Unlock Player"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <PlayerLockForm loading={loading} saveTrigger={save} setSaveTrigger={setSave} player={props.player} action={props.action} />
                }
                type={ModalType.Dialog}
                openState={props.setOpenState}
                footer={<Button color="info" onClick={() => setSave(true)} outline disabled={suc != ""}><FontAwesomeIcon icon={faFloppyDisk} /><span className="m-2" >Submit</span></Button>}
            />
        </div>
    );
}

export default PlayerLockModal;
