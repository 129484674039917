import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import GameMaintenanceModal from '../../components/_modals/Game/GameMaintenanceModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { SlotGameType } from '../../dto/enums/SlotGames';
import { SlotGameConfigurationModel } from '../../dto/models/ConfigurationModels';
import { FORMAT_DATETIME_TO_VIEW } from '../../libraries/DateHelper';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetGameRequest, SearchGameRequest } from '../../states/Game/GameActions';
import { IStore } from '../../states/store/IStore';

const GameMaintenance = () => {
    const dispatch = useDispatch();
    const gameState = useSelector((state: IStore) => state.gameState);
    const { loading, err, suc, gameList } = gameState;

    // main screen state
    const [currentPage, setPage] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    // modal state
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [id, setId] = useState(0);
    const [isSearch, setIsSearch] = useState(false);

    const gameListTable = (): IDataTable => {
        var table: IDataTable = {
            title: ["No.", "Name", "Game Type", "Last Update", "View"],
            data: [],
            isStriped: false,
            isHover: true,
            tableStyle: TableStyle.ALL_BORDER,
            isloading: loading
        };

        if (!loading && gameList) {
            if (gameList.glt && gameList.ttc > 0) {
                const startIndex = currentPage * TABLE_MAX_ROW;
                table.data = gameList.glt.map((item, index) => {
                    return [
                        startIndex + index + 1,
                        item.gmn,
                        SlotGameType[item.typ].toString().replace("_", " "),
                        item.udt ? FORMAT_DATETIME_TO_VIEW(item.udt, "yyyy-MM-dd hh:mm:ss") : "N/A",
                        <Button onClick={() => EditGame(item.gid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>,
                    ];
                });
            }
        }

        return table;
    }

    useEffect(() => {
        if (!openModal) {
            dispatch(GetGameRequest(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const EditGame = (id: number): void => {
        setEdit(true);
        setId(id);
        setOpenModal(true);
    }

    const AddGame = (): void => {
        setEdit(false);
        setId(0);
        setOpenModal(true);
    }

    const search = (): void => {
        setPage(0);
        dispatch(SearchGameRequest(searchInput, 0, TABLE_MAX_ROW));
        setIsSearch(true);
    }

    const take = (page: number) => {
        setPage(page);
        dispatch(GetGameRequest(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "gamePagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: gameList ? gameList.ttc ?? 0 : 0
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetGameRequest(0, TABLE_MAX_ROW));
        setIsSearch(false);
        setSearchInput("")
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.GAME_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <GameMaintenanceModal isEdit={isEdit} setOpenState={setOpenModal} isOpen={openModal} id={id} />
            <StandardContainer
                id="GameMaintenanceContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddGame : undefined}
                tableProps={gameListTable()}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default GameMaintenance;