import { ActionStatus } from "../../../dto/enums/ActionStatus";
import { CreditTransferType } from "../../../dto/enums/TransferMode";
import { CreditTransactionRequestModel } from "../../../dto/models/Reporting/CreditTransactionRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { CreditTransactionReportingActionTypes } from "./CreditTransactionReportingActionTypes";
type GetCreditTranscationReportRequest = { type: CreditTransactionReportingActionTypes.GET_CREDIT_TRANSACTION_REPORT_REQUEST, payload: IPayload };
type GenerateCreditTranscationPDFRequest = { type: CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_PDF_REQUEST, payload: IPayload };
type GenerateCreditTranscationCSVRequest = { type: CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetCreditTranscationReportRequest | GenerateCreditTranscationPDFRequest | GenerateCreditTranscationCSVRequest;

export const GetCreditTransactionReportRequest = (
    customerID: string, status: ActionStatus, transferType: CreditTransferType, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string,
    tradFromDate: Date, tradToDate: Date, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: CreditTransactionRequestModel = {
            cms: 1,
            csi: customerID,
            sts: status,
            typ: transferType,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            trfd: tradFromDate,
            trtd: tradToDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetCreditTransactionReport", request, CreditTransactionReportingActionTypes.GET_CREDIT_TRANSACTION_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionReportingActionTypes.GET_CREDIT_TRANSACTION_REPORT_REQUEST, dispatch);
    }
}

export const GenerateCreditTransactionPDFRequest = (
    customerID: string, status: ActionStatus, transferType: CreditTransferType, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string,
    tradFromDate: Date,  tradToDate: Date, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: CreditTransactionRequestModel = {
            cms: 1,
            csi: customerID,
            sts: status,
            typ: transferType,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            trfd: tradFromDate,
            trtd: tradToDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateCreditTransactionPDF", request, CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_PDF_REQUEST, dispatch);
    }
}

export const GenerateCreditTransactionCSVRequest = (
    customerID: string, status: ActionStatus, transferType: CreditTransferType, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string,
    tradFromDate: Date,  tradToDate: Date, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: CreditTransactionRequestModel = {
            cms: 1,
            csi: customerID,
            sts: status,
            typ: transferType,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            trfd: tradFromDate,
            trtd: tradToDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateCreditTransactionCSV", request, CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_CSV_REQUEST, dispatch);
    }
}