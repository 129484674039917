import { ISelectOption, LoadSelectOptionFromEnum } from "../components/InputBox/ISelectBox";
import { TierConnectorType, TierOperatorType, TierParameterType } from "../dto/enums/Jackpot/JackpotTierCondition";

export interface ITierConditions {
    connector: string;
    connectorString: string;
    connectorSymbol: string;
    operator: string;
    operatorString: string;
    operatorSymbol: string;
    parameter: string;
    parameterString: string;
    parameterSymbol: string;
    value: number;
}

export namespace JackpotTierCoreLib {
    export namespace Constant {
        export const parameterStringMapper: { [key: string]: TierParameterType } = {
            'Progressive': TierParameterType.PROGRESSIVE,
            'House': TierParameterType.HOUSE,
            'Reserve': TierParameterType.RESERVE
        }

        export const operatorStringMapper: { [key: string]: TierOperatorType } = {
            'Greater Than': TierOperatorType.GREATER_THAN,
            'Less Than': TierOperatorType.LESS_THAN,
            'Greater Than Or Equal To': TierOperatorType.GREATER_OR_EQUAL,
            'Less Than Or Equal To': TierOperatorType.LESS_OR_EQUAL
        }

        export const connectorStringMapper: { [key: string]: TierConnectorType } = {
            'And': TierConnectorType.AND,
            'Or': TierConnectorType.OR,
        }

        export const parameterSymbolMapper: { [key: string]: TierParameterType } = {
            'P': TierParameterType.PROGRESSIVE,
            'H': TierParameterType.HOUSE,
            'R': TierParameterType.RESERVE
        }

        export const operatorSymbolMapper: { [key: string]: TierOperatorType } = {
            '>': TierOperatorType.GREATER_THAN,
            '<': TierOperatorType.LESS_THAN,
            '>=': TierOperatorType.GREATER_OR_EQUAL,
            '<=': TierOperatorType.LESS_OR_EQUAL
        }

        export const connectorSymbolMapper: { [key: string]: TierConnectorType } = {
            '&&': TierConnectorType.AND,
            '||': TierConnectorType.OR,
        }
    }

    export namespace Convertor {
        export const toConnectorDropdownOptions = (): ISelectOption[] => {
            var selectOptions: ISelectOption[] = LoadSelectOptionFromEnum(TierConnectorType);

            return selectOptions
        }



        export const toFormParam = (paramStr: string): ITierConditions[] => {
            //ProgessiveTierParam is the tier model
            var arrayStr: Array<string> = [];
            var updateArrayStr: Array<Array<string>> = [];
            var tierCondition: ITierConditions[] = [];


            arrayStr = paramStr.replace(/\s/g, '').split(/(?<=\&&|\|\|)/);
            updateArrayStr = arrayStr.map(x => x.split(/(<=|>=|&&|>|<|\|\|)/g).filter(y => y != ''));
            tierCondition = [];

            updateArrayStr.map((x: Array<string>) => {
                var object: ITierConditions;
                var connector: string = TierConnectorType.END.toString();
                var connectorString: string = '';
                var connectorSymbol: string = '';
                var operator: string = '';
                var operatorString: string = '';
                var operatorSymbol: string = '';
                var parameter: string = '';
                var parameterString: string = '';
                var parameterSymbol: string = '';
                var value: number = Number(x[2]);

                for (let key in Constant.parameterSymbolMapper) {
                    if (key.toString() == x[0]) {
                        parameter = Constant.parameterSymbolMapper[key].toString();
                        parameterSymbol = key;

                        for (let key in Constant.parameterStringMapper) {
                            var parameterTargetValue = Constant.parameterStringMapper[key].toString();

                            if (parameterTargetValue == parameter) {
                                parameterString = key;
                            }
                        }

                    }
                }

                for (let key in Constant.operatorSymbolMapper) {
                    if (key.toString() == x[1]) {
                        operator = Constant.operatorSymbolMapper[key].toString();
                        operatorSymbol = key

                        for (let key in Constant.operatorStringMapper) {
                            var operatorTargetValue = Constant.operatorStringMapper[key].toString();

                            if (operatorTargetValue == operator) {
                                operatorString = key;
                            }
                        }
                    }
                }

                for (let key in Constant.connectorSymbolMapper) {
                    if (key.toString() == x[3]) {
                        connector = Constant.connectorSymbolMapper[key].toString();
                        connectorSymbol = key;

                        for (let key in Constant.connectorStringMapper) {
                            var connectorTargetValue = Constant.connectorStringMapper[key].toString();

                            if (connectorTargetValue == connector) {
                                connectorString = key;
                            }
                        }
                    }
                }

                object = {
                    connector: connector == null ? TierConnectorType.END.toString() : connector,
                    connectorString: connectorString,
                    connectorSymbol: connectorSymbol,
                    operator: operator,
                    operatorString: operatorString,
                    operatorSymbol: operatorSymbol,
                    parameter: parameter,
                    parameterString: parameterString,
                    parameterSymbol: parameterSymbol,
                    value: value != 0 ? value : 0
                }

                tierCondition.push(object);
            })

            return tierCondition;
        }

        export const toOperatorDropdownOptions = (): ISelectOption[] => {
            var selectOptions: ISelectOption[] = LoadSelectOptionFromEnum(TierOperatorType);
            return selectOptions;
        }

        export const toParam = (jackpotTierFormParam: Array<ITierConditions>) => {
            var tierCondition = ''
            if (jackpotTierFormParam) {
                var str = '';
                if (jackpotTierFormParam.length > 0) {
                    jackpotTierFormParam.map((val: ITierConditions) => {
                        var parameterSymbol: string = '';
                        var operatorSymbol: string = '';
                        var connectorSymbol: string = '';

                        for (let key in Constant.parameterSymbolMapper) {
                            if (Constant.parameterSymbolMapper[key.toString()] == Number(val.parameter)) {
                                parameterSymbol = key
                            }
                        }

                        for (let key in Constant.operatorSymbolMapper) {
                            if (Constant.operatorSymbolMapper[key.toString()] == Number(val.operator)) {
                                operatorSymbol = key
                            }
                        }

                        for (let key in Constant.connectorSymbolMapper) {
                            if (Constant.connectorSymbolMapper[key.toString()] == Number(val.connector)) {
                                connectorSymbol = key
                            }
                        }

                        if (connectorSymbol) {
                            str = parameterSymbol + ' ' + operatorSymbol + ' ' + val.value.toString() + ' ' + connectorSymbol + ' '
                        } else {
                            str = parameterSymbol + ' ' + operatorSymbol + ' ' + val.value.toString()
                        }

                        tierCondition = tierCondition + str;
                    })
                }
            }

            return tierCondition;
        }

        export const toParameterDropdownOptions = (): ISelectOption[] => {
            var selectOptions: ISelectOption[] = LoadSelectOptionFromEnum(TierParameterType);
            return selectOptions;
        }

        export const toParamWithString = (condtions: Array<ITierConditions>): Array<ITierConditions> => {
            var result: Array<ITierConditions> = [];
            if (condtions) {
                condtions.map((item: ITierConditions) => {
                    item.parameterString = Object.keys(TierParameterType)
                        .filter(key => TierParameterType[key as any] == item.parameter)[0]

                    item.operatorString = Object.keys(TierOperatorType)
                        .filter(key => TierOperatorType[key as any] == item.operator)[0]

                    item.connectorString = Object.keys(TierConnectorType)
                        .filter(key => TierConnectorType[key as any] == item.connector)[0]

                    result.push(item);
                })
            }

            return result;
        }

    }
}