import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { JackpotSubModule } from "../../dto/enums/SubModule/JackpotSubModule";
import { UserSubModule } from "../../dto/enums/SubModule/UserSubModule";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GenerateAuditReportCSVRequest, GenerateAuditReportPDFRequest, GetAuditReportRequest } from "../../states/Reporting/AuditReporting/AuditReportingActions";
import { IStore } from "../../states/store/IStore";

const AuditReporting = () => {
    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);
    const dispatch = useDispatch();
    const auditReportState = useSelector((state: IStore) => state.auditReportState);
    const { auditReportListData, loading, err, suc } = auditReportState;

    // Search Criteria
    const [userNameID, setUserNameID] = useState("");
    const [selectedModule, setSelectedModule] = useState(0);

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const backOfficeModule: ISelectOption[] = LoadSelectOptionFromEnum(BackOfficeAccess);

    // Sort Option List
    backOfficeModule[0].display = '~All';
    backOfficeModule.sort((a, b) => a.display.localeCompare(b.display));
    backOfficeModule[0].display = 'All';

    const emptyTable: IDataTable = {
        title: ["No", "System User", "Maintenance Module", "Sub Maintenance Module", "Value Before", "Value After", "Date Time" ],
        columnWidth: ["30px", "200px", "225px", "225px", "225px", "225px", "200px"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        var table: IDataTable = emptyTable
        if (auditReportListData && auditReportListData.rml != undefined && hasSearched) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = auditReportListData.rml.map((x, index) => {
                return [
                    startIndex + index + 1,
                    x.unm,
                    BackOfficeAccess[x.mmd],
                    GetSubModuleDisplay(x.mmd, x.smm),
                    <pre>{x.bem}</pre>,
                    <pre>{x.aem}</pre>,
                    //<pre>{highlightString(x.aem, x.bem)}</pre>,
                    //<span>{highlightString(x.aem, x.bem)}</span>,
                    FORMAT_DATETIME_TO_VIEW(new Date(x.adt), "yyyy-MM-dd hh:mm:ssa")]
                    //<Button color="secondary" outline onClick={() => ViewAccessMatrix(x.aid)} style={{ width: "100px" }}>Details</Button>]
            });

            /*setHasSearched(false);*/
        } else
            table.data = [];

        setReportTable(table);
    }, [auditReportListData]);

    function GetSubModuleDisplay(mainModuleID: number, subModuleID: number): string {
        if (BackOfficeAccess[mainModuleID] == "USER_MAINTENANCE")
            return UserSubModule[subModuleID];
        else if (BackOfficeAccess[mainModuleID] == "JACKPOT_MAINTENNACE")
            return JackpotSubModule[subModuleID];
        else
            return "N/A"
    }

    // Highlight changes function
    function highlightString(updateModelStr: string, oriModelStr: string): string {
        var oldText = oriModelStr, text = '';

        updateModelStr.split('').forEach(function (val, i) {
            //const backgroundColor = part.added ? 'green' : part.removed ? 'red' : 'white';
            //highlightedString += `<span style="background-color: ${backgroundColor}">${value}</span>`;

            if (val != oldText.charAt(i))
                text += "<span style='background-color: yellow'>" + val + "</span>";
            else
                text += val;
        });


        var dom = document.createElement('div');
        dom.innerHTML = text;
        //return parse(text);

        //updateModelStr.bold(text);
        return text;
    }

    const valid = (): boolean => {
        return fromDate !== "" && toDate !== "" && toDateValid && fromDateValid;
    }


    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetAuditReportRequest(userNameID, selectedModule, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const pagination: ITablePagination = {
        id: "auditReportPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? auditReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setPage(0);
            setHasSearched(true);
            dispatch(GetAuditReportRequest(userNameID, selectedModule, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
        else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            dispatch(GenerateAuditReportPDFRequest(userNameID, selectedModule, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const generateCSV = () => {
        if (valid()) {
            dispatch(GenerateAuditReportCSVRequest(userNameID, selectedModule, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="systemUser"
                                name="systemUser"
                                label="System User"
                                type={TextboxType.TEXT}
                                placeholder={"Name / ID"}
                                isRequired={true}
                                inputState={setUserNameID}
                                value={userNameID}
                                HasCancelledButton={true}
                            />
                        </Col>
                        <Col md={6}>
                            <SelectBox id="maintenanceModule" name="maintenanceModule" label="Maintenance Module"
                                options={backOfficeModule} value={selectedModule}
                                inputState={setSelectedModule}
                                allowEmptyData={false} placeHolderContent="---- Select Module ----"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <DateTimeRangePicker isRequired fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" label="Transaction Date" hideTime={false} />
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div>
            <StandardReportContainer
                id="AuditReporting"
                error={hasSearched ? err : ""}
                success={hasSearched ? suc : ""}
                loading={loading}
                tablePagination={pagination}
                tableProps={reportTable}
                searchCriteriaContent={SearchCriteriaContainer()}
                generateCSVEvent={generateCSV}
                generatePDFEvent={generatePDF}
            />
        </div>
    )
}



export default AuditReporting;