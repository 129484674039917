import { RoleModel, RoleModelList } from "../../dto/models/UserAccessModels";

export interface IRoleState {
    loading: boolean;
    err: string;
    suc: string;
    roleList: RoleModelList;
    roleData: RoleModel;
}


export const InitRoleState: IRoleState = {
    loading: false,
    err: "",
    suc: "",
    roleList: {} as RoleModelList,
    roleData: {} as RoleModel,
}
