import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, FormText, Label, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { JackpotPoolResetMode } from "../../../dto/enums/Jackpot/JackpotPoolResetMode";
import { JackpotPoolTitle } from "../../../dto/enums/Jackpot/JackpotPoolTitle";
import { JackpotPoolType } from "../../../dto/enums/Jackpot/JackpotPoolType";
import { JackpotTierType } from "../../../dto/enums/Jackpot/JackpotTierType";
import { TierModel } from "../../../dto/models/JackpotModels";
import { CVT_TO_FLOAT_STRING } from "../../../libraries/FloatHelper";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { AddPool, EditPool, PoolGetCreditRate, SelectPool } from "../../../states/PAS/JackpotPool/PoolActions";
import { GetTierList } from "../../../states/PAS/JackpotTier/TierActions";
import { IStore } from "../../../states/store/IStore";
import DataTable from "../../DataTable/DataTable";
import { TableStyle } from "../../DataTable/IDataTable";
import CheckBoxGroup from "../../InputBox/CheckBoxGroup";
import { ICheckBoxOption } from "../../InputBox/ICheckBoxGroup";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import TextField from "../../InputBox/TextField";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IPoolForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean
}

const PoolForm = (props: IPoolForm) => {
    // Pool
    const [nameInput, setNameInput] = useState("");
    const [nameValid, setNameValid] = useState(props.isEdit);
    const [seedInput, setSeedInput] = useState<string | undefined>(undefined);
    const [seedValid, setSeedtValid] = useState(props.isEdit);
    const [ceillingInput, setCeillingInput] = useState<string | undefined>(undefined);
    const [ceillingValid, setCeillingtValid] = useState(props.isEdit);
    const [payoutInput, setPayoutInput] = useState<string | undefined>(undefined);
    const [payoutValid, setPayouttValid] = useState(props.isEdit);
    const [thresholdInput, setThresholdInput] = useState<string | undefined>(undefined);
    const [thresholdValid, setThresholdtValid] = useState(props.isEdit);
    const [descriptionInput, setDescriptionInput] = useState("");

    const [selectedType, setType] = useState(JackpotPoolType.CONDITION_PROGESSIVE);
    const typeOptions: ISelectOption[] = LoadSelectOptionFromEnum(JackpotPoolType);
    const [selectedTitle, setTitle] = useState(JackpotPoolTitle.GRAND);
    const titleOptions: ISelectOption[] = LoadSelectOptionFromEnum(JackpotPoolTitle);
    const [selectedResetMode, setResetMode] = useState(JackpotPoolResetMode.DEFAULT_MODE);
    const resetModeOptions: ISelectOption[] = LoadSelectOptionFromEnum(JackpotPoolResetMode);

    // Jackpot Tier
    const [defaultTierOptions, setDefaultTierOptions] = useState([] as ISelectOption[]);
    const [selectedDefaultTier, setDefaultTier] = useState<number | undefined>(undefined);
    const [defaultTierValid, setDefaultTierValid] = useState(props.isEdit);

    const [TierOptions, setTierOptions] = useState([] as ICheckBoxOption[]);
    const [selectedTiers, setTiers] = useState([] as string[]);

    const [tierTable, setTierTable] = useState<any[][]>([]);

    const dispatch = useDispatch();
    const poolStates = useSelector((state: IStore) => state.poolState);
    const tierStates = useSelector((state: IStore) => state.tierState);
    const { poolData, creditRate } = poolStates;
    const { tierListData } = tierStates;
    const [isLoaded, setLoaded] = useState(false);
    const tierLoading = tierStates.loading;
    const poolLoading = poolStates.loading;

    useEffect(() => {
        setLoaded(!tierLoading && !poolLoading);
    }, [tierLoading, poolLoading])

    useEffect(() => {
        if (tierListData && tierListData.trl) {
            const dTiers: ISelectOption[] = tierListData.trl
                .filter(x => x.typ == JackpotTierType.DEFAULT)
                .map((item) => {
                    return {
                        display: item.tnm,
                        value: item.tid.toString()
                    }

                });

            setDefaultTierOptions(dTiers);
            if (!props.isEdit && dTiers.length != 0) {
                setDefaultTier(dTiers[0].value as any as number)
                setDefaultTierValid(true)
            }

            const tiers: ICheckBoxOption[] = tierListData.trl
                .filter(x => x.typ == JackpotTierType.CUSTOMIZED)
                .map((item) => {
                    return {
                        display: item.tnm,
                        value: item.tid.toString()
                    }

                });
            setTierOptions(tiers);
        }
    }, [tierListData]);

    useEffect(() => {
        dispatch(PoolGetCreditRate());
        dispatch(GetTierList());
        if (props.isEdit && props.editID) {
            dispatch(SelectPool(props.editID));
        } else
            ClearFields();

    }, [props.isEdit]);

    useEffect(() => {
        if (tierListData.trl && tierListData.trl.length > 0 && poolData.pon != "")
            GetTierListData();

    }, [poolData, tierListData, selectedTiers, selectedDefaultTier]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (props.isEdit && poolData.pon) {
            setNameInput(poolData.pon);
            setSeedInput(CVT_TO_FLOAT_STRING(poolData.sed));
            setCeillingInput(CVT_TO_FLOAT_STRING(poolData.cel));
            setThresholdInput(CVT_TO_FLOAT_STRING(poolData.tsh));
            setPayoutInput(CVT_TO_FLOAT_STRING(poolData.poa));
            setType(poolData.typ);
            setTitle(poolData.til);
            setResetMode(poolData.rsm);
            setDescriptionInput(poolData.des);
            if (poolData.tls.length > 0)
                setDefaultTier(poolData.tls.filter(x => x.typ == JackpotTierType.DEFAULT)[0].tid);
            const tiers: string[] = [];
            poolData.tls.filter(x => x.typ == JackpotTierType.CUSTOMIZED).map(item => {
                tiers.push(item.tid.toString())
            });

            setTiers(tiers);
        }
    }, [poolData]);

    const ClearFields = () => {
        setNameInput("");
        setSeedInput(undefined);
        setPayoutInput(undefined);
        setCeillingInput(undefined);
        setThresholdInput(undefined);
        setType(JackpotPoolType.CONDITION_PROGESSIVE);
        setTitle(JackpotPoolTitle.GRAND);
        setResetMode(JackpotPoolResetMode.DEFAULT_MODE);
    }

    const Valid = (): boolean => {
        return (thresholdValid && nameValid && defaultTierValid &&
            (payoutValid || selectedType == JackpotPoolType.CONDITION_PROGESSIVE) &&
            ((seedValid && ceillingValid) || selectedType == JackpotPoolType.CONDITION_FIXED));
    }

    const GetTierListData = () => {
        const data: any[][] = [];
        var i: number = 0;

        if (tierListData && tierListData.trl) {
            selectedTiers.map(t => {
                tierListData.trl.filter(x => x.typ == JackpotTierType.CUSTOMIZED && x.tid.toString() == t).map(x => {
                    data.push([++i, x.tnm, GetConditionString(x.tcd), x.tpr + "%", x.thr + "%", x.trr + "%"])
                });
            });

            tierListData.trl.filter(x => x.typ == JackpotTierType.DEFAULT && x.tid == selectedDefaultTier).map(x => {
                data.push([++i, x.tnm, GetConditionString(x.tcd), x.tpr + "%", x.thr + "%", x.trr + "%"])
            });

        }

        setTierTable(data);
    }

    const GetConditionString = (condition: string): string => {
        var response = condition;
        response = response.replace(/>=/g, "Greater Than Or Equal to");
        response = response.replace(/<=/g, "Less Than Or Equal to");
        response = response.replace(/>/g, "Greater than");
        response = response.replace(/</g, "Less than");

        response = response.replace(/R/g, "Reserve");
        response = response.replace(/P/g, "Progessive");
        response = response.replace(/H/g, "House");

        response = response.replace(/&&/g, "And \n");
        response = response.replace(/\|\|/g, "Or \n");

        return response;
    }

    const save = () => {
        if (Valid() && nameInput && thresholdInput &&
            (payoutInput || selectedType == JackpotPoolType.CONDITION_PROGESSIVE) && // Condition progessive do not have payout input
            ((seedInput && ceillingInput) || selectedType == JackpotPoolType.CONDITION_FIXED)) { // Fixed progessive do not have seed and ceilling
            const tierList: TierModel[] = [];
            if (selectedType == JackpotPoolType.CONDITION_PROGESSIVE) {
                // Add customized tiers
                selectedTiers.map(x => {
                    tierList.push(
                        {
                            tid: x as any as number,
                            des: "",
                            tcd: "",
                            thr: 0,
                            tnm: "",
                            tpr: 0,
                            trr: 0,
                            typ: JackpotTierType.DEFAULT
                        } as TierModel
                    )
                })

                // Add selected default tier
                tierList.push(
                    {
                        tid: selectedDefaultTier,
                        des: "",
                        tcd: "",
                        thr: 0,
                        tnm: "",
                        tpr: 0,
                        trr: 0,
                        typ: JackpotTierType.DEFAULT
                    } as TierModel
                )
            }

            if (!props.isEdit) {
                dispatch(
                    AddPool(
                        selectedTitle,
                        nameInput,
                        descriptionInput,
                        selectedType,
                        selectedResetMode,
                        parseFloat(thresholdInput.replace(/,/g, '')),
                        seedInput ? parseFloat(seedInput.replace(/,/g, '')) : 0,
                        ceillingInput ? parseFloat(ceillingInput.replace(/,/g, '')) : 0,
                        payoutInput ? parseFloat(payoutInput.replace(/,/g, '')) : 0,
                        tierList
                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditPool(
                            props.editID,
                            nameInput,
                            selectedTitle,
                            descriptionInput,
                            selectedType,
                            selectedResetMode,
                            parseFloat(thresholdInput.replace(/,/g, '')),
                            seedInput ? parseFloat(seedInput.replace(/,/g, '')) : 0,
                            ceillingInput ? parseFloat(ceillingInput.replace(/,/g, '')) : 0,
                            payoutInput ? parseFloat(payoutInput.replace(/,/g, '')) : 0,
                            tierList
                        ));
                }
            }
        } else {
            TRIGGER_BLUR("title");
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("description");
            TRIGGER_BLUR("type");
            TRIGGER_BLUR("resetMode");
            TRIGGER_BLUR("threshold");
            TRIGGER_BLUR("seed");
            TRIGGER_BLUR("ceilling");
            TRIGGER_BLUR("tier");
            TRIGGER_BLUR("dtier");
            TRIGGER_BLUR("payout");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            {isLoaded ?
                <Form>
                    <Row>
                        <Col>
                            <SelectBox id="type" name="type" label="Jackpot Type"
                                options={typeOptions} value={props.isEdit ? selectedType : JackpotPoolType.CONDITION_PROGESSIVE}
                                inputState={setType}
                                isRequired={true}
                                isDisabled={!props.canEdit || props.isEdit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="name">
                                    Name
                                </Label>
                                <InputBox
                                    id="name"
                                    name="name"
                                    type={TextboxType.TEXT}
                                    inputState={setNameInput}
                                    placeholder={"Name"}
                                    validState={setNameValid}
                                    isRequired={true}
                                    value={nameInput}
                                    isDisabled={!props.canEdit || props.isEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <SelectBox id="title" name="title" label="Jackpot Title"
                                    options={titleOptions} value={props.isEdit ? selectedTitle : JackpotPoolTitle.GRAND}
                                    inputState={setTitle}
                                    isRequired={true}
                                    isDisabled={!props.canEdit || props.isEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <TextField id="des" name="des" label="Description"
                                    placeholder="Description" value={props.isEdit ? descriptionInput : ""}
                                    inputState={setDescriptionInput} maxLength={300} col={5} row={5}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="threshold" name="threshold" label="Jackpot Threshold (Credit)"
                                    type={TextboxType.CURRENCY}
                                    maxlength={14}
                                    inputState={setThresholdInput}
                                    validState={setThresholdtValid}
                                    min={0}
                                    placeholder={"Jackpot Threshold"}
                                    tips={"The minimum value that determine when a jackpot is hit, whether it requires approval to approve before proceed to payout."}
                                    tipsID={"thresholdTips"}
                                    isRequired={selectedType == JackpotPoolType.CONDITION_PROGESSIVE}
                                    value={thresholdInput}
                                    isDisabled={!props.canEdit}
                                />
                                <FormText>{thresholdInput ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(parseFloat(thresholdInput.replace(/,/g, '')) * creditRate.crt / 100) : ""}</FormText>
                            </FormGroup>
                        </Col>
                        {selectedType == JackpotPoolType.CONDITION_PROGESSIVE ?
                            <Col md={6}>
                                <FormGroup>
                                    <InputBox
                                        id="ceilling" name="ceilling" label="Jackpot Ceilling (Credit)"
                                        type={TextboxType.CURRENCY}
                                        maxlength={14}
                                        inputState={setCeillingInput}
                                        validState={setCeillingtValid}
                                        tips={"The maximum value that is allowed to stored in the progessive wallet within this pool."}
                                        tipsID={"ceillingTips"}
                                        min={0}
                                        placeholder={"Jackpot Ceilling"}
                                        isRequired={selectedType == JackpotPoolType.CONDITION_PROGESSIVE}
                                        value={ceillingInput}
                                        isDisabled={!props.canEdit || props.isEdit}
                                    />
                                    <FormText>{ceillingInput ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(parseFloat(ceillingInput.replace(/,/g, '')) * creditRate.crt / 100) : ""}</FormText>
                                </FormGroup>
                            </Col>
                            : <Col md={6}>
                                <FormGroup>
                                    <InputBox
                                        id="payout" name="payout" label="Jackpot Payout Amount (Credit)"
                                        type={TextboxType.CURRENCY}
                                        maxlength={14}
                                        inputState={setPayoutInput}
                                        validState={setPayouttValid}
                                        min={0}
                                        placeholder={"Jackpot Payout Amount"}
                                        isRequired={selectedType == JackpotPoolType.CONDITION_FIXED}
                                        value={payoutInput}
                                        isDisabled={!props.canEdit || props.isEdit}
                                    />
                                    <FormText>{payoutInput ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(parseFloat(payoutInput.replace(/,/g, '')) * creditRate.crt / 100) : ""}</FormText>
                                </FormGroup>
                            </Col>
                        }

                    </Row>
                    {selectedType == JackpotPoolType.CONDITION_PROGESSIVE ?
                        <div>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <InputBox
                                            id="seed" name="seed" label="Seed Amount (Credit)"
                                            type={TextboxType.CURRENCY}
                                            maxlength={14}
                                            inputState={setSeedInput}
                                            validState={setSeedtValid}
                                            min={0}
                                            placeholder={"Seed Amount"}
                                            isRequired={selectedType == JackpotPoolType.CONDITION_PROGESSIVE}
                                            value={seedInput}
                                            isDisabled={!props.canEdit || props.isEdit}
                                        />
                                        <FormText>{seedInput ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(parseFloat(seedInput.replace(/,/g, '')) * creditRate.crt / 100) : ""}</FormText>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <SelectBox id="resetMode" name="resetMode" label="Jackpot Reset Mode"
                                            options={resetModeOptions} value={props.isEdit ? selectedResetMode : JackpotPoolResetMode.DEFAULT_MODE}
                                            inputState={setResetMode} tipsID={"resetModeTip"} tips={"After jackpot strikes a certain amount will be transferred back into jackpot pool"}
                                            isRequired={selectedType == JackpotPoolType.CONDITION_PROGESSIVE}
                                            isDisabled={!props.canEdit || props.isEdit}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <SelectBox id="dtier" name="dtier" label="Default Tier"
                                        options={defaultTierOptions} value={selectedDefaultTier}
                                        inputState={setDefaultTier} validState={setDefaultTierValid}
                                        isDisabled={!props.canEdit || props.isEdit}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <CheckBoxGroup id="tier" label="Customized Tiers" values={selectedTiers}
                                        options={TierOptions} inputState={setTiers}
                                        isLimitHeight={true} inline={false}
                                        isDisabled={!props.canEdit || props.isEdit}
                                    />
                                </Col>
                                <Col md={9}>
                                    <Label for={"sequence"}>Sequence of Tier</Label>
                                    <DataTable
                                        title={["NO.", "Name", "Conditions", "Progessive", "House", "Reserve"]}
                                        data={tierTable}
                                        isloading={false}
                                        isStriped={true}
                                        isHover={false}
                                        tableStyle={TableStyle.ALL_BORDER}
                                    />
                                </Col>
                            </Row>
                        </div> : <div></div>
                    }
                </Form>
                :
                <div className='text-center p-4'><Spinner key='1' /></div>
            }
        </Container>
    )
}

const PoolModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const poolState = useSelector((state: IStore) => state.poolState);
    const tierState = useSelector((state: IStore) => state.tierState);
    const { err, suc, loading } = poolState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        if (tierState.err !== "") {
            setErrMsg(tierState.err)
            return;
        }

        if (err !== "") {
            setErrMsg(err)
            return;
        }

        setErrMsg("");
    }, [tierState.err, err])

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit Pool" : "Add Pool"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={suc}
                child={
                    <PoolForm isEdit={props.isEdit} canEdit={canEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? < Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default PoolModal;