import axios, { AxiosRequestConfig } from "axios";
import { PylonResponseModel } from "../dto/models/PylonResponseModel";
import { IPayload, LoadingPayload } from "../states/store/IStore";
import { SessionTimeout } from "../states/User/UserActions";
import { TOKEN_CONST } from "./Global";

const initialConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
}

export async function PostRequest(path: string, data: any, type: any, dispatch: any): Promise<void> {
    try {
        dispatch({
            type: type,
            payload: LoadingPayload
        });

        var token = localStorage.getItem(TOKEN_CONST);
        var config = initialConfig;
        if (token) {
            config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': token
                },
            };
        }

        const response = await axios.post(
            path,
            data,
            config
        );

        var responseModel: PylonResponseModel = response.data;
        var errorMessage: string | false = '';

        if (responseModel) {
            if (responseModel.err) {
                errorMessage = responseModel.err;

                if (responseModel.err == "BACKOFFICE INVALID SESSION")
                    KickPlayerToLogin(dispatch);
            }
        }
        else {
            errorMessage = `Status: ${response.status} ${response.statusText}`;
        }

        var payload: IPayload = {
            data: responseModel,
            err: errorMessage,
            loading: false
        };

        dispatch({
            type: type,
            payload: payload
        });

    } catch (error) {
        RequestException(error, type, dispatch)
    }
}

export async function GetRequest(path: string, type: any, dispatch: any): Promise<void> {
    try {
        dispatch({
            type: type,
            payload: LoadingPayload
        });

        var token = localStorage.getItem(TOKEN_CONST);
        var config = initialConfig;
        if (token) {
            config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': token
                },
            };
        }

        const response = await axios.get(
            path,
            config
        );

        var responseModel: PylonResponseModel = response.data;
        var errorMessage: string | false = '';

        if (responseModel) {
            if (responseModel.err) {
                errorMessage = responseModel.err;

                if (responseModel.err == "BACKOFFICE INVALID SESSION")
                    KickPlayerToLogin(dispatch);
            }
        }
        else {
            errorMessage = `Status: ${response.status} ${response.statusText}`;
        }

        var payload: IPayload = {
            data: responseModel,
            err: errorMessage,
            loading: false
        };

        dispatch({
            type: type,
            payload: payload
        });
    } catch (error) {
        RequestException(error, type, dispatch)
    }
}


export function RequestException(error: any, type: any, dispatch: any): void {
    var payload: IPayload = {
        data: {} as PylonResponseModel,
        err: error.message,
        loading: false
    };

    dispatch({
        type: type,
        payload: payload
    });
}

export function KickPlayerToLogin(dispatch: any) {
    dispatch(SessionTimeout());
}