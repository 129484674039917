import { ManualSubmitOLRReportListModel, ManualSubmitOLRReportPDFModel, ManualSubmitOLRReportCSVModel } from "../../../dto/models/Reporting/ManualSubmitOLRReportModel";

export interface IManualSubmitOLRReportState {
    loading: boolean;
    err: string;
    suc: string;
    manualSubmitOLRReportListData: ManualSubmitOLRReportListModel;
    pdfData: ManualSubmitOLRReportPDFModel;
    csvData: ManualSubmitOLRReportCSVModel;
}

export const InitManualSubmitOLRReportState: IManualSubmitOLRReportState = {
    loading: false,
    err: "",
    suc: "",
    manualSubmitOLRReportListData: {} as ManualSubmitOLRReportListModel,
    pdfData: {} as ManualSubmitOLRReportPDFModel,
    csvData: {} as ManualSubmitOLRReportCSVModel,
}