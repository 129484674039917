import { JackpotPendingActionType } from "../../dto/enums/JackpotPendingActionType";
import { BigWinResolveModel } from "../../dto/models/BigWinPendingModels";
import { RequestListModel } from "../../dto/models/GeneralModels";
import { GET_PROFILE } from "../../libraries/Global";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload } from "../store/IStore";
import { BigWinPendingActionTypes } from "./BigWinPendingActionTypes";

type GetBigWinPendingRequest = { type: BigWinPendingActionTypes.GET_BIG_WIN_PENDING_REQUEST, payload: IPayload };
type ResolveBigWinRequest = { type: BigWinPendingActionTypes.RESOLVE_BIG_WIN_REQUEST, payload: IPayload };

export type ActionTypes = GetBigWinPendingRequest | ResolveBigWinRequest;

export const GetBigWinPendingRequest = (from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: take
        };

        PostRequest("Cashier/GetBigWinPending", request, BigWinPendingActionTypes.GET_BIG_WIN_PENDING_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, BigWinPendingActionTypes.GET_BIG_WIN_PENDING_REQUEST, dispatch);
    }
}

export const ResolveBigWinRequest = (bigWinId: number, password: string, remarks: string, action: JackpotPendingActionType) => async (dispatch: any) => {
    try {
        let request: BigWinResolveModel = {
            bid: bigWinId,
            rmk: remarks,
            act: action,
            pwd: password,
            uid: GET_PROFILE().uid
        };

        PostRequest("Cashier/ResolvePending", request, BigWinPendingActionTypes.RESOLVE_BIG_WIN_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, BigWinPendingActionTypes.RESOLVE_BIG_WIN_REQUEST, dispatch);
    }
}