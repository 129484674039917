import { CmsType } from "../../../dto/enums/CmsType";
import { WinLoseReportRequestModel } from "../../../dto/models/Reporting/WinLoseRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { WinLoseReportingActionTypes } from "./GameWinLoseReportingActionTypes";

type GetGameWinLoseReportRequest = { type: WinLoseReportingActionTypes.GET_WIN_LOSE_REPORT_REQUEST, payload: IPayload };
type GenerateGameWinLosePDFRequest = { type: WinLoseReportingActionTypes.GENERATE_WIN_LOSE_PDF_REQUEST, payload: IPayload };
type GenerateGameWinLoseCSVRequest = { type: WinLoseReportingActionTypes.GENERATE_WIN_LOSE_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetGameWinLoseReportRequest | GenerateGameWinLosePDFRequest | GenerateGameWinLoseCSVRequest;

export const GetGameWinLoseReportRequest = (
    gameServiceID: number[], tradingFromDate: Date, tradingformTime: string, tradingToDate: Date, tradingToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: WinLoseReportRequestModel = {
            gid: gameServiceID,
            tfd: tradingFromDate,
            tft: tradingformTime,
            ttd: tradingToDate,
            ttt: tradingToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetGameWinLoseReport", request, WinLoseReportingActionTypes.GET_WIN_LOSE_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, WinLoseReportingActionTypes.GET_WIN_LOSE_REPORT_REQUEST, dispatch);
    }
}

export const GenerateGameWinLosePDFRequest = (
    gameServiceID: number[], tradingFromDate: Date, tradingformTime: string, tradingToDate: Date, tradingToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: WinLoseReportRequestModel = {
            gid: gameServiceID,
            tfd: tradingFromDate,
            tft: tradingformTime,
            ttd: tradingToDate,
            ttt: tradingToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateGameWinLosePDF", request, WinLoseReportingActionTypes.GENERATE_WIN_LOSE_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, WinLoseReportingActionTypes.GENERATE_WIN_LOSE_PDF_REQUEST, dispatch);
    }
}

export const GenerateGameWinLoseCSVRequest = (
    gameServiceID: number[], tradingFromDate: Date, tradingformTime: string, tradingToDate: Date, tradingToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: WinLoseReportRequestModel = {
            gid: gameServiceID,
            tfd: tradingFromDate,
            tft: tradingformTime,
            ttd: tradingToDate,
            ttt: tradingToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateGameWinLoseCSV", request, WinLoseReportingActionTypes.GENERATE_WIN_LOSE_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, WinLoseReportingActionTypes.GENERATE_WIN_LOSE_CSV_REQUEST, dispatch);
    }
}

