import { AuditCSVReportModel, AuditPDFReportModel, AuditReportListModel } from "../../../dto/models/Reporting/AuditReportModel";

export interface IAuditReportState {
    loading: boolean;
    err: string;
    suc: string;
    auditReportListData: AuditReportListModel;
    pdfData: AuditPDFReportModel;
    csvData: AuditCSVReportModel;
}

export const InitAuditReportState: IAuditReportState = {
    loading: false,
    err: "",
    suc: "",
    auditReportListData: {} as AuditReportListModel,
    pdfData: {} as AuditPDFReportModel,
    csvData: {} as AuditCSVReportModel,
}