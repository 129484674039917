import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { BackOfficeAccess } from '../../../dto/enums/AccessMatrix/BackOfficeAccess';
import { CashierAccess } from '../../../dto/enums/AccessMatrix/CashierAccess';
import { ReportingAccess } from '../../../dto/enums/AccessMatrix/ReportingAccess';
import { TableStatus } from '../../../dto/enums/TableStatus';
import { ApprovalTierModel } from '../../../dto/models/JackpotModels';
import { HASFLAG, TRIGGER_BLUR } from '../../../libraries/Functions';
import { GET_PROFILE } from '../../../libraries/Global';
import { AddAccessMatrix, EditAccessMatrix, SelectAccessMatrix } from '../../../states/AccessMatrix/AccessMatrixActions';
import { GetApprovalTierList } from '../../../states/PAS/JackpotApprovalTier/ApprovalTierActions';
import { IStore } from '../../../states/store/IStore';
import DataTable from '../../DataTable/DataTable';
import { TableStyle } from '../../DataTable/IDataTable';
import CheckBoxGroup from '../../InputBox/CheckBoxGroup';
import { ICheckBoxOption, LoadCheckboxOptionFromEnum } from '../../InputBox/ICheckBoxGroup';
import { TextboxType } from '../../InputBox/IInputBox';
import InputBox from '../../InputBox/InputBox';
import { ISelectOption, LoadSelectOptionFromEnum } from '../../InputBox/ISelectBox';
import SelectBox from '../../InputBox/SelectBox';
import TextField from '../../InputBox/TextField';
import { ModalType } from '../../ModalBox/IModalBox';
import ModalBox from '../../ModalBox/ModalBox';

interface IAccessMatrixForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit: boolean;
}

const AccessMatrixForm = (props: IAccessMatrixForm) => {
    const [roleNameInput, setRoleNameInput] = useState("");
    const [roleNameValid, setRoleNameValid] = useState(props.isEdit);
    const [descriptionInput, setDescription] = useState("");
    const [selectedStatus, setStatus] = useState(TableStatus.ACTIVE);
    const statusOptions: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);

    const [selectedRPTAccess, setRPTAccess] = useState([] as string[]);
    const [selectedCashierAccess, setCashierAccess] = useState([] as string[]);
    const backOfficeAccess: ICheckBoxOption[] = LoadCheckboxOptionFromEnum(BackOfficeAccess).slice(1);
    const reportAccess: ICheckBoxOption[] = LoadCheckboxOptionFromEnum(ReportingAccess).slice(1);
    const cashierAccess: ICheckBoxOption[] = LoadCheckboxOptionFromEnum(CashierAccess).slice(1);

    // Approval Tier
    const [approvalTiersOptions, setApprovalTierOptions] = useState([] as ISelectOption[]);
    const [selectedApprovalTier, setApprovalTier] = useState(0);
    const [approvalTierValid, setApprovalTierValid] = useState(false);

    const backOfficeAccessW: React.ReactElement[] = [];
    const backOfficeAccessR: React.ReactElement[] = [];

    const dispatch = useDispatch();
    const amStates = useSelector((state: IStore) => state.accessMatrixState);
    const approvalTierStates = useSelector((state: IStore) => state.approvalTierState);
    const amLoading = amStates.loading;
    const approvalTierLoading = approvalTierStates.loading;
    const { roleData } = amStates;
    const { approvalTierListData } = approvalTierStates;

    const [matrixTable, setMatrixTable] = useState<any[][]>([[]]);

    const [isLoaded, setLoaded] = useState(false);
    // To get user back office access
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;
    const userBackOfficeAccess = userProfileData.acc ? userProfileData.acc.bor : 0;
    const isJackpotApprovalTierEnable = HASFLAG(userBackOfficeAccess, BackOfficeAccess.JACKPOT_APPROVAL)

    useEffect(() => {
        setLoaded(!amLoading && !approvalTierLoading);
    }, [amLoading, approvalTierLoading])

    useEffect(() => {
        dispatch(GetApprovalTierList());
        if (props.isEdit && props.editID) {
            dispatch(SelectAccessMatrix(props.editID));
        } else
            ClearFields();

        backOfficeAccess.map(value => {
            backOfficeAccessW.push(
                <FormGroup check>
                    <Input type="checkbox" id={value.value + "_w"} value={value.value} onClick={() => WriteCheckBoxClicked(value.value)} disabled={!props.canEdit} />
                </FormGroup>
            )
        });

        backOfficeAccess.map(value => {
            backOfficeAccessR.push(
                <FormGroup check>
                    <Input type="checkbox" id={value.value + "_r"} value={value.value}
                        hidden={value.value == BackOfficeAccess.PASSWORD_RESET.toString() || value.value == BackOfficeAccess.TERMINATE_GAME_SERVICE.toString()} disabled={!props.canEdit} />
                </FormGroup>
            )
        });

        var table: any[][] = [[]]
        backOfficeAccess.map((value, index) => {
            var dataRow = [value.display, backOfficeAccessW[index], backOfficeAccessR[index]];
            table.push(dataRow)
        })

        setMatrixTable(table)
    }, [props.isEdit]);

    const WriteCheckBoxClicked = (checkboxStr: string) => {
        if (checkboxStr == BackOfficeAccess.PASSWORD_RESET.toString()) return;

        const writeChkBox: HTMLInputElement = document.getElementById(checkboxStr + "_w") as HTMLInputElement;
        const readChkBox: HTMLInputElement = document.getElementById(checkboxStr + "_r") as HTMLInputElement;

        if (writeChkBox.checked)
            readChkBox.checked = true;

        readChkBox.disabled = writeChkBox.checked;
    }

    useEffect(() => {
        if (approvalTierListData && approvalTierListData.atl) {
            const approvalTiers: ISelectOption[] = approvalTierListData.atl.map((item) => {
                return {
                    display: item.tsh.toString(),
                    value: item.jid.toString()
                }
            });

            setApprovalTierOptions(approvalTiers);
            if (approvalTiers.length != 0 && props.isEdit) {
                setApprovalTier(approvalTierListData.atl[0].jid)
                setApprovalTierValid(true)
            }
        }
    }, [approvalTierListData]);


    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (props.isEdit && roleData.rnm) {
            setRoleNameInput(roleData.rnm);
            setDescription(roleData.des);
            setStatus(roleData.stt);
            SetBOData(true);
            SetBOData(false);
            SetRptData();
            SetCashierData();
            if (roleData.apt)
                setApprovalTier(roleData.apt.jid);
        }
    }, [roleData]);

    const SetBOData = (isWrite: boolean) => {
        const wCheckboxes: HTMLInputElement[] = [];
        const rCheckboxes: HTMLInputElement[] = [];
        backOfficeAccess.map(value => {
            wCheckboxes.push(document.getElementById(value.value + "_w") as HTMLInputElement);
            rCheckboxes.push(document.getElementById(value.value + "_r") as HTMLInputElement);
        });

        var i: number = 0;

        Object.values(BackOfficeAccess).slice(1).filter(x => Number(x)).map(x => {
            if (HASFLAG(isWrite ? roleData.bow : roleData.bor, x as BackOfficeAccess)) {
                if (isWrite) {
                    wCheckboxes[i].checked = true;

                    // Update read as true as well
                    rCheckboxes[i].checked = true;
                    rCheckboxes[i].disabled = true;
                }
                else {
                    rCheckboxes[i].checked = true;
                }
            }
            else
                isWrite ? wCheckboxes[i].checked = false : rCheckboxes[i].checked = false;

            i++;
        })

    }

    const SetRptData = () => {
        const selectedResult: string[] = [];
        Object.values(ReportingAccess).map(x => {
            if (HASFLAG(roleData.rpa, x as number))
                selectedResult.push(x.toString());
        })

        setRPTAccess(selectedResult);
    }

    const SetCashierData = () => {
        const selectedResult: string[] = [];
        Object.values(CashierAccess).map(x => {
            if (HASFLAG(roleData.caa, x as number))
                selectedResult.push(x.toString());
        })

        setCashierAccess(selectedResult);
    }

    const ClearFields = () => {
        setRoleNameInput("");
        setDescription("");
        setStatus(TableStatus.ACTIVE);
        setRPTAccess([]);
        setCashierAccess([]);
    }

    const Valid = (): boolean => {
        return roleNameValid && ((!isJackpotApprovalTierEnable) || (isJackpotApprovalTierEnable && approvalTierValid));
    }

    const BackOfficeAccessResult = (isWrite: boolean): BackOfficeAccess => {
        var result: BackOfficeAccess = BackOfficeAccess.NONE;
        const wCheckboxes: HTMLInputElement[] = [];
        const rCheckboxes: HTMLInputElement[] = [];
        backOfficeAccess.map(value => {
            wCheckboxes.push(document.getElementById(value.value + "_w") as HTMLInputElement);
            rCheckboxes.push(document.getElementById(value.value + "_r") as HTMLInputElement);
        });

        if (isWrite) {
            wCheckboxes.map(value => {
                if (value.checked)
                    result += Number.parseInt(value.value);
            })

        } else {
            rCheckboxes.map(value => {
                if (value.checked)
                    result += Number.parseInt(value.value);
            })
        }

        return result;
    }

    const ReportingAccessResult = (): ReportingAccess => {
        var result: ReportingAccess = ReportingAccess.NONE;
        selectedRPTAccess.forEach((item) => {
            result += Number.parseInt(item.toString());
        });

        return result;
    }

    const CashierAccessResult = (): CashierAccess => {
        var result: CashierAccess = CashierAccess.NONE;
        selectedCashierAccess.forEach((item) => {
            result += Number.parseInt(item.toString());
        });

        return result;
    }

    const save = () => {
        if (Valid() && roleNameInput) {
            const approvalTier: ApprovalTierModel = { jid: selectedApprovalTier, stt: 0, tsh: 0 };

            if (!props.isEdit) {
                dispatch(
                    AddAccessMatrix(
                        roleNameInput,
                        descriptionInput,
                        selectedStatus,
                        approvalTier,
                        BackOfficeAccessResult(false),
                        BackOfficeAccessResult(true),
                        ReportingAccessResult(),
                        CashierAccessResult(),
                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditAccessMatrix(
                            props.editID,
                            roleNameInput,
                            descriptionInput,
                            selectedStatus,
                            approvalTier,
                            BackOfficeAccessResult(false),
                            BackOfficeAccessResult(true),
                            ReportingAccessResult(),
                            CashierAccessResult(),
                        ));
                }
            }
        } else {
            if (isJackpotApprovalTierEnable)
                TRIGGER_BLUR("approvalTier");

            TRIGGER_BLUR("name");
        }


        props.setSaveTrigger(false);
    }

    return (
        <Container>
            <div style={isLoaded ? { display: 'none', visibility: 'hidden' } : { display: 'block', visibility: 'visible' }} className='text-center p-4'><Spinner key='1' /></div>
            <div style={isLoaded ? { display: 'block', visibility: 'visible' } : { display: 'none', visibility: 'hidden' }}><Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <InputBox
                                    id="name"
                                    name="name"
                                    label="Role Name"
                                    type={TextboxType.TEXT}
                                    inputState={setRoleNameInput}
                                    placeholder={"Role Name"}
                                    validState={setRoleNameValid}
                                    isRequired={true}
                                    value={roleNameInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <SelectBox id="status" name="status" label="Status"
                                    options={statusOptions} value={props.isEdit ? roleData.stt : TableStatus.ACTIVE}
                                    isDisabled={!props.isEdit || !props.canEdit}
                                    inputState={setStatus}
                                    isRequired={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col hidden={!isJackpotApprovalTierEnable}>
                            <FormGroup>
                                <SelectBox id="approvalTier" name="approvalTier" label="Jackpot Approval Tier"
                                    options={approvalTiersOptions} value={selectedApprovalTier}
                                    inputState={setApprovalTier}
                                    validState={setApprovalTierValid}
                                    isRequired={isJackpotApprovalTierEnable}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <TextField id="des" name="des" label="Description"
                                    placeholder="Description" value={props.isEdit ? roleData.des : ""}
                                    inputState={setDescription} maxLength={300} col={5} row={5}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable
                                isHover={false}
                                isloading={false}
                                isStriped={true}
                                tableStyle={TableStyle.ROW_BORDER}
                                title={["Back Office Access", "Write", "Read"]}
                                data={matrixTable}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="bowrite"><h6><b>Report Access</b></h6></Label>
                                <CheckBoxGroup id="report" values={selectedRPTAccess}
                                    options={reportAccess} inputState={setRPTAccess}
                                    inline={false}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="bowrite"><h6><b>Cashier Access</b></h6></Label>
                                <CheckBoxGroup id="cashier" values={selectedCashierAccess}
                                    options={cashierAccess} inputState={setCashierAccess}
                                    inline={false}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form></div>
        </Container>
    )
}

const AccessMatrixModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const amState = useSelector((state: IStore) => state.accessMatrixState);
    const aptState = useSelector((state: IStore) => state.approvalTierState);
    const { err, suc, loading } = amState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false)
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        if (aptState.err !== "") {
            setErrMsg(aptState.err)
            return;
        }

        if (err !== "") {
            setErrMsg(err)
            return;
        }

        setErrMsg("");
    }, [aptState.err, err])

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.ACCESS_MATRIX_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit Role" : "Add Role"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={suc}
                child={
                    <AccessMatrixForm isEdit={props.isEdit} editID={props.editID} canEdit={canEdit} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? <Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default AccessMatrixModal;

