import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { AccessControlSideBar } from "../../navigation/AccessControlRoutes";
import { AccountingSideBar } from "../../navigation/AccoutingRoutes";
import { CashierSideBar } from "../../navigation/CashierRoutes";
import { CommonSideBar } from "../../navigation/CommonRoutes";
import { GameSideBar } from "../../navigation/GameRoutes";
import { UpdateRoutes } from "../../navigation/navData";
import { PASSideBar } from "../../navigation/PASRoutes";
import { ReportingSideBar } from "../../navigation/ReportingRoutes";
import { ServiceAuthenticationSideBar } from "../../navigation/ServiceAuthenticationRoutes";
import { SystemSideBar } from "../../navigation/SystemRoutes";
import { IStore } from "../../states/store/IStore";
import "../../styles/Sidebar.css";

export default function Sidebar(prop: { openSideBar: boolean }) {
    const location = useLocation();
    const userState = useSelector((state: IStore) => state.userState);
    const { userProfileData } = userState;
    const backOfficeAccess = userProfileData.acc ? userProfileData.acc.bor : 0;
    const reportingAccess = userProfileData.acc ? userProfileData.acc.rpa : 0;
    const cashierAccess = userProfileData.acc ? userProfileData.acc.caa : 0;
    UpdateRoutes();

    return (
        <div className={prop.openSideBar ? "sideBar" : "sideBarClose"}>
            <div>
                {CommonSideBar(prop.openSideBar, backOfficeAccess, location.pathname)}
                {AccessControlSideBar(prop.openSideBar, backOfficeAccess, location.pathname)}
                {CashierSideBar(prop.openSideBar, cashierAccess, location.pathname)}
                {AccountingSideBar(prop.openSideBar, backOfficeAccess, location.pathname)}
                {GameSideBar(prop.openSideBar, backOfficeAccess, location.pathname)}
                {PASSideBar(prop.openSideBar, backOfficeAccess, location.pathname)}
                {ReportingSideBar(prop.openSideBar, reportingAccess, location.pathname)}
                {SystemSideBar(prop.openSideBar, backOfficeAccess, location.pathname)}
                {ServiceAuthenticationSideBar(prop.openSideBar, backOfficeAccess, location.pathname)}
            </div>
        </div>
    );
}
