import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { ConcludeColumn, IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer"
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { SlotGameType } from "../../dto/enums/SlotGames";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetAccumulatorList } from "../../states/PAS/JackpotAccumulator/AccumulatorActions";
import { GenerateJackpotDetailContributionCSVRequest, GenerateJackpotDetailContributionPDFRequest, GetJackpotDetailContributionReportRequest, GetJackpotContributionSummaryReportRequest } from "../../states/Reporting/JackpotContributionReport/JackpotContributionReportingActions";
import { IStore } from "../../states/store/IStore";

const JackpotContributionDetailReporting = () => {
    const dispatch = useDispatch();
    const poolContributionReportState = useSelector((state: IStore) => state.poolContributionReportState);
    const { contributionReportListData, contributionReportSummaryListData, loading, err, suc } = poolContributionReportState;

    const accStates = useSelector((state: IStore) => state.accumulatorState);
    const { accumulatorListData } = accStates;
    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    // Search Criteria
    const [tradingDate, setTradingDate] = useState("");
    const [tradingDateValid, setTradingDateValid] = useState(false);

    const [selectedAccumulator, selectAccumulator] = useState(0);
    const [selectedAccumulatorValid, setAccumulatorValid] = useState(false);

    const [accumulatorOptions, setAccumulatorOptions] = useState([] as ISelectOption[]);

    const take = (page: number) => {
        // TODO: dispatch change page, provide current page
        if (valid()) {
            setPage(page);
            dispatch(GetJackpotDetailContributionReportRequest(selectedAccumulator, new Date(tradingDate.split("T")[0]), page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const pagination: ITablePagination = {
        id:"jackpotContributionDetailPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? contributionReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const emptyTable: IDataTable = {
        title: ["No.", "Game Type", "Game ID", "Credit Rate", "Transaction Date", "Trading Date"],
        data: undefined,
        rowBoldIndex: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        dispatch(GetAccumulatorList());
    }, []);

    useEffect(() => {
        if (accumulatorListData && accumulatorListData.acl) {
            const accumulators: ISelectOption[] = accumulatorListData.acl.map((item) => {
                return {
                    display: item.jan,
                    value: item.aid.toString()
                }
            });

            setAccumulatorOptions(accumulators);

            if (accumulators.length != 0) {
                setAccumulatorValid(true);
                selectAccumulator(parseInt(accumulators[0].value));
            }
        }
    }, [accumulatorListData]);

    useEffect(() => {
        var table: IDataTable = emptyTable;
        var index: number = currentPage * TABLE_MAX_ROW;
        if (contributionReportListData && contributionReportListData.rml != undefined && contributionReportListData.rml.length > 0 && hasSearched) {
            const poolGroup = contributionReportListData.rml;
            const data: any[][] = [];
            var totalPoolAmount: number[] = [];
            var header: string[] = ["No.", "Game Type", "Game ID",];
            var columnWidth: string[] = ["50px", "200px", "100px"];
            if (poolGroup.length > 0 && poolGroup[0].dml.length > 0) {
                poolGroup[0].dml.map(x => {
                    header.push(x.pnm);
                    columnWidth.push("200px");
                    totalPoolAmount.push(0);
                })
            }
            header.push("Credit Rate");
            header.push("Transaction Date");
            header.push("Trading Date");

            columnWidth.push("150px");
            columnWidth.push("220px");
            columnWidth.push("150px");

            poolGroup.map((x) => {
                var rows: string[] = [];
                rows.push((1 + index++).toString());
                rows.push(SlotGameType[x.gty].replace("_", " "));
                rows.push(x.gid);

                var colIndex = 0;
                x.dml.map(y => {
                    totalPoolAmount[colIndex] = totalPoolAmount[colIndex] += y.pda;
                    rows.push(CVT_TO_FLOAT_STRING(y.pda));
                    colIndex++;
                })
                rows.push(CVT_TO_FLOAT_STRING(x.cdr));
                rows.push(FORMAT_DATETIME_TO_VIEW(x.tdt, "yyyy-MM-dd hh:mm:ss"));
                rows.push(FORMAT_DATETIME_TO_VIEW(x.tdd, "yyyy-MM-dd"));

                data.push(rows);
            });

            table.data = data;
            const concludeColumns: ConcludeColumn[] = [];
            var concludeColumnStartIndex: number = 3;
            for (let i = 0; i < totalPoolAmount.length; i++) {
                concludeColumns.push({ columnIndex: concludeColumnStartIndex, content: CVT_TO_FLOAT_STRING(totalPoolAmount[i]) });
                concludeColumnStartIndex++;
            }

            table.columnWidth = columnWidth;
            table.concludeColumn = concludeColumns;
            table.title = header;
        } else
            table.data = [];

        setReportTable(table);
    }, [contributionReportListData])

    const valid = (): boolean => {
        return tradingDate !== "" && tradingDateValid && selectedAccumulatorValid;
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);
                setReportTable(emptyTable)
            dispatch(GetJackpotDetailContributionReportRequest(selectedAccumulator, new Date(tradingDate.split("T")[0]), 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));

        } else {
            TRIGGER_BLUR("tradingDatePicker");
            TRIGGER_BLUR("accumulatorSelect");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateJackpotDetailContributionPDFRequest(selectedAccumulator, new Date(tradingDate.split("T")[0]), 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateJackpotDetailContributionCSVRequest(selectedAccumulator, new Date(tradingDate.split("T")[0]), 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <InputBox id="tradingDatePicker" name="tradingDatePicker" key="tradingDatePicker" isRequired={true} HasCancelledButton={true} invalidMessage="This field is required."
                            validState={setTradingDateValid} inputState={setTradingDate} value={tradingDate} type={TextboxType.DATE} label="Trading Date" />
                    </Row>
                        <Row>
                            <Col md={12}>
                            <SelectBox id="accumulatorSelect" name="accumulatorSelect" options={accumulatorOptions}
                                    isRequired={true} inputState={selectAccumulator} validState={setAccumulatorValid} label="Accumulator" />
                            </Col>
                        </Row>
                    <Row>
                        <Col>
                            <Button className="mt-3" color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="JackpotContribution"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}

export default JackpotContributionDetailReporting;
