import { PlayerLockModel } from "../../dto/models/PlayerLockModel";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./PlayerLockActions";
import { PlayerLockActionTypes } from "./PlayerLockActionTypes";
import { IPlayerLockStates, InitPlayerLockStates } from "./PlayerLockStates";

export const PlayerLockReducers = (state = InitPlayerLockStates, action: ActionTypes): IPlayerLockStates => {
    switch (action.type) {
        case PlayerLockActionTypes.SEARCH_PLAYER: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, playerLockStatus: {} as PlayerLockModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var playerLockStatus: PlayerLockModel = {} as PlayerLockModel;

                if (data && !data.err) {
                    playerLockStatus = data.res;
                }
                return { ...state, loading: false, playerLockStatus: playerLockStatus, err: action.payload.err ? action.payload.err : "" };
            }
        }
        case PlayerLockActionTypes.LOCK_PLAYER: 
        case PlayerLockActionTypes.UNLOCK_PLAYER: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, playerLockStatus: {} as PlayerLockModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var playerLockStatus: PlayerLockModel = {} as PlayerLockModel;

                if (data && !data.err) {
                    playerLockStatus = data.res;
                }
                return { ...state, loading: false, playerLockStatus: playerLockStatus, err: action.payload.err ? action.payload.err : "" };
            }
        }
        default:
            return state
    }
}