import { ManualCreditTransferListModel, CreditTransferDetailsModel } from "../../dto/models/ManualCreditTransferModel";

export interface IManualCreditTransferStates {
    loading: boolean;
    err: string;
    suc: string;
    manualCreditTransferListData: ManualCreditTransferListModel;
    creditTransferDetails: CreditTransferDetailsModel;
}


export const InitManualCreditTransferState: IManualCreditTransferStates = {
    loading: false,
    err: "",
    suc: "",
    manualCreditTransferListData: {} as ManualCreditTransferListModel,
    creditTransferDetails: {} as CreditTransferDetailsModel,
}
