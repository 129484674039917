import React from "react";

export interface ISelectBox {
    id: string;
    name: string;
    tipsID?: string;
    tips?: string | any | undefined
    label?: string;
    className?: string;
    value?: any;
    isDisabled?: boolean;
    isRequired?: boolean;
    options: ISelectOption[];
    placeHolderContent?: string;

    allowEmptyData?: boolean;

    inputState?: React.Dispatch<React.SetStateAction<any>>;
    validState?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ISelectOption {
    display: string;
    value: string;
}

export function LoadSelectOptionFromEnum<E>(Enum: E, setNoneAsAll: boolean = false): ISelectOption[] {
    if (setNoneAsAll) {
        return Object.keys(Enum).filter(key => isNaN(Number(key)))
            .map(key => (
                key == 'NONE' ? { display: "ALL", value: (Enum as any)[key] } : { display: key.replace(/_/g, " "), value: (Enum as any)[key] }
            ));
    }
    else {
        return Object.keys(Enum).filter(key => isNaN(Number(key)))
            .map(key => (
                { display: key.replace(/_/g, " "), value: (Enum as any)[key] }
            ));
    }
}