import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { BackOfficeAccess } from '../../../dto/enums/AccessMatrix/BackOfficeAccess';
import { CashierAccess } from '../../../dto/enums/AccessMatrix/CashierAccess';
import { ReportingAccess } from '../../../dto/enums/AccessMatrix/ReportingAccess';
import { TableStatus } from '../../../dto/enums/TableStatus';
import { ApprovalTierModel } from '../../../dto/models/JackpotModels';
import { RoleModel } from '../../../dto/models/UserAccessModels';
import { HASFLAG, TRIGGER_BLUR } from '../../../libraries/Functions';
import { AddAccessMatrix, EditAccessMatrix, SelectAccessMatrix } from '../../../states/AccessMatrix/AccessMatrixActions';
import { GetApprovalTierList } from '../../../states/PAS/JackpotApprovalTier/ApprovalTierActions';
import { IStore } from '../../../states/store/IStore';
import DataTable from '../../DataTable/DataTable';
import { TableStyle } from '../../DataTable/IDataTable';
import CheckBoxGroup from '../../InputBox/CheckBoxGroup';
import { ICheckBoxOption, LoadCheckboxOptionFromEnum } from '../../InputBox/ICheckBoxGroup';
import { TextboxType } from '../../InputBox/IInputBox';
import InputBox from '../../InputBox/InputBox';
import { ISelectOption, LoadSelectOptionFromEnum } from '../../InputBox/ISelectBox';
import SelectBox from '../../InputBox/SelectBox';
import TextField from '../../InputBox/TextField';
import { ModalType } from '../../ModalBox/IModalBox';
import ModalBox from '../../ModalBox/ModalBox';

interface IAccessMatrixForm {
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAccessMatrixView {
    accessMatrix: RoleModel
}

const UserListingAccessMatrixForm = (props: IAccessMatrixForm) => {
    const [roleNameInput, setRoleNameInput] = useState("");
    const [descriptionInput, setDescription] = useState("");
    const [selectedStatus, setStatus] = useState(TableStatus.ACTIVE);
    const statusOptions: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);

    const [selectedRPTAccess, setRPTAccess] = useState([] as string[]);
    const [selectedCashierAccess, setCashierAccess] = useState([] as string[]);
    const backOfficeAccess: ICheckBoxOption[] = LoadCheckboxOptionFromEnum(BackOfficeAccess).slice(1);
    const reportAccess: ICheckBoxOption[] = LoadCheckboxOptionFromEnum(ReportingAccess).slice(1);
    const cashierAccess: ICheckBoxOption[] = LoadCheckboxOptionFromEnum(CashierAccess).slice(1);

    // Approval Tier
    const [approvalTiersOptions, setApprovalTierOptions] = useState([] as ISelectOption[]);
    const [selectedApprovalTier, setApprovalTier] = useState(0);
    const [approvalTierValid, setApprovalTierValid] = useState(false);

    const backOfficeAccessW: React.ReactElement[] = [];
    const backOfficeAccessR: React.ReactElement[] = [];

    const dispatch = useDispatch();
    const amStates = useSelector((state: IStore) => state.accessMatrixState);
    const approvalTierStates = useSelector((state: IStore) => state.approvalTierState);
    const { roleData } = amStates;
    const { approvalTierListData } = approvalTierStates;

    const [matrixTable, setMatrixTable] = useState<any[][]>([[]]);

    useEffect(() => {
        dispatch(GetApprovalTierList());
        if (props.editID) {
            dispatch(SelectAccessMatrix(props.editID));
        }

        backOfficeAccess.map(value => {
            backOfficeAccessW.push(
                <FormGroup check>
                    <Input type="checkbox" id={value.value + "_w"} value={value.value} disabled={true} />
                </FormGroup>
            )
        });

        backOfficeAccess.map(value => {
            backOfficeAccessR.push(
                <FormGroup check>
                    <Input type="checkbox" id={value.value + "_r"} value={value.value} disabled={true} hidden={value.value == BackOfficeAccess.PASSWORD_RESET.toString() || value.value == BackOfficeAccess.TERMINATE_GAME_SERVICE.toString()} />
                </FormGroup>
            )
        });

        var table: any[][] = [[]]
        backOfficeAccess.map((value, index) => {
            var dataRow = [value.display, backOfficeAccessW[index], backOfficeAccessR[index]];
            table.push(dataRow)
        })

        setMatrixTable(table)
    }, [props.editID]);

    useEffect(() => {
        if (approvalTierListData && approvalTierListData.atl) {
            const approvalTiers: ISelectOption[] = approvalTierListData.atl.map((item) => {
                return {
                    display: item.tsh.toString(),
                    value: item.jid.toString()
                }
            });

            setApprovalTierOptions(approvalTiers);
            if (approvalTiers.length != 0) {
                setApprovalTier(approvalTierListData.atl[0].jid)
                setApprovalTierValid(true)
            }
        }
    }, [approvalTierListData]);

    useEffect(() => {
        if (roleData.rnm) {
            setRoleNameInput(roleData.rnm);
            setDescription(roleData.des);
            setStatus(roleData.stt);
            SetBOData(true);
            SetBOData(false);
            SetRptData();
            SetCashierData();
            if (roleData.apt)
                setApprovalTier(roleData.apt.jid);
        }
    }, [roleData]);

    const SetBOData = (isWrite: boolean) => {
        const wCheckboxes: HTMLInputElement[] = [];
        const rCheckboxes: HTMLInputElement[] = [];
        backOfficeAccess.map(value => {
            wCheckboxes.push(document.getElementById(value.value + "_w") as HTMLInputElement);
            rCheckboxes.push(document.getElementById(value.value + "_r") as HTMLInputElement);
        });
            
        var i: number = 0;
        var x =
            Object.values(BackOfficeAccess).slice(1).filter(x => Number(x)).map(x => {
                if (wCheckboxes[i] != null) {
                    if (HASFLAG(isWrite ? roleData.bow : roleData.bor, x as BackOfficeAccess))
                        isWrite ? wCheckboxes[i].checked = true : rCheckboxes[i].checked = true;
                    else
                        isWrite ? wCheckboxes[i].checked = false : rCheckboxes[i].checked = false;
                    i++;
                }

            })

    }

    const SetRptData = () => {
        const selectedResult: string[] = [];
        Object.values(ReportingAccess).map(x => {
            if (HASFLAG(roleData.rpa, x as number))
                selectedResult.push(x.toString());
        })

        setRPTAccess(selectedResult);
    }

    const SetCashierData = () => {
        const selectedResult: string[] = [];
        Object.values(CashierAccess).map(x => {
            if (HASFLAG(roleData.caa, x as number))
                selectedResult.push(x.toString());
        })

        setCashierAccess(selectedResult);
    }

    const ReportingAccessResult = (): ReportingAccess => {
        var result: ReportingAccess = ReportingAccess.NONE;
        selectedRPTAccess.forEach((item) => {
            result += Number.parseInt(item.toString());
        });

        return result;
    }

    const CashierAccessResult = (): CashierAccess => {
        var result: CashierAccess = CashierAccess.NONE;
        selectedCashierAccess.forEach((item) => {
            result += Number.parseInt(item.toString());
        });

        return result;
    }

    return (
        <Form className="pt-2">
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <InputBox
                            id="name"
                            name="name"
                            label="Role Name"
                            type={TextboxType.TEXT}
                            inputState={setRoleNameInput}
                            placeholder={"Role Name"}
                            value={roleNameInput}
                            isDisabled={true}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <SelectBox id="status" name="status" label="Status"
                            options={statusOptions} value={roleData.stt} /*value={props.isEdit ? roleData.stt : TableStatus.ACTIVE}*/
                            isDisabled={true}
                            inputState={setStatus}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <SelectBox id="approvalTier" name="approvalTier" label="Jackpot Approval Tier"
                            options={approvalTiersOptions} value={selectedApprovalTier}
                            inputState={setApprovalTier}
                            validState={setApprovalTierValid}
                            isDisabled={true}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <TextField id="des" name="des" label="Description"
                            placeholder="Description" value={roleData.des} /*value={props.isEdit ? roleData.des : ""}*/
                            inputState={setDescription} maxLength={300} col={5} row={5} isDisabled={true}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        isHover={false}
                        isloading={false}
                        isStriped={true}
                        tableStyle={TableStyle.ROW_BORDER}
                        title={["Back Office Access", "Write", "Read"]}
                        data={matrixTable}
                    />
                </Col>
            </Row>
            <Row className={"mt-3"}>
                <Col md={6}>
                    <FormGroup>
                        <Label for="bowrite"><h6><b>Report Access</b></h6></Label>
                        <CheckBoxGroup id="report" values={selectedRPTAccess}
                            options={reportAccess} inputState={setRPTAccess}
                            inline={false} isDisabled={true}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="bowrite"><h6><b>Cashier Access</b></h6></Label>
                        <CheckBoxGroup id="cashier" values={selectedCashierAccess}
                            options={cashierAccess} inputState={setCashierAccess}
                            inline={false} isDisabled={true}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

const UserListingAccessMatrixModal = (props: { editID: number, isOpen: boolean, setOpenState: any }) => {
    const amState = useSelector((state: IStore) => state.accessMatrixState);
    const { err, suc, loading } = amState;
    const [save, setSave] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    return (
        <div>
            <ModalBox
                title={"User Role"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={err}
                success={suc}
                child={
                    <UserListingAccessMatrixForm editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
            />
        </div>
    );
}

export default UserListingAccessMatrixModal;

