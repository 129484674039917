import { UserProfileListModel, UserProfileModel } from "../../dto/models/UserAccessModels";

export interface IUserState {
    loading: boolean;
    userDataErr: string;
    userDataSuc: string;
    userProfileErr: string;
    userProfileSuc: string;
    userProfileWarning: string;
    userProfileData: UserProfileModel;
    userProfileList: UserProfileListModel;
    userData: UserProfileModel;
    userDataList: UserProfileListModel;
    isLogin: boolean;
    isSessionTimeout: boolean;
}
export const InitUserState: IUserState = {
    loading: false,
    userDataErr: "",
    userDataSuc: "",
    userProfileErr: "",
    userProfileSuc: "",
    userProfileWarning: "",
    userProfileData: {} as UserProfileModel,
    userProfileList: {} as UserProfileListModel,
    userData: {} as UserProfileModel,
    userDataList: {} as UserProfileListModel,
    isLogin: false,
    isSessionTimeout: false
}