import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FORMAT_DATETIME_TO_VIEW } from '../../libraries/DateHelper';
import { IStore } from "../../states/store/IStore";
import * as Global from "../../libraries/Global"
import { GetTradingDate, EditTradingDate } from "../../states/TradingDate/TradingDateActions";
import InputBox from '../../components/InputBox/InputBox';
import { TextboxType } from '../../components/InputBox/IInputBox';
import { HASFLAG, TRIGGER_BLUR } from '../../libraries/Functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { AlertSize, AlertType } from '../../components/AlertMessage/IAlert';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';


const TradingDateMaintenance = () => {
    const [tradingDate, setTradingDate] = useState("");
    const [isAutoMode, setAutoMode] = useState(Boolean);
    const [updatedTime, setUpdatedTime] = useState("");
    const [updatedTimeValid, setUpdatedTimeValid] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [canEdit, setCanEdit] = useState(false);

    const dispatch = useDispatch();

    const tradingDateState = useSelector((state: IStore) => state.tradingDateState);
    const submit = () => {
        if (updatedTimeValid)
            dispatch(EditTradingDate(tradingDate as unknown as Date, isAutoMode, Date.now as unknown as Date, Global.GET_PROFILE().uid, updatedTime));
        else
            TRIGGER_BLUR("udt");
    }


    useEffect(() => {
        const access: BackOfficeAccess = Global.GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.TRADING_DATE_MAINTENANCE))
        }

        dispatch(GetTradingDate());
    }, [])

    useEffect(() => {
        if (tradingDateState && !tradingDateState.err && tradingDateState.data && Object.keys(tradingDateState.data).length !== 0) {
            setTradingDate(FORMAT_DATETIME_TO_VIEW(tradingDateState.data.tdd, "yyyy-MM-dd"));
            setAutoMode(tradingDateState.data.iam);
            setUpdatedTime(tradingDateState.data.udt);

            setUpdatedTimeValid(true);
        }

        if (!isAlertOpen && alertMessage != "") {
            setAlertMessage("");
        }
        else {
            var isAlert = false;
            if (tradingDateState.err != undefined && tradingDateState.err != "") {
                setAlertMessage(tradingDateState.err);
                setAlertOpen(true);
                isAlert = true;
            }
            else if (tradingDateState.suc != undefined && tradingDateState.suc != "") {
                setAlertMessage(tradingDateState.suc);
                setAlertOpen(true);
                isAlert = true;
            }

            if (isAlert) {
                var alert = document.getElementById('alertMessage');
                if (alert) {
                    var headerOffset = 65;
                    var elementPosition = alert.getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }
            }
        }

    }, [tradingDateState.data])

    return (
        <Container>
            <Row id="alertMessage">
                <Col>
                    <AlertMessage
                        content={alertMessage}
                        alertType={AlertType.FAIL}
                        alertSize={AlertSize.LONG}
                        isOpen={isAlertOpen && tradingDateState.err != undefined && tradingDateState.err != ""}
                        openState={setAlertOpen}
                        disappearTime={5}
                    />
                    <AlertMessage
                        content={alertMessage}
                        alertType={AlertType.SUCCESS}
                        alertSize={AlertSize.LONG}
                        isOpen={isAlertOpen && tradingDateState.suc != undefined && tradingDateState.suc != ""}
                        openState={setAlertOpen}
                        disappearTime={5}
                    />
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="tradingDate">
                                Trading Date
                            </Label>
                            <Input
                                id="tradingDate"
                                name="tradingDate"
                                placeholder="Trdaing Date"
                                type="date"
                                onChange={(e) => setTradingDate(e.target.value)}
                                value={tradingDate}
                                disabled={!canEdit}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup check style={{ paddingTop: "6%" }}>
                            <Label for="enableAutoMode">
                                Enable Auto Mode
                            </Label>
                            <Input
                                id="enableAutoMode"
                                name="enableAutoMode"
                                type="checkbox"
                                onChange={(e) => setAutoMode(e.target.checked)}
                                checked={isAutoMode}
                                disabled={!canEdit}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="udt">
                                Updated Time
                            </Label>
                            <InputBox
                                id="udt"
                                name="udt"
                                placeholder="Updated Time"
                                type={TextboxType.TIME}
                                inputState={setUpdatedTime}
                                validState={setUpdatedTimeValid}
                                isRequired={true}
                                value={updatedTime}
                                isDisabled={!canEdit}
                            />
                        </FormGroup>

                    </Col>
                </Row>


                <Row>
                    <Col>
                        {canEdit ? < Button color="info" onClick={submit} outline><FontAwesomeIcon icon={faFloppyDisk} />  Save</Button> : <></>}
                    </Col>
                </Row>


            </Form>
        </Container>
    );
};

export default TradingDateMaintenance;