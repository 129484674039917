import { GambleWinLoseReportListModel } from "../../../dto/models/Reporting/GambleWinLoseReportModel";
import { WinLoseCSVReportModel, WinLosePDFReportModel } from "../../../dto/models/Reporting/WinLoseReportModel";

export interface IGambleWinLoseReportState {
    loading: boolean;
    err: string;
    suc: string;
    gambleWinLoseReportListData: GambleWinLoseReportListModel;
    pdfData: WinLosePDFReportModel;
    csvData: WinLoseCSVReportModel;
}

export const InitGambleWinLoseReportState: IGambleWinLoseReportState = {
    loading: false,
    err: "",
    suc: "",
    gambleWinLoseReportListData: {} as GambleWinLoseReportListModel,
    pdfData: {} as WinLosePDFReportModel,
    csvData: {} as WinLoseCSVReportModel,
}