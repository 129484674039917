import { faLock, faTowerCell, faTowerObservation } from "@fortawesome/free-solid-svg-icons";
import PylonAuthentication from "../containers/ServiceAuthentication/PylonAuthentication";
import { BackOfficeAccess } from "../dto/enums/AccessMatrix/BackOfficeAccess";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const ServiceAuthentication_Pylon = "/PylonServiceAuthentication";

export const ServiceAuthenticationRoutesData: IRouteData[] = [
    {
        to: ServiceAuthentication_Pylon,
        element: <PylonAuthentication />
    },
];

export const ServiceAuthenticationNavigationData: INavigationData[] = [
    {
        requiredAccess: [BackOfficeAccess.SERVICE_AUTHENTICATION],
        icon: faTowerCell,
        title: "Service Authentication",
        path: "Service Authentication",
        searchKeyword: "Service Authentication Maintenance",
        to: ServiceAuthentication_Pylon
    }
];

export function ServiceAuthenticationSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(ServiceAuthenticationNavigationData, "Service Authentication", faLock, "#ab6152", open, access, currentPath);
}