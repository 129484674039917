import { UserListingRequestModel } from "../../../dto/models/Reporting/UserListingRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { UserListingReportingActionTypes } from "./UserListingReportingActionTypes";

type GetUserListingReportRequest = { type: UserListingReportingActionTypes.GET_USER_LISTING_REPORT_REQUEST, payload: IPayload };
type GenerateUserListingPDFRequest = { type: UserListingReportingActionTypes.GENERATE_USER_LISTING_PDF_REQUEST, payload: IPayload };
type GenerateUserListingCSVRequest = { type: UserListingReportingActionTypes.GENERATE_USER_LISTING_CSV_REQUEST, payload: IPayload };

export type ActionTypes =
    GetUserListingReportRequest | GenerateUserListingPDFRequest | GenerateUserListingCSVRequest;

export const GetUserListingReportRequest = (
    userId: string, userAccessMatrix: number, userAccessMatrixLabel: string, userStatus: number, daySinceLastLogin: number, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: UserListingRequestModel = {
            uid: userId,
            uam: userAccessMatrix,
            ual: userAccessMatrixLabel,
            ust: userStatus,
            dll: daySinceLastLogin,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetUserListingReport", request, UserListingReportingActionTypes.GET_USER_LISTING_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserListingReportingActionTypes.GET_USER_LISTING_REPORT_REQUEST, dispatch);
    }
}

export const GenerateUserListingReportPDFRequest = (
    userId: string, userAccessMatrix: number, userAccessMatrixLabel: string, userStatus: number, daySinceLastLogin: number, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: UserListingRequestModel = {
            uid: userId,
            uam: userAccessMatrix,
            ual: userAccessMatrixLabel,
            ust: userStatus,
            dll: daySinceLastLogin,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetUserListingPDF", request, UserListingReportingActionTypes.GENERATE_USER_LISTING_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserListingReportingActionTypes.GENERATE_USER_LISTING_PDF_REQUEST, dispatch);
    }
}

export const GenerateUserListingReportCSVRequest = (
    userId: string, userAccessMatrix: number, userAccessMatrixLabel: string, userStatus: number, daySinceLastLogin: number, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: UserListingRequestModel = {
            uid: userId,
            uam: userAccessMatrix,
            ual: userAccessMatrixLabel,
            ust: userStatus,
            dll: daySinceLastLogin,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetUserListingCSV", request, UserListingReportingActionTypes.GENERATE_USER_LISTING_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserListingReportingActionTypes.GENERATE_USER_LISTING_CSV_REQUEST, dispatch);
    }
}