import { JackpotBigWinApprovalRequestModel } from "../../../dto/models/Reporting/JackpotBigWinApprovalRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { JackpotBigWinApprovalReportingActionTypes } from "./JackpotBigWinApprovalReportingActionTypes";

type GetJackpotBigWinApprovalReportRequest = { type: JackpotBigWinApprovalReportingActionTypes.GET_JACKPOT_BIG_WIN_APPROVAL_REPORT_REQUEST, payload: IPayload };
type GenerateJackpotBigWinApprovalPDFRequest = { type: JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_PDF_REQUEST, payload: IPayload };
type GenerateJackpotBigWinApprovalCSVRequest = { type: JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_CSV_REQUEST, payload: IPayload };

export type ActionTypes =
    GetJackpotBigWinApprovalReportRequest | GenerateJackpotBigWinApprovalPDFRequest | GenerateJackpotBigWinApprovalCSVRequest;

export const GetJackpotBigWinApprovalReportRequest = (
    actionByName: string, pendingType: number, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime:string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: JackpotBigWinApprovalRequestModel = {
            abn: actionByName,
            ptp: pendingType,
            tsf: transFromDate,
            tft: transFromTime,
            tst: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetJackpotBigWinApprovalReport", request, JackpotBigWinApprovalReportingActionTypes.GET_JACKPOT_BIG_WIN_APPROVAL_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotBigWinApprovalReportingActionTypes.GET_JACKPOT_BIG_WIN_APPROVAL_REPORT_REQUEST, dispatch);
    }
}

export const GenerateJackpotBigWinApprovalReportPDFRequest = (
    actionByName: string, pendingType: number, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: JackpotBigWinApprovalRequestModel = {
            abn: actionByName,
            ptp: pendingType,
            tsf: transFromDate,
            tft: transFromTime,
            tst: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetJackpotBigWinApprovalPDF", request, JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_PDF_REQUEST, dispatch);
    }
}

export const GenerateJackpotBigWinApprovalReportCSVRequest = (
    actionByName: string, pendingType: number, transFromDate: Date, transFromTime: string, transToDate: Date, transToTime: string, from: number, take: number
) => async (dispatch: any) => {
    try {
        let request: JackpotBigWinApprovalRequestModel = {
            abn: actionByName,
            ptp: pendingType,
            tsf: transFromDate,
            tft: transFromTime,
            tst: transToDate,
            ttt: transToTime,
            frm: from,
            tak: take
        };
        PostRequest("Reporting/GetJackpotBigWinApprovalCSV", request, JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_CSV_REQUEST, dispatch);
    }
}