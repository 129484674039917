import { GameCreditCurrency } from "../../../dto/models/ConfigurationModels";
import { PoolListModel, PoolModel } from "../../../dto/models/JackpotModels";

export interface IPoolState {
    loading: boolean;
    err: string;
    suc: string;
    poolListData: PoolListModel;
    poolData: PoolModel;
    creditRate: GameCreditCurrency;
}


export const InitPoolState: IPoolState = {
    loading: false,
    err: "",
    suc: "",
    poolListData: {} as PoolListModel,
    poolData: {} as PoolModel,
    creditRate: {} as GameCreditCurrency
}
