import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ManualCreditTransactionReportListModel, ManualCreditTransactionReportPDFModel, ManualCreditTransactionReportCSVModel } from "../../../dto/models/Reporting/ManualCreditTransactionReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";

import { ActionTypes } from "./ManualCreditTransactionReportingActions";
import { ManualCreditTransactionReportingActionTypes } from "./ManualCreditTransactionReportingActionTypes";
import { InitManualCreditTransactionReportState, IManualCreditTransactionReportState } from "./ManualCreditTransactionReportingStates";


export const ManualCreditTransactionReportingReducers = (state = InitManualCreditTransactionReportState, action: ActionTypes): IManualCreditTransactionReportState => {
    switch (action.type) {
        case ManualCreditTransactionReportingActionTypes.GET_MANUAL_CREDIT_TRANSACTION_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, manualCreditTransactionReportListData: {} as ManualCreditTransactionReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: ManualCreditTransactionReportListModel = {} as ManualCreditTransactionReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, manualCreditTransactionReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case ManualCreditTransactionReportingActionTypes.GENERATE_MANUAL_CREDIT_TRANSACTION_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as ManualCreditTransactionReportPDFModel, csvData: {} as ManualCreditTransactionReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: ManualCreditTransactionReportPDFModel = {} as ManualCreditTransactionReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("ManualCreditTransactionReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case ManualCreditTransactionReportingActionTypes.GENERATE_MANUAL_CREDIT_TRANSACTION_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as ManualCreditTransactionReportPDFModel, csvData: {} as ManualCreditTransactionReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: ManualCreditTransactionReportCSVModel = {} as ManualCreditTransactionReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("ManualCreditTransactionReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
