import { TierListModel, TierModel } from "../../../dto/models/JackpotModels";
export interface ITierState {
    loading: boolean;
    err: string;
    suc: string;
    tierListData: TierListModel;
    tierData: TierModel;
}

export const InitTierState: ITierState = {
    loading: false,
    err: "",
    suc: "",
    tierListData: {} as TierListModel,
    tierData: {} as TierModel,
}
