import { TrandingDateModel } from "../../dto/models/TradingDateModels";

export interface ITradingDateState {
    loading: boolean;
    err: string;
    suc: string;
    data: TrandingDateModel;
}

export const InitialTradingState: ITradingDateState = {
    loading: false,
    err: "",
    suc: "",
    data: {} as TrandingDateModel,
};
