import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { CreditTransactionReportListModel, CreditTransactionReportCSVModel, CreditTransactionReportPDFModel } from "../../../dto/models/Reporting/CreditTransactionReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";

import { ActionTypes } from "./CreditTransactionReportingActions";
import { CreditTransactionReportingActionTypes } from "./CreditTransactionReportingActionTypes";
import { InitCreditTransactionReportState, ICreditTransactionReportState } from "./CreditTransactionReportingStates";


export const CreditTransactionReportingReducers = (state = InitCreditTransactionReportState, action: ActionTypes): ICreditTransactionReportState => {
    switch (action.type) {
        case CreditTransactionReportingActionTypes.GET_CREDIT_TRANSACTION_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, creditTransactionReportListData: {} as CreditTransactionReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: CreditTransactionReportListModel = {} as CreditTransactionReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, creditTransactionReportListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as CreditTransactionReportPDFModel, csvData: {} as CreditTransactionReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: CreditTransactionReportPDFModel = {} as CreditTransactionReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("CreditTransactionReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case CreditTransactionReportingActionTypes.GENERATE_CREDIT_TRANSACTION_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as CreditTransactionReportPDFModel, csvData: {} as CreditTransactionReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: CreditTransactionReportCSVModel = {} as CreditTransactionReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        const currentDate = new Date();
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("CreditTransactionReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
