import { JackpotStrikeReportCSVModel, JackpotStrikeReportPDFModel, JackpotStrikeReportListModel, JackpotStrikeSummaryReportListModel } from "../../../dto/models/Reporting/JackpotStrikeReportModel";

export interface IPoolStrikeReportState {
    loading: boolean;
    err: string;
    suc: string;
    strikeReportListData: JackpotStrikeReportListModel;
    strikeReportSummaryListData: JackpotStrikeSummaryReportListModel;
    pdfData: JackpotStrikeReportPDFModel;
    csvData: JackpotStrikeReportCSVModel;
}

export const InitPoolStrikeReportState: IPoolStrikeReportState = {
    loading: false,
    err: "",
    suc: "",
    strikeReportListData: {} as JackpotStrikeReportListModel,
    strikeReportSummaryListData: {} as JackpotStrikeSummaryReportListModel,
    pdfData: {} as JackpotStrikeReportPDFModel,
    csvData: {} as JackpotStrikeReportCSVModel,
}
