import { SlotGameConfigurationListModel } from "../../dto/models/ConfigurationModels";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { JackpotServiceListModel, SlotGameServiceListModel, SlotGameServiceModel } from "../../dto/models/ServiceModels";
import { ActionTypes } from "./GameServiceActions";
import { GameServiceActionTypes } from "./GameServiceActionTypes";
import { IGameServiceState, InitialGameServiceState } from "./GameServiceStates";

export const gameServiceReducer = (state = InitialGameServiceState, action: ActionTypes): IGameServiceState => {
    switch (action.type) {
        case GameServiceActionTypes.SEARCH_GAME_REQUEST:
        case GameServiceActionTypes.GET_GAME_SERVICE_REQUEST:
        case GameServiceActionTypes.GET_GAME_SERVICE_LIST_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return {
                    ...state, loading: true, gameServiceList: {} as SlotGameServiceListModel,
                    err: state.requireReload ? state.err : "", suc: state.requireReload ? state.suc : ""
                }
            } else {
                var data: PylonResponseModel = action.payload.data;
                var serviceListModel: SlotGameServiceListModel = {} as SlotGameServiceListModel;

                if (data && !data.err) {
                    serviceListModel = data.res;
                }

                let errMsg: string = state.requireReload ? state.err : "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }

                return {
                    ...state, loading: false, gameServiceList: serviceListModel, gameServiceData: {} as SlotGameServiceModel, gameList: {} as SlotGameConfigurationListModel, err: errMsg, requireReload: false
                };
            }
        }
        case GameServiceActionTypes.ADD_GAME_SERVICE_REQUEST:
        case GameServiceActionTypes.EDIT_GAME_SERVICE_REQUEST:
        case GameServiceActionTypes.SELECT_GAME_SERVICE_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, gameServiceData: {} as SlotGameServiceModel, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var serviceModel: SlotGameServiceModel = {} as SlotGameServiceModel;

                if (data && !data.err) {
                    serviceModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == GameServiceActionTypes.ADD_GAME_SERVICE_REQUEST) {
                        sucMsg = "Game Service Added Successfully.";
                    }
                    else if (action.type == GameServiceActionTypes.EDIT_GAME_SERVICE_REQUEST) {
                        sucMsg = "Game Service Updated Successfully.";
                    }
                }

                return { ...state, loading: false, gameServiceData: serviceModel, err: errMsg, suc: sucMsg };
            }
        }

        case GameServiceActionTypes.GET_GAME_LIST_REQUEST: {
            var data: PylonResponseModel = action.payload.data;
            var slotConfigurationList: SlotGameConfigurationListModel = {} as SlotGameConfigurationListModel;

            if (data && !data.err) {
                slotConfigurationList = data.res;
            }

            return { ...state, gameList: slotConfigurationList };
        }
        case GameServiceActionTypes.GET_JACKPOT_SERVICE_LIST_REQUEST: {
            var data: PylonResponseModel = action.payload.data;
            var jackpotServiceList: JackpotServiceListModel = {} as JackpotServiceListModel;

            if (data && !data.err) {
                jackpotServiceList = data.res;
            }

            return { ...state, jackpotServiceList: jackpotServiceList };
        }
        case GameServiceActionTypes.REFRESH_GAME_SERVICE: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, err: "", suc: "" };
            }
            else {
                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "Game Service Refreshed!";
                }

                return { ...state, loading: false, err: errMsg, suc: sucMsg };
            }
        }
        case GameServiceActionTypes.UPDATE_GAME_SERVICE_STATUS: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, err: "", suc: "" };
            }
            else {
                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "Game Service's Status Updated!";
                }

                return { ...state, loading: false, err: errMsg, suc: sucMsg, requireReload: true };
            }
        }
        case GameServiceActionTypes.UPDATE_ALL_GAME_SERVICE_STATUS: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, err: "", suc: "" };
            }
            else {
                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "All Game Services' Status Updated!";
                }

                return { ...state, loading: false, err: errMsg, suc: sucMsg, requireReload: sucMsg != "" };
            }
        }
        default:
            return state;
    }
}