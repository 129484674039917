import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, FormText, Row, Spinner } from "reactstrap";
import { BackOfficeAccess } from "../../../dto/enums/AccessMatrix/BackOfficeAccess";
import { TableStatus } from "../../../dto/enums/TableStatus";
import { CVT_TO_FLOAT_STRING } from "../../../libraries/FloatHelper";
import { HASFLAG, TRIGGER_BLUR } from "../../../libraries/Functions";
import { GET_PROFILE } from "../../../libraries/Global";
import { AddApprovalTier, ApprovalTierGetCreditRate, EditApprovalTier, SelectApprovalTier } from "../../../states/PAS/JackpotApprovalTier/ApprovalTierActions";
import { IStore } from "../../../states/store/IStore";
import { TextboxType } from "../../InputBox/IInputBox";
import InputBox from "../../InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../InputBox/ISelectBox";
import SelectBox from "../../InputBox/SelectBox";
import { ModalType } from "../../ModalBox/IModalBox";
import ModalBox from "../../ModalBox/ModalBox";

interface IApprovalTierForm {
    isEdit: boolean;
    loading: boolean;
    id?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit?: boolean;
}

const ApprovalTierForm = (props: IApprovalTierForm) => {
    const dispatch = useDispatch();
    const approvalTierState = useSelector((state: IStore) => state.approvalTierState);
    const { approvalTierData, creditRate, loading } = approvalTierState;

    // input & validation
    const [thresholdValue, setThresholdValue] = useState<string | undefined>(undefined);;
    const [status, setStatus] = useState(TableStatus.ACTIVE);
    const [valueValid, setValueValid] = useState(props.isEdit);
    const [statusValid, setStatusValid] = useState(true);

    // status dropdown
    const statusOption: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(!loading);
    }, [loading])

    // save button    
    const save = () => {
        if (valueValid && statusValid && thresholdValue) {
            if (props.isEdit) {
                if (props.id) {
                    dispatch(EditApprovalTier(props.id, parseFloat(thresholdValue.replace(/,/g, '')), status));
                }
            }
            else {
                dispatch(AddApprovalTier(parseFloat(thresholdValue.replace(/,/g, '')), status));
            }
        }
        else {
            TRIGGER_BLUR("value");
            TRIGGER_BLUR("status");
        }
        props.setSaveTrigger(false);
    }

    // on state changed
    useEffect(() => {
        dispatch(ApprovalTierGetCreditRate());
        if (props.isEdit && props.id) {
            dispatch(SelectApprovalTier(props.id));
        }
    }, []);

    useEffect(() => {
        if (approvalTierData && approvalTierData.jid) {
            setThresholdValue(CVT_TO_FLOAT_STRING(approvalTierData.tsh));
            setStatus(approvalTierData.stt);
        }
    }, [approvalTierData])

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    if (isLoaded) {
        return (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <InputBox id="value" name="value" label="Threshold" placeholder="Jackpot Approval Threshold (Credit)"
                                type={TextboxType.CURRENCY} maxlength={14}
                                value={thresholdValue} inputState={setThresholdValue} validState={setValueValid}
                                isRequired={true}
                                min={1}
                                isDisabled={!props.canEdit}
                            />
                            <FormText>{thresholdValue ? "= " + creditRate.crc + CVT_TO_FLOAT_STRING(parseFloat(thresholdValue.replace(/,/g, '')) * creditRate.crt / 100) : ""}</FormText>
                        </FormGroup>
                    </Col>
                    <Col>
                        <SelectBox id="status" name="status" label="Status"
                            options={statusOption} value={approvalTierData && approvalTierData.stt ? approvalTierData.stt : TableStatus.ACTIVE}
                            inputState={setStatus} validState={setStatusValid}
                            isRequired={true}
                            isDisabled={true}
                        />
                    </Col>
                </Row>
            </Form>
        );
    } else {
        return (<div className='text-center p-4'><Spinner key='1' /></div>);
    }
}

const ApprovalTierModal = (props: { isEdit: boolean, id?: number, isOpen: boolean, setOpenState: any }) => {
    const approvalTierState = useSelector((state: IStore) => state.approvalTierState);
    const { err, suc, loading } = approvalTierState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setSave(false);
    }, [loading]);

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <ModalBox
            title={props.isEdit ? "Edit Approval Tier" : "Add New Approval Tier"}
            isOpen={props.isOpen}
            child={<ApprovalTierForm isEdit={props.isEdit} canEdit={canEdit} id={props.id} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />}
            type={ModalType.Window}
            openState={props.setOpenState}
            error={err}
            success={suc}
            footer={canEdit ? <Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</ Button> : <></>}
        />
    );
}

export default ApprovalTierModal;