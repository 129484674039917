import { UserProfileModel } from "../dto/models/UserAccessModels";

export const TOKEN_CONST: string = "Token";
export const UID_CONST: string = "UID";
export const TABLE_MAX_ROW: number = 8;
export const TABLE_MAX_PAGE: number = 8;
export const GROUP_TABLE_MAX_PAGE: number = 1;
export const GROUP_TABLE_MAX_ROW: number = 1;

var _userProfile: UserProfileModel = {} as UserProfileModel;
export function SET_PROFILE(user: UserProfileModel) {
    _userProfile = user;
}
export function GET_PROFILE(): UserProfileModel {
    return _userProfile;
}