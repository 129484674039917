import { JackpotCategory } from "../../../dto/enums/Jackpot/JackpotCategory";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../../dto/models/GeneralModels";
import { AccumulatorModel, AccumulatorPoolModel } from "../../../dto/models/JackpotModels";
import { GetRequest, PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { AccumulatorActionTypes } from "../../PAS/JackpotAccumulator/AccumulatorActionTypes";
import { IPayload, LoadingPayload } from "../../store/IStore";

type AddAccumulatorRequest = { type: AccumulatorActionTypes.ADD_ACCUMULATOR_REQUEST, payload: IPayload };
type EditAccumulatorRequest = { type: AccumulatorActionTypes.EDIT_ACCUMULATOR_REQUEST, payload: IPayload };
type GetAccumulatorRequest = { type: AccumulatorActionTypes.GET_ACCUMULATOR_REQUEST, payload: IPayload };
type GetAccumulatorListRequest = { type: AccumulatorActionTypes.GET_ACCUMULATOR_LIST_REQUEST, payload: IPayload };
type SelectAccumulatorRequest = { type: AccumulatorActionTypes.SELECT_ACCUMULATOR_REQUEST, payload: IPayload };
type SearchAccumulatorRequest = { type: AccumulatorActionTypes.SEARCH_ACCUMULATOR_REQUEST, payload: IPayload };

export type ActionTypes =
    AddAccumulatorRequest | EditAccumulatorRequest | GetAccumulatorRequest | SelectAccumulatorRequest | SearchAccumulatorRequest | GetAccumulatorListRequest;

export const AddAccumulator = (
    name: string, description: string, category: JackpotCategory, accumulatorPoolList: AccumulatorPoolModel[]
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: AccumulatorModel = {
            aid: 0,
            jan: name,
            cat: category,
            des: description,
            thc: 0,
            apl: accumulatorPoolList
        };
        PostRequest("BackOffice/AddAccumulator", request, AccumulatorActionTypes.ADD_ACCUMULATOR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccumulatorActionTypes.ADD_ACCUMULATOR_REQUEST, dispatch);
    }
}

export const EditAccumulator = (
    id: number, name: string, description: string, category: JackpotCategory, accumulatorPoolList: AccumulatorPoolModel[]
) => async (dispatch: any) => {

    try {
        let request: AccumulatorModel = {
            aid: id,
            jan: name,
            cat: category,
            des: description,
            thc: 0,
            apl: accumulatorPoolList
        };
        PostRequest("BackOffice/EditAccumulator", request, AccumulatorActionTypes.EDIT_ACCUMULATOR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccumulatorActionTypes.EDIT_ACCUMULATOR_REQUEST, dispatch);
    }

}

export const GetAccumulator = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetAccumulator", request, AccumulatorActionTypes.GET_ACCUMULATOR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccumulatorActionTypes.GET_ACCUMULATOR_REQUEST, dispatch);
    }
}

export const GetAccumulatorList = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetAccumulatorList", AccumulatorActionTypes.GET_ACCUMULATOR_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccumulatorActionTypes.GET_ACCUMULATOR_LIST_REQUEST, dispatch);
    }
}


export const SearchAccumulator = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchAccumulator", request, AccumulatorActionTypes.SEARCH_ACCUMULATOR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccumulatorActionTypes.SEARCH_ACCUMULATOR_REQUEST, dispatch);
    }
}


export const SelectAccumulator = (cid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: cid
        };
        PostRequest("BackOffice/SelectAccumulator", request, AccumulatorActionTypes.SELECT_ACCUMULATOR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, AccumulatorActionTypes.SELECT_ACCUMULATOR_REQUEST, dispatch);
    }
}