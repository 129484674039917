import { SlotGambleGame, SlotGameRTP, SlotGameType } from "../../dto/enums/SlotGames";
import { TableStatus } from "../../dto/enums/TableStatus";
import { SlotGameConfigurationModel } from "../../dto/models/ConfigurationModels";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload } from "../store/IStore";
import { GameActionTypes } from "./GameActionTypes";

type GetGameRequest = { type: GameActionTypes.GET_GAME_REQUEST, payload: IPayload };
type SelectGameRequest = { type: GameActionTypes.SELECT_GAME_REQUEST, payload: IPayload };
type EditGameRequest = { type: GameActionTypes.EDIT_GAME_REQUEST, payload: IPayload };
type AddGameRequest = { type: GameActionTypes.ADD_GAME_REQUEST, payload: IPayload };
type SearchGameRequest = { type: GameActionTypes.SEARCH_GAME_REQUEST, payload: IPayload };
type GetCreditRateRequest = { type: GameActionTypes.GET_CREDIT_RATE, payload: IPayload };

export type ActionTypes = GetGameRequest | SelectGameRequest | EditGameRequest | AddGameRequest | SearchGameRequest | GetCreditRateRequest;

export const GetGameRequest = (from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: take
        };

        PostRequest("BackOffice/GetGameMaintenance", request, GameActionTypes.GET_GAME_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameActionTypes.GET_GAME_REQUEST, dispatch);
    }
}

export const SearchGameRequest = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };

        PostRequest("BackOffice/SearchGameMaintenance", request, GameActionTypes.SEARCH_GAME_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameActionTypes.SEARCH_GAME_REQUEST, dispatch);
    }
}

export const SelectGameRequest = (id: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: id
        };

        PostRequest("BackOffice/SelectGameMaintenance", request, GameActionTypes.SELECT_GAME_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GameActionTypes.SELECT_GAME_REQUEST, dispatch);
    }
}

export const AddGameRequest = (name: string, description: string, type: SlotGameType,
    rtp: SlotGameRTP, gamble: SlotGambleGame,
    denomination: number, gameIcon: number[], gameUrl: string, autoSpin: boolean, showRtp: boolean) => async (dispatch: any) => {
        try {
            let request: SlotGameConfigurationModel = {
                gid: 0,
                gmn: name,
                des: description,
                typ: type,
                rtp: rtp,
                gbg: gamble,
                dnm: denomination,
                max: 0,
                gic: gameIcon,
                gur: gameUrl,
                aas: autoSpin,
                isr: showRtp,
                stt: TableStatus.ACTIVE
            };

            PostRequest("BackOffice/AddGameMaintenance", request, GameActionTypes.ADD_GAME_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, GameActionTypes.ADD_GAME_REQUEST, dispatch);
        }
    }

export const EditGameRequest = (id: number, name: string, description: string, type: SlotGameType,
    rtp: SlotGameRTP, gamble: SlotGambleGame,
    denomination: number, gameIcon: number[], gameUrl: string, autoSpin: boolean, showRtp: boolean) => async (dispatch: any) => {
        try {
            let request: SlotGameConfigurationModel = {
                gid: id,
                gmn: name,
                des: description,
                typ: type,
                rtp: rtp,
                gbg: gamble,
                dnm: denomination,
                max: 0,
                gic: gameIcon,
                gur: gameUrl,
                aas: autoSpin,
                isr: showRtp,
                stt: TableStatus.ACTIVE
            };

            PostRequest("BackOffice/EditGameMaintenance", request, GameActionTypes.EDIT_GAME_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, GameActionTypes.EDIT_GAME_REQUEST, dispatch);
        }
    }

export const GameGetCreditRateRequest = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetCreditRate", GameActionTypes.GET_CREDIT_RATE, dispatch);
    }
    catch (error) {
        RequestException(error, GameActionTypes.GET_CREDIT_RATE, dispatch);
    }
}