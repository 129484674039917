import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import DropdownSelectBox from "../../components/InputBox/DropdownSelectBox";
import { IDropdownSelectOption, LoadDropdownOptionFromEnum } from "../../components/InputBox/IDropdownSelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { CmsType } from "../../dto/enums/CmsType";
import { GamingAreaType } from "../../dto/enums/GamingAreaType";
import { SlotGameType } from "../../dto/enums/SlotGames";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { HosehLiao } from "../../libraries/GameConstant";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetGameServiceListRequest } from "../../states/GameService/GameServiceActions";
import { GenerateGambleWinLoseCSVRequest, GenerateGambleWinLosePDFRequest, GetGambleWinLoseReportRequest } from "../../states/Reporting/GambleWinLoseReport/GambleWinLoseReportingActions";
import { IStore } from "../../states/store/IStore";

const GambleWinLoseReporting = () => {
    const dispatch = useDispatch();
    const gambleWinLoseReportState = useSelector((state: IStore) => state.gambleWinLoseReportState);
    const { gambleWinLoseReportListData, loading, err, suc } = gambleWinLoseReportState;
    const gambleServiceState = useSelector((state: IStore) => state.gameServiceState);
    const { gameServiceList } = gambleServiceState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    const [selectedGambleService, selectGambleServices] = useState<number[]>([]);
    const [selectedGambleServiceValid, setGambleServiceValid] = useState(false);

    const [gambleServiceOption, setGambleServiceOptions] = useState([] as IDropdownSelectOption[]);
    const [cmsOption, setCmsOption] = useState(LoadDropdownOptionFromEnum(CmsType));

    // Search Criteria
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const take = (page: number) => {
        // TODO: dispatch change page, provide current page
        if (valid()) {
            setPage(page);
            dispatch(GetGambleWinLoseReportRequest(selectedGambleService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    useEffect(() => {
        dispatch(GetGameServiceListRequest())
    }, []);

    useEffect(() => {
        if (gameServiceList && gameServiceList.sgl) {
            const services: IDropdownSelectOption[] = gameServiceList.sgl.map((item) => {
                return {
                    label: item.snm,
                    value: item.sid.toString()
                }
            });

            setGambleServiceOptions(services);
        }
    }, [gameServiceList]);


    useEffect(() => {
        setPagination(winLosePagination);
    }, [gambleWinLoseReportListData.ttc])

    const winLosePagination: ITablePagination = {
        id: "gambleWinLoseReportSummaryPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? gambleWinLoseReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const emptyTableSummary: IDataTable = {
        title: ["No.", "Gamble Type", "Gaming Area", "Total Game", "Total Bet", "Total Payout", "Total Win Lose", "Total Player Count", "Average Bet", "RTP"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTableSummary)
    const [pagination, setPagination] = useState(winLosePagination)

    useEffect(() => {
        var table: IDataTable = emptyTableSummary;
        var index: number = currentPage * TABLE_MAX_ROW;
        if (gambleWinLoseReportListData && gambleWinLoseReportListData.rls != undefined && hasSearched) {
            var totalGamble: number = 0;
            var totalBet: number = 0;
            var totalPayout: number = 0;
            var totalWinLose: number = 0;
            var totalPlayer: number = 0;
            var rowBoldIndex: number[] = [];

            var tempData: any[][] = [];
            gambleWinLoseReportListData.rls.map((x, xIndex) => {
                tempData.push([x.gnm]);
                rowBoldIndex.push(xIndex);

                x.gil.map((y, yIndex) => {
                    totalGamble += y.ttg;
                    totalBet += y.ttb;
                    totalPayout += y.ttp;
                    totalWinLose += y.ttw;
                    totalPlayer += y.ttpc;

                    tempData.push([
                        1 + yIndex++,
                        GetGambleType(y.gtyp, y.typ),
                        GamingAreaType[y.gat].toString().replace("_", " "),
                        y.ttg,
                        CVT_TO_FLOAT_STRING(y.ttb),
                        CVT_TO_FLOAT_STRING(y.ttp),
                        CVT_TO_FLOAT_STRING(y.ttw),
                        y.ttpc,
                        CVT_TO_FLOAT_STRING(y.avg),
                        CVT_TO_FLOAT_STRING(y.rtp) + "%",
                    ]);
                })
                 
            });

            table.data = tempData;
            table.rowBoldIndex = rowBoldIndex;
            table.concludeColumn = [
                { columnIndex: 3, content: totalGamble },
                { columnIndex: 4, content: CVT_TO_FLOAT_STRING(totalBet) },
                { columnIndex: 5, content: CVT_TO_FLOAT_STRING(totalPayout) },
                { columnIndex: 6, content: CVT_TO_FLOAT_STRING(totalWinLose) },
                { columnIndex: 7, content: totalPlayer }]
        } else
            table.data = [];
        setReportTable(table);
    }, [gambleWinLoseReportListData])

    const valid = (): boolean => {
        return selectedGambleServiceValid && fromDate !== "" && toDate !== "" && toDateValid && fromDateValid;
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);

            setReportTable(emptyTableSummary)
            dispatch(GetGambleWinLoseReportRequest(selectedGambleService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));

        } else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
            TRIGGER_BLUR("gambleServicesSelectDropdown");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateGambleWinLosePDFRequest(selectedGambleService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateGambleWinLoseCSVRequest(selectedGambleService, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const GetGambleType = (gameType: SlotGameType, gambleType: number): string => {
        switch (gameType) {
            case SlotGameType.HOSEH_LIAO:
                return HosehLiao.Gamble[gambleType].toString().replace("_", " ");
            default:
                return HosehLiao.Gamble[gambleType].toString().replace("_", " ");
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker hideTime isRequired label="Trading Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col>
                            <DropdownSelectBox allowSelectAll={true} id="gambleServicesSelectDropdown" name="gambleServicesSelectDropdown" options={gambleServiceOption}
                                isRequired={true} inputState={selectGambleServices} validState={setGambleServiceValid} placeholder="----Select Game Service----" label="Service" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="GambleWinLose"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}

export default GambleWinLoseReporting;
