import { CmsType } from "../../../dto/enums/CmsType";
import { WinLoseReportRequestModel } from "../../../dto/models/Reporting/WinLoseRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { GambleWinLoseReportingActionTypes } from "./GambleWinLoseReportingActionTypes";

type GetGambleWinLoseReportRequest = { type: GambleWinLoseReportingActionTypes.GET_GAMBLE_WIN_LOSE_REPORT_REQUEST, payload: IPayload };
type GenerateGambleWinLosePDFRequest = { type: GambleWinLoseReportingActionTypes.GENERATE_GAMBLE_WIN_LOSE_PDF_REQUEST, payload: IPayload };
type GenerateGambleWinLoseCSVRequest = { type: GambleWinLoseReportingActionTypes.GENERATE_GAMBLE_WIN_LOSE_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetGambleWinLoseReportRequest | GenerateGambleWinLosePDFRequest | GenerateGambleWinLoseCSVRequest;

export const GetGambleWinLoseReportRequest = (
    gameServiceID: number[], tradingFromDate: Date, tradingformTime: string, tradingToDate: Date, tradingToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: WinLoseReportRequestModel = {
            gid: gameServiceID,
            tfd: tradingFromDate,
            tft: tradingformTime,
            ttd: tradingToDate,
            ttt: tradingToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetGambleWinLoseReport", request, GambleWinLoseReportingActionTypes.GET_GAMBLE_WIN_LOSE_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GambleWinLoseReportingActionTypes.GET_GAMBLE_WIN_LOSE_REPORT_REQUEST, dispatch);
    }
}

export const GenerateGambleWinLosePDFRequest = (
    gameServiceID: number[], tradingFromDate: Date, tradingformTime: string, tradingToDate: Date, tradingToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: WinLoseReportRequestModel = {
            gid: gameServiceID,
            tfd: tradingFromDate,
            tft: tradingformTime,
            ttd: tradingToDate,
            ttt: tradingToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateGambleWinLosePDF", request, GambleWinLoseReportingActionTypes.GENERATE_GAMBLE_WIN_LOSE_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GambleWinLoseReportingActionTypes.GENERATE_GAMBLE_WIN_LOSE_PDF_REQUEST, dispatch);
    }
}

export const GenerateGambleWinLoseCSVRequest = (
    gameServiceID: number[], tradingFromDate: Date, tradingformTime: string, tradingToDate: Date, tradingToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: WinLoseReportRequestModel = {
            gid: gameServiceID,
            tfd: tradingFromDate,
            tft: tradingformTime,
            ttd: tradingToDate,
            ttt: tradingToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateGambleWinLoseCSV", request, GambleWinLoseReportingActionTypes.GENERATE_GAMBLE_WIN_LOSE_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, GambleWinLoseReportingActionTypes.GENERATE_GAMBLE_WIN_LOSE_CSV_REQUEST, dispatch);
    }
}

