export enum CashierAccess {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///     Transfer in
    /// </summary>
    TRANSFER_IN = 0x000001,
    /// <summary>
    ///     Transfer out
    /// </summary>
    TRANSFER_OUT = 0x000002,
    /// <summary>
    ///     Cash out
    /// </summary>
    CASH_OUT = 0x000004,
    ///// <summary>
    /////     Cash in
    ///// </summary>
    //CASH_IN = 0x000008,
    /// <summary>
    ///     OLR manual submission
    /// </summary>
    MANUAL_SUBMIT_OLR = 0x000010,
     /// <summary>
    ///     Manual Credit Transfer player
    /// </summary>
    MANUAL_CREDIT_TRANSFER = 0x000020,
    /// <summary>
    ///     Lock/Unlock player
    /// </summary>
    PLAYER_LOCK = 0x000040,
    /// <summary>
    ///     Big win approval
    /// </summary>
    BIG_WIN_APPROVAL = 0x000080
}