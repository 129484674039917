import { PlayerPerformanceCSVReportModel, PlayerPerformancePDFReportModel, PlayerPerformanceReportListModel } from "../../../dto/models/Reporting/PlayerPerformanceReportModel";

export interface IPlayerPerformanceReportState {
    loading: boolean;
    err: string;
    suc: string;
    playerPerformanceListData: PlayerPerformanceReportListModel;
    pdfData: PlayerPerformancePDFReportModel;
    csvData: PlayerPerformanceCSVReportModel;
}

export const InitPlayerPerformanceReportState: IPlayerPerformanceReportState = {
    loading: false,
    err: "",
    suc: "",
    playerPerformanceListData: {} as PlayerPerformanceReportListModel,
    pdfData: {} as PlayerPerformancePDFReportModel,
    csvData: {} as PlayerPerformanceCSVReportModel,
}