import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { IToggleButton } from './IToggleButton';


const ToggleButton = (props: IToggleButton) => {
    return (
        <FormGroup switch>
            <Label for={props.id} check>{props.label}</Label>
            <Input
                type="switch"
                role="switch"
                id={props.id}
                name={props.name}
                className={props.className}
                disabled={props.isDisabled}
                defaultChecked={props.value}
                onChange={() => props.inputState(!props.value)}
            />
        </FormGroup>
    );
}

export default ToggleButton;