import { TableStatus } from "../../dto/enums/TableStatus";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { RequestChangePasswordModel, RequestLoginModel, RoleModel, UserProfileModel } from "../../dto/models/UserAccessModels";
import { SET_PROFILE, TOKEN_CONST, UID_CONST } from "../../libraries/Global";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { UserActionTypes } from "./UserActionTypes";

type LoginRequest = { type: UserActionTypes.LOGIN_REQUEST, payload: IPayload };
type LogoutRequest = { type: UserActionTypes.LOGOUT_REQUEST, payload: IPayload };
type GetProfileRequest = { type: UserActionTypes.GET_PROFILE_REQUEST, payload: IPayload };
type EditProfileRequest = { type: UserActionTypes.EDIT_PROFILE_REQUEST, payload: IPayload };
type ChangePasswordRequest = { type: UserActionTypes.CHANGE_PASSWORD_REQUEST, payload: IPayload };

type SelectUserRequest = { type: UserActionTypes.SELECT_USER_REQUEST, payload: IPayload };
type EditUserRequest = { type: UserActionTypes.EDIT_USER_REQUEST, payload: IPayload };
type AddUserRequest = { type: UserActionTypes.ADD_USER_REQUEST, payload: IPayload };
type SearchUserRequest = { type: UserActionTypes.SEARCH_USER_REQUEST, payload: IPayload };
type GetUserListRequest = { type: UserActionTypes.GET_USER_LIST_REQUEST, payload: IPayload };
type ResetPassowrdRequest = { type: UserActionTypes.RESET_PASSWORD_REQUEST, payload: IPayload };
type SessionTimeout = { type: UserActionTypes.SESSION_TIMEOUT, payload: IPayload };

export type ActionTypes = LoginRequest | LogoutRequest |
    GetProfileRequest | EditProfileRequest | ChangePasswordRequest | SessionTimeout |
    SelectUserRequest | AddUserRequest | SearchUserRequest | GetUserListRequest | EditUserRequest | ResetPassowrdRequest;

export const UserLogin = (username: string, password: string) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: RequestLoginModel = {
            usr: username,
            pas: password
        };
        PostRequest("BackOffice/Login", request, UserActionTypes.LOGIN_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.LOGIN_REQUEST, dispatch);
    }
}

export const ChangePassword = (currentPassword: string, newPassword: string, confirmPassword: string) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: RequestChangePasswordModel = {
            ori: currentPassword,
            new: newPassword,
            con: confirmPassword
        };
        PostRequest("BackOffice/ChangePassword", request, UserActionTypes.CHANGE_PASSWORD_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.LOGIN_REQUEST, dispatch);
    }
}

export const EditProfile = (cardNumber: string, email: string) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: UserProfileModel = {
            uid: parseInt(localStorage.getItem(UID_CONST) as string),
            cno: cardNumber,
            eml: email,
            acc: {} as RoleModel,
            dob: new Date(),
            fnm: "",
            pas: "",
            ses: "",
            llg: new Date(),
            stt: TableStatus.ACTIVE,
            unm: "",
            isue: false,
            fcp: false,
            ped: new Date()
        };
        PostRequest("BackOffice/EditProfile", request, UserActionTypes.EDIT_PROFILE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.LOGIN_REQUEST, dispatch);
    }
}

export const UserLogout = () => async (dispatch: any) => {
    try {
        PostRequest("BackOffice/Logout", null, UserActionTypes.LOGOUT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.LOGOUT_REQUEST, dispatch);
    }
    localStorage.removeItem(TOKEN_CONST);
    localStorage.removeItem(UID_CONST);
    SET_PROFILE({} as UserProfileModel);
}

export const GetUserProfile = () => async (dispatch: any) => {
    try {
        PostRequest("BackOffice/GetProfile", null, UserActionTypes.GET_PROFILE_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.GET_PROFILE_REQUEST, dispatch);
    }
}

export const SessionTimeout = () => async (dispatch: any) => {
    try {
        PostRequest("", null, UserActionTypes.SESSION_TIMEOUT, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.SESSION_TIMEOUT, dispatch);
    }
}

/*--------------------------------- User Maintenance ----------------------------------------------*/

export const AddUser = (
    username: string, fullName: string, cardNumber: string,
    email: string, dob: Date, role: RoleModel, status: TableStatus,
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: UserProfileModel = {
            uid: 0,
            unm: username,
            fnm: fullName,
            cno: cardNumber,
            eml: email,
            dob: dob,
            acc: role,
            stt: status,
            llg: new Date,
            ses: "",
            pas: "",
            isue: false,
            fcp: false,
            ped: new Date()

        };
        PostRequest("BackOffice/AddUser", request, UserActionTypes.ADD_USER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.ADD_USER_REQUEST, dispatch);
    }

    }

export const EditUser = (
    id: number, fullName: string, cardNumber: string,
    email: string, dob: Date, role: RoleModel, status: TableStatus,
) => async (dispatch: any) => {

    try {
        let request: UserProfileModel = {
            uid: id,
            unm: "",
            fnm: fullName,
            cno: cardNumber,
            eml: email,
            dob: dob,
            acc: role,
            stt: status,
            llg: new Date,
            ses: "",
            pas: "",
            isue: false,
            fcp: false,
            ped: new Date()
        };
        PostRequest("BackOffice/EditUser", request, UserActionTypes.EDIT_USER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.EDIT_USER_REQUEST, dispatch);
    }
}

export const GetUser = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetUser", request, UserActionTypes.GET_USER_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.GET_USER_LIST_REQUEST, dispatch);
    }
}

export const SelectUser = (uid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: uid
        };
        PostRequest("BackOffice/SelectUser", request, UserActionTypes.SELECT_USER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.SELECT_USER_REQUEST, dispatch);
    }
}

export const SearchUser = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchUser", request, UserActionTypes.SEARCH_USER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.SEARCH_USER_REQUEST, dispatch);
    }
}

export const ResetPassword = (uid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: uid
        };
        PostRequest("BackOffice/ResetPassword", request, UserActionTypes.RESET_PASSWORD_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, UserActionTypes.RESET_PASSWORD_REQUEST, dispatch);
    }
}