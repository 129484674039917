import { CreditTransactionReportCSVModel, CreditTransactionReportListModel, CreditTransactionReportPDFModel } from "../../../dto/models/Reporting/CreditTransactionReportModel";

export interface ICreditTransactionReportState {
    loading: boolean;
    err: string;
    suc: string;
    creditTransactionReportListData: CreditTransactionReportListModel;
    pdfData: CreditTransactionReportPDFModel;
    csvData: CreditTransactionReportCSVModel;
}

export const InitCreditTransactionReportState: ICreditTransactionReportState = {
    loading: false,
    err: "",
    suc: "",
    creditTransactionReportListData: {} as CreditTransactionReportListModel,
    pdfData: {} as CreditTransactionReportPDFModel,
    csvData: {} as CreditTransactionReportCSVModel,
}