import { AccumulatorListModel, AccumulatorModel } from "../../../dto/models/JackpotModels";
import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ActionTypes } from "./AccumulatorActions";
import { AccumulatorActionTypes } from "./AccumulatorActionTypes";
import { InitAccumulatorState, IAccumulatorState } from "./AccumulatorStates";

export const AccumulatorReducer = (state = InitAccumulatorState, action: ActionTypes): IAccumulatorState => {
    switch (action.type) {
        case AccumulatorActionTypes.SELECT_ACCUMULATOR_REQUEST:
        case AccumulatorActionTypes.ADD_ACCUMULATOR_REQUEST:
        case AccumulatorActionTypes.EDIT_ACCUMULATOR_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, accumulatorData: {} as AccumulatorModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var accumulatorModel: AccumulatorModel = {} as AccumulatorModel;

                if (data && !data.err) {
                    accumulatorModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == AccumulatorActionTypes.ADD_ACCUMULATOR_REQUEST) {
                        sucMsg = "Jackpot Accumulator Added Successfully.";
                    }
                    else if (action.type == AccumulatorActionTypes.EDIT_ACCUMULATOR_REQUEST) {
                        sucMsg = "Jackpot Accumulator Updated Successfully.";
                    }
                }
                //  Set accumulatorState for IStore--> accumulatorState
                return { ...state, loading: false, accumulatorData: accumulatorModel, err: errMsg, suc: sucMsg };
            }
        }
        case AccumulatorActionTypes.GET_ACCUMULATOR_REQUEST:
        case AccumulatorActionTypes.SEARCH_ACCUMULATOR_REQUEST:
        case AccumulatorActionTypes.GET_ACCUMULATOR_LIST_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, accumulatorListData: {} as AccumulatorListModel, accumulatorData: {} as AccumulatorModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var accumulatorListModel: AccumulatorListModel = {} as AccumulatorListModel;

                if (data && !data.err) {
                    accumulatorListModel = data.res;
                }
                //  Set accListState for IStore--> accumulatorListState
                return { ...state, loading: false, accumulatorListData: accumulatorListModel, err: action.payload.err ? action.payload.err : "" };
            }
        default:
            return state
    }
}
