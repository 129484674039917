import { PlayerLockRequestModel } from "../../../dto/models/Reporting/PlayerLockRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { PlayerLockReportingActionTypes } from "./PlayerLockReportActionTypes";

type GetPlayerLockReportRequest = { type: PlayerLockReportingActionTypes.GET_PLAYER_LOCK_REPORT_REQUEST, payload: IPayload };
type GeneratePlayerLockPDFRequest = { type: PlayerLockReportingActionTypes.GENERATE_PLAYER_LOCK_PDF_REQUEST, payload: IPayload };
type GeneratePlayerLockCSVRequest = { type: PlayerLockReportingActionTypes.GENERATE_PLAYER_LOCK_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetPlayerLockReportRequest | GeneratePlayerLockPDFRequest | GeneratePlayerLockCSVRequest;

export const GetPlayerLockReportRequest = (
    cms: number[], action: number, username: string, transactionFromDate: Date,  transactionformTime: string, transactionToDate: Date, transactionToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        let request: PlayerLockRequestModel = {
            cms: cms,
            pid: username,
            act: action == -1 ? undefined : action,
            tfd: transactionFromDate,
            tft: transactionformTime,
            ttd: transactionToDate,
            ttt: transactionToTime,
            frm: from,
            tak: takeCount
        };

        PostRequest("Reporting/GetPlayerLockReport", request, PlayerLockReportingActionTypes.GET_PLAYER_LOCK_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLockReportingActionTypes.GET_PLAYER_LOCK_REPORT_REQUEST, dispatch);
    }
}

export const GeneratePlayerLockPDFRequest = (
    cms: number[], action: number, username: string, transactionFromDate: Date, transactionformTime: string, transactionToDate: Date, transactionToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        let request: PlayerLockRequestModel = {
            cms: cms,
            pid: username,
            act: action == -1 ? undefined : action,
            tfd: transactionFromDate,
            tft: transactionformTime,
            ttd: transactionToDate,
            ttt: transactionToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GeneratePlayerLockPDF", request, PlayerLockReportingActionTypes.GENERATE_PLAYER_LOCK_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLockReportingActionTypes.GENERATE_PLAYER_LOCK_PDF_REQUEST, dispatch);
    }
}

export const GeneratePlayerLockCSVRequest = (
    cms: number[], action: number, username: string, transactionFromDate: Date, transactionformTime: string, transactionToDate: Date, transactionToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        let request: PlayerLockRequestModel = {
            cms: cms,
            pid: username,
            act: action == -1 ? undefined : action,
            tfd: transactionFromDate,
            tft: transactionformTime,
            ttd: transactionToDate,
            ttt: transactionToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GeneratePlayerLockCSV", request, PlayerLockReportingActionTypes.GENERATE_PLAYER_LOCK_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLockReportingActionTypes.GENERATE_PLAYER_LOCK_CSV_REQUEST, dispatch);
    }
}

