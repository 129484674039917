import { Currency } from "../../dto/enums/Currency";
import { SessionMode } from "../../dto/enums/SessionMode";
import { SystemConfiguration } from "../../dto/models/ConfigurationModels";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { SystemActionTypes } from "./SystemActionTypes";

type GetSystemRequest = { type: SystemActionTypes.GET_SYSTEM_REQUEST, payload: IPayload };
type EditSystemRequest = { type: SystemActionTypes.EDIT_SYSTEM_REQUEST, payload: IPayload };

export type ActionTypes = GetSystemRequest | EditSystemRequest;

export const GetSystem = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetSystem", SystemActionTypes.GET_SYSTEM_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, SystemActionTypes.GET_SYSTEM_REQUEST, dispatch);
    }
}

export const EditSystem = (currency: Currency, creditRate: number, serviceSyncInterval: number, lobbySyncInterval: number, jackpotSyncInterval: number,
    backOfficeSessionTimeout: number, playerPortalSessionMode: SessionMode, playerPortalSessionTimeout: number,
    playerPortalIdleTimeout: number, passwordExpiryMonth: number, passwordNotifyDay: number) => async (dispatch: any) => {
        try {
            // ask reducer handle response data
            let request: SystemConfiguration = {
                cur: currency,
                crt: creditRate,
                ssi: serviceSyncInterval,
                lsi: lobbySyncInterval,
                jsi: jackpotSyncInterval,
                bst: backOfficeSessionTimeout,
                psm: playerPortalSessionMode,
                pst: playerPortalSessionTimeout,
                pit: playerPortalIdleTimeout,
                pem: passwordExpiryMonth,
                pen: passwordNotifyDay
            };

            PostRequest("BackOffice/EditSystem", request, SystemActionTypes.EDIT_SYSTEM_REQUEST, dispatch);
        }
        catch (error) {
            RequestException(error, SystemActionTypes.EDIT_SYSTEM_REQUEST, dispatch);
        }
    }