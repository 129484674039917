import React, { useEffect, useState } from 'react';
import { Form, Row, Col, FormGroup, Button, Container, Spinner } from 'reactstrap';
import { TextboxType } from '../../InputBox/IInputBox';
import InputBox from '../../InputBox/InputBox';
import ModalBox from '../../ModalBox/ModalBox';
import { ModalType } from '../../ModalBox/IModalBox';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../states/store/IStore';
import { ISelectOption, LoadSelectOptionFromEnum } from '../../InputBox/ISelectBox';
import SelectBox from '../../InputBox/SelectBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { HASFLAG, TRIGGER_BLUR } from '../../../libraries/Functions';
import { TableStatus } from '../../../dto/enums/TableStatus';
import { RoleModel } from '../../../dto/models/UserAccessModels';
import { BackOfficeAccess } from '../../../dto/enums/AccessMatrix/BackOfficeAccess';
import { CashierAccess } from '../../../dto/enums/AccessMatrix/CashierAccess';
import { ReportingAccess } from '../../../dto/enums/AccessMatrix/ReportingAccess';
import { AddUser, EditUser, SelectUser } from '../../../states/User/UserActions';
import { GetAccessMatrixList } from '../../../states/AccessMatrix/AccessMatrixActions';
import { FORMAT_DATETIME_TO_VIEW } from '../../../libraries/DateHelper';
import { ApprovalTierModel } from '../../../dto/models/JackpotModels';
import { GET_PROFILE } from '../../../libraries/Global';

interface IUserMaintenanceForm {
    isEdit: boolean;
    loading: boolean;
    editID?: number;

    saveTrigger: boolean;
    setSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    canEdit: boolean;
}

const UserMaintenanceForm = (props: IUserMaintenanceForm) => {
    const [usernameInput, setUsernameInput] = useState("");
    const [usernameValid, setUsernameValid] = useState(props.isEdit);
    const [nameInput, setNameInput] = useState("");
    const [nameValid, setNameValid] = useState(props.isEdit);
    const [cardNumberInput, setCardNumberInput] = useState("");
    const [cardNumberValid, setCardNumberValid] = useState(props.isEdit);
    const [emailInput, setEmailInput] = useState("");
    const [emailValid, setEmailValid] = useState(props.isEdit);
    const [dobInput, setDOBInput] = useState<undefined | Date>(undefined);
    const [dobValid, setDOBValid] = useState(props.isEdit);

    const [selectedStatus, setStatus] = useState(TableStatus.ACTIVE);
    const statusOptions: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);
    const [selectedRole, setRole] = useState(0);
    const [roleOption, setRoleOption] = useState([] as ISelectOption[]);
    const [roleValid, setRoleValid] = useState(props.isEdit);

    const [isLoaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const amStates = useSelector((state: IStore) => state.accessMatrixState);
    const userStates = useSelector((state: IStore) => state.userState);
    const { userData } = userStates;
    const { roleList } = amStates;
    const userLoading = userStates.loading;
    const roleLoading = amStates.loading;

    useEffect(() => {
        setLoaded(!userLoading && !roleLoading);
    }, [userLoading, roleLoading])

    useEffect(() => {
        if (roleList.rll) {
            const role: ISelectOption[] = roleList.rll.map((item) => {
                return {
                    display: item.rnm,
                    value: item.rid.toString()
                }
            });

            setRoleOption(role);
            if (!props.isEdit) {
                if (role.length != 0) {
                    setRole(roleList.rll[0].rid)
                    setRoleValid(true)
                }
            }
        }
    }, [roleList]);

    useEffect(() => {
        dispatch(GetAccessMatrixList());
        if (props.isEdit && props.editID) {
            dispatch(SelectUser(props.editID));
        } else
            ClearFields();

    }, [props.isEdit]);

    useEffect(() => {
        if (props.saveTrigger) {
            save();
        }
    }, [props.saveTrigger]);

    useEffect(() => {
        if (userData && props.isEdit && userData.fnm) {
            setNameInput(userData.fnm);
            setUsernameInput(userData.unm);
            setCardNumberInput(userData.cno);
            setEmailInput(userData.eml);
            setRole(userData.acc.rid);
            if (userData.dob)
                setDOBInput(FORMAT_DATETIME_TO_VIEW(userData.dob, "yyyy-MM-dd") as any as Date);
        }
    }, [userData]);

    const ClearFields = () => {
        setNameInput("");
        setUsernameInput("");
        setCardNumberInput("");
        setEmailInput("");
        setDOBInput(undefined);
        setRole(0);
    }


    const Valid = (): boolean => {
        return nameValid && cardNumberValid && emailValid && roleValid && usernameValid;
    }

    const save = () => {
        if (Valid() && nameInput && cardNumberInput && emailInput) {
            var role: RoleModel = {
                rid: selectedRole, apt: {} as ApprovalTierModel, bor: BackOfficeAccess.NONE, bow: BackOfficeAccess.NONE,
                caa: CashierAccess.NONE, des: "", rnm: "", rpa: ReportingAccess.NONE, stt: TableStatus.ACTIVE
            }
            if (!props.isEdit) {
                dispatch(
                    AddUser(
                        usernameInput,
                        nameInput,
                        cardNumberInput,
                        emailInput,
                        dobInput as unknown as Date,
                        role,
                        selectedStatus,

                    ));
            } else {
                if (props.editID) {
                    dispatch(
                        EditUser(
                            props.editID,
                            nameInput,
                            cardNumberInput,
                            emailInput,
                            dobInput as unknown as Date,
                            role,
                            selectedStatus,
                        ));
                }
            }
        } else {
            TRIGGER_BLUR("name");
            TRIGGER_BLUR("username");
            TRIGGER_BLUR("cardNo");
            TRIGGER_BLUR("email");
            TRIGGER_BLUR("role");
            TRIGGER_BLUR("dob");
        }
        props.setSaveTrigger(false);
    }

    return (
        <Container>
            {isLoaded ?
                <Form>
                    <Row>
                        {
                            !props.isEdit ?
                                <Col md={4}>
                                    <FormGroup>
                                        <InputBox
                                            id="username"
                                            name="username"
                                            label="Username"
                                            type={TextboxType.TEXT}
                                            inputState={setUsernameInput}
                                            placeholder={"Username"}
                                            validState={setUsernameValid}
                                            isRequired={true}
                                            value={usernameInput}
                                            isDisabled={props.isEdit}
                                        />
                                    </FormGroup>
                                </Col>
                                :
                                ""
                        }
                        <Col md={!props.isEdit ? 4 : 6}>
                            <FormGroup>
                                <SelectBox id="status" name="status" label="Status"
                                    options={statusOptions} value={userData && props.isEdit ? userData.stt : TableStatus.ACTIVE}
                                    isDisabled={!props.isEdit || !props.canEdit}
                                    inputState={setStatus}
                                    isRequired={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={!props.isEdit ? 4 : 6}>
                            <FormGroup>
                                <SelectBox id="role" name="role" label="Role"
                                    options={roleOption} value={selectedRole}
                                    inputState={setRole} validState={setRoleValid}
                                    isRequired={true}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="name"
                                    name="name"
                                    label="Full Name"
                                    type={TextboxType.TEXT}
                                    inputState={setNameInput}
                                    validState={setNameValid}
                                    placeholder={"Full Name"}
                                    isRequired={true}
                                    value={nameInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type={TextboxType.EMAIL}
                                    inputState={setEmailInput}
                                    placeholder={"Email"}
                                    validState={setEmailValid}
                                    isRequired={true}
                                    value={emailInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="dob"
                                name="dob"
                                label="Birth Date"
                                type={TextboxType.DATE}
                                inputState={setDOBInput}
                                placeholder={"Date of Birth"}
                                validState={setDOBValid}
                                value={dobInput}
                                isDisabled={!props.canEdit}
                            />
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputBox
                                    id="cardNo"
                                    name="cardNo"
                                    label="Card Number"
                                    type={TextboxType.TEXT}
                                    inputState={setCardNumberInput}
                                    placeholder={"Card Number"}
                                    validState={setCardNumberValid}
                                    isRequired={true}
                                    value={cardNumberInput}
                                    isDisabled={!props.canEdit}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {props.isEdit ?
                        <Row>
                            <Col>

                            </Col>
                        </Row> : undefined
                    }
                </Form>
                :
                <div className='text-center p-4'><Spinner key='1' /></div>
            }
        </Container>
    )
}

const UserMaintenanceModal = (props: { isEdit: boolean, editID?: number, isOpen: boolean, setOpenState: any }) => {
    const userState = useSelector((state: IStore) => state.userState);
    const amState = useSelector((state: IStore) => state.accessMatrixState);
    const { userDataErr, userDataSuc, loading } = userState;
    const [save, setSave] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.USER_MAINTENANCE))
        }
    }, []);

    useEffect(() => {
        if (amState.err !== "") {
            setErrMsg(amState.err)
            return;
        }

        if (userDataErr !== "") {
            setErrMsg(userDataErr)
            return;
        }

        setErrMsg("");
    }, [amState.err, userDataErr])

    useEffect(() => {
        setSave(false);
    }, [loading]);

    return (
        <div>
            <ModalBox
                title={props.isEdit ? "Edit User" : "Add User"}
                isOpen={props.isOpen}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                error={errMsg}
                success={userDataSuc}
                child={
                    <UserMaintenanceForm isEdit={props.isEdit} canEdit={canEdit} editID={props.editID} loading={loading} saveTrigger={save} setSaveTrigger={setSave} />
                }
                type={ModalType.Window}
                openState={props.setOpenState}
                footer={canEdit ? < Button color="info" onClick={() => setSave(true)} outline><FontAwesomeIcon icon={faFloppyDisk} /> Save</Button> : <></>}
            />
        </div>
    );
}

export default UserMaintenanceModal;

