import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import DropdownSelectBox from "../../components/InputBox/DropdownSelectBox";
import { IDropdownSelectOption } from "../../components/InputBox/IDropdownSelectBox";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import StandardReportContainer from "../../components/StandardReportContainer"
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { JackpotPoolType } from "../../dto/enums/Jackpot/JackpotPoolType";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetPoolList } from "../../states/PAS/JackpotPool/PoolActions";
import { GenerateJackpotSummaryStrikeCSVRequest, GenerateJackpotSummaryStrikePDFRequest, GetJackpotStrikeSummaryReportRequest } from "../../states/Reporting/JackpotStrikeReport/JackpotStrikeReportingActions";
import { IStore } from "../../states/store/IStore";

const JackpotStrikeSummaryReporting = () => {
    const dispatch = useDispatch();
    const poolStrikeReportState = useSelector((state: IStore) => state.poolStrikeReportState);
    const { strikeReportSummaryListData, loading, err, suc } = poolStrikeReportState;
    const poolStates = useSelector((state: IStore) => state.poolState);
    const { poolListData } = poolStates;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    const [selectedPool, selectPool] = useState<number[]>([]);
    const [selectedPoolValid, setPoolValid] = useState(false);
    const [gameIDInput, setGameIDInput] = useState<number | undefined>(undefined);
    const [gameIDValid, setGameIDValid] = useState(false);

    const [poolOptions, setPoolOptions] = useState([] as IDropdownSelectOption[]);

    // Search Criteria
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [toDateValid, setToDateValid] = useState(false);
    const [fromDateValid, setFromDateValid] = useState(false);

    const take = (page: number) => {
        // TODO: dispatch change page, provide current page
        if (valid()) {
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            setPage(page);
            dispatch(GetJackpotStrikeSummaryReportRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    useEffect(() => {
        dispatch(GetPoolList());
    }, []);

    useEffect(() => {
        if (poolListData && poolListData.pll) {
            const pools: IDropdownSelectOption[] = poolListData.pll.map((item) => {
                return {
                    label: item.pon,
                    value: item.jid.toString()
                }
            });

            setPoolOptions(pools);
        }
    }, [poolListData]);

    useEffect(() => {
        setPagination(summaryPagination);
    }, [strikeReportSummaryListData.ttc])

    const summaryPagination: ITablePagination = {
        id: "strikeReportSummaryPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? strikeReportSummaryListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const emptyTableSummary: IDataTable = {
        title: ["No.", "Pool Name", "Pool Type", "Number of Strike", "Total Strike Amount"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTableSummary)
    const [pagination, setPagination] = useState(summaryPagination)

    useEffect(() => {
        var table: IDataTable = emptyTableSummary;
        var index: number = currentPage * TABLE_MAX_ROW;
        if (strikeReportSummaryListData && strikeReportSummaryListData.rml != undefined && hasSearched) {
            var totalStrikeNumber: number = 0;
            var totalStrikeAmount: number = 0;
            table.data = strikeReportSummaryListData.rml.map(x => {
                totalStrikeNumber += x.strc;
                totalStrikeAmount += x.tsa;
                return [
                    1 + index++,
                    x.pnm,
                    JackpotPoolType[x.typ] ? JackpotPoolType[x.typ].toString().replace("_", " ") : "",
                    x.strc,
                    CVT_TO_FLOAT_STRING(x.tsa)
                ]
            });

            table.concludeColumn = [{ columnIndex: 3, content: totalStrikeNumber }, { columnIndex: 4, content: CVT_TO_FLOAT_STRING(totalStrikeAmount) }]
        } else
            table.data = [];
        setReportTable(table);
    }, [strikeReportSummaryListData])

    const valid = (): boolean => {
        return selectedPoolValid && fromDate !== "" && toDate !== "" && toDateValid && fromDateValid;
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            setHasSearched(true);
            setPage(0);

            setReportTable(emptyTableSummary)
            dispatch(GetJackpotStrikeSummaryReportRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));

        } else {
            TRIGGER_BLUR("fromDate");
            TRIGGER_BLUR("toDate");
            TRIGGER_BLUR("poolSelectDropdown");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            dispatch(GenerateJackpotSummaryStrikePDFRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            var gameID: number = gameIDInput == undefined ? 0 : gameIDInput;
            dispatch(GenerateJackpotSummaryStrikeCSVRequest(selectedPool, gameID, new Date(fromDate.split("T")[0]), fromDate.split("T")[1], new Date(toDate.split("T")[0]), toDate.split("T")[1], 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker isRequired label="Transaction Date" fromDateinputState={setFromDate} fromDatevalidState={setFromDateValid}
                            toDateinputState={setToDate} toDatevalidState={setToDateValid} fromDatePickerID="fromDate" toDatePickerID="toDate" />
                    </Row>
                    <Row>
                        <Col>
                            <DropdownSelectBox allowSelectAll={true} id="poolSelectDropdown" name="poolSelectDropdown" options={poolOptions}
                                isRequired={true} inputState={selectPool} validState={setPoolValid} placeholder="----Select Pool----" label="Pool" />
                        </Col>
                    </Row>
                    <Row>
                        <InputBox
                            id="gameID"
                            name="gameID"
                            label="Game ID"
                            type={TextboxType.NUMBER}
                            min={0}
                            inputState={setGameIDInput}
                            placeholder={"Game ID"}
                            validState={setGameIDValid}
                            value={gameIDInput}
                        />
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="JackpotStrike"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}

export default JackpotStrikeSummaryReporting;
