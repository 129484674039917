import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row, Tooltip } from 'reactstrap';
import { ISelectBox } from './ISelectBox';
import '../../styles/ToolTip.css'

const SelectBox = (props: ISelectBox) => {
    const [invalidMsg, setInvalidMsg] = useState("");
    const [options, SetOptions] = useState([] as JSX.Element[]);
    const [selectedValue, SelectValue] = useState();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
        var value: any = e.target.value ?? "";
        setInvalidMsg("");

        if (props.validState) {
            props.validState(true);
            setInvalidMsg("");

            if (props.isRequired) {
                if (value == "" && !props.allowEmptyData) {
                    props.validState(false);
                    setInvalidMsg("This field is required.");
                }
            }
        }

        SelectValue(value);
        if (props.inputState) {
            props.inputState(value);
        }
    }

    const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
        onChangeEvent(e);
    }

    useEffect(() => {
        SelectValue(props.value);
    }, [props.value]);

    useEffect(() => {
        const opt = props.options.map((item, index) => (
            props.allowEmptyData ?
                <option key={index + 1} value={item.value}>{item.display}</ option>
                :
                <option key={index} value={item.value}>{item.display}</ option>
        ));

        var emptyOpt: JSX.Element[] = [<option key={0} value={-1}>{props.placeHolderContent}</ option>];
        emptyOpt = emptyOpt.concat(opt);

        props.allowEmptyData ? SetOptions(emptyOpt) : SetOptions(opt);
    }, [props.options]);

    return (
        <FormGroup>
            <Row>
                <Col>
                    {props.label ? <Label for={props.id}>{props.label}</Label> : ""}
                    {props.tips && props.tipsID ? <Button id={props.tipsID} type="button" className={"btn-sm btn-circle btnTooltip"}><FontAwesomeIcon className={"fa-xs btnIcon"} icon={faInfo} /></Button> : ""}
                    {props.tips && props.tipsID ? <Tooltip
                        placement={'right'}
                        isOpen={tooltipOpen}
                        target={props.tipsID}
                        toggle={toggle}
                        style={{ maxWidth: '300px' }}
                    ><p className={"tipsContent"}>{props.tips}</p></Tooltip> : ""}
                </Col>
            </Row>
            <Input
                type="select"
                id={props.id}
                name={props.name}
                className={props.className}
                disabled={props.isDisabled}
                required={props.isRequired}
                invalid={invalidMsg != ""}
                onChange={onChangeEvent}
                onBlur={onBlurEvent}
                value={selectedValue}
            >
                {options}
            </Input>
        </FormGroup>
    );
}

export default SelectBox;