import { TableStatus } from "../../dto/enums/TableStatus";
import { DenominationModel } from "../../dto/models/DenominationModels";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload } from "../store/IStore";
import { DenominationActionTypes } from "./DenominationActionTypes";

type GetDenominationRequest = { type: DenominationActionTypes.GET_DENOMINATION_REQUEST, payload: IPayload };
type SelectDenominationRequest = { type: DenominationActionTypes.SELECT_DENOMINATION_REQUEST, payload: IPayload };
type EditDenominationRequest = { type: DenominationActionTypes.EDIT_DENOMINATION_REQUEST, payload: IPayload };
type AddDenominationRequest = { type: DenominationActionTypes.ADD_DENOMINATION_REQUEST, payload: IPayload };
type SearchDenominationRequest = { type: DenominationActionTypes.SEARCH_DENOMINATION_REQUEST, payload: IPayload };
type GetCreditRateRequest = { type: DenominationActionTypes.GET_CREDIT_RATE, payload: IPayload };

export type ActionTypes = GetDenominationRequest | SelectDenominationRequest | EditDenominationRequest | AddDenominationRequest | SearchDenominationRequest | GetCreditRateRequest;

export const GetDenominationRequest = (from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: take
        };

        PostRequest("BackOffice/GetDenomination", request, DenominationActionTypes.GET_DENOMINATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, DenominationActionTypes.GET_DENOMINATION_REQUEST, dispatch);
    }
}

export const SearchDenominationRequest = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };

        PostRequest("BackOffice/SearchDenomination", request, DenominationActionTypes.SEARCH_DENOMINATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, DenominationActionTypes.SEARCH_DENOMINATION_REQUEST, dispatch);
    }
}

export const SelectDenominationRequest = (id: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: id
        };

        PostRequest("BackOffice/SelectDenomination", request, DenominationActionTypes.SELECT_DENOMINATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, DenominationActionTypes.SELECT_DENOMINATION_REQUEST, dispatch);
    }
}

export const AddDenominationRequest = (value: number) => async (dispatch: any) => {
    try {
        let request: DenominationModel = {
            did: 0,
            dva: value,
            stt: TableStatus.ACTIVE
        };

        PostRequest("BackOffice/AddDenomination", request, DenominationActionTypes.ADD_DENOMINATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, DenominationActionTypes.ADD_DENOMINATION_REQUEST, dispatch);
    }
}

export const EditDenomination = (id: number, value: number, status: TableStatus) => async (dispatch: any) => {
    try {
        let request: DenominationModel = {
            did: id,
            dva: value,
            stt: status
        };

        PostRequest("BackOffice/EditDenomination", request, DenominationActionTypes.EDIT_DENOMINATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, DenominationActionTypes.EDIT_DENOMINATION_REQUEST, dispatch);
    }
}

export const DenominationGetCreditRate = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetCreditRate", DenominationActionTypes.GET_CREDIT_RATE, dispatch);
    }
    catch (error) {
        RequestException(error, DenominationActionTypes.GET_CREDIT_RATE, dispatch);
    }
}