import { GameCreditCurrency } from "../../../dto/models/ConfigurationModels";
import { ApprovalTierListModel, ApprovalTierModel } from "../../../dto/models/JackpotModels";

export interface IApprovalTierState {
    loading: boolean;
    err: string;
    suc: string;
    approvalTierListData: ApprovalTierListModel;
    approvalTierData: ApprovalTierModel;
    creditRate: GameCreditCurrency;
}


export const InitApprovalTierState: IApprovalTierState = {
    loading: false,
    err: "",
    suc: "",
    approvalTierListData: {} as ApprovalTierListModel,
    approvalTierData: {} as ApprovalTierModel,
    creditRate: {} as GameCreditCurrency
}
