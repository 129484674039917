import { GameCreditCurrency, SlotGameConfigurationListModel, SlotGameConfigurationModel } from "../../dto/models/ConfigurationModels";

export interface IGameState {
    loading: boolean;
    err: string;
    suc: string;
    gameList: SlotGameConfigurationListModel;
    gameData: SlotGameConfigurationModel;
    creditRate: GameCreditCurrency;
}

export const InitialGameState: IGameState = {
    loading: false,
    err: "",
    suc: "",
    gameList: {} as SlotGameConfigurationListModel,
    gameData: {} as SlotGameConfigurationModel,
    creditRate: {} as GameCreditCurrency
};