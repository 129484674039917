export interface IDataTable {
    title: string[];
    subHeader?: any[]
    data?: any[][];
    concludeColumn?: ConcludeColumn[]
    columnWidth?: string[]
    isloading: boolean;
    isStriped: boolean;
    isHover: boolean;
    rowBoldIndex?: number[];
    tableStyle: TableStyle;
    responsive?: boolean;
}

export interface ConcludeColumn {
    columnIndex: number;
    content: any;
}

export enum TableStyle {
    ALL_BORDER = 0,
    ROW_BORDER = 1,
    NO_BORDER = 2
}