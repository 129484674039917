import { JackpotAdjustmentRequestModel } from "../../../dto/models/Reporting/JackpotAdjustmentRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { JackpotAdjustmentReportingActionTypes } from "./JackpotAdjustmentReportingActionTypes";
type GetJackpotAdjustmentReportRequest = { type: JackpotAdjustmentReportingActionTypes.GET_JACKPOT_ADJUSTMENT_REPORT_REQUEST, payload: IPayload };
type GenerateJackpotAdjustmentPDFRequest = { type: JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_PDF_REQUEST, payload: IPayload };
type GenerateJackpotAdjustmentCSVRequest = { type: JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetJackpotAdjustmentReportRequest | GenerateJackpotAdjustmentPDFRequest | GenerateJackpotAdjustmentCSVRequest;

export const GetJackpotAdjustmentReportRequest = (
    poolID: number[], transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotAdjustmentRequestModel = {
            pid: poolID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetAdjustmentReport", request, JackpotAdjustmentReportingActionTypes.GET_JACKPOT_ADJUSTMENT_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotAdjustmentReportingActionTypes.GET_JACKPOT_ADJUSTMENT_REPORT_REQUEST, dispatch);
    }
    }

export const GenerateJackpotAdjustmentPDFRequest = (
    poolID: number[], transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotAdjustmentRequestModel = {
            pid: poolID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotAdjustmentPDF", request, JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_PDF_REQUEST, dispatch);
    }
    }

export const GenerateJackpotAdjustmentCSVRequest = (
    poolID: number[], transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: JackpotAdjustmentRequestModel = {
            pid: poolID,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateJackpotAdjustmentCSV", request, JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, JackpotAdjustmentReportingActionTypes.GENERATE_JACKPOT_ADJUSTMENT_CSV_REQUEST, dispatch);
    }
}

