import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Button } from 'reactstrap';
import { ModalType } from './components/ModalBox/IModalBox';
import ModalBox from './components/ModalBox/ModalBox';
import Login from "./containers/Common/Login";
import Sidebar from './containers/Shared/Sidebar';
import Topbar from './containers/Shared/Topbar';
import { TOKEN_CONST } from './libraries/Global';
import { AccessControlRoutesData } from './navigation/AccessControlRoutes';
import { AccountingRoutesData } from './navigation/AccoutingRoutes';
import { CashierRoutesData } from './navigation/CashierRoutes';
import { CommonRoutesData } from './navigation/CommonRoutes';
import { GameRoutesData } from './navigation/GameRoutes';
import { PASRoutesData } from './navigation/PASRoutes';
import { ReportingRoutesData } from './navigation/ReportingRoutes';
import { ServiceAuthenticationRoutesData } from './navigation/ServiceAuthenticationRoutes';
import { SystemRoutesData } from './navigation/SystemRoutes';
import { IStore } from "./states/store/IStore";
import { GetUserProfile, UserLogout } from './states/User/UserActions';
import './styles/App.css';

function App() {
    const dispatch = useDispatch();
    const userState = useSelector((state: IStore) => state.userState);
    const { isLogin, isSessionTimeout } = userState;
    const [open, setOpen] = useState(true);

    // Session Timeout Warning
    const [warningDialogVisible, openWarningDialog] = useState(false);

    const onStorageChange = (e: StorageEvent) => {
        if (e.key === TOKEN_CONST) {
            if (isLogin && !localStorage.getItem(TOKEN_CONST)) {
                dispatch(UserLogout());
            }
            else {
                dispatch(GetUserProfile());
            }
        }
    }

    const loginRoute = (<Route path="/*" element={<Login />} />);

    const commonRoutes = CommonRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const cashierRoutes = CashierRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const gameRoutes = GameRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const systemRoutes = SystemRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const accountingRoutes = AccountingRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const accessControlRoutes = AccessControlRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const PASRoutes = PASRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const ReportingRoutes = ReportingRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));
    const serviceAuthenticationRoutes = ServiceAuthenticationRoutesData.map((item, index) => (
        <Route key={index} path={item.to} element={item.element} />
    ));

    useEffect(() => {
        window.addEventListener('storage', onStorageChange);

        if (localStorage.getItem(TOKEN_CONST))
            dispatch(GetUserProfile());
    }, [])

    useEffect(() => {

        if (isSessionTimeout && isLogin)
            openWarningDialog(true);

    }, [isSessionTimeout])

    const dialogBoxButton = () => {
        return (
            <div>
                <Button
                    color="secondary"
                    onClick={() => {
                        dispatch(UserLogout());
                        openWarningDialog(false);
                    }}
                    outline> OK 
                </Button>
            </div>
        );
    }

    return (
        <div className="App">
            {isLogin ? (<Sidebar openSideBar={open} />) : ""}
            {isLogin ? (<Topbar setOpen={setOpen} />) : ""}
            <main id="Main" style={isLogin ? (open ? { maxWidth: "75%" } : { maxWidth: "95%" }) : { maxWidth: "100%" }}>
                {
                    isLogin ?
                        <Routes>
                            {commonRoutes}
                            {accessControlRoutes}
                            {cashierRoutes}
                            {accountingRoutes}
                            {gameRoutes}
                            {PASRoutes}
                            {ReportingRoutes}
                            {systemRoutes}
                            {serviceAuthenticationRoutes}
                        </Routes>
                        :
                        <Routes>
                            {loginRoute}
                        </Routes>
                }
            </main>
            <ModalBox
                title={"Warning"}
                isOpen={warningDialogVisible}
                isFade={true}
                isCentered={true}
                isScrollable={false}
                child={"Session Timeout. Please login again to continue."}
                type={ModalType.Dialog}
                openState={openWarningDialog}
                footer={dialogBoxButton()}
                error={""}
                success={""}
                hasCancelBtn={true}
            />
        </div>
    );
}
export default App;