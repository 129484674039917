import { GameCreditCurrency } from "../../../dto/models/ConfigurationModels";
import { PASPoolAdjustmentModel, PASPoolModel } from "../../../dto/models/JackpotModels";

export interface IPoolAdjustmentState {
    loading: boolean;
    err: string;
    suc: string;
    poolListData: PASPoolModel[];
    poolData: PASPoolModel;
    adjustmentData: PASPoolAdjustmentModel;
    creditRate: GameCreditCurrency;
}

export const InitPoolAdjustmentState: IPoolAdjustmentState = {
    loading: false,
    err: "",
    suc: "",
    poolListData: {} as PASPoolModel[],
    poolData: {} as PASPoolModel,
    adjustmentData: {} as PASPoolAdjustmentModel,
    creditRate: {} as GameCreditCurrency
}
