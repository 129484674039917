import { PlayerLockCSVReportModel, PlayerLockPDFReportModel, PlayerLockReportListModel } from "../../../dto/models/Reporting/PlayerLockReportModel";

export interface IPlayerLockReportState {
    loading: boolean;
    err: string;
    suc: string;
    playerLockReportListData: PlayerLockReportListModel;
    pdfData: PlayerLockPDFReportModel;
    csvData: PlayerLockCSVReportModel;
}

export const InitPlayerLockReportState: IPlayerLockReportState = {
    loading: false,
    err: "",
    suc: "",
    playerLockReportListData: {} as PlayerLockReportListModel,
    pdfData: {} as PlayerLockPDFReportModel,
    csvData: {} as PlayerLockCSVReportModel,
}
