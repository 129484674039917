import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { CmsConfigurationModel, CmsConfigurationListModel } from "../../dto/models/ConfigurationModels";
import { ActionTypes } from "./CmsActions";
import { CmsActionTypes } from "./CmsActionTypes";
import { InitCmsState, ICmsState } from "./CmsStates";
import { GamingAreaListModel } from "../../dto/models/GamingAreaModels";

export const cmsReducer = (state = InitCmsState, action: ActionTypes): ICmsState => {
    switch (action.type) {
        case CmsActionTypes.SELECT_CMS_REQUEST:
        case CmsActionTypes.ADD_CMS_REQUEST:
        case CmsActionTypes.EDIT_CMS_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, cmsData: {} as CmsConfigurationModel, cmsListData: {} as CmsConfigurationListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var cmsConfigModel: CmsConfigurationModel = {} as CmsConfigurationModel;

                if (data && !data.err) {
                    cmsConfigModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == CmsActionTypes.ADD_CMS_REQUEST) {
                        sucMsg = "Cms Added Successfully.";
                    }
                    else if (action.type == CmsActionTypes.EDIT_CMS_REQUEST) {
                        sucMsg = "Cms Updated Successfully.";
                    }
                }
                //  Set cmsState for IStore--> cmsState
                return { ...state, loading: false, cmsData: cmsConfigModel, err: errMsg, suc: sucMsg};
            }
        }
        case CmsActionTypes.GET_CMS_REQUEST:
        case CmsActionTypes.SEARCH_CMS_REQUEST:
        case CmsActionTypes.GET_CMS_LIST_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, cmsData: {} as CmsConfigurationModel, cmsListData: {} as CmsConfigurationListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var cmsConfigListModel: CmsConfigurationListModel = {} as CmsConfigurationListModel;

                if (data && !data.err) {
                    cmsConfigListModel = data.res;
                }
                //  Set cmsListState for IStore--> cmsListState
                return { ...state, loading: false, cmsListData: cmsConfigListModel, err: action.payload.err ? action.payload.err : "" };
            }
        }
        case CmsActionTypes.GET_NONVIP_GAMING_AREA_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, vipGamingArea: {} as GamingAreaListModel, nonVipGamingArea: {} as GamingAreaListModel,
                };
            } else {
                var data: PylonResponseModel = action.payload.data;

                if (data) {
                    var gamingAreaList: GamingAreaListModel = {} as GamingAreaListModel;

                    if (!data.err) {
                        gamingAreaList = data.res;
                    }

                    return { ...state, nonVipGamingArea: gamingAreaList, loading: false, err: action.payload.err ? action.payload.err : "" };
                }
            }
            
        }
        case CmsActionTypes.GET_VIP_GAMING_AREA_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, vipGamingArea: {} as GamingAreaListModel, nonVipGamingArea: {} as GamingAreaListModel
                };
            } else {
                var data: PylonResponseModel = action.payload.data;

                if (data) {
                    var gamingAreaList: GamingAreaListModel = {} as GamingAreaListModel;

                    if (!data.err) {
                        gamingAreaList = data.res;
                    }

                    return { ...state, vipGamingArea: gamingAreaList, loading: false, err: action.payload.err ? action.payload.err : "" };
                }
            }
        }
        default:
            return state
    }
}
