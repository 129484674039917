export interface IInputBox {
    id: string;
    name: string;
    label?: string;
    tips?: string | any | undefined;
    tipsID?: string;
    placeholder?: string;
    maxlength?: number;
    min?: string | number;
    max?: number;
    integer?: boolean;
    className?: string;
    type: TextboxType;
    value?: any;
    isDisabled?: boolean;
    isRequired?: boolean;
    invalidMessage?: string;

    inputState?: React.Dispatch<React.SetStateAction<any>>
    validState?: React.Dispatch<React.SetStateAction<boolean>>

    onKeyPress?: (e: any) => void;
    HasCancelledButton?: boolean;
}

export enum TextboxType {
    TEXT = 'text',
    PASSWORD = 'password',
    NUMBER = 'number',
    EMAIL = 'email',
    URL = 'url',
    IP_ADDRESS = 'ip',
    MAC_ADDRESS = 'mac',
    TIME = 'time',
    DATE = 'date',
    CURRENCY = 'currency',
    DATE_TIME = "datetime-local"
}