export enum UserSubModule {
    /// <summary>
    ///     None
    /// </summary>
    NONE = 0,
    /// <summary>
    ///     User Maintenance
    /// </summary>
    USER_MAINTENANCE = 1,
    /// <summary>
    ///     Reset Password
    /// </summary>
    RESET_PASSWORD = 2,
}