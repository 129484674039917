import { ManualCreditTransferListModel, CreditTransferDetailsModel } from "../../dto/models/ManualCreditTransferModel";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./ManualCreditTransferActions";
import { ManualCreditTransferActionTypes } from "./ManualCreditTransferActionTypes";
import { IManualCreditTransferStates, InitManualCreditTransferState } from "./ManualCreditTransferStates";

export const manualCreditTransferReducers = (state = InitManualCreditTransferState, action: ActionTypes): IManualCreditTransferStates => {
    switch (action.type) {
        case ManualCreditTransferActionTypes.SELECT_MANUAL_CREDIT_TRANSFER_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, creditTransferDetails: {} as CreditTransferDetailsModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var creditTransferDetails: CreditTransferDetailsModel = {} as CreditTransferDetailsModel;

                if (data && !data.err) {
                    creditTransferDetails = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }

                return { ...state, loading: false, creditTransferDetails: creditTransferDetails, err: errMsg, suc: sucMsg };
            }
        }
        case ManualCreditTransferActionTypes.RESOLVE_MANUAL_CREDIT_TRANSFER_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                        sucMsg = "Resolved!";
                }
                return { ...state, loading: false,  err: errMsg, suc: sucMsg };
            }
        }
        case ManualCreditTransferActionTypes.GET_RESOLVED_MANUAL_CREDIT_TRANSFER_REQUEST:
        case ManualCreditTransferActionTypes.GET_UNRESOLVED_MANUAL_CREDIT_TRANSFER_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, manualCreditTransferListData: {} as ManualCreditTransferListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var manualCrediTransferList: ManualCreditTransferListModel = {} as ManualCreditTransferListModel;

                if (data && !data.err) {
                    manualCrediTransferList = data.res;
                }

                return { ...state, loading: false, manualCreditTransferListData: manualCrediTransferList, err: action.payload.err ? action.payload.err : "" };
            }
        }
        default:
            return state
    }
}
