import { NotificationModel } from "../../dto/models/NotificationModel";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./NotificationActions";
import { InitialNotificationState, INotificationState } from "./NotificationStates";
import { NotificationActionTypes } from "./NotificationTypes";

export const notificationReducer = (state = InitialNotificationState, action: ActionTypes): INotificationState => {
    switch (action.type) {
        case NotificationActionTypes.GET_NOTIFICATION_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, data: {} as NotificationModel, err: "", suc: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var notificationData: NotificationModel = {} as NotificationModel;

                if (data && !data.err) {
                    notificationData = data.res;
                }

                return { ...state, loading: false, data: notificationData, err: action.payload.err ? action.payload.err : "", suc: "" };
            }
        }
        case NotificationActionTypes.EDIT_NOTIFICATION_REQUEST: {
            var isloading = action.payload.loading;
            if (isloading) {
                return { ...state, loading: true, data: {} as NotificationModel, err: "" };
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var notificationData: NotificationModel = {} as NotificationModel;

                if (data && !data.err) {
                    notificationData = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    sucMsg = "Notification Updated Successfully.";
                }

                return { ...state, loading: false, data: notificationData, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}