export enum TableStatus {
    /// <summary>
    ///     Active
    /// </summary>
    ACTIVE = 0,
    /// <summary>
    ///     Deleted
    /// </summary>
    DELETED = 1,
    /// <summary>
    ///     Disabled
    /// </summary>
    DISABLED = 2
}