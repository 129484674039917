import { RequestItemModel, RequestListModel } from "../../dto/models/GeneralModels";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { ManualCreditTransferActionTypes } from "./ManualCreditTransferActionTypes";
import { ResolveManualCreditTransferRequestModel } from "../../dto/models/ManualCreditTransferModel";

type ResolveManualCreditTransferRequest = { type: ManualCreditTransferActionTypes.RESOLVE_MANUAL_CREDIT_TRANSFER_REQUEST, payload: IPayload };
type GetUnresolvedManualCreditTransferRequest = { type: ManualCreditTransferActionTypes.GET_UNRESOLVED_MANUAL_CREDIT_TRANSFER_REQUEST, payload: IPayload };
type GetResolvedManualCreditTransferRequest = { type: ManualCreditTransferActionTypes.GET_RESOLVED_MANUAL_CREDIT_TRANSFER_REQUEST, payload: IPayload };
type SelectManualCreditTransferRequest = { type: ManualCreditTransferActionTypes.SELECT_MANUAL_CREDIT_TRANSFER_REQUEST, payload: IPayload };

export type ActionTypes =
    ResolveManualCreditTransferRequest | GetUnresolvedManualCreditTransferRequest | GetResolvedManualCreditTransferRequest | SelectManualCreditTransferRequest;

export const ResolveManualCreditTransfer = (id: number, user: number, name:string, remarks: string) => async (dispatch: any) => {
    try {
        let request: ResolveManualCreditTransferRequestModel = {
            mctid: id,
            isrs: true,
            rsby: user,
            rsbyn: name,
            rmk: remarks
        };
        PostRequest("Cashier/ResolveManualCreditTransfer", request, ManualCreditTransferActionTypes.RESOLVE_MANUAL_CREDIT_TRANSFER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualCreditTransferActionTypes.RESOLVE_MANUAL_CREDIT_TRANSFER_REQUEST, dispatch);
    }

}

export const GetUnresolvedManualCreditTransfer = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("Cashier/GetUnresolvedManualCreditTransfer", request, ManualCreditTransferActionTypes.GET_UNRESOLVED_MANUAL_CREDIT_TRANSFER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualCreditTransferActionTypes.GET_UNRESOLVED_MANUAL_CREDIT_TRANSFER_REQUEST, dispatch);
    }
}

export const SelectManualCreditTransfer = (ctid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: ctid
        };
        PostRequest("Cashier/SelectManualCreditTransfer", request, ManualCreditTransferActionTypes.SELECT_MANUAL_CREDIT_TRANSFER_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualCreditTransferActionTypes.SELECT_MANUAL_CREDIT_TRANSFER_REQUEST, dispatch);
    }
}