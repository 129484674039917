import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { RoleModel, RoleModelList } from "../../dto/models/UserAccessModels";
import { ActionTypes } from "./AccessMatrixActions";
import { AccessMatrixActionTypes } from "./AccessMatrixActionTypes";
import { InitRoleState, IRoleState } from "./AccessMatrixStates";

export const accessMatrixReducer = (state = InitRoleState, action: ActionTypes): IRoleState => {
    switch (action.type) {
        case AccessMatrixActionTypes.SELECT_ACCESS_MATRIX_REQUEST:
        case AccessMatrixActionTypes.ADD_ACCESS_MATRIX_REQUEST:
        case AccessMatrixActionTypes.EDIT_ACCESS_MATRIX_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, roleData: {} as RoleModel, roleList: {} as RoleModelList, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var roleModel: RoleModel = {} as RoleModel;

                if (data && !data.err) {
                    roleModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == AccessMatrixActionTypes.ADD_ACCESS_MATRIX_REQUEST) {
                        sucMsg = "Role Added Successfully.";
                    }
                    else if (action.type == AccessMatrixActionTypes.EDIT_ACCESS_MATRIX_REQUEST) {
                        sucMsg = "Access Matrix Updated Successfully.";
                    }
                }
                //  Set cmsState for IStore--> AccessMatrixListState
                return { ...state, loading: false, roleData: roleModel, err: errMsg, suc: sucMsg };
            }
        }
        case AccessMatrixActionTypes.GET_ACCESS_MATRIX_REQUEST:
        case AccessMatrixActionTypes.SEARCH_ACCESS_MATRIX_REQUEST:
        case AccessMatrixActionTypes.READ_ACCESS_MATRIX_LIST_REQUEST:
            {
                var isLoading = action.payload.loading;
                if (isLoading) {
                    //  Set loading states
                    return {
                        ...state, loading: true, roleData: {} as RoleModel, roleList: {} as RoleModelList, err: "", suc: ""
                    };
                }
                else {
                    var data: PylonResponseModel = action.payload.data
                    var roleListModel: RoleModelList = {} as RoleModelList;

                    if (data && !data.err) {
                        roleListModel = data.res;
                    }
                    //  Set AccessMatrixListState for IStore--> AccessMatrixListState
                    return { ...state, loading: false, roleList: roleListModel, err: action.payload.err ? action.payload.err : "" };
                }
            }
        default:
            return state
    }
}
