import { JackpotBigWinType } from "../../../dto/enums/Jackpot/JackpotBigWinType";
import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { JackpotBigWinApprovalCSVReportModel, JackpotBigWinApprovalPDFReportModel, JackpotBigWinApprovalReportListModel } from "../../../dto/models/Reporting/JackpotBigWinApprovalReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";
import { ActionTypes } from "./JackpotBigWinApprovalReportingActions";
import { JackpotBigWinApprovalReportingActionTypes } from "./JackpotBigWinApprovalReportingActionTypes";
import { IJackpotBigWinApprovalReportState, InitJackpotBigWinApprovalReportState } from "./JackpotBigWinApprovalReportingStates";

export const JackpotBigWinApprovalReportingReducer = (state = InitJackpotBigWinApprovalReportState, action: ActionTypes): IJackpotBigWinApprovalReportState => {
    switch (action.type) {
        case JackpotBigWinApprovalReportingActionTypes.GET_JACKPOT_BIG_WIN_APPROVAL_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, jackpotBigWinApprovalListData: {} as JackpotBigWinApprovalReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: JackpotBigWinApprovalReportListModel = {} as JackpotBigWinApprovalReportListModel;

                if (data && !data.err) {
                    reportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err !== "") {
                    errMsg = action.payload.err as string;
                } else {
                    if (reportListModel.ttc && reportListModel.ttc != 0)
                        sucMsg = reportListModel.ttc.toString() + " records have been retrived."
                    else
                        errMsg = "No Records Found"
                }

                return { ...state, loading: false, jackpotBigWinApprovalListData: reportListModel, err: errMsg, suc: sucMsg };
            }
        }
        case JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotBigWinApprovalPDFReportModel, csvData: {} as JackpotBigWinApprovalCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: JackpotBigWinApprovalPDFReportModel = {} as JackpotBigWinApprovalPDFReportModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel.pdf.length != 0) {
                        var reportTitle = JackpotBigWinType[pdfModel.ptp] == "BIG_WIN" ? "BigWin" : "Jackpot"
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME(reportTitle + "ApprovalReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case JackpotBigWinApprovalReportingActionTypes.GENERATE_JACKPOT_BIG_WIN_APPROVAL_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotBigWinApprovalPDFReportModel, csvData: {} as JackpotBigWinApprovalCSVReportModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: JackpotBigWinApprovalCSVReportModel = {} as JackpotBigWinApprovalCSVReportModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err && errMsg != "") {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel.csv.length != 0) {
                        var reportTitle = JackpotBigWinType[csvModel.ptp] == "BIG_WIN" ? "BigWin" : "Jackpot"
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME(reportTitle + "ApprovalReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state;
    }
}