import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { ApplicationState, reducers } from ".";
import { accessMatrixReducer } from '../AccessMatrix/AccessMatrixReducers';
import { AccountingReducers } from '../Accounting/AccountingReducers';
import { bigWinPendingReducer } from '../BigWinPending/BigWinPendingReducers';
import { cmsReducer } from '../Cms/CmsReducers';
import { CreditTransactionReducer } from '../CreditTransaction/CreditTransactionReducer';
import { dashboardReducer } from '../Dashboard/DashboardReducers';
import { denominationReducer } from '../Denomination/DenominationReducers';
import { gameReducer } from '../Game/GameReducers';
import { gameServiceReducer } from '../GameService/GameServiceReducers';
import { gamingAreaReducer } from '../GamingArea/GamingAreaReducers';
import { manualCreditTransferReducers } from '../ManualCreditTransfer/ManualCreditTransferReducers';
import { manualSubmitOLRReducers } from '../ManualSubmitOLR/ManualSubmitOLRReducers';
import { notificationReducer } from '../Notification/NotificationReducers';
import { AccumulatorReducer } from '../PAS/JackpotAccumulator/AccumulatorReducers';
import { jackpotApprovalReducer } from '../PAS/JackpotApproval/JackpotApprovalReducers';
import { ApprovalTierReducer } from '../PAS/JackpotApprovalTier/ApprovalTierReducers';
import { PoolReducer } from '../PAS/JackpotPool/PoolReducers';
import { PoolAdjustmentReducer } from '../PAS/JackpotPoolAdjustment/PoolAdjustmentReducers';
import { JackpotServiceReducers } from '../PAS/JackpotService/JackpotServiceReducers';
import { TierReducer } from '../PAS/JackpotTier/TierReducers';
import { PlayerLockReducers } from '../PlayerLock/PlayerLockReducers';
import { pylonAuthenticationReducers } from '../PylonAuthentication/PylonAuthenticationReducers';
import { AuditReportingReducer } from '../Reporting/AuditReporting/AuditReportingReducers';
import { CreditTransactionReportingReducers } from '../Reporting/CreditTransactionReport/CreditTransactionReportingReducers';
import { GambleWinLoseReportingReducer } from '../Reporting/GambleWinLoseReport/GambleWinLoseReportingReducers';
import { GameWinLoseReportingReducer } from '../Reporting/GameWinLoseReport/GameWinLoseReportingReducers';
import { JackpotAdjustmentReportingReducer } from '../Reporting/JackpotAdjustmentReport/JackpotAdjustmentReportingReducers';
import { JackpotContributionReportingReducer } from '../Reporting/JackpotContributionReport/JackpotContributionReportingReducers';
import { JackpotStrikeReportingReducer } from '../Reporting/JackpotStrikeReport/JackpotStrikeReportingReducers';
import { ManualCreditTransactionReportingReducers } from '../Reporting/ManualCreditTransactionReport/ManualCreditTransactionReportingReducers';
import { ManualSubmitOLRReportingReducers } from '../Reporting/ManualSubmitOLRReport/ManualSubmitOLRReportingReducers';
import { OLRReportingReducers } from '../Reporting/OLRReport/OLRReportingReducers';
import { PlayerLeftOverBalanceReportingReducer } from '../Reporting/PlayerLeftOverBalanceReport/PlayerLeftOverBalanceReportReducers';
import { UserListingReportingReducer } from '../Reporting/UserListingReport/UserListingReportingReducers';
import { systemReducer } from '../System/SystemReducers';
import { tradingReducer } from '../TradingDate/TradingDateReducers';
import { userReducer } from '../User/UserReducers';
import { InitUserState } from '../User/UserStates';
import { PlayerPerformanceReportingReducer } from '../Reporting/PlayerPerformanceReport/PlayerPerformanceReportingReducers';
import { JackpotBigWinApprovalReportingReducer } from '../Reporting/JackpotBigWinApprovalReport/JackpotBigWinApprovalReportingReducers';
import { GameResultReportingReducer } from '../Reporting/GameResultReport/GameResultReportingReducers';
import { PlayerLockReportingReducer } from '../Reporting/PlayerLockReport/PlayerLockReportReducers';

export default function configureStore(history: History, initialState?: ApplicationState) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        userState: userReducer,
        systemState: systemReducer,
        tradingDateState: tradingReducer,
        gameState: gameReducer,
        gameServiceState: gameServiceReducer,
        denominationState: denominationReducer,
        cmsState: cmsReducer,
        accountingServiceState: AccountingReducers,
        accessMatrixState: accessMatrixReducer,
        accumulatorState: AccumulatorReducer,
        poolState: PoolReducer,
        poolAdjustmentState: PoolAdjustmentReducer,
        tierState: TierReducer,
        approvalTierState: ApprovalTierReducer,
        jackpotServiceState: JackpotServiceReducers,
        creditTransactionState: CreditTransactionReducer,
        manualSubmitOLRState: manualSubmitOLRReducers,
        manualCreditTransferState: manualCreditTransferReducers,
        playerLockState: PlayerLockReducers,
        pylonAuthenticationState: pylonAuthenticationReducers,
        gamingAreaState: gamingAreaReducer,
        notificationState: notificationReducer,
        bigWinPendingState: bigWinPendingReducer,
        jackpotApprovalState: jackpotApprovalReducer,
        dashboardState: dashboardReducer,

        // Report
        poolAdjustmentReportState: JackpotAdjustmentReportingReducer,
        poolContributionReportState: JackpotContributionReportingReducer,
        poolStrikeReportState: JackpotStrikeReportingReducer,
        userListingReportState: UserListingReportingReducer,
        creditTransactionReportState:CreditTransactionReportingReducers,
        manualCreditTransactionReportState: ManualCreditTransactionReportingReducers,
        manualSubmitOLRReportState: ManualSubmitOLRReportingReducers,
        olrReportState:OLRReportingReducers,
        playerLeftOverBalanceReportState: PlayerLeftOverBalanceReportingReducer,
        auditReportState: AuditReportingReducer,
        playerPerformanceReportState: PlayerPerformanceReportingReducer,
        gameWinLoseReportState: GameWinLoseReportingReducer,
        gambleWinLoseReportState: GambleWinLoseReportingReducer,
        jackpotBigWinApprovalReportState: JackpotBigWinApprovalReportingReducer,
        gameResultReportState: GameResultReportingReducer,
        playerLockReportState: PlayerLockReportingReducer,

        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    initialState = {
        userState: InitUserState,
    };

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}