import { ActionStatus } from "../../../dto/enums/ActionStatus";
import { OLRRequestModel } from "../../../dto/models/Reporting/OLRRequestModel";
import { PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload } from "../../store/IStore";
import { OLRReportingActionTypes } from "./OLRReportingActionTypes";
type GetOLRReportRequest = { type: OLRReportingActionTypes.GET_OLR_REPORT_REQUEST, payload: IPayload };
type GenerateOLRPDFRequest = { type: OLRReportingActionTypes.GENERATE_OLR_PDF_REQUEST, payload: IPayload };
type GenerateOLRCSVRequest = { type: OLRReportingActionTypes.GENERATE_OLR_CSV_REQUEST, payload: IPayload };


export type ActionTypes =
    GetOLRReportRequest | GenerateOLRPDFRequest | GenerateOLRCSVRequest;

export const GetOLRReportRequest = (
    customerID: string, status: ActionStatus, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string,
    tradFromDate: Date, tradToDate: Date, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: OLRRequestModel = {
            cms: 1,
            csi: customerID,
            sts: status,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            trfd: tradFromDate,
            trtd: tradToDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GetOLRReport", request, OLRReportingActionTypes.GET_OLR_REPORT_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, OLRReportingActionTypes.GET_OLR_REPORT_REQUEST, dispatch);
    }
}

export const GenerateOLRPDFRequest = (
    customerID: string, status: ActionStatus, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string,
    tradFromDate: Date, tradToDate: Date, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: OLRRequestModel = {
            cms: 1,
            csi: customerID,
            sts: status,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            trfd: tradFromDate,
            trtd: tradToDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateOLRPDF", request, OLRReportingActionTypes.GENERATE_OLR_PDF_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, OLRReportingActionTypes.GENERATE_OLR_PDF_REQUEST, dispatch);
    }
}

export const GenerateOLRCSVRequest = (
    customerID: string, status: ActionStatus, transFromDate: Date, transformTime: string, transToDate: Date, transToTime: string,
    tradFromDate: Date, tradToDate: Date, from: number, takeCount: number
) => async (dispatch: any) => {
    try {
        // ask reducer handle response data
        let request: OLRRequestModel = {
            cms: 1,
            csi: customerID,
            sts: status,
            tfd: transFromDate,
            tft: transformTime,
            ttd: transToDate,
            ttt: transToTime,
            trfd: tradFromDate,
            trtd: tradToDate,
            frm: from,
            tak: takeCount
        };
        PostRequest("Reporting/GenerateOLRCSV", request, OLRReportingActionTypes.GENERATE_OLR_CSV_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, OLRReportingActionTypes.GENERATE_OLR_CSV_REQUEST, dispatch);
    }
}