import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import AccumulatorModal from '../../components/_modals/PAS/AccumulatorModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { GetAccumulator, SearchAccumulator } from '../../states/PAS/JackpotAccumulator/AccumulatorActions';
import { IStore } from '../../states/store/IStore';

const JackpotAccumulator = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const accumulatorState = useSelector((state: IStore) => state.accumulatorState);
    const { loading, err, suc, accumulatorListData } = accumulatorState;

    const emptyTable: IDataTable = {
        title: ["No", "Name", "Description", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [accumulatorTable, setAccumulatorTable] = useState(emptyTable)
    const [isSearch, setSearch] = useState(false);


    useEffect(() => {
        if (!openModal) {
            dispatch(GetAccumulator(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchAccumulator(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetAccumulator(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddAccumulator = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditAccumulator = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (accumulatorListData.acl && accumulatorListData.acl.length > 0) {

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = accumulatorListData.acl.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.jan,
                    item.des,
                    <Button onClick={() => EditAccumulator(item.aid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>
                ];
            });
        }
        else {
            table.data = [];
        }

        setAccumulatorTable(table)
    }, [accumulatorListData])

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetAccumulator(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchAccumulator(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "accumulatorPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: accumulatorListData.ttc ?? 0
    }

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.JACKPOT_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <AccumulatorModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="JackpotAccumulator"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddAccumulator : undefined}
                tableProps={accumulatorTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default JackpotAccumulator;