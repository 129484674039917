import { GamingAreaType } from "../enums/GamingAreaType";
import { ResponseListModel } from "./GeneralModels";

export interface GamingAreaModel {
    /** Gaming area id */
    gid: number;
    /** Gaming area name */
    gan: string;
    /** Gaming area type */
    typ: GamingAreaType;
    /** Non-restricted transfer in limit */
    nti: number;
    /** Non-restricted transfer out limit */
    nto: number;
    /** Non-restricted wallet type */
    nwt: string;
    /** Restricted transfer in limit */
    rti: number;
    /** Restricted transfer out limit */
    rto: number;
    /** Restricted wallet type */
    rwt: string;
    /** Slot location */
    slt: string;
    /** Big win limit */
    bwl: number;
    /** Ip address range list */
    ipr: IPAddressRange[];
}

export interface IPAddressRange {
    /** Start */
    ist: string;
    /** End */
    ien: string;
}

export interface GamingAreaListModel extends ResponseListModel {
    /** Gaming area list */
    gal: GamingAreaModel[];
}

export interface RequestGamingAreaListByTypeModel {
    /** Gaming area type */
    gat: GamingAreaType;
}

export const InitGamingArea: GamingAreaModel = {
    gid: 0,
    gan: "",
    typ: GamingAreaType.NON_VIP,
    nti: 0,
    nto: 0,
    nwt: "",
    rti: 0,
    rto: 0,
    rwt: "",
    slt: "",
    bwl: 0,
    ipr: [] as IPAddressRange[]
}