import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TABLE_MAX_ROW } from "../../libraries/Global";
import { GetPoolList } from "../../states/PAS/JackpotPool/PoolActions";
import { GeneratePlayerLeftOverBalanceCSVRequest, GeneratePlayerLeftOverBalancePDFRequest, GetPlayerLeftOverBalanceReportRequest } from "../../states/Reporting/PlayerLeftOverBalanceReport/PlayerLeftOverBalanceReportActions";
import { IStore } from "../../states/store/IStore";


const PlayerLeftOverBalanceReporting = () => {
    const dispatch = useDispatch();
    const playerLeftOverBalanceReportState = useSelector((state: IStore) => state.playerLeftOverBalanceReportState);
    const { leftOverBalanceReportListData, loading, err, suc } = playerLeftOverBalanceReportState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    const [username, setUsername] = useState<string | undefined>(undefined);

    // Search Criteria
    const emptyTable: IDataTable = {
        title: ["No.", "Player Username", "EC Wallet", "EC Balance", "NN Wallet", "NN Balance", "VIP EC Wallet", "VIP EC Balance", "VIP NN Wallet", "VIP NN Balance", "Last Logout Date Time"],
        columnWidth: ["80px", "150px", "150px", "200px", "150px", "200px", "150px", "200px", "150px", "200px", "230px"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        dispatch(GetPoolList());
    }, []);

    useEffect(() => {
        var table: IDataTable = emptyTable
        var index: number = currentPage * TABLE_MAX_ROW;
        if (leftOverBalanceReportListData && leftOverBalanceReportListData.plobl != undefined && hasSearched) {
            var totalECW: number = 0;
            var totalNNW: number = 0;
            var totalVEW: number = 0;
            var totalVNW: number = 0;

            table.data = leftOverBalanceReportListData.plobl.map(x => {
                totalECW += x.ecw.bal;
                totalNNW += x.nnw.bal;
                totalVEW += x.vew.bal;
                totalVNW += x.vnw.bal;

                return [
                    1 + index++,
                    x.unm,
                    x.ecw.wnm,
                    CVT_TO_FLOAT_STRING(x.ecw.bal),
                    x.nnw.wnm,
                    CVT_TO_FLOAT_STRING(x.nnw.bal),
                    x.vew.wnm,
                    CVT_TO_FLOAT_STRING(x.vew.bal),
                    x.vnw.wnm,
                    CVT_TO_FLOAT_STRING(x.vnw.bal),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.ldt), "yyyy-MM-dd hh:mm:ssa")
                ]
            });

            table.concludeColumn = [
                { columnIndex: 3, content: CVT_TO_FLOAT_STRING(totalECW) },
                { columnIndex: 5, content: CVT_TO_FLOAT_STRING(totalNNW) },
                { columnIndex: 7, content: CVT_TO_FLOAT_STRING(totalVEW) },
                { columnIndex: 9, content: CVT_TO_FLOAT_STRING(totalVNW) },
            ];
        } else
            table.data = [];

        setReportTable(table);

    }, [leftOverBalanceReportListData]);


    const take = (page: number) => {
        setPage(page);
        dispatch(GetPlayerLeftOverBalanceReportRequest(page * TABLE_MAX_ROW, TABLE_MAX_ROW, username));
    }

    const pagination: ITablePagination = {
        id:"playerLeftOverBalancePagination",
        maxPage: 8,
        onChangePage: take,
        totalCount: hasSearched ? leftOverBalanceReportListData.ttc ?? 0 : 0,
    }

    const search: MouseEventHandler = () => {
        setHasSearched(true);
        setPage(0);
        dispatch(GetPlayerLeftOverBalanceReportRequest(0 * TABLE_MAX_ROW, TABLE_MAX_ROW, username));
    }

    const generatePDF = () => {
        setHasSearched(true);
        dispatch(GeneratePlayerLeftOverBalancePDFRequest(0 * TABLE_MAX_ROW, TABLE_MAX_ROW, username))
    }

    const generateCSV = () => {
        setHasSearched(true);
        dispatch(GeneratePlayerLeftOverBalanceCSVRequest(0 * TABLE_MAX_ROW, TABLE_MAX_ROW, username))
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <InputBox id="username" name="username" label="Username" inputState={setUsername} value={username} placeholder="Username" type={TextboxType.TEXT} />
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="PlayerLeftOverBalanceReporting"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}



export default PlayerLeftOverBalanceReporting;