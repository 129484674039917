import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption, LoadSelectOptionFromEnum } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import UserListingAccessMatrixModal from "../../components/_modals/Reporting/UserListingAccessMatrixModel";
import { TableStatus } from "../../dto/enums/TableStatus";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetAccessMatrix } from "../../states/AccessMatrix/AccessMatrixActions";
import { GenerateUserListingReportCSVRequest, GenerateUserListingReportPDFRequest, GetUserListingReportRequest } from "../../states/Reporting/UserListingReport/UserListingReportingActions";
import { IStore } from "../../states/store/IStore";

const UserListingReporting = () => {
    const accessMatrixWidth = "200px";
    const [openModal, setOpenModal] = useState(false);
    const [accessViewID, setAccessViewID] = useState(Number);

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);
    const dispatch = useDispatch();

    const userListingReportState = useSelector((state: IStore) => state.userListingReportState);
    const { userListingReportListData, loading, err, suc } = userListingReportState;

    const amStates = useSelector((state: IStore) => state.accessMatrixState);
    const { roleList } = amStates;

    // Search Criteria
    const [userNameID, setUserNameID] = useState("");
    const [daySinceLastLogin, setDaySinceLastLogin] = useState(0);
    const [selectedUserStatus, setSelectedUserStatus] = useState(-1);
    const [selectedAccessMatrix, setSelectedAccessMatrix] = useState(-1);

    const [daysValid, setDaysValid] = useState(true);
    const userStatus: ISelectOption[] = LoadSelectOptionFromEnum(TableStatus);
    const [accessMatrixOptionList, setAccessMatrixOptionList] = useState([] as ISelectOption[]);

    useEffect(() => {
        dispatch(GetAccessMatrix(0, -1));
    }, []);

    useEffect(() => {
        if (roleList && roleList.rll != undefined) {

            const accessMatrix: ISelectOption[] = roleList.rll.map((item) => {
                return {
                    display: item.rnm,
                    value: item.rid.toString()
                }
            });

            setAccessMatrixOptionList(accessMatrix);
        }
    }, [roleList]);

    const emptyTable: IDataTable = {
        title: ["No", "Name", "Status", "Access Matrix", "Last Login", "Day Since Last Login", "Created By"],
        columnWidth: ["30px", "150px", accessMatrixWidth, "200px", "200px", "250px"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        var table: IDataTable = emptyTable
        var index: number = currentPage * TABLE_MAX_ROW;
        if (userListingReportListData && userListingReportListData.rml != undefined && hasSearched) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = userListingReportListData.rml.map((x, index) => {
                return [
                    startIndex + index + 1,
                    x.unm,
                    TableStatus[x.ust],
                    <Button color="secondary" outline onClick={() => ViewAccessMatrix(x.rid)} style={{ width: accessMatrixWidth }}>{x.rnm}</Button>,
                    x.nll ? "N/A" : FORMAT_DATETIME_TO_VIEW(new Date(x.lld), "yyyy-MM-dd hh:mm:ssa"),
                    x.nll ? "N/A" : x.dsl,
                    x.cby]
            });

            /*setHasSearched(false);*/
        } else
            table.data = [];

        setReportTable(table);
    }, [userListingReportListData]);


    const valid = (): boolean => {
        return daysValid;
    }

    const ViewAccessMatrix = (id: number) => {
        setOpenModal(true);
        setAccessViewID(id);
    }

    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetUserListingReportRequest(userNameID, selectedAccessMatrix, selectedAccessMatrix == -1 || selectedAccessMatrix == 0 ? "N/A" : accessMatrixOptionList.find((accessMatrix) => accessMatrix.value === selectedAccessMatrix.toString())?.display ?? "N/A", selectedUserStatus, daySinceLastLogin, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const pagination: ITablePagination = {
        id:"userListingReportPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: hasSearched ? userListingReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setPage(0);
            setHasSearched(true);
            dispatch(GetUserListingReportRequest(userNameID, selectedAccessMatrix, selectedAccessMatrix == -1 || selectedAccessMatrix == 0 ? "N/A" : accessMatrixOptionList.find((accessMatrix) => accessMatrix.value === selectedAccessMatrix.toString())?.display ?? "N/A", selectedUserStatus, daySinceLastLogin, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const generatePDF = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateUserListingReportPDFRequest(userNameID, selectedAccessMatrix, selectedAccessMatrix == -1 || selectedAccessMatrix == 0 ? "N/A" : accessMatrixOptionList.find((accessMatrix) => accessMatrix.value === selectedAccessMatrix.toString())?.display ?? "N/A", selectedUserStatus, daySinceLastLogin, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const generateCSV = () => {
        if (valid()) {
            setHasSearched(true);
            dispatch(GenerateUserListingReportCSVRequest(userNameID, selectedAccessMatrix, selectedAccessMatrix == -1 || selectedAccessMatrix == 0 ? "N/A" : accessMatrixOptionList.find((accessMatrix) => accessMatrix.value === selectedAccessMatrix.toString())?.display ?? "N/A", selectedUserStatus, daySinceLastLogin, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <Col md={6}>
                            <InputBox
                                id="systemUser"
                                name="systemUser"
                                label="System User"
                                type={TextboxType.TEXT}
                                placeholder={"Name / ID"}
                                isRequired={true}
                                inputState={setUserNameID}
                                value={userNameID}
                                HasCancelledButton={true}
                            />
                        </Col>
                        <Col md={6}>
                            <SelectBox id="accessMatrixDropDown" name="accessMatrixDropDown" label="Access Matrix"
                                options={accessMatrixOptionList} value={selectedAccessMatrix}
                                inputState={setSelectedAccessMatrix}
                                isRequired={true} allowEmptyData={true} placeHolderContent="---- Select Access Matrix ----"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <SelectBox id="status" name="status" label="Status"
                                options={userStatus} value={selectedUserStatus}
                                inputState={setSelectedUserStatus}
                                isRequired={true} allowEmptyData={true} placeHolderContent="---- Select Status ----"
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                id="daySinceLastLogin"
                                name="daySinceLastLogin"
                                label="Days Since Last Login"
                                type={TextboxType.NUMBER}
                                isRequired={true}
                                inputState={setDaySinceLastLogin}
                                validState={setDaysValid}
                                value={daySinceLastLogin}
                                min={0}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div>
            <UserListingAccessMatrixModal editID={accessViewID} setOpenState={setOpenModal} isOpen={openModal}  />
            <StandardReportContainer
                id="UserListingReporting"
                error={hasSearched ? err : ""}
                success={hasSearched ? suc : ""}
                loading={loading}
                tablePagination={pagination}
                tableProps={reportTable}
                searchCriteriaContent={SearchCriteriaContainer()}
                generateCSVEvent={generateCSV}
                generatePDFEvent={generatePDF}
            />
        </div>
    )
}



export default UserListingReporting;