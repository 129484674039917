import React from "react";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import ICardContainer from "./ICardContainer";

const CardContainer = (props: ICardContainer) => {
    return (
        <div>
            <Card
                className={props.className}
                color={props.color}
                inverse={props.inverse}
                outline={props.outline}
            >
                {props.loading ?
                    <div className='text-center p-4'><Spinner /></div>
                    :
                    <div>
                        <CardHeader>
                            {props.title}
                        </CardHeader>
                        <CardBody>
                            {props.content}
                        </CardBody>
                    </div>
                }

            </Card>
        </div>
    );
}

export default CardContainer;