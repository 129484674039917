import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import StandardContainer from "../../components/StandardContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import GamingAreaMaintenanceModal from "../../components/_modals/CMS/GamingAreaModal";
import { BackOfficeAccess } from "../../dto/enums/AccessMatrix/BackOfficeAccess";
import { GamingAreaType } from "../../dto/enums/GamingAreaType";
import { HASFLAG } from "../../libraries/Functions";
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetGamingAreaRequest, SearchGamingArea } from "../../states/GamingArea/GamingAreaActions";
import { IStore } from "../../states/store/IStore";

const GamingAreaMaintenance = () => {
    const [openModal, setOpenModal] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(0);
    const [currentPage, setPage] = useState(0);

    const dispatch = useDispatch();
    const gamingAreaState = useSelector((state: IStore) => state.gamingAreaState);
    const { loading, err, suc, gamingAreaList } = gamingAreaState;
    const prevIsSearch = useRef(false);

    const emptyTable: IDataTable = {
        title: ["No", "Name", "Area Type", "Gaming Area", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [gamingAreaTable, setGamingAreaTable] = useState(emptyTable);

    useEffect(() => {
        if (!openModal) {
            dispatch(GetGamingAreaRequest(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    useEffect(() => {
        prevIsSearch.current = isSearch;
    }, [isSearch]);

    useEffect(() => {
        var table: IDataTable = emptyTable;
        if (gamingAreaList && gamingAreaList.gal && gamingAreaList.gal.length > 0) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = gamingAreaList.gal.map((item, index) => {
                return [
                    startIndex + index + 1,
                    item.gan,
                    GamingAreaType[item.typ] ? GamingAreaType[item.typ].toString().replace("_", " ") : "N/A",
                    item.slt ? item.slt : "N/A",
                    <Button onClick={() => editGamingArea(item.gid)}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                ];
            })
        }
        else {
            table.data = [];
        }

        setGamingAreaTable(table);
    }, [gamingAreaList]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchGamingArea(searchInput, 0, TABLE_MAX_ROW));
        setIsSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetGamingAreaRequest(0, TABLE_MAX_ROW));
        setIsSearch(false);
        setSearchInput("")
    }

    const take = (page: number) => {
        setPage(page);

        if (!isSearch) {
            dispatch(GetGamingAreaRequest(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
        else {
            dispatch(SearchGamingArea(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }

    const addGamingArea = (): void => {
        setOpenModal(true);
        setIsEdit(false);
    }

    const editGamingArea = (id: number): void => {
        setOpenModal(true);
        setIsEdit(true);
        setEditID(id);
    }

    const pagination: ITablePagination = {
        id: "gamingAreaPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: gamingAreaList?.ttc ?? 0
    };

    useEffect(() => {
        const access: BackOfficeAccess = GET_PROFILE().acc.bow;

        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.GAMING_AREA_MAINTENANCE))
        }
    }, [])

    return (
        <div>
            <GamingAreaMaintenanceModal isEdit={isEdit} id={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="GamingAreaMaintenanceContainer"
                loading={loading}
                error={err}
                success={suc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? addGamingArea : undefined}
                tableProps={gamingAreaTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
}

export default GamingAreaMaintenance;