import { faUserLock, faUserGroup, faShield } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import AccessMatrixMaintenance from "../containers/AccessControl/AccessMatrixMaintenance";
import UserMaintenance from "../containers/AccessControl/UserMaintenance";
import { BackOfficeAccess } from "../dto/enums/AccessMatrix/BackOfficeAccess";
import { GetSideBar, INavigationData, IRouteData } from "./navData";

const USER_MAINTENANANCE_URL = "/UserMaintenance";
const ACCESS_MATRIX_MAINTENANCE_URL = "/AccessMatrixMaintenance";

export const AccessControlRoutesData: IRouteData[] = [
    {
        to: USER_MAINTENANANCE_URL,
        element: <UserMaintenance />
    },
    {
        to: ACCESS_MATRIX_MAINTENANCE_URL,
        element: <AccessMatrixMaintenance />
    }
]

export const AccessControlNavigationData: INavigationData[] = [
    {
        requiredAccess: [BackOfficeAccess.USER_MAINTENANCE],
        icon: faUserGroup,
        title: "User Maintenance",
        path: "Access Control / User Maintenance",
        searchKeyword:"User Maintenance",
        to: USER_MAINTENANANCE_URL
    },
    {
        requiredAccess: [BackOfficeAccess.ACCESS_MATRIX_MAINTENANCE],
        icon: faUserLock,
        title: "Access Matrix",
        path: "Access Control / Access Matrix",
        searchKeyword: "Access Matrix",
        to: ACCESS_MATRIX_MAINTENANCE_URL
    }
]

export function AccessControlSideBar(open: boolean, access: number, currentPath: string): JSX.Element {
    return GetSideBar(AccessControlNavigationData, "Access Control", faShield, "#2cafc6", open, access, currentPath);
}