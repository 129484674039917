import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../dto/models/GeneralModels";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { PlayerLockActionTypes } from "./PlayerLockActionTypes";
import { PlayerLockRequestModel, PlayerLockStatusRequestModel } from "../../dto/models/PlayerLockModel";

type SearchPlayerRequest = { type: PlayerLockActionTypes.SEARCH_PLAYER, payload: IPayload };
type LockPlayerRequest = { type: PlayerLockActionTypes.LOCK_PLAYER, payload: IPayload };
type UnlockPlayerRequest = { type: PlayerLockActionTypes.UNLOCK_PLAYER, payload: IPayload };

export type ActionTypes =
    SearchPlayerRequest | LockPlayerRequest | UnlockPlayerRequest ;

export const SearchPlayer = (name: string) => async (dispatch: any) => {
    try {
        let request: PlayerLockStatusRequestModel = {
            usr: name,
        };
        PostRequest("Cashier/SearchPlayer", request, PlayerLockActionTypes.SEARCH_PLAYER, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLockActionTypes.SEARCH_PLAYER, dispatch);
    }
}

export const LockPlayer = (name: string, userID: number, remarks: string) => async (dispatch: any) => {
    try {
        let request: PlayerLockRequestModel = {
            usr: name,
            acb: userID,
            rmk: remarks
        };
        PostRequest("Cashier/LockPlayer", request, PlayerLockActionTypes.LOCK_PLAYER, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLockActionTypes.LOCK_PLAYER, dispatch);
    }
}

export const UnlockPlayer = (name: string, userID: number, remarks: string) => async (dispatch: any) => {
    try {
        let request: PlayerLockRequestModel = {
            usr: name,
            acb: userID,
            rmk: remarks
        };
        PostRequest("Cashier/UnlockPlayer", request, PlayerLockActionTypes.UNLOCK_PLAYER, dispatch);
    }
    catch (error) {
        RequestException(error, PlayerLockActionTypes.UNLOCK_PLAYER, dispatch);
    }
}
