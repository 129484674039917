import { JackpotPoolResetMode } from "../../../dto/enums/Jackpot/JackpotPoolResetMode";
import { JackpotPoolTitle } from "../../../dto/enums/Jackpot/JackpotPoolTitle";
import { JackpotPoolType } from "../../../dto/enums/Jackpot/JackpotPoolType";
import { RequestItemModel, RequestListModel, RequestSearchItemModel } from "../../../dto/models/GeneralModels";
import { PoolModel, TierModel } from "../../../dto/models/JackpotModels";
import { GetRequest, PostRequest, RequestException } from "../../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../../store/IStore";
import { PoolActionTypes } from "./PoolActionTypes";

type AddPoolRequest = { type: PoolActionTypes.ADD_POOL_REQUEST, payload: IPayload };
type EditPoolRequest = { type: PoolActionTypes.EDIT_POOL_REQUEST, payload: IPayload };
type GetPoolListRequest = { type: PoolActionTypes.GET_POOL_LIST_REQUEST, payload: IPayload };
type GetPoolRequest = { type: PoolActionTypes.GET_POOL_REQUEST, payload: IPayload };
type SelectPoolRequest = { type: PoolActionTypes.SELECT_POOL_REQUEST, payload: IPayload };
type SearchPoolRequest = { type: PoolActionTypes.SEARCH_POOL_REQUEST, payload: IPayload };
type GetCreditRateRequest = { type: PoolActionTypes.GET_CREDIT_RATE, payload: IPayload };

export type ActionTypes =
    AddPoolRequest | EditPoolRequest | GetPoolListRequest | GetPoolRequest | SelectPoolRequest | SearchPoolRequest | GetCreditRateRequest;

export const AddPool = (
    title: JackpotPoolTitle, name: string, description: string, poolType: JackpotPoolType, resetMode: JackpotPoolResetMode, threshold: number,
    seed: number, ceiling: number, payout: number, tierList: TierModel[]
) => async (dispatch: any) => {

    try {
        // ask reducer handle response data
        let request: PoolModel = {
            jid: 0,
            pon: name,
            til: title,
            des: description,
            typ: poolType,
            rsm: resetMode,
            tsh: threshold,
            sed: seed,
            cel: ceiling,
            poa: payout,
            tls: tierList
        };
        PostRequest("BackOffice/AddPool", request, PoolActionTypes.ADD_POOL_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolActionTypes.ADD_POOL_REQUEST, dispatch);
    }
}

export const EditPool = (
    id: number, name: string, title: JackpotPoolTitle, description: string, poolType: JackpotPoolType, resetMode: JackpotPoolResetMode, threshold: number,
    seed: number, ceiling: number, payout: number, tierList: TierModel[]
) => async (dispatch: any) => {

    try {
        let request: PoolModel = {
            jid: id,
            pon: name,
            til: title,
            des: description,
            typ: poolType,
            rsm: resetMode,
            tsh: threshold,
            sed: seed,
            cel: ceiling,
            poa: payout,
            tls: tierList
        };
        PostRequest("BackOffice/EditPool", request, PoolActionTypes.EDIT_POOL_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolActionTypes.EDIT_POOL_REQUEST, dispatch);
    }

}

export const GetPool = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("BackOffice/GetPool", request, PoolActionTypes.GET_POOL_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolActionTypes.GET_POOL_REQUEST, dispatch);
    }
}

export const SearchPool = (keyword: string, from: number, take: number) => async (dispatch: any) => {
    try {
        let request: RequestSearchItemModel = {
            kwd: keyword,
            frm: from,
            tak: take
        };
        PostRequest("BackOffice/SearchPool", request, PoolActionTypes.SEARCH_POOL_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolActionTypes.SEARCH_POOL_REQUEST, dispatch);
    }
}

export const GetPoolList = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetPoolList", PoolActionTypes.GET_POOL_LIST_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolActionTypes.GET_POOL_LIST_REQUEST, dispatch);
    }
}

export const SelectPool = (id: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: id
        };
        PostRequest("BackOffice/SelectPool", request, PoolActionTypes.SELECT_POOL_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, PoolActionTypes.SELECT_POOL_REQUEST, dispatch);
    }
}

export const PoolGetCreditRate = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetCreditRate", PoolActionTypes.GET_CREDIT_RATE, dispatch);
    }
    catch (error) {
        RequestException(error, PoolActionTypes.GET_CREDIT_RATE, dispatch);
    }
}