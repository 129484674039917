import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { JackpotContributionReportCSVModel, JackpotContributionReportListModel, JackpotContributionReportPDFModel, JackpotContributionSummaryReportListModel } from "../../../dto/models/Reporting/JackpotContributionReportModel";
import { DOWNLOAD_BYTES_FILE, GET_REPORT_FILENAME } from "../../../libraries/FileHelper";

import { ActionTypes } from "./JackpotContributionReportingActions";
import { JackpotContributionReportingActionTypes } from "./JackpotContributionReportingActionTypes";
import { InitPoolContributionReportState, IPoolContributionReportState } from "./JackpotContributionReportingStates";


export const JackpotContributionReportingReducer = (state = InitPoolContributionReportState, action: ActionTypes): IPoolContributionReportState => {
    switch (action.type) {
        case JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_REPORT_REQUEST:
        case JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_SUMMARY_REPORT_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, contributionReportListData: {} as JackpotContributionReportListModel, contributionReportSummaryListData: {} as JackpotContributionSummaryReportListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var reportListModel: JackpotContributionReportListModel = {} as JackpotContributionReportListModel;
                var summaryReportListModel: JackpotContributionSummaryReportListModel = {} as JackpotContributionSummaryReportListModel;

                if (data && !data.err) {
                    if (action.type == JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_REPORT_REQUEST)
                        reportListModel = data.res;
                    else
                        summaryReportListModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""

                if (action.type == JackpotContributionReportingActionTypes.GET_JACKPOT_CONTRIBUTION_REPORT_REQUEST) {
                    if (action.payload.err !== "") {
                        errMsg = action.payload.err as string;
                    } else {
                        if (reportListModel.ttc && reportListModel.ttc != 0)
                            sucMsg = reportListModel.ttc.toString() + " records have been retrieved."
                        else
                            errMsg = "No Records Found"
                    }
                    return { ...state, loading: false, contributionReportListData: reportListModel, err: errMsg, suc: sucMsg };
                } else {
                    if (action.payload.err !== "") {
                        errMsg = action.payload.err as string;
                    } else {
                        if (summaryReportListModel.ttc && summaryReportListModel.ttc != 0)
                            sucMsg = summaryReportListModel.ttc.toString() + " records have been retrieved."
                        else
                            errMsg = "No Records Found"
                    }
                    return { ...state, loading: false, contributionReportSummaryListData: summaryReportListModel, err: errMsg, suc: sucMsg };
                }

            }
        }
        case JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_PDF_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotContributionReportPDFModel, csvData: {} as JackpotContributionReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var pdfModel: JackpotContributionReportPDFModel = {} as JackpotContributionReportPDFModel;

                if (data && !data.err) {
                    pdfModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (pdfModel && pdfModel.pdf && pdfModel.pdf.length != 0) {
                        DOWNLOAD_BYTES_FILE(pdfModel.pdf, GET_REPORT_FILENAME("JackpotContributionReport", "pdf"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, pdfData: pdfModel, err: errMsg, suc: sucMsg };
            }
        case JackpotContributionReportingActionTypes.GENERATE_JACKPOT_CONTRIBUTION_CSV_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, pdfData: {} as JackpotContributionReportPDFModel, csvData: {} as JackpotContributionReportCSVModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var csvModel: JackpotContributionReportCSVModel = {} as JackpotContributionReportCSVModel;

                if (data && !data.err) {
                    csvModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = ""
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (csvModel && csvModel.csv && csvModel.csv.length != 0) {
                        DOWNLOAD_BYTES_FILE(csvModel.csv, GET_REPORT_FILENAME("JackpotContributionReport", "csv"))
                        sucMsg = "Report Downloaded."
                    }
                }
                return { ...state, loading: false, csvData: csvModel, err: errMsg, suc: sucMsg };
            }
        }
        default:
            return state
    }
}
