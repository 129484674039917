import { ManualSubmitOLRListModel, OLRDetailsModel } from "../../dto/models/ManualOLRModel";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { ActionTypes } from "./ManualSubmitOLRActions";
import { ManualSubmitOLRActionTypes } from "./ManualSubmitOLRActionTypes";
import { IManualSubmitOLRStates, InitManualSubmitOLRState } from "./ManualSubmitOLRStates";

export const manualSubmitOLRReducers = (state = InitManualSubmitOLRState, action: ActionTypes): IManualSubmitOLRStates => {
    switch (action.type) {
        case ManualSubmitOLRActionTypes.SELECT_MANUAL_SUBMIT_OLR_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, OLRDetails: {} as OLRDetailsModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var olrDetails: OLRDetailsModel = {} as OLRDetailsModel;

                if (data && !data.err) {
                    olrDetails = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }

                return { ...state, loading: false, OLRDetails: olrDetails, err: errMsg, suc: sucMsg };
            }
        }
        case ManualSubmitOLRActionTypes.RESOLVE_MANUAL_SUBMIT_OLR_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                        sucMsg = "Resolved!";
                }
                return { ...state, loading: false,  err: errMsg, suc: sucMsg };
            }
        }
        case ManualSubmitOLRActionTypes.GET_RESOLVED_MANUAL_SUBMIT_OLR_REQUEST:
        case ManualSubmitOLRActionTypes.GET_UNRESOLVED_MANUAL_SUBMIT_OLR_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, manualSubmitOLRListData: {} as ManualSubmitOLRListModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var manualSubmitOLRList: ManualSubmitOLRListModel = {} as ManualSubmitOLRListModel;

                if (data && !data.err) {
                    manualSubmitOLRList = data.res;
                }

                return { ...state, loading: false, manualSubmitOLRListData: manualSubmitOLRList, err: action.payload.err ? action.payload.err : "" };
            }
        }
        default:
            return state
    }
}
