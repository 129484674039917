import { faArrowsSpin, faFloppyDisk, faPenToSquare, faRefresh, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import { ModalType } from '../../components/ModalBox/IModalBox';
import ModalBox from '../../components/ModalBox/ModalBox';
import StandardContainer from '../../components/StandardContainer';
import { ITablePagination } from '../../components/TablePagination/ITablePagination';
import UserMaintenanceModal from '../../components/_modals/AccessControl/UserMaintenanceModal';
import { BackOfficeAccess } from '../../dto/enums/AccessMatrix/BackOfficeAccess';
import { TableStatus } from '../../dto/enums/TableStatus';
import { FORMAT_DATETIME_TO_VIEW } from '../../libraries/DateHelper';
import { HASFLAG } from '../../libraries/Functions';
import { GET_PROFILE, TABLE_MAX_PAGE, TABLE_MAX_ROW } from '../../libraries/Global';
import { IStore } from '../../states/store/IStore';
import { GetUser, ResetPassword, SearchUser } from '../../states/User/UserActions';

const UserMaintenance = () => {
    const [confirmDialogVisible, openConfirmDialog] = useState(false);
    const [selectedResetPassword, setRSPassword] = useState(0);
    const [searchInput, setSearchInput] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [editID, setEditID] = useState(Number);
    const [currentPage, setPage] = useState(0);
    const access: BackOfficeAccess = GET_PROFILE().acc.bow;

    const dispatch = useDispatch();
    const userStates = useSelector((state: IStore) => state.userState);
    const { loading, userDataErr, userDataSuc, userDataList } = userStates;
    const [isSearch, setSearch] = useState(false);
    const prevIsSearch = useRef(false);

    const emptyTable: IDataTable = {
        title: HASFLAG(access, BackOfficeAccess.PASSWORD_RESET) ? ["No", "Full Name", "Role", "Status", "Last Login", "View", "Reset Password"] : ["No", "Full Name", "Role", "Status", "Last Login", "View"],
        data: undefined,
        isStriped: false,
        isHover: true,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading
    };
    const [userTable, setUserTable] = useState(emptyTable)

    useEffect(() => {
        if (!openModal) {
            dispatch(GetUser(currentPage * TABLE_MAX_ROW, TABLE_MAX_ROW));
        }
    }, [openModal]);

    useEffect(() => {
        prevIsSearch.current = isSearch;
    }, [isSearch]);

    const search = (): void => {
        setPage(0);
        dispatch(SearchUser(searchInput, 0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(true);
    }

    const clear = (): void => {
        setPage(0);
        dispatch(GetUser(0 * TABLE_MAX_ROW, TABLE_MAX_ROW));
        setSearch(false);
        setSearchInput("")
    }

    const AddUser = (): void => {
        setOpenModal(true);
        setEdit(false);
    }

    const EditUser = (id: number): void => {
        setOpenModal(true);
        setEdit(true);
        setEditID(id);
    }

    useEffect(() => {
        var table: IDataTable = emptyTable

        if (userDataList.usl && userDataList.usl.length > 0 && userDataList.usl[0].fnm) {
            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = userDataList.usl.map((item, index) => {
                if (HASFLAG(access, BackOfficeAccess.PASSWORD_RESET)) {
                    return [
                        startIndex + index + 1,
                        item.fnm,
                        item.acc.rnm,
                        TableStatus[item.stt],
                        FORMAT_DATETIME_TO_VIEW(item.llg as any as Date, "yyyy-MM-dd hh:mm:ssa"),
                        <Button onClick={() => EditUser(item.uid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>,
                        <Button color="primary" outline onClick={e => { openConfirmDialog(true); setRSPassword(item.uid) }}><FontAwesomeIcon icon={faRefresh} /></Button>
                    ];
                } else {
                    return [
                        startIndex + index + 1,
                        item.fnm,
                        item.acc.rnm,
                        TableStatus[item.stt],
                        FORMAT_DATETIME_TO_VIEW(item.llg as any as Date, "yyyy-MM-dd hh:mm:ssa"),
                        <Button onClick={() => EditUser(item.uid)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>
                    ];
                }
            });
        }
        else {
            table.data = [];
        }

        setUserTable(table)
    }, [userDataList])

    useEffect(() => {
        if (access) {
            setCanEdit(HASFLAG(access, BackOfficeAccess.USER_MAINTENANCE))
        }
    }, [])


    const resetPassword = (id: number) => {
        dispatch(ResetPassword(id));
    }

    const dialogBoxButton = () => {
        return (
            <div>
                <Button
                    color="info"
                    onClick={() => {
                        resetPassword(selectedResetPassword);
                        openConfirmDialog(false);
                        setRSPassword(0);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faFloppyDisk} /> Confirm
                </Button>
                {' '}
                <Button
                    color="secondary"
                    onClick={() => {
                        openConfirmDialog(false);
                        setRSPassword(0);
                    }}
                    outline>
                    <FontAwesomeIcon icon={faXmark} /> Cancel
                </Button>
            </div>
        );
    }

    const take = (page: number) => {
        setPage(page);
        if (!isSearch)
            dispatch(GetUser(page * TABLE_MAX_ROW, TABLE_MAX_ROW));
        else
            dispatch(SearchUser(searchInput, page * TABLE_MAX_ROW, TABLE_MAX_ROW));
    }

    const pagination: ITablePagination = {
        id: "userPagination",
        maxPage: TABLE_MAX_PAGE,
        onChangePage: take,
        totalCount: userDataList.ttc ?? 0
    }


    return (
        <div>
            <ModalBox
                title={"Warning"}
                isOpen={confirmDialogVisible}
                isFade={true}
                isCentered={true}
                isScrollable={true}
                child={"Are you sure to reset the login password of this user?"}
                type={ModalType.Dialog}
                openState={openConfirmDialog}
                footer={dialogBoxButton()}
                error={""}
                success={""}
            />
            <UserMaintenanceModal isEdit={isEdit} editID={editID} setOpenState={setOpenModal} isOpen={openModal} />
            <StandardContainer
                id="UserMaintenanceModal"
                loading={loading}
                error={userDataErr}
                success={userDataSuc}
                searchEvent={search}
                clearEvent={clear}
                searchInput={setSearchInput}
                isSearch={isSearch}
                addEvent={canEdit ? AddUser : undefined}
                tableProps={userTable}
                tablePagination={pagination}
                searchValue={searchInput}
            />
        </div>
    );
};

export default UserMaintenance;
