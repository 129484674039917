import { NotificationModel } from "../../dto/models/NotificationModel";
import { GetRequest, PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload } from "../store/IStore";
import { NotificationActionTypes } from "./NotificationTypes";

type GetNotificationRequest = { type: NotificationActionTypes.GET_NOTIFICATION_REQUEST, payload: IPayload };
type EditNotificationRequest = { type: NotificationActionTypes.EDIT_NOTIFICATION_REQUEST, payload: IPayload };

export type ActionTypes = GetNotificationRequest | EditNotificationRequest;

export const GetNotification = () => async (dispatch: any) => {
    try {
        GetRequest("BackOffice/GetNotification", NotificationActionTypes.GET_NOTIFICATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, NotificationActionTypes.GET_NOTIFICATION_REQUEST, dispatch);
    }
}

export const EditNotification = (cen: string, csc: string, ctc: string) => async (dispatch: any) => {
    try {
        let request: NotificationModel = {
            cen: cen,
            csc: csc,
            ctc: ctc
        };

        PostRequest("BackOffice/EditNotification", request, NotificationActionTypes.EDIT_NOTIFICATION_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, NotificationActionTypes.EDIT_NOTIFICATION_REQUEST, dispatch);
    }
}