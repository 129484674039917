import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, FormGroup, Row, Table } from 'reactstrap';
import { IStore } from "../../states/store/IStore";
import InputBox from '../../components/InputBox/InputBox';
import { TextboxType } from '../../components/InputBox/IInputBox';
import { ICheckBoxOption } from '../../components/InputBox/ICheckBoxGroup';
import CheckBoxGroup from '../../components/InputBox/CheckBoxGroup';
import { CashIn, CashOut, GetPlayerWallet, TransferIn, TransferOut } from '../../states/CreditTransaction/CreditTransactionActions';
import { WalletType } from '../../dto/enums/WalletType';
import TextField from '../../components/InputBox/TextField';
import { CreditTransferType } from '../../dto/enums/TransferMode';
import SelectBox from '../../components/InputBox/SelectBox';
import { ISelectOption, LoadSelectOptionFromEnum } from '../../components/InputBox/ISelectBox';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { AlertSize, AlertType } from '../../components/AlertMessage/IAlert';
import Loader from '../../components/Loader';
import { WalletDetailsModel } from '../../dto/models/CreditTransactionModels';
import { PlayerStatus } from '../../dto/enums/PlayerStatus';
import { HASFLAG, TRIGGER_BLUR } from '../../libraries/Functions';
import { CashierAccess } from '../../dto/enums/AccessMatrix/CashierAccess';
import { GET_PROFILE } from '../../libraries/Global';

const TransferCreditMaintenance = () => {
    const creditTransactionState = useSelector((state: IStore) => state.creditTransactionState);
    const { loading, err, creditTransferData, playerWalletData } = creditTransactionState
    const [searchPlayerID, setSearchPlayerID] = useState("")
    const [isSearch, setIsSearch] = useState(false);
    const [transferAmount, setTransferAmount] = useState<number | undefined>(undefined);
    const [transferAmountValid, setTransferAmountValid] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [remarksValid, setRemarksValid] = useState(false);
    const [playerName, setPlayerName] = useState("-")

    const [cmsEcBalance, setCmsEcBalance] = useState("0.00")
    const [ecBalance, setEcBalance] = useState("0.00")
    const [cmsNnBalance, setCmsNnBalance] = useState("0.00")
    const [nnBalance, setNnBalance] = useState("0.00")
    const [ecWalletName, setEcWalletName] = useState("-")
    const [nnWalletName, setNnWalletName] = useState("-")
    const [playerStatus, setPlayerStatus] = useState("-")
    const [isEdit, setIsEdit] = useState(false)

    const [cmsVipEcBalance, setCmsVipEcBalance] = useState("0.00")
    const [vipEcBalance, setVipEcBalance] = useState("0.00")
    const [cmsVipNnBalance, setCmsVipNnBalance] = useState("0.00")
    const [vipNnBalance, setVipNnBalance] = useState("0.00")
    const [vipEcWalletName, setVipEcWalletName] = useState("-")
    const [vipNnWalletName, setVipNnWalletName] = useState("-")

    const [nonvipWallet, setNonvipWallet] = useState({} as WalletDetailsModel)
    const [vipWallet, setVipWallet] = useState({} as WalletDetailsModel)

    const [transferType, setTransferType] = useState(0)
    const [creditTypeError, setCreditTypeError] = useState("")
    const transferOptions = useRef<ISelectOption[]>([])
    const [transferTypeError, setTransferTypeError] = useState("")
    const [transferAmountError, setTransferAmountError] = useState<string | undefined>(undefined)
    const [isAlertOpen, setAlertOpen] = useState(true);
    const [alertMessage, setAlertMessage] = useState("");
    const [isValid, setIsValid] = useState(true);

    const creditType: ICheckBoxOption[] = [
        {
            display: "E-Cash",
            value: WalletType.NVIP_EC.toString()
        },
        {
            display: "NN",
            value: WalletType.NVIP_NN.toString()
        },
        {
            display: "VIP E-Cash",
            value: WalletType.VIP_EC.toString()
        },
        {
            display: "VIP NN",
            value: WalletType.VIP_NN.toString()
        }
    ]

    const cashOutCreditType: ICheckBoxOption[] = [
        {
            display: "E-Cash",
            value: WalletType.NVIP_EC.toString()
        },
        {
            display: "VIP E-Cash",
            value: WalletType.VIP_EC.toString()
        }
    ]

    const [selectedWalletType, setSelectedWalletType] = useState([] as string[]);

    const dispatch = useDispatch();

    useEffect(() => {
        clearFields();
    }, [isEdit]);

    const clearFields = () => {
        setTransferAmountError(undefined)
        setCreditTypeError("")
        setTransferAmount(undefined)
        setRemarks("")
        setSelectedWalletType([] as string[])
    }

    const validateInput = () => {
        var isValid = true
        setTransferAmountError("")
        setCreditTypeError("")
        setTransferTypeError("")

        if (searchPlayerID == "") {
            isValid = false
            setIsValid(isValid)
            setAlertMessage("No member selected")
            setAlertOpen(true)
        }
        else {
            if (transferAmount != undefined && Object.keys(playerWalletData).length > 0) {
                if (transferAmount == 0) {
                    isValid = false
                    setIsValid(isValid)
                    setTransferAmountError("Please Enter Amount!")
                }
                else if (transferAmount < 0) {
                    isValid = false
                    setIsValid(isValid)
                    setTransferAmountError("Amount cannot be lesser than 0!")
                }               
            }
        
            if (selectedWalletType.length == 0) {
                isValid = false
                setIsValid(isValid)
                setCreditTypeError("Please Select Credit Type!")
            }

            if (transferType == CreditTransferType.NONE) {
                isValid = false
                setIsValid(isValid)
                setTransferTypeError("Please Select Transfer Type!")
            }
        }
        return isValid && remarksValid && transferAmountValid
    }

    const submit = () => {
        if (transferAmount != undefined && validateInput()) {
            switch (transferType) {
                case CreditTransferType.TRANSFER_IN:
                    dispatch(TransferIn(searchPlayerID, transferAmount, parseInt(selectedWalletType[0]), remarks))
                    break;
                case CreditTransferType.TRANSFER_OUT:
                    dispatch(TransferOut(searchPlayerID, transferAmount, parseInt(selectedWalletType[0]), remarks))
                    break;
                case CreditTransferType.CASH_OUT:
                    dispatch(CashOut(searchPlayerID, transferAmount, parseInt(selectedWalletType[0]), remarks))
                    break;
                //case CreditTransferType.CASH_IN:
                //    dispatch(CashIn(searchPlayerID, transferAmount, parseInt(selectedWalletType[0]), remarks))
                //    break;
            }
            var memberName = document.getElementById('memberName')
            if (memberName != null)
                memberName.scrollIntoView()
        }
        else {
            TRIGGER_BLUR("creditType");
            TRIGGER_BLUR("transferType");
            TRIGGER_BLUR("transferAmount");
            TRIGGER_BLUR("transferRemark");
        }
    }

    const search = (input: string) => {
        if (input != "") {
            dispatch(GetPlayerWallet(input))
            setIsSearch(true);
        }
    }

    const clear = () => {
        setSearchPlayerID("")
        setIsEdit(false)
        clearWallet();
    }

    const clearWallet = () => {
        setPlayerName("-")
        setPlayerStatus("-")

        setCmsEcBalance("0.00")
        setEcBalance("0.00")
        setCmsNnBalance("0.00")
        setNnBalance("0.00")
        setEcWalletName("-")
        setNnWalletName("-")

        setCmsVipEcBalance("0.00")
        setVipEcBalance("0.00")
        setCmsVipNnBalance("0.00")
        setVipNnBalance("0.00")
        setVipEcWalletName("-")
        setVipNnWalletName("-")

        setNonvipWallet({} as WalletDetailsModel)
        setVipWallet({} as WalletDetailsModel)
    }

    useEffect(() => {
        if (searchPlayerID != "") {
            clearFields();

            if (Object.keys(playerWalletData).length > 0) {

                setNonvipWallet(Object.keys(creditTransferData).length > 0 ? creditTransferData.cwlt : playerWalletData.cwlt)
                setVipWallet(Object.keys(creditTransferData).length > 0 ? creditTransferData.vwlt : playerWalletData.vwlt)

                if (playerWalletData.fnm != null) {
                    setPlayerName(playerWalletData.fnm)
                    setPlayerStatus(PlayerStatus[playerWalletData.stt].replace('_', ' '))
                    setIsEdit(playerWalletData.stt == PlayerStatus.ACTIVE)

                    if (playerWalletData.cwlt) {
                        setEcWalletName(playerWalletData.cwlt.ecn)
                        setNnWalletName(playerWalletData.cwlt.nnn)
                    }
                    if (playerWalletData.vwlt) {
                        setVipEcWalletName(playerWalletData.vwlt.ecn)
                        setVipNnWalletName(playerWalletData.vwlt.nnn)
                    }
                }
                setIsSearch(false)
            } else {
                clearWallet()
                setIsEdit(false)
                clearFields()
            }
        }
        else {
            clearWallet();
            clearFields();
        }

    }, [playerWalletData, creditTransferData])

    useEffect(() => {
        if (nonvipWallet && Object.keys(nonvipWallet).length > 0) {
            setEcBalance(nonvipWallet.wltecb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            setNnBalance(nonvipWallet.wltnnb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            setCmsEcBalance(nonvipWallet.cmsecb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            setCmsNnBalance(nonvipWallet.cmsnnb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

            if (nonvipWallet.wltecb == 0 && nonvipWallet.cmsecb == 0)
                setEcWalletName("N/A")
            if (nonvipWallet.wltnnb == 0 && nonvipWallet.cmsnnb == 0)
                setNnWalletName("N/A")
        }

        if (vipWallet && Object.keys(vipWallet).length > 0) {
            setVipEcBalance(vipWallet.wltecb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            setVipNnBalance(vipWallet.wltnnb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            setCmsVipEcBalance(vipWallet.cmsecb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
            setCmsVipNnBalance(vipWallet.cmsnnb.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

            if (vipWallet.wltecb == 0 && vipWallet.cmsecb == 0)
                setVipEcWalletName("N/A")
            if (vipWallet.wltnnb == 0 && vipWallet.cmsnnb == 0)
                setVipNnWalletName("N/A")
        }

    }, [nonvipWallet, vipWallet])

    useEffect(() => {
        const access: CashierAccess = GET_PROFILE().acc.caa;

        if (access) {
            var options = LoadSelectOptionFromEnum(CreditTransferType);
            options.forEach((type) => {
                if (type.value == CreditTransferType.TRANSFER_IN.toString()) {
                    if (HASFLAG(access, CashierAccess.TRANSFER_IN)) {
                        transferOptions.current.push(type)
                    }
                }

                if (type.value == CreditTransferType.TRANSFER_OUT.toString()) {
                    if (HASFLAG(access, CashierAccess.TRANSFER_OUT)) {
                        transferOptions.current.push(type)
                    }
                }

                if (type.value == CreditTransferType.CASH_OUT.toString()) {
                    if (HASFLAG(access, CashierAccess.CASH_OUT)) {
                        transferOptions.current.push(type)
                    }
                }
            })

            if (transferOptions.current.length > 0) {
                setTransferType(parseInt(transferOptions.current[0].value));
            }
        }
    }, [])

    const transferTypeSelectBox = () => {
        return (
            <SelectBox id="transferType" name="transferType" label="Transfer Type"
                options={transferOptions.current}
                value={transferType}
                inputState={(e) => {
                    setTransferType(parseInt(e));
                    setSelectedWalletType([] as string[]);
                }}
                isRequired={true}
                isDisabled={!isEdit}
                allowEmptyData={false}
            />
        )
    }

    return (
        <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
            <Row>
                <Col sm={2}><h3>Member ID</h3></Col>
                <Col>
                    <InputBox
                        type={TextboxType.TEXT}
                        id="container_search"
                        name="container_search"
                        placeholder="Search"
                        inputState={setSearchPlayerID}
                        value={searchPlayerID}
                    />
                </Col>
                <Col>
                    <Button color="secondary" className={"me-1"} outline onClick={(e) => search(searchPlayerID)}>Search</Button>
                    <Button color="danger" outline disabled={isSearch} onClick={clear}>Clear</Button>
                </Col>
            </Row>
            <Row>
                <AlertMessage
                    content="No member selected"
                    alertType={AlertType.WARNING}
                    isOpen={isAlertOpen && !isValid && searchPlayerID == ""}
                    openState={setAlertOpen}
                    disappearTime={5}
                    alertSize={AlertSize.LONG}
                />
            </Row>
            <Form className="pt-2">
                <Row id="memberName" className="mb-3">
                    <Col>
                        <Row id="memberName" className=" d-flex" >
                            <Col className="member-name-label" md={2}> <h4>Member Name: </h4></Col>
                            <Col className="member-name"><h5>{playerName}</h5></Col>
                        </Row>
                        <Row className=" d-flex">
                            <Col md={2}><h4>Status: </h4></Col>
                            <Col className={playerStatus != "-" ? (playerStatus == PlayerStatus[PlayerStatus.ACTIVE] ? "status-active" : "status-lock") : ""}><h5>{playerStatus}</h5></Col>
                        </Row>
                    </Col>
                </Row>
                {
                    playerName != "-" && err ?
                        <AlertMessage
                            content={err}
                            alertType={AlertType.FAIL}
                            isOpen={true}
                            alertSize={AlertSize.LONG}
                        /> :
                        <></>
                }
                <Row className="mb-3">
                    <Card id="memberWallet">
                        <Row>
                            <Col>
                                <Row><h5>Non-VIP Wallet</h5></Row>
                                <Row>
                                    <Col className="wallet-row">
                                        <Card className="wallet-card">
                                            {loading ?
                                                <Loader />
                                                :
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td>Wallet Name:</td>
                                                            <td>{ecWalletName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CMS Credit Balance:</td>
                                                            <td>{cmsEcBalance}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FSG-Slot Wallet Balance:</td>
                                                            <td>{ecBalance}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>}
                                        </Card>
                                        <Card className="wallet-card">
                                            {loading ?
                                                <Loader />
                                                : <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td>Wallet Name:</td>
                                                            <td>{nnWalletName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CMS Credit Balance:</td>
                                                            <td>{cmsNnBalance}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FSG-Slot Wallet Balance:</td>
                                                            <td>{nnBalance}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row><h5>VIP Wallet</h5></Row>
                                <Row>
                                    <Col className="wallet-row">
                                        <Card className="wallet-card">
                                            {loading ?
                                                <Loader />
                                                :
                                                <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td>Wallet Name:</td>
                                                            <td>{vipEcWalletName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CMS Credit Balance:</td>
                                                            <td>{cmsVipEcBalance}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FSG-Slot Wallet Balance:</td>
                                                            <td>{vipEcBalance}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>}
                                        </Card>
                                        <Card className="wallet-card">
                                            {loading ?
                                                <Loader />
                                                : <Table borderless>
                                                    <tbody>
                                                        <tr>
                                                            <td>Wallet Name:</td>
                                                            <td>{vipNnWalletName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CMS Credit Balance:</td>
                                                            <td>{cmsVipNnBalance}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FSG-Slot Wallet Balance:</td>
                                                            <td>{vipNnBalance}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <FormGroup className="mb-3">
                            {transferOptions.current.length > 0 && transferTypeSelectBox()}
                        </FormGroup>
                        <span className="error-message">{transferTypeError}</span>
                        <CheckBoxGroup
                            id="creditType"
                            label="Credit Type"
                            values={selectedWalletType}
                            options={transferType == CreditTransferType.CASH_OUT ? cashOutCreditType : creditType}
                            inputState={setSelectedWalletType}
                            className="checkbox-tile"
                            singleChoice={true}
                            isDisabled={!isEdit}
                            inline={false}
                        />
                        <span className="error-message">{creditTypeError}</span>
                        <FormGroup>
                            <InputBox
                                type={TextboxType.CURRENCY}
                                id="transferAmount"
                                name="transferAmount"
                                label="Transfer Amount"
                                inputState={setTransferAmount}
                                value={transferAmount}
                                min={1}
                                invalidMessage={transferAmountError}
                                isRequired={true}
                                isDisabled={!isEdit}
                                validState={setTransferAmountValid}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <TextField id="transferRemark" name="remark" label="Remarks"
                                inputState={setRemarks} value={remarks} maxLength={300} col={5} row={5}
                                isDisabled={!isEdit} validState={setRemarksValid} isRequired={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button color="info" onClick={submit} outline disabled={!isEdit}>Submit</Button>
                    </Col>
                </Row>
            </Form >
        </div >
    );
};

export default TransferCreditMaintenance;