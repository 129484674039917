import React, { useEffect, useRef, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { TABLE_MAX_ROW } from "../../libraries/Global";
import { ITablePagination } from "./ITablePagination";

const TablePagination = (props: ITablePagination) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pagination, setPagination] = useState([] as JSX.Element[]);
    const prevPage = useRef<number>(currentPage);
    const [tableMaxRow, setTableMaxRow] = useState(0);

    useEffect(() => {
        getPagination();
    }, [props.totalCount]);

    useEffect(() => {
        setCurrentPage(0);
    }, [props.isSearch]);

    useEffect(() => {
        if (prevPage.current != currentPage) {
            getPagination();
            prevPage.current = currentPage;
        }
    }, [currentPage]);

    // for manual setting the current page
    useEffect(() => {
        if (props.currentPage != undefined && prevPage.current != props.currentPage) {
            setCurrentPage(props.currentPage);
        }
    }, [props.currentPage]);

    useEffect(() => {
        props.customTableMaxRow ? setTableMaxRow(props.customTableMaxRow) : setTableMaxRow(TABLE_MAX_ROW);
    }, [props.customTableMaxRow])

    const onPageChanged = (page: number) => {
        setCurrentPage(page);
        props.onChangePage(page);
    }

    const getPagination = () => {
        const totalPage = Math.ceil(props.totalCount / tableMaxRow);
        const pages = [];

        pages.push(
            <PaginationItem key={props.id + -2} disabled={currentPage == 0}>
                <PaginationLink first onClick={onPageChanged.bind(null, 0)} />
            </PaginationItem>
        );
        pages.push(
            <PaginationItem key={props.id + -3} disabled={currentPage == 0}>
                <PaginationLink previous onClick={onPageChanged.bind(null, currentPage -1)} />
            </PaginationItem>
        );

        if (totalPage > 0) {
            var startPage = Math.max(currentPage - Math.floor(props.maxPage / 2), 0);
            var endPage = Math.min(startPage + props.maxPage, totalPage);

            if (endPage - startPage < props.maxPage && startPage > 0) {
                startPage = endPage - props.maxPage;
            }

            for (var i = startPage; i < endPage; i++) {
                pages.push(
                    <PaginationItem key={props.id + i} active={currentPage == i}>
                        <PaginationLink onClick={onPageChanged.bind(null, i)} >{i + 1}</PaginationLink>
                    </PaginationItem>
                )
            }
        }
        
        pages.push(
            <PaginationItem key={props.id + -4} disabled={currentPage == totalPage - 1}>
                <PaginationLink next onClick={onPageChanged.bind(null, currentPage + 1)} />
            </PaginationItem>
        );
        pages.push(
            <PaginationItem key={props.id + -5} disabled={currentPage == totalPage - 1}>
                <PaginationLink last onClick={onPageChanged.bind(null, totalPage - 1)} />
            </PaginationItem>
        );

        setPagination(pages);
    }

    return (

        <div className="text-center" style={{ display: 'ruby' }}>
            {props.totalCount > tableMaxRow ?
                <Pagination>
                    {pagination}
                </Pagination>
                : ""
            }
        </div>
    );
}

export default TablePagination;