import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { ICheckBoxGroup } from "./ICheckBoxGroup";
import "../../styles/Common.css";

const CheckBoxGroup = (props: ICheckBoxGroup) => {
    const [invalidMsg, setInvalidMsg] = useState("");
    const [options, setOptions] = useState([] as JSX.Element[]);

    useEffect(() => {
        if (!props.isDisabled && props.values && (props.maxChoice || props.minChoice || props.singleChoice)) {
            let valid = true;
            if (props.maxChoice && props.values.length > props.maxChoice) {
                setInvalidMsg("Maximum of the selection: " + props.maxChoice);
                valid = false;
            }
            else if (props.minChoice && props.values.length < props.minChoice) {
                setInvalidMsg("Minimum of the selection: " + props.minChoice);
                valid = false;
            }
            else if (props.singleChoice && props.values.length != 1)
            {
                setInvalidMsg("Invalid selection: Please select only 1 option.");
                valid = false;
            }
            else {
                setInvalidMsg("");
            }

            if (props.validState) {
                props.validState(valid);
            }
        }

        const opt = props.options.map((item, index) => {
            let isChecked: boolean = false;
            if (props.values) {
                isChecked = props.values.includes(item.value);
            }

            return (
                <FormGroup inline={props.inline} check key={item.display} style={props.singleChoice ? { paddingLeft: "0px" } : undefined}>
                    <Input type="checkbox" value={item.value}
                        disabled={props.isDisabled} onChange={onChange}
                        id={item.display + 'input'}
                        checked={isChecked}
                    />
                    <Label for={item.display + 'input'} check>{item.display}</Label>
                </FormGroup>
            );
        });

        setOptions(opt);
    }, [props.options, props.values]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        let target = e.target;
        const currentValue: any[] = [];

        if (!props.singleChoice) {
            if (props.values) {
                props.values.forEach((item) => {
                    currentValue.push(item);
                })
            }
        }

        if (target.checked) {
            currentValue.push(target.value);
        } else {
            const index = currentValue.indexOf(target.value, 0);
            if (index > -1) {
                currentValue.splice(index, 1);
            }
        }

        props.inputState(currentValue);
    }

    return (
        <FormGroup>
            {props.label ? <Label>{props.label}</Label> : ""}
            <span className="invalid-p">{invalidMsg}</span>
            <div id={props.id} className={props.isLimitHeight ? "scrollable-7h" : props.className} >
                {options}
            </div>
        </FormGroup>
    );
}

export default CheckBoxGroup;