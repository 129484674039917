import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { JackpotServiceListModel, JackpotServiceModel } from "../../../dto/models/ServiceModels";
import { ActionTypes } from "./JackpotServiceActions";
import { JackpotServiceActionTypes } from "./JackpotServiceActionTypes";
import { IJackpotServiceStates, InitJackpotServiceState } from "./JackpotServiceStates";

export const JackpotServiceReducers = (state = InitJackpotServiceState, action: ActionTypes): IJackpotServiceStates => {
    switch (action.type) {
        case JackpotServiceActionTypes.SELECT_JACKPOT_SERVICE_REQUEST:
        case JackpotServiceActionTypes.ADD_JACKPOT_SERVICE_REQUEST:
        case JackpotServiceActionTypes.EDIT_JACKPOT_SERVICE_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, jackpotServiceData: {} as JackpotServiceModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var jackpotServiceConfigModel: JackpotServiceModel = {} as JackpotServiceModel;

                if (data && !data.err) {
                    jackpotServiceConfigModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == JackpotServiceActionTypes.ADD_JACKPOT_SERVICE_REQUEST) {
                        sucMsg = "Jackpot Service Service Added Successfully.";
                    }
                    else if (action.type == JackpotServiceActionTypes.EDIT_JACKPOT_SERVICE_REQUEST) {
                        sucMsg = "Jackpot Service Service Updated Successfully.";
                    }
                }
                //  Set jackpotServiceState for IStore--> jackpotServiceState
                return { ...state, loading: false, jackpotServiceData: jackpotServiceConfigModel, err: errMsg, suc: sucMsg };
            }
        }
        case JackpotServiceActionTypes.GET_JACKPOT_SERVICE_REQUEST:
        case JackpotServiceActionTypes.SEARCH_JACKPOT_SERVICE_REQUEST:
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return {
                    ...state, loading: true, jackpotServiceListData: {} as JackpotServiceListModel, jackpotServiceData: {} as JackpotServiceModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var jackpotServiceConfigListModel: JackpotServiceListModel = {} as JackpotServiceListModel;

                if (data && !data.err) {
                    jackpotServiceConfigListModel = data.res;
                }
                //  Set jackpotServiceListState for IStore--> jackpotServiceListState
                return { ...state, loading: false, jackpotServiceListData: jackpotServiceConfigListModel, err: action.payload.err ? action.payload.err : "" };
            }
        default:
            return state
    }
}
