import { GameCreditCurrency } from "../../../dto/models/ConfigurationModels";
import { PASPoolAdjustmentModel, PASPoolModel } from "../../../dto/models/JackpotModels";
import { PylonResponseModel } from "../../../dto/models/PylonResponseModel";
import { ActionTypes } from "./PoolAdjustmentActions";
import { PoolAdjustmentActionTypes } from "./PoolAdjustmentActionTypes";
import { InitPoolAdjustmentState, IPoolAdjustmentState } from "./PoolAdjustmentStates";

export const PoolAdjustmentReducer = (state = InitPoolAdjustmentState, action: ActionTypes): IPoolAdjustmentState => {
    switch (action.type) {
        case PoolAdjustmentActionTypes.ADJUST_PAS_POOL_REQUEST:
        case PoolAdjustmentActionTypes.GET_PAS_POOL_INFO_REQUEST:
            {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, poolData: {} as PASPoolModel, poolListData: {} as PASPoolModel[], adjustmentData: {} as PASPoolAdjustmentModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var model: PASPoolModel | PASPoolAdjustmentModel = {} as PASPoolModel | PASPoolAdjustmentModel;

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == PoolAdjustmentActionTypes.ADJUST_PAS_POOL_REQUEST) {
                        sucMsg = "Pool Adjusted Successfully.";
                    }
                }

                if (data && !data.err) {
                    model = data.res;
                }

                if (action.type == PoolAdjustmentActionTypes.GET_PAS_POOL_INFO_REQUEST)
                    return { ...state, loading: false, poolData: model as PASPoolModel, err: errMsg, suc: sucMsg };
                else
                    return { ...state, loading: false, adjustmentData: model as PASPoolAdjustmentModel, err: errMsg, suc: sucMsg };
            }
        }
        case PoolAdjustmentActionTypes.GET_PAS_POOL_LIST_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, poolData: {} as PASPoolModel, poolListData: {} as PASPoolModel[], adjustmentData: {} as PASPoolAdjustmentModel, err: "", suc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var poolList: PASPoolModel[] = {} as PASPoolModel[];

                if (data && !data.err) {
                    poolList = data.res;
                }
                return { ...state, loading: false, poolListData: poolList, err: action.payload.err ? action.payload.err : "" };
            }
        }
        case PoolAdjustmentActionTypes.GET_CREDIT_RATE: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return state;
            }
            else {
                var data: PylonResponseModel = action.payload.data;
                var rate: GameCreditCurrency = {} as GameCreditCurrency;

                if (data && !data.err) {
                    rate = data.res;
                }
                return { ...state, creditRate: rate };
            }
        }
        default:
            return state
    }
}
