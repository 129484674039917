import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { AlertSize, AlertType } from '../../components/AlertMessage/IAlert';
import DataTable from '../../components/DataTable/DataTable';
import { IDataTable, TableStyle } from '../../components/DataTable/IDataTable';
import { ISelectOption } from '../../components/InputBox/ISelectBox';
import SelectBox from '../../components/InputBox/SelectBox';
import PoolAdjustmentModal from '../../components/_modals/PAS/PoolAdjustmentModal';
import { TierParameterType } from '../../dto/enums/Jackpot/JackpotTierCondition';
import { PASPoolModel } from '../../dto/models/JackpotModels';
import { CVT_TO_FLOAT_STRING } from '../../libraries/FloatHelper';
import { GetPASPoolInfo, GetPASPoolList } from '../../states/PAS/JackpotPoolAdjustment/PoolAdjustmentActions';
import { IStore } from '../../states/store/IStore';

const JackpotPoolAdjustment = () => {
    const [msg, setMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [poolSelectOptions, setPoolSelectOptions] = useState([] as ISelectOption[]);
    const [selectedPool, setSelectedPool] = useState<number | undefined>(undefined);
    const dispatch = useDispatch();
    const { err, suc, loading, poolData, poolListData } = useSelector((state: IStore) => state.poolAdjustmentState)
    const [currentPoolInfo, setCurrentPoolInfo] = useState({} as PASPoolModel);

    // adjustment modal state
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [poolID, setPoolID] = useState(0);
    const [distribution, setDistribution] = useState(TierParameterType.PROGRESSIVE);

    useEffect(() => {
            dispatch(GetPASPoolList());
    }, []);

    useEffect(() => {
        if (poolListData && poolListData.length) {
            const pools: ISelectOption[] = poolListData.map((item) => {
                return {
                    display: item.pnm,
                    value: item.pid.toString()
                }
            });

            setPoolSelectOptions(pools);
            if (pools.length != 0) {
                setSelectedPool(parseInt(pools[0].value));
                dispatch(GetPASPoolInfo(parseInt(pools[0].value)));
            }
        }
    }, [poolListData]);

    useEffect(() => {
        if (poolData && poolData.ptt !== undefined) {
            setCurrentPoolInfo(poolData)
        } else {
            setCurrentPoolInfo({} as PASPoolModel);
        }
    }, [poolData])

    useEffect(() => {
        setMsg(err);
        if (err !== undefined && err !== "") {
            setAlertOpen(true);
        }
    }, [err])

    useEffect(() => {
        setMsg(suc);
        if (suc !== undefined && suc !== "") {
            setAlertOpen(true);
        }
    }, [suc])

    useEffect(() => {
        if (selectedPool)
            dispatch(GetPASPoolInfo(selectedPool as number));
    }, [selectedPool])

    useEffect(() => {
        if (selectedPool && !openModal)
            dispatch(GetPASPoolInfo(selectedPool as number));
    }, [openModal])

    const AdjustPool = (id: number, distribution: TierParameterType): void => {
        // adjust the pool amount
        setPoolID(id);
        setDistribution(distribution);
        setOpenModal(true);
    }

    const infoTable = (): IDataTable => {
        var table: IDataTable = {
            title: ["Distribution", "Jackpot Ceilling (Credit)", "Total Credit", "View"],
            data: [],
            isStriped: true,
            isHover: false,
            tableStyle: TableStyle.ALL_BORDER,
            isloading: loading
        };

        if (!loading && currentPoolInfo && currentPoolInfo.ptt !== undefined) {
            table.data =
                [
                    ["Progessive", CVT_TO_FLOAT_STRING(currentPoolInfo.cil), CVT_TO_FLOAT_STRING(currentPoolInfo.pga), <Button onClick={() => AdjustPool(currentPoolInfo.pid, TierParameterType.PROGRESSIVE)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>],
                    ["House", "N/A", CVT_TO_FLOAT_STRING(currentPoolInfo.hsa), <Button onClick={() => AdjustPool(currentPoolInfo.pid, TierParameterType.HOUSE)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>],
                    ["Reserve", "N/A", CVT_TO_FLOAT_STRING(currentPoolInfo.rsa), <Button onClick={() => AdjustPool(currentPoolInfo.pid, TierParameterType.RESERVE)}><FontAwesomeIcon icon={faPenToSquare} /></ Button>]
                ];

        } else {
            table.data = []
        }

        return table;
    }

    return (
        <Container>
            <PoolAdjustmentModal setOpenState={setOpenModal} isOpen={openModal} poolID={poolID} tierType={distribution} />
            <hr />
            <Row>
                <Col>
                    <AlertMessage
                        content={msg}
                        alertType={AlertType.FAIL}
                        alertSize={AlertSize.LONG}
                        isOpen={alertOpen && err != undefined && err != ""}
                        openState={setAlertOpen}
                    />
                    <AlertMessage
                        content={msg}
                        alertType={AlertType.SUCCESS}
                        alertSize={AlertSize.LONG}
                        isOpen={alertOpen && suc != undefined && suc != ""}
                        openState={setAlertOpen}
                    />
                </Col>
            </Row>
            <div>
                <Row>
                    <SelectBox id="poolSelect" label="Jackpot Pool" name="poolSelect" options={poolSelectOptions} inputState={setSelectedPool} />
                </Row>
                <Row>
                    <div>
                        {DataTable(infoTable())}
                    </div>

                </Row>
            </div>
        </Container>
    )
};

export default JackpotPoolAdjustment;