import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { IDataTable, TableStyle } from "../../components/DataTable/IDataTable";
import DateTimeRangePicker from "../../components/DateTimePicker/DateTimeRangePicker";
import DropdownSelectBox from "../../components/InputBox/DropdownSelectBox";
import { IDropdownSelectOption } from "../../components/InputBox/IDropdownSelectBox";
import { TextboxType } from "../../components/InputBox/IInputBox";
import InputBox from "../../components/InputBox/InputBox";
import { ISelectOption } from "../../components/InputBox/ISelectBox";
import SelectBox from "../../components/InputBox/SelectBox";
import StandardReportContainer from "../../components/StandardReportContainer";
import { ITablePagination } from "../../components/TablePagination/ITablePagination";
import { ActionStatus } from "../../dto/enums/ActionStatus";
import { FORMAT_DATETIME_TO_VIEW } from "../../libraries/DateHelper";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import { TRIGGER_BLUR } from "../../libraries/Functions";
import { GROUP_TABLE_MAX_ROW, TABLE_MAX_PAGE, TABLE_MAX_ROW } from "../../libraries/Global";
import { GetManualSubmitOLRReportRequest, GenerateManualSubmitOLRPDFRequest, GenerateManualSubmitOLRCSVRequest } from "../../states/Reporting/ManualSubmitOLRReport/ManualSubmitOLRReportingActions";
import { IStore } from "../../states/store/IStore";


const ManualSubmitOLRReporting = () => {
    const dispatch = useDispatch();
    const manualSubmitOLRReportState = useSelector((state: IStore) => state.manualSubmitOLRReportState);
    const { manualSubmitOLRReportListData, loading, err, suc } = manualSubmitOLRReportState;

    const [currentPage, setPage] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    // Search Criteria
    const [fromCreateDate, setFromCreateDate] = useState("");
    const [toCreateDate, setToCreateDate] = useState("");
    const [toCreateDateValid, setToCreateDateValid] = useState(false);
    const [fromCreateDateValid, setFromCreateDateValid] = useState(false);
    const resolveStatus: ISelectOption[] = [
        {
            display: "ALL",
            value: "-1"
        },
        {
            display: "RESOLVED",
            value: "1"
        },
        {
            display: "NOT RESOLVED",
            value: "0"
        }
    ]
    const [status, setStatus] = useState("-1")

    const isSystemResolved: ISelectOption[] = [
        {
            display: "ALL",
            value: "-1"
        },
        {
            display: "TRUE",
            value: "1"
        },
        {
            display: "FALSE",
            value: "0"
        }
    ]
    const [systemResolve, setSystemResolve] = useState("-1")

    const emptyTable: IDataTable = {
        title: ["No.", "Transaction ID", "Created At", "Is Resolved", "Resolved At", "Is System Resolved", "Resolved By", "Remarks"],
        columnWidth: ["50px", "200px", "200px", "120px", "200px", "120px", "150px", "150px"],
        data: undefined,
        isStriped: false,
        isHover: false,
        tableStyle: TableStyle.ALL_BORDER,
        isloading: loading,
        responsive: true
    };

    const [reportTable, setReportTable] = useState(emptyTable)

    useEffect(() => {
        var table: IDataTable = emptyTable
        if (manualSubmitOLRReportListData && manualSubmitOLRReportListData.rml != undefined && hasSearched) {
            const dataList = manualSubmitOLRReportListData.rml;

            const startIndex = currentPage * TABLE_MAX_ROW;
            table.data = dataList.map((x, index) => {
                return [
                    startIndex + index + 1,
                    x.oti,
                    FORMAT_DATETIME_TO_VIEW(new Date(x.cra), "yyyy-MM-dd hh:mm:ssa"),
                    x.res.toString().toUpperCase(),
                    FORMAT_DATETIME_TO_VIEW(new Date(x.rsa), "yyyy-MM-dd hh:mm:ssa"),
                    x.sysr.toString().toUpperCase(),
                    x.rbn,
                    x.rmk]
            });
        } else
            table.data = [];

        setReportTable(table);

    }, [manualSubmitOLRReportListData]);


    const take = (page: number) => {
        if (valid()) {
            setPage(page);
            dispatch(GetManualSubmitOLRReportRequest(parseInt(status), parseInt(systemResolve), new Date(fromCreateDate.split("T")[0]), fromCreateDate.split("T")[1], new Date(toCreateDate.split("T")[0]), toCreateDate.split("T")[1], page * TABLE_MAX_PAGE, TABLE_MAX_PAGE));
        }
    }

    const valid = (): boolean => {
        return (fromCreateDate !== "" && fromCreateDateValid) && (toCreateDate !== "" && toCreateDateValid);
    }

    const pagination: ITablePagination = {
        id: "manualSubmitOLRReportListPagination",
        maxPage: 8,
        onChangePage: take,
        totalCount: hasSearched ? manualSubmitOLRReportListData.ttc ?? 0 : 0,
        currentPage: currentPage,
    }

    const search: MouseEventHandler = () => {
        if (valid()) {
            setHasSearched(true);
            setPage(0);
            dispatch(GetManualSubmitOLRReportRequest(parseInt(status), parseInt(systemResolve), new Date(fromCreateDate.split("T")[0]), fromCreateDate.split("T")[1], new Date(toCreateDate.split("T")[0]), toCreateDate.split("T")[1], 0 * TABLE_MAX_PAGE, TABLE_MAX_PAGE));
        } else {
            TRIGGER_BLUR("fromCreateDate");
            TRIGGER_BLUR("toCreateDate");
            TRIGGER_BLUR("resolveStatus");
            TRIGGER_BLUR("isSystemResolve");
        }
    }

    const generatePDF = () => {
        if (valid()) {
            dispatch(GenerateManualSubmitOLRPDFRequest(parseInt(status), parseInt(systemResolve), new Date(fromCreateDate.split("T")[0]), fromCreateDate.split("T")[1], new Date(toCreateDate.split("T")[0]), toCreateDate.split("T")[1], 0 * TABLE_MAX_PAGE, TABLE_MAX_PAGE))
        }
    }

    const generateCSV = () => {
        if (valid()) {
            dispatch(GenerateManualSubmitOLRCSVRequest(parseInt(status), parseInt(systemResolve), new Date(fromCreateDate.split("T")[0]), fromCreateDate.split("T")[1], new Date(toCreateDate.split("T")[0]), toCreateDate.split("T")[1], 0 * TABLE_MAX_PAGE, TABLE_MAX_PAGE))
        }
    }

    const SearchCriteriaContainer = () => {
        return (
            <div>
                <Form className="pt-2">
                    <Row>
                        <DateTimeRangePicker isRequired label="Create Date" fromDateinputState={setFromCreateDate} fromDatevalidState={setFromCreateDateValid}
                            toDateinputState={setToCreateDate} toDatevalidState={setToCreateDateValid} fromDatePickerID="fromCreateDate" toDatePickerID="toCreateDate" />
                    </Row>
                    <Row>
                        <Col>
                            <SelectBox id="resolveStatus" name="resolveStatus" label="Status"
                                options={resolveStatus} value={status}
                                inputState={(e) => { setStatus(e) }}
                                isRequired={true}
                            />
                        </Col>
                        <Col>
                            <SelectBox id="isSystemResolve" name="isSystemResolve" label="System Resolve"
                                options={isSystemResolved} value={systemResolve}
                                inputState={(e) => { setSystemResolve(e) }}
                                isRequired={true}
                                isDisabled={status == "0"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary" outline onClick={search}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <StandardReportContainer
            id="ManualSubmitOLRReporting"
            error={hasSearched ? err : ""}
            success={hasSearched ? suc : ""}
            loading={loading}
            tablePagination={pagination}
            tableProps={reportTable}
            searchCriteriaContent={SearchCriteriaContainer()}
            generateCSVEvent={generateCSV}
            generatePDFEvent={generatePDF}
        />

    )
}


export default ManualSubmitOLRReporting;