import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Tooltip } from "reactstrap";
import { CVT_TO_FLOAT_STRING } from "../../libraries/FloatHelper";
import '../../styles/ToolTip.css';
import { IInputBox, TextboxType } from "./IInputBox";

const InputBox = (props: IInputBox) => {
    const [invalidMsg, setInvalidMsg] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [isFocus, setFocus] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const ConvertToDecimal = (text: string): string => {
        if (text && props.type == TextboxType.CURRENCY && !isNaN(parseInt(text))) {
            return CVT_TO_FLOAT_STRING(parseFloat(text.replace(/,/g, '')));
        }

        return text;
    }

    const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
        var text: string = e.target.value ?? "";

        if (props.inputState) {
            if ((props.type == TextboxType.NUMBER || props.type == TextboxType.CURRENCY) && text === "")
                props.inputState(undefined);
            else {
                if (props.type == TextboxType.NUMBER && props.integer) {
                    text = (Math.round(text as unknown as number)).toString();
                }
                props.inputState(text);
            }
        }

        setInvalidMsg("");
        if (props.validState) {
            props.validState(true);
            setInvalidMsg("");

            if (props.isRequired) {
                if (text == "") {
                    props.validState(false);
                    setInvalidMsg(props.invalidMessage ?? "This field is required.");
                }
            }

            if (text != "") {
                switch (props.type) {
                    case TextboxType.EMAIL: //email
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(text))
                            if (props.validState) {
                                props.validState(false);
                                setInvalidMsg(props.invalidMessage ?? "The email is invalid.");
                            }
                        break;
                    case TextboxType.URL: //  url
                        const urlRegex = /^(ftp|http|https):\/\/(?:\d{1,3}\.){3}\d{1,3}(?::\d{1,5})?(?:\/\S*)?$|^(ftp|http|https):\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?::\d{1,5})?(?:\/\S*)?$/;
                        if (!urlRegex.test(text))
                            if (props.validState) {
                                props.validState(false);
                                setInvalidMsg(props.invalidMessage ?? "The URL is not valid");
                            }
                        break;
                    case TextboxType.IP_ADDRESS: // ip
                        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})){3}$/;
                        if (!ipRegex.test(text))
                            if (props.validState) {
                                props.validState(false);
                                setInvalidMsg(props.invalidMessage ?? "The IP address is not valid");
                            }
                        break;
                    case TextboxType.MAC_ADDRESS: // mac address
                        const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
                        if (!macRegex.test(text)) {
                            setInvalidMsg(props.invalidMessage ?? "The MAC address is not valid");
                            props.validState(false);

                        }
                        break;
                    case TextboxType.NUMBER:
                        const number = text as unknown as number
                        if (props.max && number > props.max) {
                            setInvalidMsg(props.invalidMessage ?? "Number cannot be bigger than " + props.max + "");
                            props.validState(false);
                        }
                        else if (props.min && number < props.min) {
                            setInvalidMsg(props.invalidMessage ?? "Number cannot be smaller than " + props.min + "");
                            props.validState(false);
                        }
                        break;
                    case TextboxType.TIME:
                        if (props.isRequired && text == "") {
                            setInvalidMsg(props.invalidMessage ?? "Please Select a time");
                            props.validState(false);
                        }
                        break;
                    case TextboxType.DATE:
                        if (props.isRequired && text == "") {
                            setInvalidMsg(props.invalidMessage ?? "Please Select a date");
                            props.validState(false);
                        }
                        break;
                    case TextboxType.CURRENCY:
                        const money = parseInt(text);
                        if (isNaN(money)) {
                            setInvalidMsg(props.invalidMessage ?? "Invalid number");
                            props.validState(false);
                        } else {
                            if (props.max && money > props.max) {
                                setInvalidMsg(props.invalidMessage ?? "Amount cannot be bigger than " + props.max + "");
                                props.validState(false);
                            }
                            else if (props.min && money < props.min) {
                                setInvalidMsg(props.invalidMessage ?? "Amount cannot be smaller than " + props.min + "");
                                props.validState(false);
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const getInputType = (): any => {
        switch (props.type) {
            case TextboxType.MAC_ADDRESS:
            case TextboxType.IP_ADDRESS:
                return "";
            case TextboxType.CURRENCY:
                return "text";
            default:
                return props.type as any
        }
    }

    const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
        onChangeEvent(e);
        setFocus(false);
    }

    const onFocusEvent = (e: FocusEvent<HTMLInputElement>) => {
        setFocus(true);
    }

    const clearValue = () => {
        setInputValue("");
        if (props.inputState) {
            const input: HTMLInputElement = document.getElementById(props.id) as HTMLInputElement;
            input.value = "";
            props.inputState("");
        }
    }

    useEffect(() => {
        setInputValue(props.value == undefined ? "" : props.value)
    }, [props.value])

    return (
        <FormGroup>
            <Row>
                <Col>
                    {props.label ? <Label for={props.id}>{props.label}</Label> : ""}
                    {props.tips && props.tipsID ? <Button id={props.tipsID} type="button" className={"btn-sm btn-circle btnTooltip"}><FontAwesomeIcon className={"fa-xs btnIcon"} icon={faInfo} /></Button> : ""}
                    {props.tips && props.tipsID ? <Tooltip
                        placement={'right'}
                        isOpen={tooltipOpen}
                        target={props.tipsID}
                        style={{ maxWidth: '300px' }}
                        toggle={toggle}
                    ><p className={"tipsContent"}>{props.tips}</p></Tooltip> : ""}
                </Col>
            </Row>
            <InputGroup>
                <Col>
                    <Input
                        type={getInputType()}
                        id={props.id}
                        name={props.name}
                        className={props.className}
                        placeholder={props.placeholder}
                        maxLength={props.maxlength}
                        min={props.min}
                        max={props.max}
                        disabled={props.isDisabled}
                        required={props.isRequired}
                        invalid={invalidMsg != ""}
                        onChange={onChangeEvent}
                        onBlur={onBlurEvent}
                        value={inputValue}
                        onKeyPress={props.onKeyPress ?? undefined}
                        onBlurCapture={e => e.currentTarget.value = ConvertToDecimal(e.currentTarget.value)}
                        step={props.type == TextboxType.CURRENCY ? "0.01" : undefined}
                        onFocus={onFocusEvent}
                    />
                    <FormFeedback>{invalidMsg}</FormFeedback>
                </Col>
                <Col className="col-auto">
                    {props.type != TextboxType.DATE && props.type != TextboxType.DATE_TIME && props.type != TextboxType.TIME ?
                        props.HasCancelledButton && props.value !== undefined && props.value !== "" /*&& isFocus*/ ? <Button onClick={clearValue} color="link" ><FontAwesomeIcon icon={faTimes} /></Button> : ""
                        :
                        //if the type is not time or date, show the cross button as long as it has content regardless if it is focused
                        props.HasCancelledButton && props.value !== undefined && props.value !== "" ? <Button onClick={clearValue} color="link"><FontAwesomeIcon icon={faTimes} /></Button> : ""
                    }
                </Col>
            </InputGroup>
        </FormGroup>
    );
}

export default InputBox;