import { RequestItemModel, RequestListModel } from "../../dto/models/GeneralModels";
import { PostRequest, RequestException } from "../../libraries/RequestHelper";
import { IPayload, LoadingPayload } from "../store/IStore";
import { ManualSubmitOLRActionTypes } from "./ManualSubmitOLRActionTypes";
import { ResolveManualOLRRequestModel } from "../../dto/models/ManualOLRModel";

type ResolveManualSubmitOLRRequest = { type: ManualSubmitOLRActionTypes.RESOLVE_MANUAL_SUBMIT_OLR_REQUEST, payload: IPayload };
type GetUnresolvedManualSubmitOLRRequest = { type: ManualSubmitOLRActionTypes.GET_UNRESOLVED_MANUAL_SUBMIT_OLR_REQUEST, payload: IPayload };
type GetResolvedManualSubmitOLRRequest = { type: ManualSubmitOLRActionTypes.GET_RESOLVED_MANUAL_SUBMIT_OLR_REQUEST, payload: IPayload };
type SelectManualSubmitOLRRequest = { type: ManualSubmitOLRActionTypes.SELECT_MANUAL_SUBMIT_OLR_REQUEST, payload: IPayload };

export type ActionTypes =
    ResolveManualSubmitOLRRequest | GetUnresolvedManualSubmitOLRRequest | GetResolvedManualSubmitOLRRequest | SelectManualSubmitOLRRequest;

export const ResolveManualSubmitOLR = (id: number, user: number, name:string, remarks: string, ) => async (dispatch: any) => {

    try {
        let request: ResolveManualOLRRequestModel = {
            oid: id,
            isrs: true,
            rsby: user,
            rsbyn: name,
            rmk: remarks
        };
        PostRequest("Cashier/ResolveManualSubmitOLR", request, ManualSubmitOLRActionTypes.RESOLVE_MANUAL_SUBMIT_OLR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualSubmitOLRActionTypes.RESOLVE_MANUAL_SUBMIT_OLR_REQUEST, dispatch);
    }

}

export const GetUnresolvedManualSubmitOLR = (from: number, takeCount: number) => async (dispatch: any) => {
    try {
        let request: RequestListModel = {
            frm: from,
            tak: takeCount
        };
        PostRequest("Cashier/GetUnresolvedManualSubmitOLR", request, ManualSubmitOLRActionTypes.GET_UNRESOLVED_MANUAL_SUBMIT_OLR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualSubmitOLRActionTypes.GET_UNRESOLVED_MANUAL_SUBMIT_OLR_REQUEST, dispatch);
    }
}

export const SelectManualSubmitOLR = (oid: number) => async (dispatch: any) => {
    try {
        let request: RequestItemModel = {
            id: oid
        };
        PostRequest("Cashier/SelectManualSubmitOLR", request, ManualSubmitOLRActionTypes.SELECT_MANUAL_SUBMIT_OLR_REQUEST, dispatch);
    }
    catch (error) {
        RequestException(error, ManualSubmitOLRActionTypes.SELECT_MANUAL_SUBMIT_OLR_REQUEST, dispatch);
    }
}