export enum DefaultLanguage {
    English = 1,
    Simplified_Chinese = 2,
    Traditional_Chinese = 3,
}

export const DefaultLanguageName: { [id: number]: string; } = {
    1: "English",
    2: "Simplified Chinese",
    3: "Traditional Chinese",
}

export function GetLanguageName(lang: DefaultLanguage): string {
    let id: number = lang;
    return DefaultLanguageName[id];
}