import { useSelector } from "react-redux";
import { PylonResponseModel } from "../../dto/models/PylonResponseModel";
import { UserProfileListModel, UserProfileModel } from "../../dto/models/UserAccessModels";
import * as Global from "../../libraries/Global";
import { ActionTypes } from "./UserActions";
import { UserActionTypes } from "./UserActionTypes";
import { InitUserState, IUserState } from "./UserStates";

export const userReducer = (state = InitUserState, action: ActionTypes): IUserState => {
    switch (action.type) {
        case UserActionTypes.LOGOUT_REQUEST: {
            return { ...state, loading: false, userProfileData: {} as UserProfileModel, userProfileErr: "", userProfileSuc: "", userProfileWarning: "", isLogin: false, isSessionTimeout: false };
        }
        case UserActionTypes.LOGIN_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, userProfileData: {} as UserProfileModel, userProfileErr: "", userProfileSuc: "", userProfileWarning: "", isLogin: false, isSessionTimeout: false };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var userProfile: UserProfileModel = {} as UserProfileModel;
                var warningMsg: string = "";
                if (data && !data.err) {
                    userProfile = data.res;
                    localStorage.setItem(Global.TOKEN_CONST, userProfile.ses);
                    localStorage.setItem(Global.UID_CONST, userProfile.uid.toString());
                    Global.SET_PROFILE(userProfile);

                    if (userProfile.fcp)
                        warningMsg = "You are required to change your password during first login / after resetting password";
                }


                //  Set userState for IStore--> userState
                return { ...state, loading: false, userProfileData: userProfile, userProfileWarning: warningMsg, userProfileErr: action.payload.err ? action.payload.err : "", isLogin: !action.payload.err };
            }
        }
        case UserActionTypes.GET_PROFILE_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                //  Set loading states
                return { ...state, loading: true, userProfileData: {} as UserProfileModel, userProfileWarning: "", userProfileErr: "", userProfileSuc: "", isLogin: false };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var userProfile: UserProfileModel = {} as UserProfileModel;

                var warningMsg: string = "";
                if (data && !data.err) {
                    userProfile = data.res;
                    localStorage.setItem(Global.UID_CONST, userProfile.uid.toString());
                    Global.SET_PROFILE(userProfile);

                    if (userProfile.fcp)
                        warningMsg = "You are required to change your password during first login / after resetting password";
                } else {
                    localStorage.removeItem(Global.TOKEN_CONST);
                    localStorage.removeItem(Global.UID_CONST);
                }

                //  Set userState for IStore--> userState
                return { ...state, loading: false, userProfileData: userProfile, userProfileWarning: warningMsg, userProfileErr: action.payload.err ? action.payload.err : "", isLogin: !action.payload.err };
            }
        }
        case UserActionTypes.EDIT_PROFILE_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return { ...state, loading: true, userProfileErr: "", userProfileSuc: "", };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var userProfile: UserProfileModel = data.res;
                const existingProfileState: UserProfileModel = Global.GET_PROFILE();

                existingProfileState.cno = userProfile.cno;
                existingProfileState.eml = userProfile.eml;

                var errMsg: string = "";
                var sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    if (userProfile != undefined && userProfile.cno != undefined) {
                        sucMsg = "Profile Updated Successfully";
                    }
                }

                return { ...state, loading: false, userProfileData: existingProfileState, userProfileErr: errMsg, userProfileSuc: sucMsg };
            }
        }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return { ...state, loading: true, userProfileErr: "", userProfileSuc: "", };
            }
            else {
                var errMsg: string = "";
                var sucMsg: string = "";
                var isLogin: boolean = true;
                if (action.payload.err) {
                    errMsg = action.payload.err;
                } else {
                    sucMsg = "Password Changed Sucessfully";
                    isLogin = false;
                }

                return { ...state, loading: false, userProfileErr: errMsg, userProfileSuc: sucMsg, isLogin: isLogin };
            }
        }
        case UserActionTypes.SESSION_TIMEOUT: {
            return { ...state, loading: false, isSessionTimeout: true };
        }
        case UserActionTypes.SELECT_USER_REQUEST:
        case UserActionTypes.ADD_USER_REQUEST:
        case UserActionTypes.EDIT_USER_REQUEST: {
            var isLoading = action.payload.loading;
            if (isLoading) {
                return {
                    ...state, loading: true, userData: {} as UserProfileModel, userDataList: {} as UserProfileListModel, userDataErr: "", userDataSuc: ""
                };
            }
            else {
                var data: PylonResponseModel = action.payload.data
                var profileModel: UserProfileModel = {} as UserProfileModel;

                if (data && !data.err) {
                    profileModel = data.res;
                }

                let errMsg: string = "";
                let sucMsg: string = "";

                if (action.payload.err) {
                    errMsg = action.payload.err;
                }
                else {
                    if (action.type == UserActionTypes.ADD_USER_REQUEST) {
                        sucMsg = "User Added Successfully.";
                    }
                    else if (action.type == UserActionTypes.EDIT_USER_REQUEST) {
                        sucMsg = "User Updated Successfully.";
                    }
                }
                return { ...state, loading: false, userData: profileModel, userDataErr: errMsg, userDataSuc: sucMsg };
            }
        }
        case UserActionTypes.GET_USER_LIST_REQUEST:
        case UserActionTypes.SEARCH_USER_REQUEST:
            {
                var isLoading = action.payload.loading;
                if (isLoading) {
                    return {
                        ...state, loading: true, userData: {} as UserProfileModel, userDataList: {} as UserProfileListModel, userDataErr: "", userDataSuc: ""
                    };
                }
                else {
                    var data: PylonResponseModel = action.payload.data
                    var profileListModel: UserProfileListModel = {} as UserProfileListModel;

                    if (data && !data.err) {
                        profileListModel = data.res;
                    }
                    return { ...state, loading: false, userDataList: profileListModel, userDataErr: action.payload.err ? action.payload.err : "" };
                }
            }
        case UserActionTypes.RESET_PASSWORD_REQUEST:
            {
                var isLoading = action.payload.loading;
                if (isLoading) {
                    return {
                        ...state, loading: true, userDataErr: "", userDataSuc: ""
                    };
                }
                else {
                    let errMsg: string = "";
                    let sucMsg: string = "";

                    if (action.payload.err)
                        errMsg = action.payload.err;
                    else 
                        sucMsg = "Reset Password Successfully.";

                    return { ...state, loading: false, userDataSuc: sucMsg, userDataErr: errMsg };
                }
            }
        default:
            return state
    }
}
