import { ManualCreditTransactionReportCSVModel, ManualCreditTransactionReportListModel, ManualCreditTransactionReportPDFModel } from "../../../dto/models/Reporting/ManualCreditTransactionReportModel";

export interface IManualCreditTransactionReportState {
    loading: boolean;
    err: string;
    suc: string;
    manualCreditTransactionReportListData: ManualCreditTransactionReportListModel;
    pdfData: ManualCreditTransactionReportPDFModel;
    csvData: ManualCreditTransactionReportCSVModel;
}

export const InitManualCreditTransactionReportState: IManualCreditTransactionReportState = {
    loading: false,
    err: "",
    suc: "",
    manualCreditTransactionReportListData: {} as ManualCreditTransactionReportListModel,
    pdfData: {} as ManualCreditTransactionReportPDFModel,
    csvData: {} as ManualCreditTransactionReportCSVModel,
}